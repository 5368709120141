import {
  GET_PHOTOREQ,
  GET_PHOTOREQ_SUCCESS,
  GET_PHOTOREQ_FAILURE,
  GET_PHOTOREQ_ALL,
  GET_PHOTOREQ_ALL_SUCCESS,
  GET_PHOTOREQ_ALL_FAILURE,
  SEND_PHOTOREQ,
  SEND_PHOTOREQ_SUCCESS,
  SEND_PHOTOREQ_FAILURE,
  UPDATE_PHOTOREQ,
  UPDATE_PHOTOREQ_SUCCESS,
  UPDATE_PHOTOREQ_FAILURE,
} from "../constants";

const initialState = {
  photoreq: [],
  photoreqCount: 0,
  photoreqAll: [],
  photoreqAllCount: 0,
  pendingPhotoReqCount: 0,
  isLoading: false,
  error: null,
};

export default function PhotoreqReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PHOTOREQ:
    case GET_PHOTOREQ_ALL:
    case SEND_PHOTOREQ:
    case UPDATE_PHOTOREQ:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_PHOTOREQ_SUCCESS:
      state = {
        ...state,
        photoreq: action.payload.data,
        photoreqCount: action.payload.count,
        pendingPhotoReqCount:
          action.payload.pendingCount ?? state?.pendingPhotoReqCount,
        isLoading: false,
      };
      break;
    case GET_PHOTOREQ_ALL_SUCCESS:
      state = {
        ...state,
        photoreqAll: action.payload.data,
        photoreqAllCount: action.payload.count,
        isLoading: false,
      };
      break;
    case SEND_PHOTOREQ_SUCCESS:
    case UPDATE_PHOTOREQ_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;

    case GET_PHOTOREQ_FAILURE:
    case GET_PHOTOREQ_ALL_FAILURE:
    case SEND_PHOTOREQ_FAILURE:
    case UPDATE_PHOTOREQ_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }

  return state;
}
