import React, { useState } from "react";
import Spacer from "../../components/spacer/spacer";
import { PrimaryButton } from "../../components/button";
import { TextInput } from "../../components/inputbox";

import images from "../../assets";

import "./styles.css";

const ResetForm = (props) => {
  const [state, setState] = useState({
    isNewPassword: true,
    isConfirmPassword: true,
  });

  return (
    <>
      <img src={images?.changePassword} alt="" />
      <Spacer height="32px" />
      <h2 className="forgot-password-title">Reset your password.</h2>
      <Spacer height="32px" />
      <div className="relative w-full">
        <div>
          <TextInput
            className="password-input"
            type={state?.isNewPassword ? "password" : "text"}
            placeholder="Enter new password"
            onChange={(e) =>
              props?.setState({ ...props?.state, password: e.target.value })
            }
          />
          <img
            src={state?.isNewPassword ? images.eyeIcon : images.eyeOfIcon}
            className="h-5 absolute top-3 right-4"
            onClick={() =>
              setState({ ...state, isNewPassword: !state.isNewPassword })
            }
            alt="see"
          />
        </div>
      </div>
      <Spacer height="32px" />
      <div className="relative w-full">
        <div>
          <TextInput
            className="password-input"
            type={state?.isConfirmPassword ? "password" : "text"}
            placeholder="Confirm new password"
            onChange={(e) =>
              props?.setState({
                ...props?.state,
                confirmPassword: e.target.value,
              })
            }
          />
          <img
            src={state?.isConfirmPassword ? images.eyeIcon : images.eyeOfIcon}
            className="h-5 absolute top-3 right-4"
            onClick={() =>
              setState({
                ...state,
                isConfirmPassword: !state.isConfirmPassword,
              })
            }
            alt="see"
          />
        </div>
      </div>
      {props?.state?.error && (
        <div className="text-center">
          <p className="text-danger font-semibold text-[14px] m-0">
            {props?.state?.error}
          </p>
        </div>
      )}
      <Spacer height="32px" />
      <PrimaryButton text={"Submit"} onClick={props?.handleResetSubmit} />
    </>
  );
};

export default ResetForm;
