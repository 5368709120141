import {
  CHANGE_REQ,
  CHANGE_REQ_SUCCESS,
  CHANGE_REQ_FAILURE,
  CHANGE_PROFILE_STATUS,
  CHANGE_PROFILE_STATUS_SUCCESS,
  CHANGE_PROFILE_STATUS_FAILURE,
  DELETE_PHOTO,
  DELETE_PHOTO_SUCCESS,
  DELETE_PHOTO_FAILURE,
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  GET_TRUST_BADGE,
  GET_TRUST_BADGE_SUCCESS,
  GET_TRUST_BADGE_FAILURE,
  DELETE_TRUST_BADGE,
  DELETE_TRUST_BADGE_SUCCESS,
  DELETE_TRUST_BADGE_FAILURE,
  REMOVE_PROFILE_STORE,
  REMOVE_PROFILE_STORE_SUCCESS,
  REMOVE_PROFILE_STORE_FAILURE,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_PIC,
  UPDATE_PROFILE_PIC_SUCCESS,
  UPDATE_PROFILE_PIC_FAILURE,
  UPLOAD_PHOTO,
  UPLOAD_PHOTO_SUCCESS,
  UPLOAD_PHOTO_FAILURE,
  UPLOAD_TRUST_BADGE,
  UPLOAD_TRUST_BADGE_SUCCESS,
  UPLOAD_TRUST_BADGE_FAILURE,
} from "../constants";

const initialState = {
  profile: {},
  trustbadge: [],
  isLoading: false,
  isChangeLoading: false,
  isPhotoLoading: false,
  isTrustbadgeLoading: false,
  error: null,
};

export default function ProfileReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROFILE:
    case UPDATE_PROFILE:
    case DELETE_PHOTO:
    case UPDATE_PROFILE_PIC:
    case UPLOAD_TRUST_BADGE:
    case CHANGE_PROFILE_STATUS:
    case REMOVE_PROFILE_STORE:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case CHANGE_REQ:
      state = {
        ...state,
        isChangeLoading: true,
        error: null,
      };
      break;
    case UPLOAD_PHOTO:
      state = {
        ...state,
        isPhotoLoading: true,
        error: null,
      };
      break;
    case GET_TRUST_BADGE:
    case DELETE_TRUST_BADGE:
      state = {
        ...state,
        isTrustbadgeLoading: false,
      };
      break;
    case GET_PROFILE_SUCCESS:
      state = {
        ...state,
        profile: action.payload,
        isLoading: false,
      };
      break;
    case CHANGE_REQ_SUCCESS:
      state = {
        ...state,
        isChangeLoading: false,
      };
      break;
    case REMOVE_PROFILE_STORE_SUCCESS:
      state = {
        ...state,
        profile: {},
        isLoading: false,
      };
      break;
    case GET_TRUST_BADGE_SUCCESS:
      state = {
        ...state,
        trustbadge: action.payload,
        isTrustbadgeLoading: false,
      };
      break;
    case DELETE_TRUST_BADGE_SUCCESS:
      state = {
        ...state,
        isTrustbadgeLoading: false,
      };
      break;
    case UPDATE_PROFILE_SUCCESS:
    case DELETE_PHOTO_SUCCESS:
    case UPDATE_PROFILE_PIC_SUCCESS:
    case UPLOAD_TRUST_BADGE_SUCCESS:
    case CHANGE_PROFILE_STATUS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case UPLOAD_PHOTO_SUCCESS:
      state = {
        ...state,
        isPhotoLoading: false,
      };
      break;

    case GET_PROFILE_FAILURE:
    case UPDATE_PROFILE_FAILURE:
    case DELETE_PHOTO_FAILURE:
    case UPDATE_PROFILE_PIC_FAILURE:
    case UPLOAD_TRUST_BADGE_FAILURE:
    case REMOVE_PROFILE_STORE_FAILURE:
    case CHANGE_PROFILE_STATUS_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    case CHANGE_REQ_FAILURE:
      state = {
        ...state,
        isChangeLoading: false,
        error: action.payload,
      };
      break;
    case UPLOAD_PHOTO_FAILURE:
      state = {
        ...state,
        isPhotoLoading: false,
        error: action.payload,
      };
      break;
    case GET_TRUST_BADGE_FAILURE:
    case DELETE_TRUST_BADGE_FAILURE:
      state = {
        ...state,
        isTrustbadgeLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }

  return state;
}
