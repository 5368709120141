import images from "../../../../assets";
export default function UpgradeButton(props) {
  return (
    <div className="upgrade-button">
      <div {...props} className={`${props.className} membership-bg`}>
        <div className="membership-icon-text">
          <img
            className="premium-membership"
            src={images.premiumIcon}
            alt="Premium Membership"
          />
          <p className="upgrade-text">{props.title}</p>
        </div>
      </div>
      <div className="membership-disscunt">
        <p className="tweenty-off">{props.disscunt}</p>
      </div>
    </div>
  );
}
