import { put } from "redux-saga/effects";
import { message } from "antd";

import ActivityApiCaller from "../../config/api/activityapicaller";

import { ProfileViewerAction } from "../actions";

export default class ProfileViewerMiddleware {
  static *getProfileViewer({ payload, cb }) {
    try {
      const res = yield ActivityApiCaller.Get(
        `/activity/profile-view/viewed?from=${payload.from ?? ""}&size=${
          payload.size ?? ""
        }&sort=${payload.sort ?? ""}`
      );
      if (res?.status === 200) {
        yield put(
          ProfileViewerAction.getProfileViewerSuccess({
            count: res.data?.count,
            data: res.data?.data,
          })
        );
      } else {
        yield put(ProfileViewerAction.getProfileViewerFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      console.log(err);
      yield put(ProfileViewerAction.getProfileViewerFailure());
    }
  }
  static *getAllProfileViewer({ payload, cb }) {
    try {
      const res = yield ActivityApiCaller.Get(`/activity/profile-view/viewer`);

      if (res?.status === 200) {
        yield put(
          ProfileViewerAction.getAllProfileViewerSuccess({
            count: res.data?.count,
            data: res.data?.data,
          })
        );
      } else {
        yield put(ProfileViewerAction.getAllProfileViewerFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      console.log(err);
      yield put(ProfileViewerAction.getAllProfileViewerFailure());
    }
  }
  static *getProfileViewed({ payload, cb }) {
    try {
      const res = yield ActivityApiCaller.Get(
        `/activity/profile-view/viewer?from=${payload.from ?? ""}&size=${
          payload.size ?? ""
        }&sort=${payload.sort ?? ""}`
      );

      if (res?.status === 200) {
        yield put(
          ProfileViewerAction.getProfileViewedSuccess({
            count: res.data?.count,
            data: res.data?.data,
          })
        );
      } else {
        yield put(ProfileViewerAction.getProfileViewedFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      console.log(err);
      yield put(ProfileViewerAction.getProfileViewedFailure());
    }
  }
  static *setProfileView({ payload, cb }) {
    try {
      const res = yield ActivityApiCaller.Post(
        `/activity/profile-view`,
        payload
      );

      if (res.status === 200) {
        yield put(ProfileViewerAction.setProfileViewSuccess(res.data.Data));
      } else {
        yield put(ProfileViewerAction.setProfileViewFailure());
      }
      if (cb) {
        cb(res.data.Data);
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
      yield put(ProfileViewerAction.setProfileViewFailure());
    }
  }
}
