import React from "react";
import { Route, Routes } from "react-router-dom";

import { PROTECTED_ROUTES } from "./sitemap";

import { DashboardLayout } from "../../components/layout";
import MessagesLayout from "../../containers/messages/messageslayout";
import { ExportPDF } from "../../containers";

const AppRoutes = () => {
  return (
    <Routes>
      {PROTECTED_ROUTES.map((route, index) => {
        return (
          <Route
            element={
              route.path === "/messages" ? (
                <MessagesLayout />
              ) : route.path === "/print-profile" ? (
                <ExportPDF />
              ) : (
                <DashboardLayout />
              )
            }
            key={index}
          >
            <Route
              path={route.path}
              element={route.component}
              exact={route.exact}
            />
          </Route>
        );
      })}
    </Routes>
  );
};

export default AppRoutes;
