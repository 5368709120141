export default function NeedHelpColumn(props) {
  return (
    <div {...props} className="need-help-content mb-5">
      <div className="need-help-left-text">{props.titlename}</div>
      <div className="need-help-right-text flex items-center">
        : {props.values}
      </div>
    </div>
  );
}
