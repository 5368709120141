import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { TawkProvider } from "../../TawkContext";

import { AuthAction } from "../../store/actions";

import AppRoutes from "./approutes";
import AuthRoutes from "./authroutes";

const AllRoutes = () => {
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn || false);

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get("token");
    const redirect = searchParams.get("redirect");
    if (redirect) {
      localStorage.setItem(
        "authUser",
        JSON.stringify({
          AccessToken: token,
        })
      );
      dispatch(
        AuthAction.SignInSuccess({
          AccessToken: token,
        })
      );
    }
  }, []);

  if (isLoggedIn) {
    return (
      <TawkProvider>
        <AppRoutes />
      </TawkProvider>
    );
  } else {
    return (
      <TawkProvider>
        <AuthRoutes />
      </TawkProvider>
    );
  }
};

export default AllRoutes;
