import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Input, message } from "antd";

import { ProfileAction, MatchAction } from "../../store/actions";

import EditProfileBoxLabel from "../../components/label/editprofileboxlabel";
import PrimaryButton from "../../components/button/primarybutton";
import ApprovedButton from "../../components/button/approvedbutton";
import Spacer from "../../components/spacer/spacer";
import Loader from "../../components/loader/loader";

import validateDescription from "../../utils/validate-description";

import images from "../../assets";

import TabHeading from "./tabheading";
import "./styles.css";
const { TextArea } = Input;

const Description = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profileLoading = useSelector((state) => state?.profile?.isLoading);
  const matchLoading = useSelector((state) => state?.matches?.isLoading);
  const profileData = useSelector((state) => state?.profile?.profile);

  const [isDisabled, setDisabled] = useState(false);

  const [idealMatchDescription, setIdealMatchDescription] = useState("");

  useEffect(() => {
    setIdealMatchDescription(
      profileData?.matchPreference?.idealMatchDescription
    );
  }, [profileLoading]);

  const handleSubmit = () => {
    if (!validateDescription(idealMatchDescription)) {
      message.error(
        "Invalid input. Please avoid phone numbers, email formats, addresses, and numeric patterns."
      );
      return;
    }

    setDisabled(true);
    const payload = {
      username: profileData?.username,
      matchPreference: {
        ...profileData?.matchPreference,
        idealMatchDescription,
      },
    };
    dispatch(
      MatchAction.updateMatchPreference(payload, () => {
        setDisabled(false);
        dispatch(ProfileAction.getProfile());
        navigate("/trust-badge");
      })
    );
  };

  return (
    <>
      <TabHeading
        image={images.editProfileDescription}
        title="Ideal Match Description"
        subtitle="Tell us a little about your preferences."
      />
      <div className="edit-profile-content">
        {profileLoading || matchLoading ? (
          <Loader />
        ) : (
          <>
            {/* Describe you ideal match */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Describe your ideal match" />
              {/* <ApprovedButton /> */}
            </div>
            <Spacer height="8px" />
            <div className="edit-profile-input match-padding">
              <TextArea
                style={{
                  width: "100%",
                  outline: "none",
                  background: "transparent",
                }}
                showCount
                maxLength={1000}
                rows="10"
                onChange={(e) => setIdealMatchDescription(e.target.value)}
                placeholder="Describe you ideal match"
                value={idealMatchDescription || undefined}
              />
            </div>
            <Spacer height="8px" />
          </>
        )}
        <Spacer height="32px" />
        <PrimaryButton
          text="Save"
          onClick={handleSubmit}
          disabled={isDisabled}
        />
      </div>
    </>
  );
};

export default Description;
