import {
  GET_OTHERS_PROFILE,
  GET_OTHERS_PROFILE_SUCCESS,
  GET_OTHERS_PROFILE_FAILURE,
} from "../constants";

const initialState = {
  profile: {},
  isLoading: false,
  error: null,
};

export default function OthersProfileReducer(state = initialState, action) {
  switch (action.type) {
    case GET_OTHERS_PROFILE:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_OTHERS_PROFILE_SUCCESS:
      state = {
        ...state,
        profile: action.payload,
        isLoading: false,
      };
      break;

    case GET_OTHERS_PROFILE_FAILURE:
      state = {
        ...state,
        profile: {},
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }

  return state;
}
