import {
  GET_NEW_MEMBERS,
  GET_NEW_MEMBERS_SUCCESS,
  GET_NEW_MEMBERS_FAILURE,
} from "../constants";

export default class NewMembersAction {
  static getNewMembers(payload, cb) {
    return {
      type: GET_NEW_MEMBERS,
      payload,
      cb,
    };
  }
  static getNewMembersSuccess(payload) {
    return {
      type: GET_NEW_MEMBERS_SUCCESS,
      payload,
    };
  }
  static getNewMembersFailure() {
    return {
      type: GET_NEW_MEMBERS_FAILURE,
    };
  }
}
