import React from "react";

const skeletonStyle = (height, width, borderRadius = "0px") => ({
  height,
  width,
  borderRadius,
});

const Skeleton = ({ height, width, borderRadius }) => (
  <div className="skeleton" style={skeletonStyle(height, width, borderRadius)}>
    &zwnj;
  </div>
);

const Card = () => (
  <div className="flex flex-col items-start shadow-md rounded-xl">
    <Skeleton height="337px" borderRadius="12px 12px 0px 0px" />
    <div
      className="flex flex-col justify-center items-center gap-4 h-[179px] w-full px-1 py-3 bg-white"
      style={{ borderRadius: "0px 0px 12px 12px" }}
    >
      <div className="flex justify-center items-center gap-2 flex-col">
        <Skeleton height="22px" width="135px" />
        <Skeleton height="18px" width="121px" />
        <Skeleton height="18px" width="73px" />
      </div>
      <div className="flex justify-center items-center gap-2 flex-col">
        <Skeleton height="12px" width="87px" />
        <Skeleton height="12px" width="105px" />
      </div>
      <Skeleton height="32px" width="148px" />
    </div>
  </div>
);

const Cardloader = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
      {[...Array(4)].map((_, index) => (
        <Card key={index} />
      ))}
    </div>
  );
};

export default Cardloader;
