import React from "react";

import "./styles.css";
const WhitePurpleButton = (props) => {
  return (
    <button {...props} className="whitepurple-button-style">
      <p
        className="whitespace-nowrap overflow-hidden text-ellipsis"
        title={props?.text}
      >
        {props?.text}
      </p>
    </button>
  );
};

export default WhitePurpleButton;
