import {
  GET_SHORTLIST,
  GET_SHORTLIST_SUCCESS,
  GET_SHORTLIST_FAILURE,
  GET_SHORTLIST_ALL,
  GET_SHORTLIST_ALL_SUCCESS,
  GET_SHORTLIST_ALL_FAILURE,
  SEND_SHORTLIST,
  SEND_SHORTLIST_SUCCESS,
  SEND_SHORTLIST_FAILURE,
} from "../constants";

export default class ShortlistAction {
  static getShortlist(payload, cb) {
    return {
      type: GET_SHORTLIST,
      payload,
      cb,
    };
  }
  static getShortlistSuccess(payload) {
    return {
      type: GET_SHORTLIST_SUCCESS,
      payload,
    };
  }
  static getShortlistFailure() {
    return {
      type: GET_SHORTLIST_FAILURE,
    };
  }
  static getShortlistAll(payload, cb) {
    return {
      type: GET_SHORTLIST_ALL,
      payload,
      cb,
    };
  }
  static getShortlistAllSuccess(payload) {
    return {
      type: GET_SHORTLIST_ALL_SUCCESS,
      payload,
    };
  }
  static getShortlistAllFailure() {
    return {
      type: GET_SHORTLIST_ALL_FAILURE,
    };
  }
  static sendShortlist(payload, cb) {
    return {
      type: SEND_SHORTLIST,
      payload,
      cb,
    };
  }
  static sendShortlistSuccess(payload) {
    return {
      type: SEND_SHORTLIST_SUCCESS,
      payload,
    };
  }
  static sendShortlistFailure() {
    return {
      type: SEND_SHORTLIST_FAILURE,
    };
  }
}
