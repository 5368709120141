import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ProfileAction, MatchAction } from "../../store/actions";

import EditProfileBoxLabel from "../../components/label/editprofileboxlabel";
import EditProfileMultipleSelect from "../../components/dropdown/editprofilemultipleselection";
import RangeSlider from "../../components/rangeslider/rangeslider";
import PrimaryButton from "../../components/button/primarybutton";
import Spacer from "../../components/spacer/spacer";
import Loader from "../../components/loader/loader";

import images from "../../assets";

import {
  residencyStatus,
  relocationPlan,
  countries,
  ethnicOrigin,
  maritalStatusMale,
  maritalStatusFemale,
  spokenLanguages,
  healthCondition,
  livingWithInlaws,
  foodHabit,
  religiousHistory,
  religiousSect,
  polygamy,
  dailyPrayer,
  religiousCommitment,
} from "../../utils/arrayitems";

import TabHeading from "./tabheading";

import "./styles.css";

const BasicInfo = (props) => {
  const dispatch = useDispatch();
  const profileLoading = useSelector((state) => state?.profile?.isLoading);
  const matchLoading = useSelector((state) => state?.matches?.isLoading);
  const profileData = useSelector((state) => state?.profile?.profile);

  const [isDisabled, setDisabled] = useState(false);
  const [state, setState] = useState({
    ageMin: 18,
    ageMax: 30,
    country: [],
    residencyStatus: [],
    relocationPlan: [],
    ethnicOrigin: [],
    maritalStatus: [],
    spokenLanguage: [],
    physicalStatus: [],
    foodHabit: [],
    livingWithInlaws: [],
    religiousHistory: [],
    religiousSect: [],
    polygamy: [],
    dailyPrayer: [],
    religiousCommitment: [],
  });

  useEffect(() => {
    setState({
      ...state,
      ageMin: profileData?.matchPreference?.ageMin ?? 18,
      ageMax: profileData?.matchPreference?.ageMax ?? 30,
      country: profileData?.matchPreference?.country ?? [],
      residencyStatus: profileData?.matchPreference?.residencyStatus ?? [],
      relocationPlan: profileData?.matchPreference?.relocationPlan ?? [],
      ethnicOrigin: profileData?.matchPreference?.ethnicOrigin ?? [],
      maritalStatus: profileData?.matchPreference?.maritalStatus ?? [],
      spokenLanguage: profileData?.matchPreference?.spokenLanguage ?? [],
      physicalStatus: profileData?.matchPreference?.physicalStatus ?? [],
      foodHabit: profileData?.matchPreference?.foodHabit ?? [],
      livingWithInlaws: profileData?.matchPreference?.livingWithInlaws ?? [],
      religiousHistory: profileData?.matchPreference?.religiousHistory ?? [],
      religiousSect: profileData?.matchPreference?.religiousSect ?? [],
      polygamy: profileData?.matchPreference?.polygamy ?? [],
      dailyPrayer: profileData?.matchPreference?.dailyPrayer ?? [],
      religiousCommitment:
        profileData?.matchPreference?.religiousCommitment ?? [],
    });
  }, [profileLoading]);

  const handleSubmit = () => {
    setDisabled(true);
    const payload = {
      username: profileData?.username,
      matchPreference: { ...profileData?.matchPreference, ...state },
    };

    dispatch(
      MatchAction.updateMatchPreference(payload, () => {
        setDisabled(false);
        dispatch(ProfileAction.getProfile());
        props?.handleTab("bodyPreference");
      })
    );
  };

  return (
    <>
      <TabHeading
        image={images.matchPrefBasic}
        title="Basic Information"
        subtitle="Tell us a little about your preferences."
      />
      <div className="edit-profile-content">
        {profileLoading || matchLoading ? (
          <Loader />
        ) : (
          <>
            {/* Age Range */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Age Range" />
            </div>
            <RangeSlider
              onChange={(e) =>
                setState({ ...state, ageMin: e[0], ageMax: e[1] })
              }
              value={[state.ageMin, state.ageMax] || undefined}
            />

            <Spacer height="12px" />

            {/* Country */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Country" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="Country"
              data={countries}
              onChange={(e) => setState({ ...state, country: e })}
              value={state.country || undefined}
            />

            <Spacer height="12px" />

            {/* Residency Status */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Residency Status" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="Residency Status"
              data={residencyStatus}
              onChange={(e) => setState({ ...state, residencyStatus: e })}
              value={state.residencyStatus || undefined}
            />

            <Spacer height="12px" />

            {/* Relocation Plan */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Relocation Plan" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="Relocation Plan"
              data={relocationPlan}
              onChange={(e) => setState({ ...state, relocationPlan: e })}
              value={state.relocationPlan || undefined}
            />

            <Spacer height="12px" />

            {/* Ethnic Origin */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Ethnic Origin" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="Ethnic Origin"
              data={ethnicOrigin}
              onChange={(e) => setState({ ...state, ethnicOrigin: e })}
              value={state.ethnicOrigin || undefined}
            />

            <Spacer height="12px" />
            {/* Marital Status */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Marital Status" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="Marital Status"
              data={
                profileData?.gender === "male"
                  ? maritalStatusFemale
                  : maritalStatusMale
              }
              onChange={(e) => setState({ ...state, maritalStatus: e })}
              value={state.maritalStatus || undefined}
            />

            <Spacer height="12px" />

            {/* Preferred Spoken Languages */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Preferred Spoken Languages" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="Preferred Spoken Languages"
              data={spokenLanguages}
              onChange={(e) => setState({ ...state, spokenLanguage: e })}
              value={state.spokenLanguage || undefined}
            />

            <Spacer height="12px" />

            {/* Physical Status */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Physical Status" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="Physical Status"
              data={healthCondition}
              onChange={(e) => setState({ ...state, physicalStatus: e })}
              value={state.physicalStatus || undefined}
            />

            <Spacer height="12px" />

            {/* Food Habit */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Food Habit" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="Food Habit"
              data={foodHabit}
              onChange={(e) => setState({ ...state, foodHabit: e })}
              value={state.foodHabit || undefined}
            />

            <Spacer height="12px" />

            {/* Living with In-laws */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Living with In-laws" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="Living with In-laws"
              data={livingWithInlaws}
              onChange={(e) => setState({ ...state, livingWithInlaws: e })}
              value={state.livingWithInlaws || undefined}
            />

            <Spacer height="12px" />

            {/* Religious History */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Religious History" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="Religious History"
              data={religiousHistory}
              onChange={(e) => setState({ ...state, religiousHistory: e })}
              value={state.religiousHistory || undefined}
            />

            <Spacer height="12px" />

            {/* Religious Sect */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Religious Sect" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="Religious Sect"
              data={religiousSect}
              onChange={(e) => setState({ ...state, religiousSect: e })}
              value={state.religiousSect || undefined}
            />

            <Spacer height="12px" />

            {/* Polygamy */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Polygamy" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="Polygamy"
              data={polygamy}
              onChange={(e) => setState({ ...state, polygamy: e })}
              value={state.polygamy || undefined}
            />

            <Spacer height="12px" />

            {/* Daily Prayer */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Daily Prayer" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="Daily Prayer"
              data={dailyPrayer}
              onChange={(e) => setState({ ...state, dailyPrayer: e })}
              value={state.dailyPrayer || undefined}
            />

            <Spacer height="12px" />

            {/* Religious Commitment Level */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Religious Commitment Level" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="Religious Commitment Level"
              data={religiousCommitment}
              onChange={(e) => setState({ ...state, religiousCommitment: e })}
              value={state.religiousCommitment || undefined}
            />
          </>
        )}
        <Spacer height="32px" />
        <PrimaryButton
          text="Save & Continue"
          onClick={handleSubmit}
          disabled={isDisabled}
        />
      </div>
    </>
  );
};

export default BasicInfo;
