import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { message, Avatar } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { UserOutlined } from "@ant-design/icons";

import useScreenSize from "../../../utils/usescreensize";

import ProfileInfoCom from "../profile/profileinfo";
import OtherPrintDownloadCom from "../profile/otherprintdownload";
import PrintDownloadCom from "../profile/printdownload";
import PrimaryButton from "../../button/primarybutton";
import Spacer from "../../spacer/spacer";
import Loader from "../../loader/loader";

import SimilarProfileCarousel from "./similarprofilecarousel";
import ProfileCompletion from "./profilecompletion";

import images from "../../../assets/index";
import "./styles.css";

export default function ViewProfileLayout({ children }) {
  const screenSize = useScreenSize();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [showButtons, setShowButtons] = useState(false);
  const [idList, setIdList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const othersProfileLoading = useSelector(
    (state) => state?.othersProfile?.isLoading
  );
  const profileData = useSelector((state) => state?.othersProfile?.profile);

  const { advancedMatches, basicMatches, recommendedMatches, quickMatches } =
    useSelector((state) => state.search);
  const { interests } = useSelector((state) => state.interests);
  const { myNotes } = useSelector((state) => state?.myNotes);
  const { newMembers } = useSelector((state) => state.newMembers);
  const { photoreq } = useSelector((state) => state.photoreq);
  const { profileViewed, profileViewer } = useSelector(
    (state) => state.profileView
  );
  const { contactView } = useSelector((state) => state.contactView);
  const { shortlists } = useSelector((state) => state.shortlists);

  useEffect(() => {
    const from = searchParams.get("from");

    if (!from || from === "undefined") {
      setShowButtons(false);
    } else {
      setShowButtons(true);
      let ids = [];

      if (from === "newMembers") {
        newMembers?.forEach((item) => ids.push(item?.username));
      } else if (from === "recommendedSearch") {
        recommendedMatches?.forEach((item) => ids.push(item?.username));
      } else if (from === "standardSearch") {
        basicMatches?.forEach((item) => ids.push(item?.username));
      } else if (from === "quickSearch") {
        quickMatches?.forEach((item) => ids.push(item?.username));
      } else if (from === "advancedSearch") {
        advancedMatches?.forEach((item) => ids.push(item?.username));
      } else if (from === "interests") {
        interests?.forEach((item) => ids.push(item?.username));
      } else if (from === "photoRequests") {
        photoreq?.forEach((item) => ids.push(item?.username));
      } else if (from === "shortlists") {
        shortlists?.forEach((item) => ids.push(item?.username));
      } else if (from === "profileViewer") {
        profileViewer?.forEach((item) => ids.push(item?.username));
      } else if (from === "profileViewed") {
        profileViewed?.forEach((item) => ids.push(item?.username));
      } else if (from === "myNotes") {
        myNotes?.forEach((item) => ids.push(item?.username));
      } else if (from === "contactView") {
        contactView?.forEach((item) => ids.push(item?.username));
      }

      // Ensure profileData.username is in idList
      if (profileData?.username && !ids.includes(profileData.username)) {
        ids.unshift(profileData.username);
      }

      setIdList(ids);
      setCurrentIndex(ids.indexOf(profileData?.username));
    }
  }, [searchParams, profileData]);

  const handleNext = () => {
    if (currentIndex < idList.length - 1) {
      const nextId = idList[currentIndex + 1];
      navigate(`/view-profile/${nextId}?from=${searchParams.get("from")}`);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      const prevId = idList[currentIndex - 1];
      navigate(`/view-profile/${prevId}?from=${searchParams.get("from")}`);
    }
  };

  return (
    <>
      <div className="profile-layout other-layout">
        {othersProfileLoading ? (
          <Loader />
        ) : (
          <div className="profile-layout-inner">
            {screenSize.width < 1023 && (
              <div className="my-profile-header">
                <div className="my-profile-head-back">
                  <img src={images.headBackArrow} alt="Back" />
                  <p onClick={() => window.history.back()}>Back</p>
                </div>
              </div>
            )}
            {screenSize.width > 1023 && showButtons && (
              <div className="view-profile-next-prev">
                <div>
                  <PrimaryButton
                    text={"Previous"}
                    iconalign="left"
                    icon={images.arrowIconLeft}
                    style={{ width: "120px" }}
                    onClick={handlePrev}
                    disabled={currentIndex <= 0}
                  />
                </div>
                <div>
                  <PrimaryButton
                    icon={images.arrowIconRight}
                    iconalign="right"
                    text={"Next"}
                    style={{ width: "120px" }}
                    onClick={handleNext}
                    disabled={currentIndex >= idList.length - 1}
                  />
                </div>
              </div>
            )}
            <div
              style={{
                background: `radial-gradient(120% 110% at 80% 160%, #001528 74%, #073AFF00 92%),radial-gradient(150% 80% at 120% 115%, #02305E 70%, #073AFF00 88%),radial-gradient(140% 140% at 110% 140%, #5D4D8C 76%, #5E519200 92%, #073AFF00 100%),radial-gradient(75% 75% at 50% 50%, #362955 100%, #073AFF00 100%)`,
                backgroundSize: "100% 100%",
              }}
              className="profile-head other-profile-head"
            >
              <div className="profile-head-inner other-head-inner">
                <div className="profile-info">
                  <div className="profile-info-thumb other-info-thumb">
                    <>
                      {!profileData?.gender ? (
                        <Avatar
                          size={192}
                          icon={<UserOutlined />}
                          shape="square"
                        />
                      ) : (
                        <img
                          src={
                            profileData?.profilePicture ??
                            (profileData?.gender === "male"
                              ? images.defaultMaleIcon
                              : images.defaultFemaleIcon)
                          }
                          alt=""
                        />
                      )}
                    </>
                  </div>
                  {screenSize.width < 1023 && (
                    <OtherPrintDownloadCom profileData={profileData} />
                  )}
                  <div className="profile-info-content other-info-content">
                    <ProfileInfoCom
                      profileData={profileData}
                      othersProfile={true}
                    />
                    {screenSize.width > 1023 && <ProfileCompletion />}
                  </div>
                </div>
                {screenSize.width > 1023 && (
                  <PrintDownloadCom profileData={profileData} />
                )}
              </div>
              {/* User id and Special info */}
              <div className="special-info">
                {profileData?.username && (
                  <p className="profile-id2">
                    User ID: {profileData?.username}&nbsp;
                    <CopyToClipboard
                      text={profileData?.username}
                      onCopy={() => {
                        message.success("Copied to clipboard");
                      }}
                    >
                      <ion-icon
                        style={{ cursor: "pointer" }}
                        name="copy-outline"
                      ></ion-icon>
                    </CopyToClipboard>
                  </p>
                )}
                {profileData?.religiousHistory === "Revert" && (
                  <div className="special-info-text">
                    <span className="text-white text-xs font-semibold leading-[18px]">
                      Special Info:{" "}
                    </span>
                    <span className="text-white text-xs font-normal leading-[18px]">
                      {profileData?.firstName} is a Revert Muslim. Don't forget
                      to congratulate{" "}
                      {profileData?.gender === "male" ? "him" : "her"}.
                    </span>
                  </div>
                )}
              </div>
              {/* end special info */}
            </div>
            <div className="profile-body">
              <div className="profile-body-inner">{children}</div>
            </div>
          </div>
        )}
      </div>
      {screenSize.width < 1023 && (
        <div className="bottom-bar-container other-profile">
          <div style={{ height: "0px" }}></div>
          <ProfileCompletion />
        </div>
      )}
    </>
  );
}
