const hairTypeMale = [
  { label: "Straight", value: "Straight" },
  { label: "Wavy", value: "Wavy" },
  { label: "Curly", value: "Curly" },
  { label: "Bald on top", value: "Bald on top" },
  { label: "Other", value: "Other" },
];
const hairTypeFemale = [
  { label: "Straight", value: "Straight" },
  { label: "Wavy", value: "Wavy" },
  { label: "Curly", value: "Curly" },
  { label: "Other", value: "Other" },
];
export { hairTypeMale, hairTypeFemale };
