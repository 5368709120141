import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAILURE,
} from "../constants";

export default class NotificationAction {
  static getNotifications(cb) {
    return {
      type: GET_NOTIFICATIONS,
      cb,
    };
  }
  static getNotificationsSuccess(payload) {
    return {
      type: GET_NOTIFICATIONS_SUCCESS,
      payload,
    };
  }
  static getNotificationsFailure() {
    return {
      type: GET_NOTIFICATIONS_FAILURE,
    };
  }
  static updateNotification(cb) {
    return {
      type: UPDATE_NOTIFICATION,
      cb,
    };
  }
  static updateNotificationSuccess(payload) {
    return {
      type: UPDATE_NOTIFICATION_SUCCESS,
      payload,
    };
  }
  static updateNotificationFailure() {
    return {
      type: UPDATE_NOTIFICATION_FAILURE,
    };
  }
}
