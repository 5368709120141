import moment from "moment/moment";
import { Popover } from "antd";
import { IoMdInformationCircle } from "react-icons/io";

import calculateage from "../../../utils/calculateage";
import getMaritalStatusLabel from "../../../utils/getmaritalstatuslabel";
import images from "../../../assets/index";

const convertedCentoFeet = (values) => {
  const realFeet = (values * 0.3937) / 12;
  const feet = Math.floor(realFeet);
  const inches = Math.round((realFeet - feet) * 12);
  return feet + "'" + inches + "''";
};

export default function ProfileInfoCom(props) {
  return (
    <div className="profile-info-main-content">
      <h2>
        {props?.profileData?.firstName}&nbsp;{props?.profileData?.lastName}
      </h2>
      {props?.profileData?.dateOfBirth && (
        <p>
          <img src={images.calenderIcon} alt="Calender" />
          &nbsp;
          {!props?.othersProfile && (
            <>{moment(props?.profileData?.dateOfBirth).format("MMM-D-YYYY")}</>
          )}
          {!props?.othersProfile
            ? `(${calculateage(props?.profileData?.dateOfBirth)} years)`
            : `${calculateage(props?.profileData?.dateOfBirth)} years`}
          {!props?.othersProfile && (
            <Popover
              content={
                "Other user will only see your age, not your Date of Birth."
              }
              placement="right"
            >
              <IoMdInformationCircle color="white" />
            </Popover>
          )}
        </p>
      )}
      {props?.profileData?.height && (
        <p>
          <img src={images.heightIcon} alt="Height" />
          &nbsp;
          {`${convertedCentoFeet(props?.profileData?.height)} (${
            props?.profileData?.height
          } cm)`}
        </p>
      )}
      {props?.profileData?.country && props?.profileData?.stateProvince && (
        <p>
          <img src={images.locationIcon} alt="Location" />
          &nbsp;{props?.profileData?.stateProvince},{" "}
          {props?.profileData?.country}
        </p>
      )}
      {props?.profileData?.maritalStatus && (
        <p style={{ textTransform: "capitalize" }}>
          <img src={images.neverMarride} alt="Marrital Status" />
          &nbsp;{getMaritalStatusLabel(props?.profileData?.maritalStatus)}
        </p>
      )}
      {props?.profileData?.profession && (
        <p>
          <img src={images.computerProfession} alt="Calender" />
          &nbsp;{props?.profileData?.profession}
        </p>
      )}
    </div>
  );
}
