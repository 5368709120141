import React from "react";
import { useNavigate } from "react-router-dom";

import images from "../../assets";

import "./styles.css";

export default function UnderHeaderMenu(props) {
  const navigate = useNavigate();

  return (
    <div className="my-profile-header">
      <div
        {...props}
        className="my-profile-head-back"
        onClick={() => {
          navigate(props?.navigateTo || window.history.back());
          props?.setOpen && props?.setOpen(false);
        }}
      >
        <img src={images.headBackArrow} alt="Back" />
        <p>Back</p>
      </div>
      <div className="my-profile-head-title">
        <p
          style={{
            color: "#310048",
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          {props.titlename}
        </p>
      </div>
      <div className="my-profile-head-edit">{props.children}</div>
    </div>
  );
}
