import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Country, State } from "country-state-city";

import {
  spokenLanguages,
  ethnicOrigin,
  haveChildren,
  residencyStatus,
  maritalStatusMale,
  maritalStatusFemale,
  relocationPlan,
  profileCreatedBy,
  typeOfProfile,
} from "../../utils/arrayitems";

import EditProfileBoxLabel from "../../components/label/editprofileboxlabel";
import BasicSearchMultipleSelectCountry from "../../components/dropdown/basicsearchmultipleselectcountry";
import Spacer from "../../components/spacer/spacer";
import EditProfileMultipleSelect from "../../components/dropdown/editprofilemultipleselection";
import RangeSlider from "../../components/rangeslider/rangeslider";
import CheckboxGroup from "../../components/checkbox/checkboxgroup";

import "./styles.css";

const countries = Country.getAllCountries();
export default function BasicInfoFrom(props) {
  const gender = useSelector((state) => state.profile?.profile?.gender);

  const [countryCode, setCountryCode] = useState([]);
  const [states, setStates] = useState([]);

  useEffect(() => {
    if (!props?.state?.country) return;
    const countryCodes = props?.state.country.flatMap((country) =>
      countries
        .filter((item) => item?.name === country)
        .map((item) => item?.isoCode)
    );
    setCountryCode(countryCodes);
  }, [props?.state?.country, countries]);

  useEffect(() => {
    if (!countryCode) return;
    const stateProvince = countryCode.flatMap((countryCode) => {
      const states = State.getStatesOfCountry(countryCode);
      return states ? states.map((state) => state) : [];
    });
    setStates(stateProvince);
  }, [countryCode]);

  return (
    <div className="adv-search-basic-info-area">
      <div
        style={{
          background: `radial-gradient(120% 110% at 80% 160%, #001528 74%, #073AFF00 92%),radial-gradient(150% 80% at 120% 115%, #02305E 70%, #073AFF00 88%),radial-gradient(140% 140% at 110% 140%, #5D4D8C 76%, #5E519200 92%, #073AFF00 100%),radial-gradient(75% 75% at 50% 50%, #362955 100%, #073AFF00 100%)`,
          backgroundSize: "100%",
          backgroundPosition: "center",
        }}
        className="adv-search-basic-info"
      >
        <h2 className="adv-search-info-title">Basic Information</h2>
      </div>
      <div className="adv-search-from-content">
        <div className="adv-search-from-left">
          {/* Age Range */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel label="Age Range" />
          </div>
          <RangeSlider
            onChange={(e) =>
              props?.setState({ ...props?.state, ageMin: e[0], ageMax: e[1] })
            }
            value={[props?.state.ageMin, props?.state.ageMax] || undefined}
          />

          <Spacer height="21px" />
          {/* Country */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel label="Country" />
          </div>
          <Spacer height="8px" />
          <BasicSearchMultipleSelectCountry
            placeholder="Country"
            data={countries}
            onChange={(e) => {
              props?.setState({ ...props?.state, country: e });
            }}
            value={props?.state.country || undefined}
          />

          <Spacer height="12px" />
          {/* State Province */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel label="State/Province" />
          </div>
          <Spacer height="8px" />
          <BasicSearchMultipleSelectCountry
            placeholder="States"
            data={states}
            onChange={(e) => {
              props?.setState({ ...props?.state, stateProvince: e });
            }}
            value={props?.state.stateProvince || undefined}
          />

          <Spacer height="12px" />
          {/* Residency Status */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel label="Residency Status" />
          </div>
          <Spacer height="8px" />
          <EditProfileMultipleSelect
            placeholder="Residency Status"
            data={residencyStatus}
            onChange={(e) =>
              props?.setState({ ...props?.state, residencyStatus: e })
            }
            value={props?.state.residencyStatus || undefined}
          />

          <Spacer height="12px" />
          {/* Marital Status */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel label="Marital Status" />
          </div>
          <Spacer height="8px" />
          <EditProfileMultipleSelect
            placeholder="Marital Status"
            data={gender === "male" ? maritalStatusFemale : maritalStatusMale}
            onChange={(e) =>
              props?.setState({ ...props?.state, maritalStatus: e })
            }
            value={props?.state.maritalStatus || undefined}
          />

          <Spacer height="12px" />
          {/* Relocation Plan */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel label="Relocation Plan" />
          </div>
          <Spacer height="8px" />
          <EditProfileMultipleSelect
            placeholder="Relocation Plan"
            data={relocationPlan}
            onChange={(e) =>
              props?.setState({ ...props?.state, relocationPlan: e })
            }
            value={props?.state.relocationPlan || undefined}
          />
        </div>
        {/* Right From Area */}
        <div className="adv-search-from-right">
          {/* Spoken Languages */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel label="Spoken Languages" />
          </div>
          <Spacer height="8px" />
          <EditProfileMultipleSelect
            placeholder="Spoken Languages"
            data={spokenLanguages}
            onChange={(e) =>
              props?.setState({ ...props?.state, spokenLanguage: e })
            }
            value={props?.state.spokenLanguage || undefined}
          />

          <Spacer height="12px" />
          {/* Ethnic Origin */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel label="Ethnic Origin" />
          </div>
          <Spacer height="8px" />
          <EditProfileMultipleSelect
            placeholder="Ethnic Origin"
            data={ethnicOrigin}
            onChange={(e) =>
              props?.setState({ ...props?.state, ethnicOrigin: e })
            }
            value={props?.state.ethnicOrigin || undefined}
          />

          <Spacer height="12px" />
          {/* Have Children? */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel label="Have Children?" />
          </div>
          <Spacer height="8px" />
          <EditProfileMultipleSelect
            placeholder="Have Children?"
            data={haveChildren}
            onChange={(e) =>
              props?.setState({ ...props?.state, haveChildren: e })
            }
            value={props?.state.haveChildren || undefined}
          />

          <Spacer height="12px" />
          {/* Profile Created By */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel label="Profile Created By" />
          </div>
          <Spacer height="8px" />
          <EditProfileMultipleSelect
            placeholder="Profile Created By"
            data={profileCreatedBy}
            onChange={(e) =>
              props?.setState({ ...props?.state, profileCreatedBy: e })
            }
            value={props?.state.profileCreatedBy || undefined}
          />

          <Spacer height="12px" />

          {/* Type of Profile */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel label="Type of Profile" />
          </div>
          <Spacer height="8px" />
          <EditProfileMultipleSelect
            placeholder="Type of Profile"
            data={typeOfProfile}
            onChange={(e) =>
              props?.setState({ ...props?.state, typeOfProfile: e })
            }
            value={props?.state.typeOfProfile || undefined}
          />

          <Spacer height="12px" />
          {/* Has Photo? */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel label="Has Photo?" />
          </div>
          <Spacer height="8px" />
          <div className="adv-search-has-photo">
            <CheckboxGroup
              items={[
                { label: "Doesn't Matter", value: "Doesn't Matter" },
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ]}
              value={props?.state.hasPhoto || undefined}
              onChange={(e) =>
                props?.setState({ ...props?.state, hasPhoto: e })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
