import {
  SET_MESSAGES_LIST,
  SET_MESSAGES_LIST_SUCCESS,
  SET_MESSAGES_LIST_FAILURE,
} from "../constants";

const initialState = {
  userList: [],
  isMessageListLoading: false,
  isLoading: false,
  error: null,
};

export default function MessageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MESSAGES_LIST:
      state = {
        ...state,
        isMessageListLoading: true,
        error: null,
      };
      break;
    case SET_MESSAGES_LIST_SUCCESS:
      state = {
        ...state,
        userList: action.payload?.userList,
        isMessageListLoading: false,
        isLoading: action.payload?.isLoading,
      };
      break;
    case SET_MESSAGES_LIST_FAILURE:
      state = {
        ...state,
        isMessageListLoading: false,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }

  return state;
}
