import { put } from "redux-saga/effects";
import { message } from "antd";

import ActivityApiCaller from "../../config/api/activityapicaller";

import { PhotoreqAction } from "../actions";

export default class PhotoreqMiddleware {
  static *getPhotoreq({ payload, cb }) {
    try {
      const action =
        payload?.filter === "Pending"
          ? "receiver/pending"
          : "receiver/accepted";

      const res = yield ActivityApiCaller.Get(
        `/activity/photo-request/${action}?from=${payload.from ?? ""}&size=${
          payload.size ?? ""
        }&sort=${payload.sort ?? ""}`
      );

      if (res?.status === 200) {
        if (payload?.filter === "Pending") {
          yield put(
            PhotoreqAction.getPhotoreqSuccess({
              count: res.data?.count,
              data: res.data?.data,
              pendingCount: res.data?.count,
            })
          );
        } else {
          yield put(
            PhotoreqAction.getPhotoreqSuccess({
              count: res.data?.count,
              data: res.data?.data,
            })
          );
        }
      } else {
        yield put(PhotoreqAction.getPhotoreqFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      console.log(err);
      yield put(PhotoreqAction.getPhotoreqFailure());
    }
  }
  static *getPhotoreqAll({ payload, cb }) {
    try {
      const action = "receiver/accepted";

      const res = yield ActivityApiCaller.Get(
        `/activity/photo-request/${action}?from=${payload.from ?? ""}&size=${
          payload.size ?? ""
        }&sort=${payload.sort ?? ""}`
      );

      if (res?.status === 200) {
        yield put(
          PhotoreqAction.getPhotoreqAllSuccess({
            count: res.data?.count,
            data: res.data?.data,
          })
        );
      } else {
        yield put(PhotoreqAction.getPhotoreqAllFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      console.log(err);
      yield put(PhotoreqAction.getPhotoreqAllFailure());
    }
  }
  static *sendPhotoreq({ payload, cb }) {
    try {
      const res = yield ActivityApiCaller.Post(
        "/activity/photo-request",
        payload
      );

      if (res.status === 200) {
        message.success("Photo request sent succesfully.");
        yield put(PhotoreqAction.sendPhotoreqSuccess(res.data.Data));
      } else if (res.status === 409) {
        message.error("Photo request already sent.");
        yield put(PhotoreqAction.sendPhotoreqFailure());
      } else {
        // message.error("Could not send photo request.");
        yield put(PhotoreqAction.sendPhotoreqFailure());
      }
      if (cb) {
        cb(res.data.Data);
      }
    } catch (err) {
      console.log(err);
      // message.error("Something went wrong while sending photo request.");
      yield put(PhotoreqAction.sendPhotoreqFailure());
    }
  }
  static *updatePhotoreq({ payload, cb }) {
    try {
      const res = yield ActivityApiCaller.Put(
        "/activity/photo-request",
        payload
      );

      if (res.status === 200) {
        message.success("Photo request updated succesfully.");
        yield put(PhotoreqAction.updatePhotoreqSuccess(res.data.Data));
      } else {
        message.error("Could not update photo request.");
        yield put(PhotoreqAction.updatePhotoreqFailure());
      }
      if (cb) {
        cb(res.data.Data);
      }
    } catch (err) {
      console.log(err);
      // message.error("Something went wrong while updating photo request.");
      yield put(PhotoreqAction.updatePhotoreqFailure());
    }
  }
}
