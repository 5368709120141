const motherStatus = [
  { label: "Homemaker", value: "Homemaker" },
  { label: "Government Service", value: "Government Service" },
  { label: "Private service", value: "Private service" },
  { label: "Employed", value: "Employed" },
  { label: "Business Person", value: "Business Person" },
  { label: "Retired", value: "Retired" },
  { label: "Passed Away", value: "Passed Away" },
];
export default motherStatus;
