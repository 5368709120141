import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { message } from "antd";

import { ProfileAction } from "../../store/actions";

import PhoneForm from "./phoneform";

import "./styles.css";

const ChangePhone = (props) => {
  const dispatch = useDispatch();

  const [phone, setPhone] = useState("");

  const handlePhoneSubmit = () => {
    if (!phone) {
      message.error("Please enter a phone number");
    } else {
      const payload = {
        data: {
          phone,
          type: "phonechange",
        },
      };

      dispatch(
        ProfileAction.changeRequest(payload, (res) => {
          console.log(res);
        })
      );
      props?.setIsPhoneModalOpen(false);
    }
  };

  return (
    <div className="forgot-password-container">
      <PhoneForm
        phone={phone}
        setPhone={setPhone}
        handlePhoneSubmit={handlePhoneSubmit}
      />
    </div>
  );
};

export default ChangePhone;
