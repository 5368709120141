const hairColor = [
  { label: "Black", value: "Black" },
  { label: "Blonde", value: "Blonde" },
  { label: "Brown", value: "Brown" },
  { label: "Grey / White", value: "Grey / White" },
  { label: "Light Brown", value: "Light Brown" },
  { label: "Red", value: "Red" },
  { label: "Changes frequently", value: "Changes frequently" },
  { label: "Other", value: "Other" },
];

export default hairColor;
