import React from "react";
import { useSwiper } from "swiper/react";
import images from "../../assets";

import "./styles.css";

export const SwiperNavButtons = ({ index, isEnd, isOverflowing }) => {
  const swiper = useSwiper();

  return (
    <div className="swiper-nav-btns">
      <button
        onClick={() => swiper.slidePrev()}
        className="nav-button"
        style={{ opacity: index === 0 ? "0.5" : "1" }}
        disabled={index === 0}
      >
        <img
          src={images.arrowIcon}
          alt="arrow"
          style={{ transform: "rotate(180deg)" }}
        />
      </button>
      <button
        onClick={() => swiper.slideNext()}
        className="nav-button"
        style={{ opacity: isEnd || !isOverflowing ? "0.5" : "1" }}
        disabled={isEnd || !isOverflowing}
      >
        <img src={images.arrowIcon} alt="arrow" />
      </button>
    </div>
  );
};
