import React, { useState } from "react";
import { Modal } from "antd";

import {
  ApprovedButton,
  RejectedButton,
  PendingButton,
} from "../../components/button";

import Spacer from "../../components/spacer/spacer";

import PhotoModal from "./photomodal";

import images from "../../assets";

export default function DocumentView(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [item, setitem] = useState({});

  return (
    <>
      {props?.trustbadge?.map((item, index) => (
        <div
          className="trustbadge-document"
          key={index}
          onClick={() => {
            setitem(item);
            setIsModalOpen(true);
          }}
        >
          <img src={item?.url} alt="" className="trustbadge-doc-img" />
          <div className="trustbadge-document-info">
            <p className="trustbadge-document-info-file-type">
              {item?.documentType}
            </p>
            <p className="trustbadge-document-info-file-length">
              {props?.trustbadge?.length} file
            </p>
          </div>
          <Spacer height="16px" />
          <div className="flex gap-2 items-center">
            <p>Status: </p>
            {item?.status === "pending" ? (
              <PendingButton />
            ) : item?.status === "rejected" ? (
              <RejectedButton />
            ) : (
              <ApprovedButton />
            )}
          </div>
        </div>
      ))}

      <Modal
        centered={true}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={false}
        closable={false}
        className="gallery-modal-main"
      >
        <PhotoModal item={item}>
          <div className="photo-modal-left">
            <h2>{item?.documentType}</h2>
            <p>{props?.trustbadge?.length} Files</p>
          </div>
          <div className="photo-modal-right">
            {/* <img src={images.zoomingIcon} alt="" height={44} width={44} /> */}
            <img
              onClick={() => setIsModalOpen(false)}
              src={images.closeIcon}
              alt=""
              height={44}
              width={44}
            />
          </div>
        </PhotoModal>
      </Modal>
    </>
  );
}
