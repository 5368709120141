import {
  GET_PAYMENT_PLAN,
  GET_PAYMENT_PLAN_SUCCESS,
  GET_PAYMENT_PLAN_FAILURE,
  GET_SUBSCRIPTION_INFO,
  GET_SUBSCRIPTION_INFO_SUCCESS,
  GET_SUBSCRIPTION_INFO_FAILURE,
} from "../constants";

const initialState = {
  plan: {},
  paymentPlans: [],
  isLoading: false,
  isPaymentPlanLoading: false,
  error: null,
};

export default function SubscriptionReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUBSCRIPTION_INFO:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_PAYMENT_PLAN:
      state = {
        ...state,
        isPaymentPlanLoading: true,
        error: null,
      };
      break;
    case GET_SUBSCRIPTION_INFO_SUCCESS:
      state = {
        ...state,
        plan: action.payload,
        isLoading: false,
      };
      break;
    case GET_PAYMENT_PLAN_SUCCESS:
      state = {
        ...state,
        paymentPlans: action.payload,
        isPaymentPlanLoading: false,
      };
      break;

    case GET_SUBSCRIPTION_INFO_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    case GET_PAYMENT_PLAN_FAILURE:
      state = {
        ...state,
        isPaymentPlanLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }

  return state;
}
