import { useSelector } from "react-redux";
import images from "../../assets";
import "./styles.css";
import BasicInfoColumn from "./basicinfocolumn";

export default function ReligiousStatus() {
  const profileData = useSelector((state) => {
    return state?.profile?.profile;
  });
  return (
    <div className="religious-status basic-info-card">
      <div className="basic-info-head">
        <img src={images.religiousIcon} alt="Religious Status" />{" "}
        <span>Religious Status</span>
      </div>
      <div className="basic-info-content">
        {profileData?.religiousHistory && (
          <BasicInfoColumn
            titlename="Religious History"
            values={profileData?.religiousHistory}
          />
        )}
        {profileData?.religiousSect && (
          <BasicInfoColumn
            titlename="Sect"
            values={profileData?.religiousSect}
          />
        )}
        {profileData?.polygamy && (
          <BasicInfoColumn
            titlename="Polygamy"
            values={profileData?.polygamy}
          />
        )}
        {profileData?.dailyPrayer && (
          <BasicInfoColumn titlename="Pray" values={profileData?.dailyPrayer} />
        )}
        {profileData?.readQuran && (
          <BasicInfoColumn
            titlename="How often do you read Quran?"
            values={profileData?.readQuran}
          />
        )}
        {profileData?.readIslamicBook && (
          <BasicInfoColumn
            titlename="Do you read Islamic books, lectures?"
            values={profileData?.readIslamicBook}
          />
        )}
        {profileData?.religiousCommitment && (
          <BasicInfoColumn
            titlename="Relgious Commitment Level"
            values={profileData?.religiousCommitment}
          />
        )}
      </div>
    </div>
  );
}
