const editProfileTabItems = [
  { name: "basicInfo", title: "Basic Information" },
  { name: "physicalLifestyle", title: "Physcial Appearance & Lifestyle" },
  { name: "familyDetails", title: "Family Details" },
  { name: "religiousStatus", title: "Religious Status" },
  { name: "educationCareer", title: "Education & Career" },
  { name: "personalityHobbies", title: "Personality & Hobbies" },
  { name: "profileDesc", title: "Profile Description" },
];

export default editProfileTabItems;
