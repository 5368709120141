import {
  GET_PROFILE_VIEWED,
  GET_PROFILE_VIEWED_SUCCESS,
  GET_PROFILE_VIEWED_FAILURE,
  GET_ALL_PROFILE_VIEWED,
  GET_ALL_PROFILE_VIEWED_SUCCESS,
  GET_ALL_PROFILE_VIEWED_FAILURE,
  GET_PROFILE_VIEWER,
  GET_PROFILE_VIEWER_SUCCESS,
  GET_PROFILE_VIEWER_FAILURE,
  SET_PROFILE_VIEW,
  SET_PROFILE_VIEW_SUCCESS,
  SET_PROFILE_VIEW_FAILURE,
} from "../constants";

const initialState = {
  profileViewed: [],
  profileViewedCount: 0,
  profileViewedAll: [],
  profileViewedAllCount: 0,
  profileViewer: [],
  profileViewerCount: 0,
  isLoading: false,
  isAllLoading: false,
  error: null,
};

export default function ProfileViewerReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROFILE_VIEWER:
    case GET_PROFILE_VIEWED:
    case SET_PROFILE_VIEW:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_ALL_PROFILE_VIEWED:
      state = {
        ...state,
        isAllLoading: true,
        error: null,
      };
      break;
    case GET_PROFILE_VIEWER_SUCCESS:
      state = {
        ...state,
        profileViewer: action.payload.data,
        profileViewerCount: action.payload.count,
        isLoading: false,
      };
      break;
    case GET_PROFILE_VIEWED_SUCCESS:
      state = {
        ...state,
        profileViewed: action.payload.data,
        profileViewedCount: action.payload.count,
        isLoading: false,
      };
      break;
    case GET_ALL_PROFILE_VIEWED_SUCCESS:
      state = {
        ...state,
        profileViewedAll: action.payload.data,
        profileViewedAllCount: action.payload.count,
        isAllLoading: false,
      };
      break;
    case SET_PROFILE_VIEW_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;

    case GET_PROFILE_VIEWER_FAILURE:
    case GET_PROFILE_VIEWED_FAILURE:
    case SET_PROFILE_VIEW_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;

    case GET_ALL_PROFILE_VIEWED_FAILURE:
      state = {
        ...state,
        isAllLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }

  return state;
}
