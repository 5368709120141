import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select } from "antd";

import { ProfileAction, SearchAction } from "../../store/actions";

import EditProfileBoxLabel from "../../components/label/editprofileboxlabel";
import EditProfileDropdown from "../../components/dropdown/editprofiledropdown";
import PrimaryButton from "../../components/button/primarybutton";
import Loader from "../../components/loader/loader";
import ErrorText from "../../components/errortext/errortext";

import {
  education,
  profession,
  occupationStatus,
  incomeCurrency,
  incomeValue,
} from "../../utils/arrayitems";

import Spacer from "../../components/spacer/spacer";
import images from "../../assets";

import TabHeading from "./tabheading";
import "./styles.css";

const EducationCareer = (props) => {
  const dispatch = useDispatch();

  const profileLoading = useSelector((state) => state?.profile?.isLoading);
  const profileData = useSelector((state) => state?.profile?.profile);
  const universities = useSelector((state) => state?.search?.universities);

  const [search, setSearch] = useState("");
  const [isDisabled, setDisabled] = useState(false);
  const [state, setState] = useState({
    education: "",
    instituteName: "",
    profession: "",
    occupationStatus: "",
    incomeCurrency: "",
    annualIncomeMin: "",
    annualIncomeMax: "",
    islamicInstituteAttended: "",
  });
  const [error, setError] = useState({
    education: "",
    profession: "",
    occupationStatus: "",
    incomeError: "",
  });

  useEffect(() => {
    setState({
      ...state,
      education: profileData?.education ?? "",
      instituteName: profileData?.instituteName ?? "",
      profession: profileData?.profession ?? "",
      occupationStatus: profileData?.occupationStatus ?? "",
      incomeCurrency: profileData?.incomeCurrency ?? "",
      annualIncomeMin: profileData?.annualIncomeMin ?? "",
      annualIncomeMax: profileData?.annualIncomeMax ?? "",
      islamicInstituteAttended: profileData?.islamicInstituteAttended ?? "",
    });
  }, [profileLoading]);

  useEffect(() => {
    if (search?.length > 0) {
      const payload = { searchText: search };
      dispatch(SearchAction.getUniversities(payload));
    }
  }, [search]);

  const filteredIncomeMinOptions = incomeValue.filter(
    (option) => !state.annualIncomeMax || option.value < state.annualIncomeMax
  );

  const filteredIncomeMaxOptions = incomeValue.filter(
    (option) => !state.annualIncomeMin || option.value > state.annualIncomeMin
  );

  const handleSubmit = () => {
    if (!state?.education) {
      setError({
        ...error,
        education: "Education cannot be empty",
      });
    } else if (!state?.profession) {
      setError({
        ...error,
        profession: "Profession cannot be empty",
      });
    } else if (!state?.occupationStatus) {
      setError({
        ...error,
        occupationStatus: "Occupation status cannot be empty",
      });
    } else if (
      (state?.annualIncomeMax || state?.annualIncomeMin) &&
      state?.annualIncomeMax <= state?.annualIncomeMin
    ) {
      setError({
        ...error,
        incomeError: "Max income must be greater than minimum income",
      });
    } else {
      setDisabled(true);
      const payload = {
        ...state,
        incomeCurrency:
          state?.occupationStatus === "Not Employed"
            ? ""
            : state?.incomeCurrency,
        annualIncomeMin:
          state?.occupationStatus === "Not Employed"
            ? ""
            : state?.annualIncomeMin,
        annualIncomeMax:
          state?.occupationStatus === "Not Employed"
            ? ""
            : state?.annualIncomeMax,
      };
      dispatch(
        ProfileAction.updateProfile(payload, () => {
          setDisabled(false);
          dispatch(ProfileAction.getProfile());
          props?.handleTab("personalityHobbies");
        })
      );
    }
  };

  return (
    <>
      <TabHeading
        image={images.editProfileEducation}
        title="Education & Career"
        subtitle="Complete to easily find your ideal partner!"
      />
      <div className="edit-profile-content">
        {profileLoading ? (
          <Loader />
        ) : (
          <>
            {/* Education */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Education" required={true} />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={education}
              onChange={(e) => {
                setState({ ...state, education: e });
                setError({ ...error, education: "" });
              }}
              value={state.education || undefined}
            />
            {error?.education && <ErrorText text={error?.education} />}
            <Spacer height="12px" />
            {/* Education institute */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="School / College / University Name" />
            </div>
            <Spacer height="8px" />
            <div className="edit-profile-input mobile">
              <Select
                suffixIcon={<img src={images.dropdownIcon} alt="dropdown" />}
                className="edit-profile-select"
                value={state.instituteName || undefined}
                placeholder="Please select"
                onChange={(e) => {
                  setState({ ...state, instituteName: e });
                }}
                onSearch={(value) => {
                  setSearch(value);
                }}
                optionFilterProp="children"
                showSearch
              >
                {universities?.map((uni, index) => (
                  <Select.Option value={uni.name} key={index}>
                    {uni.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <Spacer height="12px" />
            {/* Profession */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Profession" required={true} />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={profession}
              onChange={(e) => {
                setState({ ...state, profession: e });
                setError({ ...error, profession: "" });
              }}
              value={state.profession || undefined}
            />
            {error?.profession && <ErrorText text={error?.profession} />}
            <Spacer height="12px" />
            {/* Occupation Status */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Occupation Status" required={true} />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={occupationStatus}
              onChange={(e) => {
                setState({ ...state, occupationStatus: e });
                setError({ ...error, occupationStatus: "" });
              }}
              value={state.occupationStatus || undefined}
            />
            {error?.occupationStatus && (
              <ErrorText text={error?.occupationStatus} />
            )}
            <Spacer height="12px" />

            {/* Annual Income */}
            {state.occupationStatus !== "Not Employed" && (
              <>
                <div className="edit-profile-label-container">
                  <EditProfileBoxLabel label="Annual Income" />
                </div>
                <Spacer height="8px" />
                <EditProfileDropdown
                  placeholder="Please select"
                  data={incomeCurrency}
                  onChange={(e) => setState({ ...state, incomeCurrency: e })}
                  value={state.incomeCurrency || undefined}
                />
                <Spacer height="8px" />
                <div className="edit-profile-annual-income-container">
                  <div style={{ width: "45%" }}>
                    <EditProfileDropdown
                      placeholder="Minimum annual income"
                      data={filteredIncomeMinOptions}
                      onChange={(e) => {
                        setState({ ...state, annualIncomeMin: e });
                        setError({
                          ...error,
                          incomeError: "",
                        });
                      }}
                      value={state.annualIncomeMin || undefined}
                    />
                  </div>
                  <div style={{ width: "10%", textAlign: "center" }}>to</div>
                  <div style={{ width: "45%" }}>
                    <EditProfileDropdown
                      placeholder="Maximum annual income"
                      data={filteredIncomeMaxOptions}
                      onChange={(e) => {
                        setState({ ...state, annualIncomeMax: e });
                        setError({
                          ...error,
                          incomeError: "",
                        });
                      }}
                      value={state.annualIncomeMax || undefined}
                    />
                  </div>
                </div>
                {error?.incomeError && <ErrorText text={error?.incomeError} />}
                <Spacer height="12px" />
              </>
            )}

            {/* Islamic Institute Attended */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Islamic Institute Attended" />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
              onChange={(e) =>
                setState({ ...state, islamicInstituteAttended: e })
              }
              value={state.islamicInstituteAttended || undefined}
            />
          </>
        )}
        <Spacer height="32px" />
        <PrimaryButton
          text="Save & Continue"
          onClick={handleSubmit}
          disabled={isDisabled}
        />
      </div>
    </>
  );
};

export default EducationCareer;
