import React from "react";
import { Link } from "react-router-dom";

import images from "../../../assets";
import "./styles.css";

export default function MembershipPlanLayout(props) {
  return (
    <div {...props} className="membership-payment-container">
      <div className="membership-payment-content-container">
        <div className="rounded-lg bg-[#F3F3FA] border border-primary50 font-semibold text-[12px] flex sm:justify-center items-center overflow-auto py-[5px] px-[15px] w-[90vw] sm:w-max gap-2">
          <img src={images.homeIcon} alt="icon" className="mr-2.5" />
          {props?.breadcrumbs.map((breadcrumb, index) => (
            <div className="flex items-center" key={index}>
              {breadcrumb.route ? (
                <Link to={breadcrumb.route} className="mb-0">
                  {breadcrumb.label}
                </Link>
              ) : (
                <p className="mb-0">{breadcrumb.label}</p>
              )}
              {index !== props?.breadcrumbs.length - 1 && (
                <img
                  src={images.whiteButtonBack}
                  alt="icon"
                  className="mx-3 rotate-180"
                />
              )}
            </div>
          ))}
        </div>
        <div className="membership-payment-heading">
          <h1>{props.titlename}</h1>
        </div>
        {props.children}
      </div>
    </div>
  );
}
