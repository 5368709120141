import {
  UPDATE_MATCH_PREFERENCE,
  UPDATE_MATCH_PREFERENCE_SUCCESS,
  UPDATE_MATCH_PREFERENCE_FAILURE,
} from "../constants";

export default class MatchAction {
  static updateMatchPreference(payload, cb) {
    return {
      type: UPDATE_MATCH_PREFERENCE,
      payload,
      cb,
    };
  }
  static updateMatchPreferenceSuccess(payload) {
    return {
      type: UPDATE_MATCH_PREFERENCE_SUCCESS,
      payload,
    };
  }
  static updateMatchPreferenceFailure() {
    return {
      type: UPDATE_MATCH_PREFERENCE_FAILURE,
    };
  }
}
