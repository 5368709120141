import React from "react";

const WhiteButton = (props) => {
  return (
    <button
      {...props}
      className="w-full rounded-xl bg-white font-medium text-[16px] text-basePrimary h-12 border-2 border-basePrimary whitespace-nowrap overflow-hidden text-ellipsis"
      title={props?.text}
    >
      {props.text}
    </button>
  );
};

export default WhiteButton;
