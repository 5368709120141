import React from "react";
import { Select } from "antd";
import images from "../../assets";
import "./styles.css";

const SortDropdown = (props) => {
  const filterOption = (input, option) => {
    return (option?.value?.toString() ?? "")
      .toLowerCase()
      .startsWith(input.toLowerCase());
  };

  return (
    <div className="sort-input">
      <Select
        {...props}
        suffixIcon={<img src={images.dropdownIcon} alt="dropdown" />}
        showSearch
        placeholder={props?.placeholder || "Please select"}
        optionFilterProp="children"
        filterOption={filterOption}
        className="edit-profile-select"
      >
        {props?.data?.map((item, index) => {
          return (
            <Select.Option key={index} value={item.value}>
              {item.label}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};

export default SortDropdown;
