import React from "react";
import { FaCircle } from "react-icons/fa6";

const CardBottomButton = (props) => {
  return (
    <button
      {...props}
      className={` ${
        props?.disabled ? "card-bottom-icon-disabled" : "card-bottom-icon"
      }`}
    >
      <FaCircle className="card-bottom-circle" />
      <img src={props.icon} className="card-bottom-icon-fixed" alt="icon" />
    </button>
  );
};

export default CardBottomButton;
