import React, { useState } from "react";
import useScreenSize from "../../../utils/usescreensize";
import PrimaryButton from "../../button/primarybutton";
import WhiteButton from "../../button/whitebutton";

import images from "../../../assets";

const Navbar = () => {
  const screenSize = useScreenSize();

  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!isOpen);
  };

  const navbarItems = [
    { text: "Home", route: "/" },
    { text: "Blog", route: "/blog" },
    { text: "How it works", route: "/howItWorks" },
    { text: "Membership Plans", route: "/plan" },
    { text: "About Us", route: "/about" },
    { text: "Contact Us", route: "/contact" },
  ];
  return (
    <>
      <div className="px-4 lg:px-8 fixed lg:h-[80px] flex items-center justify-between bg-white lg:bg-transparent w-full lg:w-auto z-50 shadow-lg lg:shadow-none">
        <img src={images.mainLogo} className="py-1 lg:py-0" alt="logo" />
        {screenSize.width < 1024 && (
          <div onClick={() => handleOpen()}>
            {isOpen ? (
              <img
                src={images.navbarCross}
                className="p-2 rounded-md border border-n20 bg-n10"
                alt="cross"
              />
            ) : (
              <img
                src={images.hamburger}
                className="p-2 rounded-md border border-n20 bg-n10"
                alt="hamburger"
              />
            )}
          </div>
        )}
      </div>
      {screenSize.width < 1024 && (
        <div
          className={`flex flex-col justify-between transition-all duration-300 ease-in-out absolute w-full h-full bg-white p-5 z-20 ${
            isOpen ? "left-0 " : "left-[-100vw]"
          }`}
        >
          <div className="flex flex-col mt-[80px]">
            {navbarItems?.map((item, index) => (
              <div
                className="p-2 text-[16px] font-normal mb-4 text-basePrimary"
                key={index}
              >
                {item.text}
              </div>
            ))}
          </div>
          <div className="p-2">
            <div className="text-[18px] font-semibold text-basePrimary mb-2">
              Download our App
            </div>
            <img src={images.googlePlay} alt="googleplay" />
          </div>
          <div>
            <div className="p-4">
              <WhiteButton text={"Register"} />
            </div>
            <div className="p-4">
              <PrimaryButton text={"Login"} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
