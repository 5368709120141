/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateRoom = /* GraphQL */ `
  subscription OnCreateRoom {
    onCreateRoom {
      id
      senderId
      receiverId
      senderName
      senderImageUrl
      receiverName
      receiverImageUrl
      lastMessage
      lastSenderId
      lastMessageRead
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateRoom = /* GraphQL */ `
  subscription OnUpdateRoom {
    onUpdateRoom {
      id
      senderId
      receiverId
      senderName
      senderImageUrl
      receiverName
      receiverImageUrl
      lastMessage
      lastSenderId
      lastMessageRead
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateMessageByRoomId = /* GraphQL */ `
  subscription OnCreateMessageByRoomId($roomId: ID) {
    onCreateMessageByRoomId(roomId: $roomId) {
      id
      content {
        text
        sender
        __typename
      }
      createdAt
      updatedAt
      roomId
      status
      __typename
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage($roomId: ID) {
    onUpdateMessage(roomId: $roomId) {
      id
      content {
        text
        sender
        __typename
      }
      createdAt
      updatedAt
      roomId
      status
      __typename
    }
  }
`;
export const onUserStatusChange = /* GraphQL */ `
  subscription OnUserStatusChange($username: String!) {
    onUserStatusChange(username: $username) {
      username
      onlineStatus
      __typename
    }
  }
`;
export const onMessageStatusUpdate = /* GraphQL */ `
  subscription OnMessageStatusUpdate {
    onMessageStatusUpdate {
      roomId
      __typename
    }
  }
`;
