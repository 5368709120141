import BasicInfoColumn from "../../components/profilecard/basicinfocolumn";
import PrimaryButton from "../../components/button/primarybutton";
import { useSelector } from "react-redux";
import images from "../../assets";
import "./styles.css";

export default function FamilyDetails(props) {
  const subscriptionData = useSelector((state) => state?.subscription?.plan);

  return (
    <>
      <div className="family-details basic-info-card">
        <div className="basic-info-head">
          <img src={images.familyIcon} alt="Family Details" />{" "}
          <span>Family Details</span>
        </div>
        {subscriptionData?.name ? (
          <div className="basic-info-content">
            {props?.othersProfileData?.fatherStatus && (
              <BasicInfoColumn
                titlename="Father’s Status"
                values={props?.othersProfileData?.fatherStatus}
              />
            )}
            {props?.othersProfileData?.motherStatus && (
              <BasicInfoColumn
                titlename="Mother’s Status"
                values={props?.othersProfileData?.motherStatus}
              />
            )}
            {props?.othersProfileData?.financialStatus && (
              <BasicInfoColumn
                titlename="Financial Status"
                values={props?.othersProfileData?.financialStatus}
              />
            )}
            {props?.othersProfileData?.familyCountry &&
              props?.othersProfileData?.familyStateProvince && (
                <BasicInfoColumn
                  titlename="Family Location"
                  values={`${props?.othersProfileData?.familyStateProvince}, ${props?.othersProfileData?.familyCountry}`}
                />
              )}
            {props?.othersProfileData?.fatherEthnicOrigin && (
              <BasicInfoColumn
                titlename="Father’s Ethnic Origin"
                values={props?.othersProfileData?.fatherEthnicOrigin}
              />
            )}
            {props?.othersProfileData?.motherEthnicOrigin && (
              <BasicInfoColumn
                titlename="Mother’s Ethnic Origin"
                values={props?.othersProfileData?.motherEthnicOrigin}
              />
            )}
            {props?.othersProfileData?.maleSiblingMarried > 0 && (
              <BasicInfoColumn
                titlename="Male Sibling (Married)"
                values={props?.othersProfileData?.maleSiblingMarried}
              />
            )}
            {props?.othersProfileData?.maleSiblingUnmarried > 0 && (
              <BasicInfoColumn
                titlename="Male Sibling (Unmarried)"
                values={props?.othersProfileData?.maleSiblingUnmarried}
              />
            )}
            {props?.othersProfileData?.femaleSiblingMarried > 0 && (
              <BasicInfoColumn
                titlename="Female Sibling (Married)"
                values={props?.othersProfileData?.femaleSiblingMarried}
              />
            )}
            {props?.othersProfileData?.femaleSiblingUnmarried > 0 && (
              <BasicInfoColumn
                titlename="Female Sibling (Unmarried)"
                values={props?.othersProfileData?.femaleSiblingUnmarried}
              />
            )}
            {props?.othersProfileData?.maleChildren > 0 && (
              <BasicInfoColumn
                titlename="Male Children"
                values={props?.othersProfileData?.maleChildren}
              />
            )}
            {props?.othersProfileData?.femaleChildren > 0 && (
              <BasicInfoColumn
                titlename="Female Children"
                values={props?.othersProfileData?.femaleChildren}
              />
            )}
            {props?.othersProfileData?.familyType && (
              <BasicInfoColumn
                titlename="Family Type"
                values={props?.othersProfileData?.familyType}
              />
            )}
            {props?.othersProfileData?.familyValues && (
              <BasicInfoColumn
                titlename="Family Values"
                values={props?.othersProfileData?.familyValues}
              />
            )}
          </div>
        ) : (
          <div className="basic-info-content">
            <div className="basic-info-column">
              <div className="basic-info-title">
                <p>Father’s Status</p>
                <p>Mother’s Status</p>
                <p>Financial Status</p>
                <p>Family Location</p>
                <p>Father’s Ethnic Origin</p>
                <p>Mother’s Ethnic Origin</p>
                <p>Family Type</p>
                <p>Family Values</p>
                <p>Male Sibling</p>
                <p>Female Sibling</p>
              </div>
              <div className="basic-info-value-hidden">
                <div className="blure-bg">
                  <p>: Unknown</p>
                  <p>: Unknown xx</p>
                  <p>: Unknown xx</p>
                  <p>: Unknownxx</p>
                  <p>: Unknown</p>
                  <p>: Unknown xxx xx</p>
                  <p>: Unknown</p>
                  <p>: Unknown x</p>
                  <p>: xx</p>
                  <p>: xx</p>
                </div>
                <div className="upgrade-family-info">
                  <div className="upgrade-family-content">
                    <img src={images.upgradeLock} alt="" />
                    <h2>UPGRADE</h2>
                    <p>to see Family details</p>
                  </div>
                  <PrimaryButton
                    text="View Plans"
                    style={{
                      width: "auto",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      height: "32px",
                      borderRadius: "8px",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
