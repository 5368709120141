import BasicInfoColumn from "../../components/profilecard/basicinfocolumn";

import images from "../../assets";
import "./styles.css";

export default function ReligiousStatus(props) {
  return (
    <div className="religious-status basic-info-card">
      <div className="basic-info-head">
        <img src={images.religiousIcon} alt="Religious Status" />{" "}
        <span>Religious Status</span>
      </div>
      <div className="basic-info-content">
        {props?.othersProfileData?.religiousHistory && (
          <BasicInfoColumn
            titlename="Religious History"
            values={props?.othersProfileData?.religiousHistory}
          />
        )}
        {props?.othersProfileData?.religiousSect && (
          <BasicInfoColumn
            titlename="Sect"
            values={props?.othersProfileData?.religiousSect}
          />
        )}
        {props?.othersProfileData?.polygamy && (
          <BasicInfoColumn
            titlename="Polygamy"
            values={props?.othersProfileData?.polygamy}
          />
        )}
        {props?.othersProfileData?.dailyPrayer && (
          <BasicInfoColumn
            titlename="Pray"
            values={props?.othersProfileData?.dailyPrayer}
          />
        )}
        {props?.othersProfileData?.readQuran && (
          <BasicInfoColumn
            titlename="How often do you read Quran?"
            values={props?.othersProfileData?.readQuran}
          />
        )}
        {props?.othersProfileData?.readIslamicBook && (
          <BasicInfoColumn
            titlename="Do you read Islamic books, lectures?"
            values={props?.othersProfileData?.readIslamicBook}
          />
        )}
        {props?.othersProfileData?.religiousCommitment && (
          <BasicInfoColumn
            titlename="Relgious Commitment Level"
            values={props?.othersProfileData?.religiousCommitment}
          />
        )}
      </div>
    </div>
  );
}
