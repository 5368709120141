const education = [
  {
    label: "Primary (Elementary) School",
    value: "Primary (Elementary) School",
  },
  {
    label: "Middle School / Junior High",
    value: "Middle School / Junior High",
  },
  { label: "High School", value: "High School" },
  { label: "Vocational / Diploma", value: "Vocational / Diploma" },
  { label: "Bachelors Degree", value: "Bachelors Degree" },
  { label: "Masters Degree", value: "Masters Degree" },
  { label: "PhD / Doctorate", value: "PhD / Doctorate" },
];
export default education;
