function getFirstLetters(str) {
  if (!str) {
    return null;
  }
  let words = str.split(" ");

  let result = "";

  for (let i = 0; i < words.length; i++) {
    if (words[i].length > 0) {
      result += words[i][0];
    }
  }

  return result;
}

export default getFirstLetters;
