import React from "react";

import ProfileCompleteInfoCard from "./profilecompleteinfocard";

import images from "../../../assets";
import "./styles.css";

const getCompletedState = (total, filled) => {
  const completed = (filled / total) * 100;
  return completed === 100 ? "complete" : "incomplete";
};

const PercentageComponent = (props) => {
  return (
    <>
      <ProfileCompleteInfoCard
        icon={
          getCompletedState(
            props?.profileCompleteBreak?.basicInfo?.totalFields,
            props?.profileCompleteBreak?.basicInfo?.filledFields
          )
            ? images.checkIcon
            : images.upComProgressCheck
        }
        titletext="Basic Info"
        route="/edit-profile?tab=basicInfo"
        completenum={`${props?.profileCompleteBreak?.basicInfo?.filledFields}/${props?.profileCompleteBreak?.basicInfo?.totalFields}`}
        completepercent={`+${props?.profileCompleteBreak?.basicInfo?.maxPoints}%`}
        className={getCompletedState(
          props?.profileCompleteBreak?.basicInfo?.totalFields,
          props?.profileCompleteBreak?.basicInfo?.filledFields
        )}
      />
      <ProfileCompleteInfoCard
        icon={
          getCompletedState(
            props?.profileCompleteBreak?.physicalAppearance?.totalFields,
            props?.profileCompleteBreak?.physicalAppearance?.filledFields
          )
            ? images.checkIcon
            : images.upComProgressCheck
        }
        titletext="Physical Appearance"
        route="/edit-profile?tab=physicalLifestyle"
        completenum={`${props?.profileCompleteBreak?.physicalAppearance?.filledFields}/${props?.profileCompleteBreak?.physicalAppearance?.totalFields}`}
        completepercent={`+${props?.profileCompleteBreak?.physicalAppearance?.maxPoints}%`}
        className={getCompletedState(
          props?.profileCompleteBreak?.physicalAppearance?.totalFields,
          props?.profileCompleteBreak?.physicalAppearance?.filledFields
        )}
      />
      <ProfileCompleteInfoCard
        icon={
          getCompletedState(
            props?.profileCompleteBreak?.familyDetails?.totalFields,
            props?.profileCompleteBreak?.familyDetails?.filledFields
          )
            ? images.checkIcon
            : images.upComProgressCheck
        }
        titletext="Family Details"
        route="/edit-profile?tab=familyDetails"
        completenum={`${props?.profileCompleteBreak?.familyDetails?.filledFields}/${props?.profileCompleteBreak?.familyDetails?.totalFields}`}
        completepercent={`+${props?.profileCompleteBreak?.familyDetails?.maxPoints}%`}
        className={getCompletedState(
          props?.profileCompleteBreak?.familyDetails?.totalFields,
          props?.profileCompleteBreak?.familyDetails?.filledFields
        )}
      />
      <ProfileCompleteInfoCard
        icon={
          getCompletedState(
            props?.profileCompleteBreak?.religiousStatus?.totalFields,
            props?.profileCompleteBreak?.religiousStatus?.filledFields
          )
            ? images.checkIcon
            : images.upComProgressCheck
        }
        titletext="Religious Status"
        route="/edit-profile?tab=religiousStatus"
        completenum={`${props?.profileCompleteBreak?.religiousStatus?.filledFields}/${props?.profileCompleteBreak?.religiousStatus?.totalFields}`}
        completepercent={`+${props?.profileCompleteBreak?.religiousStatus?.maxPoints}%`}
        className={getCompletedState(
          props?.profileCompleteBreak?.religiousStatus?.totalFields,
          props?.profileCompleteBreak?.religiousStatus?.filledFields
        )}
      />
      <ProfileCompleteInfoCard
        icon={
          getCompletedState(
            props?.profileCompleteBreak?.educationCareer?.totalFields,
            props?.profileCompleteBreak?.educationCareer?.filledFields
          )
            ? images.checkIcon
            : images.upComProgressCheck
        }
        titletext="Education & Employment"
        route="/edit-profile?tab=educationCareer"
        completenum={`${props?.profileCompleteBreak?.educationCareer?.filledFields}/${props?.profileCompleteBreak?.educationCareer?.totalFields}`}
        completepercent={`+${props?.profileCompleteBreak?.educationCareer?.maxPoints}%`}
        className={getCompletedState(
          props?.profileCompleteBreak?.educationCareer?.totalFields,
          props?.profileCompleteBreak?.educationCareer?.filledFields
        )}
      />
      <ProfileCompleteInfoCard
        icon={
          getCompletedState(
            props?.profileCompleteBreak?.personalityHobbies?.totalFields,
            props?.profileCompleteBreak?.personalityHobbies?.filledFields
          )
            ? images.checkIcon
            : images.upComProgressCheck
        }
        titletext="Personalities & Hobbies"
        route="/edit-profile?tab=personalityHobbies"
        completenum={`${props?.profileCompleteBreak?.personalityHobbies?.filledFields}/${props?.profileCompleteBreak?.personalityHobbies?.totalFields}`}
        completepercent={`+${props?.profileCompleteBreak?.personalityHobbies?.maxPoints}%`}
        className={getCompletedState(
          props?.profileCompleteBreak?.personalityHobbies?.totalFields,
          props?.profileCompleteBreak?.personalityHobbies?.filledFields
        )}
      />
      <ProfileCompleteInfoCard
        icon={
          getCompletedState(
            props?.profileCompleteBreak?.profileDescription?.totalFields,
            props?.profileCompleteBreak?.profileDescription?.filledFields
          )
            ? images.checkIcon
            : images.upComProgressCheck
        }
        titletext="Profile Description"
        route="/edit-profile?tab=profileDesc"
        completenum={`${props?.profileCompleteBreak?.profileDescription?.filledFields}/${props?.profileCompleteBreak?.profileDescription?.totalFields}`}
        completepercent={`+${props?.profileCompleteBreak?.profileDescription?.maxPoints}%`}
        className={getCompletedState(
          props?.profileCompleteBreak?.profileDescription?.totalFields,
          props?.profileCompleteBreak?.profileDescription?.filledFields
        )}
      />
      <ProfileCompleteInfoCard
        icon={
          getCompletedState(
            props?.profileCompleteBreak?.photos?.totalFields,
            props?.profileCompleteBreak?.photos?.filledFields
          )
            ? images.checkIcon
            : images.upComProgressCheck
        }
        titletext="Photos"
        route="/gallery"
        completenum={`${props?.profileCompleteBreak?.photos?.filledFields}/${props?.profileCompleteBreak?.photos?.totalFields}`}
        completepercent={`+${props?.profileCompleteBreak?.photos?.maxPoints}%`}
        className={getCompletedState(
          props?.profileCompleteBreak?.photos?.totalFields,
          props?.profileCompleteBreak?.photos?.filledFields
        )}
      />
      <ProfileCompleteInfoCard
        icon={
          getCompletedState(
            props?.profileCompleteBreak?.trustbadge?.totalFields,
            props?.profileCompleteBreak?.trustbadge?.filledFields
          )
            ? images.checkIcon
            : images.upComProgressCheck
        }
        titletext="Trust Badge"
        route="/trust-badge"
        completenum={`${props?.profileCompleteBreak?.trustbadge?.filledFields}/${props?.profileCompleteBreak?.trustbadge?.totalFields}`}
        completepercent={`+${props?.profileCompleteBreak?.trustbadge?.maxPoints}%`}
        className={getCompletedState(
          props?.profileCompleteBreak?.trustbadge?.totalFields,
          props?.profileCompleteBreak?.trustbadge?.filledFields
        )}
      />
    </>
  );
};

export default PercentageComponent;
