import React from "react";
import { DatePicker } from "antd";
import images from "../../assets";

import "./styles.css";

const EditProfileDatepicker = (props) => {
  return (
    <div className="edit-profile-input">
      <DatePicker
        {...props}
        suffixIcon={<img src={images.datepickerIcon} alt="datepicker" />}
        className="edit-profile-datepicker"
        // format="DD/MM/YYYY"
      />
    </div>
  );
};

export default EditProfileDatepicker;
