import {
  GET_NEW_MEMBERS,
  GET_NEW_MEMBERS_SUCCESS,
  GET_NEW_MEMBERS_FAILURE,
} from "../constants";

const initialState = {
  newMembers: [],
  newMembersCount: 0,
  isLoading: false,
  error: null,
};

export default function NewMembersReducer(state = initialState, action) {
  switch (action.type) {
    case GET_NEW_MEMBERS:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_NEW_MEMBERS_SUCCESS:
      state = {
        ...state,
        newMembers: action.payload.data,
        newMembersCount: action.payload.count,
        isLoading: false,
      };
      break;

    case GET_NEW_MEMBERS_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }

  return state;
}
