import {
  GET_OTHERS_PROFILE,
  GET_OTHERS_PROFILE_SUCCESS,
  GET_OTHERS_PROFILE_FAILURE,
} from "../constants";

export default class OthersProfileAction {
  static getOthersProfile(payload, cb) {
    return {
      type: GET_OTHERS_PROFILE,
      payload,
      cb,
    };
  }
  static getOthersProfileSuccess(payload) {
    return {
      type: GET_OTHERS_PROFILE_SUCCESS,
      payload,
    };
  }
  static getOthersProfileFailure() {
    return {
      type: GET_OTHERS_PROFILE_FAILURE,
    };
  }
}
