import React from "react";
import Spacer from "../../components/spacer/spacer";
import { TextInput } from "../../components/inputbox";
import { PrimaryButton } from "../../components/button";

import images from "../../assets";

const EmailForm = (props) => {
  return (
    <>
      <img src={images?.changePassword} alt="" />
      <Spacer height="32px" />
      <h2 className="forgot-password-title">Enter New Email</h2>
      <Spacer height="24px" />
      <TextInput
        className="password-input"
        type="email"
        id="email"
        placeholder="Your email address"
        onChange={(e) =>
          props?.setState({ ...props?.state, email: e.target.value, error: "" })
        }
      />
      {props?.state?.error && (
        <div className="text-center">
          <p className="text-danger font-semibold text-[14px] m-0">
            {props?.state?.error}
          </p>
        </div>
      )}
      <Spacer height="32px" />
      <PrimaryButton text={"Send OTP"} onClick={props?.handleEmailSubmit} />
    </>
  );
};

export default EmailForm;
