const weekend = [
  { label: "Dinner at a restaurant", value: "Dinner at a restaurant" },
  { label: "Gardening", value: "Gardening" },
  { label: "Go out of town", value: "Go out of town" },
  { label: "Household work", value: "Household work" },
  { label: "Visit families", value: "Visit families" },
  { label: "Visit friends", value: "Visit friends" },
  { label: "Watch movies", value: "Watch movies" },
  { label: "Go to park", value: "Go to park" },
  { label: "Hiking", value: "Hiking" },
  { label: "Camping", value: "Camping" },
];
export default weekend;
