import { combineReducers } from "redux";

import AuthReducer from "./authreducer";
import BlocklistReducer from "./blocklistreducer";
import ContactViewerReducer from "./contactviewerreducer";
import InterestReducer from "./interestsreducer";
import MatchReducer from "./matchreducer";
import MessageReducer from "./messagereducer";
import MyNotesReducer from "./mynotesreducer";
import NewMembersReducer from "./newmembersreducer";
import NotificationReducer from "./notificationreducer";
import OthersProfileReducer from "./othersprofilereducer";
import PhotoreqReducer from "./photoreqreducer";
import ProfileReducer from "./profilereducer";
import ProfileViewerReducer from "./profileviewerreducer";
import SearchReducer from "./searchreducer";
import ShortlistReducer from "./shortlistreducer";
import SubscriptionReducer from "./subscriptionreducer";
import UiReducer from "./uireducer";
import { LOGOUT_SUCCESS } from "../constants";

const appReducer = combineReducers({
  auth: AuthReducer,
  blocklist: BlocklistReducer,
  contactView: ContactViewerReducer,
  interests: InterestReducer,
  matches: MatchReducer,
  message: MessageReducer,
  myNotes: MyNotesReducer,
  newMembers: NewMembersReducer,
  notifications: NotificationReducer,
  othersProfile: OthersProfileReducer,
  photoreq: PhotoreqReducer,
  profile: ProfileReducer,
  profileView: ProfileViewerReducer,
  search: SearchReducer,
  shortlists: ShortlistReducer,
  subscription: SubscriptionReducer,
  ui: UiReducer,
});

const RootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default RootReducer;
