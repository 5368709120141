import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Avatar } from "antd";
import moment from "moment";

import { PrimaryButton } from "../../components/button";
import Spacer from "../../components/spacer/spacer";
import ProfileTab from "../../components/tab/profiletab";
import Loader from "../../components/loader/loader";
import useScreenSize from "../../utils/usescreensize";
import truncateString from "../../utils/truncatestring";
import getFirstLetters from "../../utils/getfirstletter";

import images from "../../assets";

import "./styles.css";

const MessagesSidebar = (props) => {
  const navigate = useNavigate();
  const activeUserRef = useRef(null);

  const profileData = useSelector((state) => state.profile?.profile);
  const screenSize = useScreenSize();

  useEffect(() => {
    if (activeUserRef.current) {
      activeUserRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [props.state.activeUser]);

  const handleTab = (name) => {
    props?.setState({ ...props?.state, tab: name });
  };
  const handleSearch = (value) => {
    props?.setState({ ...props?.state, search: value });
  };

  const tabItems = [
    { name: "All", title: "All" },
    { name: "Unread", title: "Unread" },
  ];

  return (
    <div className={"sidebar-full sidebar"}>
      {screenSize.width > 1023 && (
        <div className="sidebar-logo-container-basic">
          <img
            src={images.mainLogo}
            alt="logo"
            onClick={() => navigate("/dashboard")}
            style={{ cursor: "pointer" }}
          />
        </div>
      )}
      <div
        style={{
          height: "90vh",
          overflowY: "auto",
          overflowX: "hidden",
          width: "100%",
        }}
      >
        {screenSize.width > 1023 && (
          <>
            <PrimaryButton
              text={"Back"}
              icon={images.arrowIconLeft}
              iconalign="left"
              style={{ width: "120px" }}
              onClick={() => window.history.back()}
            />
            <Spacer height="16px" />
            <h1 className="sidebar-basic-search-title">Messages</h1>
            <Spacer height="16px" />
          </>
        )}
        {/* <div className="flex justify-center">
          <div style={{ width: "130px" }}>
            <ProfileTab
              tabItems={tabItems}
              handleTab={handleTab}
              activeTab={props?.state?.tab}
            />
          </div>
        </div> */}
        {/* <Spacer height="16px" /> */}
        <div className={"header-searchbar"}>
          <img src={images.headerSearch} alt="search" />
          <input
            type="text"
            placeholder="Enter Name"
            className="header-searchbar-input"
            value={props?.state?.search}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <Spacer height="16px" />
        {props?.userListLoading ? (
          <Loader />
        ) : (
          <div className="flex flex-col gap-2">
            {props?.userList?.map((item) => {
              let image = "";
              let name = "";
              let unread = false;

              if (item?.senderId === profileData?.username) {
                image = item?.receiverImageUrl;
                name = item?.receiverName;
              } else {
                image = item?.senderImageUrl;
                name = item?.senderName;
              }
              if (
                item?.lastSenderId !== profileData?.username &&
                item?.lastMessageRead
              ) {
                unread = true;
              }

              return (
                <div
                  key={item?.id}
                  className={
                    item?.id === props?.state?.activeUser?.id
                      ? "selected-message-component"
                      : unread
                      ? "unread-message-component"
                      : "message-component"
                  }
                  ref={
                    item?.id === props?.state?.activeUser?.id
                      ? activeUserRef
                      : null
                  }
                  onClick={() =>
                    props?.setState({ ...props?.state, activeUser: item })
                  }
                >
                  <div className="message-photo-container">
                    {item?.online && (
                      <img
                        src={images.cardActive}
                        className="message-active"
                        alt="active"
                      />
                    )}
                    {image && image !== "null" && image !== "undefined" ? (
                      <img src={image} alt="dp" className="message-photo" />
                    ) : (
                      <Avatar size={40}>{getFirstLetters(name)}</Avatar>
                    )}
                  </div>
                  <div className="messages-info-container">
                    <div className="flex justify-between">
                      <p className="name-text">{name}</p>
                      {unread && (
                        <img
                          src={images.unreadDot}
                          alt="active"
                          height={6}
                          width={6}
                        />
                      )}
                    </div>

                    <p className="last-message-text">
                      {item?.lastMessage && truncateString(item?.lastMessage)}
                    </p>

                    <p className="date-text">
                      {moment(item?.updatedAt).format("DD MMM YYYY - hh:mm A")}
                    </p>
                  </div>
                  {/* {unread && (
                    <div className="messages-unread-count">
                      {item?.unreadCount > 100 ? "99+" : item?.unreadCount}
                    </div>
                  )} */}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default MessagesSidebar;
