import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Switch, Modal } from "antd";

import { AuthAction, ProfileAction } from "../../store/actions";

import Loader from "../../components/loader/loader";
import EditProfileInput from "../../components/inputbox/editprofileinput";
import {
  ChangeButton,
  SettingsButton,
  LogoutButton,
} from "../../components/button";

import images from "../../assets";
import Spacer from "../../components/spacer/spacer";

import ChangeEmail from "./changeemail";

const AccountSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profileLoading = useSelector((state) => state?.profile?.isLoading);
  const profileData = useSelector((state) => state?.profile?.profile);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [state, setState] = useState({
    email: "",
    otp: "",
    newEmail: "",
    gender: "",
  });

  useEffect(() => {
    setState({
      ...state,
      email: profileData?.email ?? "",
      gender: profileData?.gender ?? "",
    });
  }, [profileLoading]);

  const handleLogout = () => {
    dispatch(
      ProfileAction.removeProfileStore(() => {
        dispatch(AuthAction.Logout());
      })
    );
  };

  const handlePhotoBlur = (e) => {
    const payload = {
      isPhotoBlured: e,
    };
    dispatch(
      ProfileAction.updateProfile(payload, () => {
        dispatch(ProfileAction.getProfile());
      })
    );
  };
  const handleContactHidden = (e) => {
    const payload = {
      isContactHidden: e,
    };
    dispatch(
      ProfileAction.updateProfile(payload, () => {
        dispatch(ProfileAction.getProfile());
      })
    );
  };

  return (
    <div>
      <h2 className="settings-content-header">My Profile</h2>
      <Spacer height="20px" />
      {profileLoading ? (
        <Loader />
      ) : (
        <>
          <h5 className="settings-content-subheader">Email Address</h5>
          <div className="flex lg:flex-row flex-col item-start justify-between lg:items-center gap-1">
            <p className="settings-content-subheader-subheader">
              The email address associated with your account.
            </p>
            <ChangeButton onClick={() => setIsModalOpen(true)} />
          </div>
          <Spacer height="8px" />
          <EditProfileInput
            placeholder="Email"
            onChange={(e) => {
              setState({ ...state, email: e.target.value });
            }}
            value={state.email || undefined}
            disabled
          />
          <div className="settings-content-divider"></div>

          <div className="flex lg:flex-row flex-col justify-between lg:items-center item-start gap-4 lg:gap-0">
            <div className="flex flex-col">
              <h5 className="settings-content-subheader">Password</h5>
              <Spacer height="8px" />
              <p className="settings-content-subheader-subheader">
                Set a unique password to protect your account.
              </p>
            </div>
            <SettingsButton
              text={"Change Password"}
              onClick={() => navigate("/change-password")}
            />
          </div>
          <div className="settings-content-divider"></div>
          <div className="flex lg:flex-row flex-col justify-between lg:items-center item-start gap-4 lg:gap-0">
            <div className="flex flex-col">
              <h5
                className="settings-content-subheader"
                style={{ color: "#F31260" }}
              >
                Deactivate Account
              </h5>
              <Spacer height="8px" />
              <p className="settings-content-subheader-subheader">
                We’d hate to see you go, but you’re welcome to deactivate your
                account anytime. Just remember, once you delete it, it’s gone
                forever.
              </p>
            </div>
            <SettingsButton
              text={"Deactivate"}
              onClick={() => navigate("/deactivate")}
            />
          </div>
          <Spacer height="42px" />
          <h2 className="settings-content-header">My Privacy</h2>
          <Spacer height="20px" />
          {state?.gender?.toLowerCase() === "male" && (
            <>
              <h5 className="settings-content-subheader">Photo Access</h5>
              <Spacer height="8px" />
              <div className="flex justify-between items-center">
                <p className="switch-text">Keep my photo blurred</p>
                <Switch
                  defaultChecked={profileData?.isPhotoBlured}
                  onChange={(e) => {
                    handlePhotoBlur(e);
                  }}
                />
              </div>
              <Spacer height="8px" />
              <p className="settings-content-subheader-subheader">
                Blur or reveal your photo. Males can toggle; females are blurred
                by default.
              </p>
              <div className="settings-content-divider"></div>
            </>
          )}
          <h5 className="settings-content-subheader">Contact Access</h5>
          <Spacer height="8px" />
          <div className="flex justify-between items-center">
            <p className="switch-text">Keep my number hidden</p>
            <Switch
              defaultChecked={profileData?.isContactHidden}
              onChange={(e) => {
                handleContactHidden(e);
              }}
              disabled={profileData?.gender === "male"}
            />
          </div>
          <Spacer height="8px" />
          <p className="settings-content-subheader-subheader">
            Only females can keep their number hidden.
          </p>
          <Spacer height="58px" />
          <LogoutButton onClick={handleLogout} />
        </>
      )}

      <Modal
        centered={true}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={false}
        closable={false}
        className="forgot-password-modal"
      >
        <img
          src={images?.closeIcon}
          className="modal-close-icon"
          alt=""
          onClick={() => setIsModalOpen(false)}
        />
        <ChangeEmail setIsModalOpen={setIsModalOpen} />
      </Modal>
    </div>
  );
};

export default AccountSettings;
