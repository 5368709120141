import {
  GET_PHOTOREQ,
  GET_PHOTOREQ_SUCCESS,
  GET_PHOTOREQ_FAILURE,
  GET_PHOTOREQ_ALL,
  GET_PHOTOREQ_ALL_SUCCESS,
  GET_PHOTOREQ_ALL_FAILURE,
  SEND_PHOTOREQ,
  SEND_PHOTOREQ_SUCCESS,
  SEND_PHOTOREQ_FAILURE,
  UPDATE_PHOTOREQ,
  UPDATE_PHOTOREQ_SUCCESS,
  UPDATE_PHOTOREQ_FAILURE,
} from "../constants";

export default class PhotoreqAction {
  static getPhotoreq(payload, cb) {
    return {
      type: GET_PHOTOREQ,
      payload,
      cb,
    };
  }
  static getPhotoreqSuccess(payload) {
    return {
      type: GET_PHOTOREQ_SUCCESS,
      payload,
    };
  }
  static getPhotoreqFailure() {
    return {
      type: GET_PHOTOREQ_FAILURE,
    };
  }
  static getPhotoreqAll(payload, cb) {
    return {
      type: GET_PHOTOREQ_ALL,
      payload,
      cb,
    };
  }
  static getPhotoreqAllSuccess(payload) {
    return {
      type: GET_PHOTOREQ_ALL_SUCCESS,
      payload,
    };
  }
  static getPhotoreqAllFailure() {
    return {
      type: GET_PHOTOREQ_ALL_FAILURE,
    };
  }
  static sendPhotoreq(payload, cb) {
    return {
      type: SEND_PHOTOREQ,
      payload,
      cb,
    };
  }
  static sendPhotoreqSuccess(payload) {
    return {
      type: SEND_PHOTOREQ_SUCCESS,
      payload,
    };
  }
  static sendPhotoreqFailure() {
    return {
      type: SEND_PHOTOREQ_FAILURE,
    };
  }
  static updatePhotoreq(payload, cb) {
    return {
      type: UPDATE_PHOTOREQ,
      payload,
      cb,
    };
  }
  static updatePhotoreqSuccess(payload) {
    return {
      type: UPDATE_PHOTOREQ_SUCCESS,
      payload,
    };
  }
  static updatePhotoreqFailure() {
    return {
      type: UPDATE_PHOTOREQ_FAILURE,
    };
  }
}
