import React from "react";
import { Slider } from "antd";

import { heightList } from "../../utils/arrayitems";

import "./styles.css";

const getHeightString = (height) => {
  const value = heightList.filter((item) => height === item.value);
  return value[0]?.label;
};

const HeightSlider = (props) => {
  const marks = {
    [props.value[0]]:
      getHeightString(props.value[0]) ?? `(${props.value[0]} cm)`,
    [props.value[1]]:
      getHeightString(props.value[1]) ?? `(${props.value[1]} cm)`,
  };

  return (
    <Slider
      {...props}
      marks={marks}
      range
      min={134}
      max={219}
      tooltip={{ open: false }}
      styles={{
        track: { backgroundColor: "#310048" },
      }}
      className="match-pref-slider"
    />
  );
};

export default HeightSlider;
