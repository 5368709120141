const hairLengthMale = [
  { label: "Bald", value: "Bald" },
  { label: "Shaved", value: "Shaved" },
  { label: "Medium", value: "Medium" },
  { label: "Long", value: "Long" },
  { label: "Changes frequently", value: "Changes frequently" },
];

const hairLengthFemale = [
  { label: "Shaved", value: "Shaved" },
  { label: "Medium", value: "Medium" },
  { label: "Long", value: "Long" },
  { label: "Changes frequently", value: "Changes frequently" },
];

export { hairLengthMale, hairLengthFemale };
