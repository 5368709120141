import { useSelector } from "react-redux";
import images from "../../assets";
import "./styles.css";
import PersonalityHobColumn from "./personalityhobcolumn";

export default function PersonalityHobbiCard() {
  const profileData = useSelector((state) => {
    return state?.profile?.profile;
  });

  return (
    <div className="personality-hobbies hobbies-info-card">
      <div className="basic-info-head ">
        <div className="h-[25px] w-[35px] border-[2px]  border-basePrimary rounded-md bg-[#eae6ed] flex justify-center items-center">
          <img
            src={images.personalityHobbiesIcon}
            alt=""
            className="h-[12px]"
          />{" "}
        </div>
        <span>Personality & Hobbies</span>
      </div>
      <div className="hobbies-info-content">
        {profileData?.foodPreference?.length > 0 && (
          <>
            <div className="hobbies-info-title">
              What sort of food do you like?
            </div>
            <PersonalityHobColumn>
              {profileData?.foodPreference?.map((item, index) => {
                if (index === 0) {
                  return <p key={index}>{item}</p>;
                } else {
                  return <p key={index}>{item}</p>;
                }
              })}
            </PersonalityHobColumn>
          </>
        )}
        {profileData?.musicPreference?.length > 0 && (
          <>
            <div style={{ paddingTop: "10px" }} className="hobbies-info-title">
              What sort of music do you like?
            </div>

            <PersonalityHobColumn>
              {profileData?.musicPreference?.map((item, index) => {
                if (index === 0) {
                  return <p key={index}>{item}</p>;
                } else {
                  return <p key={index}>{item}</p>;
                }
              })}
            </PersonalityHobColumn>
          </>
        )}
        {profileData?.personalityTraits?.length > 0 && (
          <>
            <div style={{ paddingTop: "10px" }} className="hobbies-info-title">
              What are your main personality traits?
            </div>

            <PersonalityHobColumn>
              {profileData?.personalityTraits?.map((item, index) => {
                if (index === 0) {
                  return <p key={index}>{item}</p>;
                } else {
                  return <p key={index}>{item}</p>;
                }
              })}
            </PersonalityHobColumn>
          </>
        )}
        {profileData?.visitedCountries?.length > 0 && (
          <>
            <div style={{ paddingTop: "10px" }} className="hobbies-info-title">
              What are the countries you visited?
            </div>

            <PersonalityHobColumn>
              {profileData?.visitedCountries?.map((item, index) => {
                if (index === 0) {
                  return <p key={index}>{item}</p>;
                } else {
                  return <p key={index}>{item}</p>;
                }
              })}
            </PersonalityHobColumn>
          </>
        )}
        {profileData?.weekendPreference?.length > 0 && (
          <>
            <div style={{ paddingTop: "10px" }} className="hobbies-info-title">
              How would you like to spend weekend with your family?
            </div>

            <PersonalityHobColumn>
              {profileData?.weekendPreference?.map((item, index) => {
                if (index === 0) {
                  return <p key={index}>{item}</p>;
                } else {
                  return <p key={index}>{item}</p>;
                }
              })}
            </PersonalityHobColumn>
          </>
        )}
        {profileData?.bookPreference?.length > 0 && (
          <>
            <div style={{ paddingTop: "10px" }} className="hobbies-info-title">
              What are the types of books you like?
            </div>

            <PersonalityHobColumn>
              {profileData?.bookPreference?.map((item, index) => {
                if (index === 0) {
                  return <p key={index}>{item}</p>;
                } else {
                  return <p key={index}>{item}</p>;
                }
              })}
            </PersonalityHobColumn>
          </>
        )}
        {profileData?.hobbies?.length > 0 && (
          <>
            <div style={{ paddingTop: "10px" }} className="hobbies-info-title">
              What are your hobbies?
            </div>

            <PersonalityHobColumn>
              {profileData?.hobbies?.map((item, index) => {
                if (index === 0) {
                  return <p key={index}>{item}</p>;
                } else {
                  return <p key={index}>{item}</p>;
                }
              })}
            </PersonalityHobColumn>
          </>
        )}
      </div>
    </div>
  );
}
