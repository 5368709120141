import {
  ADVANCED_SEARCH,
  ADVANCED_SEARCH_SUCCESS,
  ADVANCED_SEARCH_FAILURE,
  ADVANCED_SEARCH_PAYLOAD,
  ADVANCED_SEARCH_PAYLOAD_SUCCESS,
  ADVANCED_SEARCH_PAYLOAD_FAILURE,
  BASIC_SEARCH,
  BASIC_SEARCH_SUCCESS,
  BASIC_SEARCH_FAILURE,
  GET_MOD,
  GET_MOD_SUCCESS,
  GET_MOD_FAILURE,
  GET_UNIVERSITIES,
  GET_UNIVERSITIES_SUCCESS,
  GET_UNIVERSITIES_FAILURE,
  SEARCH_PAYLOAD,
  SEARCH_PAYLOAD_SUCCESS,
  SEARCH_PAYLOAD_FAILURE,
  QUICK_SEARCH_PAYLOAD,
  QUICK_SEARCH_PAYLOAD_SUCCESS,
  QUICK_SEARCH_PAYLOAD_FAILURE,
  QUICK_SEARCH,
  QUICK_SEARCH_SUCCESS,
  QUICK_SEARCH_FAILURE,
  RECOMMENDED_SEARCH,
  RECOMMENDED_SEARCH_SUCCESS,
  RECOMMENDED_SEARCH_FAILURE,
} from "../constants";

export default class SearchAction {
  static advancedSearch(payload, cb) {
    return {
      type: ADVANCED_SEARCH,
      payload,
      cb,
    };
  }
  static advancedSearchSuccess(payload) {
    return {
      type: ADVANCED_SEARCH_SUCCESS,
      payload,
    };
  }
  static advancedSearchFailure() {
    return {
      type: ADVANCED_SEARCH_FAILURE,
    };
  }
  static basicSearch(payload, cb) {
    return {
      type: BASIC_SEARCH,
      payload,
      cb,
    };
  }
  static basicSearchSuccess(payload) {
    return {
      type: BASIC_SEARCH_SUCCESS,
      payload,
    };
  }
  static basicSearchFailure() {
    return {
      type: BASIC_SEARCH_FAILURE,
    };
  }

  static getUniversities(payload, cb) {
    return {
      type: GET_UNIVERSITIES,
      payload,
      cb,
    };
  }
  static getUniversitiesSuccess(payload) {
    return {
      type: GET_UNIVERSITIES_SUCCESS,
      payload,
    };
  }
  static getUniversitiesFailure() {
    return {
      type: GET_UNIVERSITIES_FAILURE,
    };
  }
  static getMod(payload, cb) {
    return {
      type: GET_MOD,
      payload,
      cb,
    };
  }
  static getModSuccess(payload) {
    return {
      type: GET_MOD_SUCCESS,
      payload,
    };
  }
  static getModFailure() {
    return {
      type: GET_MOD_FAILURE,
    };
  }
  static searchPayload(payload, cb) {
    return {
      type: SEARCH_PAYLOAD,
      payload,
      cb,
    };
  }
  static searchPayloadSuccess(payload) {
    return {
      type: SEARCH_PAYLOAD_SUCCESS,
      payload,
    };
  }
  static searchPayloadFailure() {
    return {
      type: SEARCH_PAYLOAD_FAILURE,
    };
  }
  static advancedSearchPayload(payload, cb) {
    return {
      type: ADVANCED_SEARCH_PAYLOAD,
      payload,
      cb,
    };
  }
  static advancedSearchPayloadSuccess(payload) {
    return {
      type: ADVANCED_SEARCH_PAYLOAD_SUCCESS,
      payload,
    };
  }
  static advancedSearchPayloadFailure() {
    return {
      type: ADVANCED_SEARCH_PAYLOAD_FAILURE,
    };
  }
  static quickSearchPayload(payload, cb) {
    return {
      type: QUICK_SEARCH_PAYLOAD,
      payload,
      cb,
    };
  }
  static quickSearchPayloadSuccess(payload) {
    return {
      type: QUICK_SEARCH_PAYLOAD_SUCCESS,
      payload,
    };
  }
  static quickSearchPayloadFailure() {
    return {
      type: QUICK_SEARCH_PAYLOAD_FAILURE,
    };
  }
  static quickSearch(payload, cb) {
    return {
      type: QUICK_SEARCH,
      payload,
      cb,
    };
  }
  static quickSearchSuccess(payload) {
    return {
      type: QUICK_SEARCH_SUCCESS,
      payload,
    };
  }
  static quickSearchFailure() {
    return {
      type: QUICK_SEARCH_FAILURE,
    };
  }
  static recommendedSearch(payload, cb) {
    return {
      type: RECOMMENDED_SEARCH,
      payload,
      cb,
    };
  }
  static recommendedSearchSuccess(payload) {
    return {
      type: RECOMMENDED_SEARCH_SUCCESS,
      payload,
    };
  }
  static recommendedSearchFailure() {
    return {
      type: RECOMMENDED_SEARCH_FAILURE,
    };
  }
}
