import PersonalityHobColumn from "../../components/profilecard/personalityhobcolumn";

import images from "../../assets";
import "./styles.css";

export default function PersonalityHobbiCard(props) {
  return (
    <div className="personality-hobbies hobbies-info-card">
      <div className="basic-info-head">
        <div className="h-[25px] w-[35px] border-[2px]  border-basePrimary rounded-md bg-[#eae6ed] flex justify-center items-center">
          <img
            src={images.personalityHobbiesIcon}
            alt="personalityhobby"
            className="h-[12px]"
          />{" "}
        </div>
        <span>Personality & Hobbies</span>
      </div>
      <div className="hobbies-info-content">
        {props?.othersProfileData?.foodPreference?.length > 0 && (
          <>
            <div className="hobbies-info-title">
              What sort of food do you like?
            </div>
            <PersonalityHobColumn>
              {props?.othersProfileData?.foodPreference?.map((item, index) => {
                if (index === 0) {
                  return <p key={index}>{item}</p>;
                } else {
                  return <p key={index}>{item}</p>;
                }
              })}
            </PersonalityHobColumn>
          </>
        )}
        {props?.othersProfileData?.musicPreference?.length > 0 && (
          <>
            <div style={{ paddingTop: "10px" }} className="hobbies-info-title">
              What sort of music do you like?
            </div>

            <PersonalityHobColumn>
              {props?.othersProfileData?.musicPreference?.map(
                (item, index, key) => {
                  if (index === 0) {
                    return <p key={index}>{item}</p>;
                  } else {
                    return <p key={index}>{item}</p>;
                  }
                }
              )}
            </PersonalityHobColumn>
          </>
        )}
        {props?.othersProfileData?.personalityTraits?.length > 0 && (
          <>
            <div style={{ paddingTop: "10px" }} className="hobbies-info-title">
              What are your main personality traits?
            </div>

            <PersonalityHobColumn>
              {props?.othersProfileData?.personalityTraits?.map(
                (item, index, key) => {
                  if (index === 0) {
                    return <p key={index}>{item}</p>;
                  } else {
                    return <p key={index}>{item}</p>;
                  }
                }
              )}
            </PersonalityHobColumn>
          </>
        )}
        {props?.othersProfileData?.visitedCountries?.length > 0 && (
          <>
            <div style={{ paddingTop: "10px" }} className="hobbies-info-title">
              What are the countries you visited?
            </div>

            <PersonalityHobColumn>
              {props?.othersProfileData?.visitedCountries?.map(
                (item, index, key) => {
                  if (index === 0) {
                    return <p key={index}>{item}</p>;
                  } else {
                    return <p key={index}>{item}</p>;
                  }
                }
              )}
            </PersonalityHobColumn>
          </>
        )}
        {props?.othersProfileData?.weekendPreference?.length > 0 && (
          <>
            <div style={{ paddingTop: "10px" }} className="hobbies-info-title">
              How would you like to spend weekend with your family?
            </div>

            <PersonalityHobColumn>
              {props?.othersProfileData?.weekendPreference?.map(
                (item, index, key) => {
                  if (index === 0) {
                    return <p key={index}>{item}</p>;
                  } else {
                    return <p key={index}>{item}</p>;
                  }
                }
              )}
            </PersonalityHobColumn>
          </>
        )}
        {props?.othersProfileData?.bookPreference?.length > 0 && (
          <>
            <div style={{ paddingTop: "10px" }} className="hobbies-info-title">
              What are the types of books you like?
            </div>

            <PersonalityHobColumn>
              {props?.othersProfileData?.bookPreference?.map(
                (item, index, key) => {
                  if (index === 0) {
                    return <p key={index}>{item}</p>;
                  } else {
                    return <p key={index}>{item}</p>;
                  }
                }
              )}
            </PersonalityHobColumn>
          </>
        )}
        {props?.othersProfileData?.hobbies?.length > 0 && (
          <>
            <div style={{ paddingTop: "10px" }} className="hobbies-info-title">
              What are your hobbies?
            </div>

            <PersonalityHobColumn>
              {props?.othersProfileData?.hobbies?.map((item, index, key) => {
                if (index === 0) {
                  return <p key={index}>{item}</p>;
                } else {
                  return <p key={index}>{item}</p>;
                }
              })}
            </PersonalityHobColumn>
          </>
        )}
      </div>
    </div>
  );
}
