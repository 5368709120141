import React from "react";
import "./styles.css";

const EditProfileTextbox = (props) => {
  return (
    <div className="edit-profile-input">
      <textarea
        {...props}
        style={{
          width: "100%",
          outline: "none",
          background: "transparent",
        }}
        maxLength="1000"
        rows="10"
        type="textbox"
      />
    </div>
  );
};

export default EditProfileTextbox;
