export default function BasicInfoColumn(props) {
  return (
    <div {...props} className="basic-info-column">
      <div className="basic-info-title">{props.titlename}</div>
      <div className="basic-info-value">
        :<p>{props.values} </p>
        {props.icon && (
          <img src={props.icon} alt="Lock" className="lock-icon" />
        )}{" "}
      </div>
    </div>
  );
}
