import { put } from "redux-saga/effects";

import { UiAction } from "../actions";

export default class UiMiddleware {
  static *setCollapsed({ payload, cb }) {
    try {
      yield put(UiAction.setCollapsedSuccess(payload.collapsed));
    } catch (err) {
      yield put(UiAction.setCollapsedFailure());
    }
  }
}
