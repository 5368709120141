import React, { useState } from "react";
import images from "../../assets";

const BlurredImage = (props) => {
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            filter: "blur(5px)",
          }}
        >
          <img
            src={images?.basicInfoIcon}
            alt={"asdw"}
            style={{ width: "100%", height: "100%" }}
            {...props}
          />
        </div>
      )}
      <img
        src={props?.src}
        alt={props?.alt}
        style={{ width: "100%", height: "100%", opacity: loading ? 0 : 1 }}
        onLoad={handleLoad}
        {...props}
      />
    </div>
  );
};

export default BlurredImage;
