import React from "react";
import { Radio } from "antd";
export default function PaymentButton(props) {
  return (
    <div {...props} className="checkout-payment-main-btn">
      <div>
        <Radio className="radio-btn" value={props.value}>{props.titlename}</Radio>
      </div>
      <div>
        <img src={props.icon} alt={props.alt} />
      </div>
    </div>
  );
}
