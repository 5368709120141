const bodyType = [
  { label: "Athletic", value: "Athletic" },
  { label: "Average", value: "Average" },
  { label: "Few Extra Pounds", value: "Few Extra Pounds" },
  { label: "Muscular / Built", value: "Muscular / Built" },
  { label: "Overweight", value: "Overweight" },
  { label: "Petite", value: "Petite" },
  { label: "Skinny", value: "Skinny" },
];
export default bodyType;
