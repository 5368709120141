import { useSelector } from "react-redux";
import images from "../../assets";
import "./styles.css";

export default function MyIdealMatchCard() {
  const matchPrefData = useSelector((state) => {
    return state?.profile?.profile?.matchPreference;
  });
  return (
    <div className="myideal-match basic-info-card">
      <div className="basic-info-head">
        <img src={images.idealMatchIcon} alt="Religious Status" />{" "}
        <span>My Ideal Match</span>
      </div>
      <div className="ideal-match-desc-area">
        {matchPrefData?.idealMatchDescription ? (
          <p className="ideal-match-content">
            {matchPrefData?.idealMatchDescription}
          </p>
        ) : (
          <div className="text-secondary font-semibold text-[14px]">
            Your ideal match description is empty. Add a few details to let
            others know what you’re looking for in a partner!
          </div>
        )}
      </div>
    </div>
  );
}
