const personalityTraits = [
  { label: "Extrovert", value: "Extrovert" },
  { label: "Hardworking", value: "Hardworking" },
  { label: "Highly ambitious", value: "Highly ambitious" },
  { label: "Introvert", value: "Introvert" },
  { label: "Laid-back", value: "Laid-back" },
  { label: "Punctual", value: "Punctual" },
  { label: "Proactive", value: "Proactive" },
  { label: "Reactive", value: "Reactive" },
  { label: "Risk-taker", value: "Risk-taker" },
  { label: "Rational", value: "Rational" },
  { label: "Sensitive", value: "Sensitive" },
  { label: "Procrastinator", value: "Procrastinator" },
];
export default personalityTraits;
