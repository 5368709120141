import moment from "moment";
import dayjs from "dayjs";

import Spacer from "../../components/spacer/spacer";
import { PrimaryButton } from "../../components/button";
import EditProfileDatepicker from "../../components/datepicker/editprofiledatepicker";

const DateOfBirthForm = (props) => {
  const todayMinus18Years = dayjs().subtract(18, "year");

  const disableFutureDates = (currentDate) => {
    return currentDate && currentDate.isAfter(todayMinus18Years, "day");
  };

  return (
    <>
      <div className="change-email-head">
        <h2 className="change-email-title">Date of Birth Change Request</h2>
      </div>
      <div className="change-email-body">
        <p>
          Please select your date of birth. We will review your request and
          update it, and you will be notified soon.
        </p>
        <Spacer height="32px" />
        <EditProfileDatepicker
          placeholder="Select Date"
          onChange={(date, dateString) => {
            props.setDateOfBirth(dateString);
          }}
          disabledDate={disableFutureDates}
          defaultPickerValue={todayMinus18Years}
          value={
            props?.dateOfBirth ? moment(props?.dateOfBirth, "YYYY-MM-DD") : null
          }
        />
      </div>
      <div className="change-email-btn">
        <PrimaryButton text="Submit Request" onClick={props?.handleDobSubmit} />
      </div>
    </>
  );
};

export default DateOfBirthForm;
