import { useContext } from "react";
import NeedHelpColumn from "./needhelpcolumn";

import TawkContext from "../../TawkContext";

import images from "../../assets";

import "./styles.css";

export default function NeedHelpCard(props) {
  const tawkMessengerRef = useContext(TawkContext);

  const needHelpInfo = [
    {
      titleName: "Contact Us",
      values: "support@noblemarriage.net",
    },
    {
      titleName: "For Inquiries",
      values: "info@noblemarriage.com",
    },
    {
      titleName: "Success Stories",
      values: "info@noblemarriage.com",
    },
    {
      titleName: "For Assistance",
      values: (
        <div
          className="ml-1 cursor-pointer"
          onClick={() => {
            if (tawkMessengerRef && tawkMessengerRef.current) {
              tawkMessengerRef.current.showWidget();
              tawkMessengerRef.current.maximize();
            }
          }}
        >
          <span className="text-red-600 font-semibold"> Live Chat </span>, now
        </div>
      ),
    },
  ];
  return (
    <div className="need-help-body">
      <div {...props} className="need-help-inner">
        <div className="need-help-head">
          <h2>Need Help?</h2>
        </div>
        {needHelpInfo?.map((item, index) => (
          <NeedHelpColumn
            titlename={item?.titleName}
            values={item?.values}
            key={index}
          />
        ))}
        <a
          className="need-help-whatsapp"
          href="https://wa.me/447501247364"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={images.whatsAppBtn} alt="" />
        </a>
      </div>
    </div>
  );
}
