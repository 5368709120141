import {
  CONFIRM_PASSWORD,
  CONFIRM_PASSWORD_SUCCESS,
  CONFIRM_PASSWORD_FAILURE,
  EMAIL_CHANGE_REQ,
  EMAIL_CHANGE_REQ_SUCCESS,
  EMAIL_CHANGE_REQ_FAILURE,
  CONFIRM_OTP_RECOVER,
  CONFIRM_OTP_RECOVER_SUCCESS,
  CONFIRM_OTP_RECOVER_FAILURE,
  RESEND_OTP,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_FAILURE,
  EMAIL_CHANGE_VERIFY,
  EMAIL_CHANGE_VERIFY_SUCCESS,
  EMAIL_CHANGE_VERIFY_FAILURE,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  LOGIN,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  LOADER_FALSE,
  LOADER_TRUE,
} from "../constants";

export default class AuthAction {
  static forgotPassword(payload, cb) {
    return {
      type: FORGOT_PASSWORD,
      payload,
      cb,
    };
  }
  static forgotPasswordSuccess(payload) {
    return {
      type: FORGOT_PASSWORD_SUCCESS,
      payload,
    };
  }
  static forgotPasswordFailure() {
    return {
      type: FORGOT_PASSWORD_FAILURE,
    };
  }
  static confirmOtpRecover(payload, cb) {
    return {
      type: CONFIRM_OTP_RECOVER,
      payload,
      cb,
    };
  }
  static confirmOtpRecoverSuccess(payload) {
    return {
      type: CONFIRM_OTP_RECOVER_SUCCESS,
      payload,
    };
  }
  static confirmOtpRecoverFailure() {
    return {
      type: CONFIRM_OTP_RECOVER_FAILURE,
    };
  }
  static resendOtp(payload, cb) {
    return {
      type: RESEND_OTP,
      payload,
      cb,
    };
  }
  static resendOtpSuccess(payload) {
    return {
      type: RESEND_OTP_SUCCESS,
      payload,
    };
  }
  static resendOtpFailure() {
    return {
      type: RESEND_OTP_FAILURE,
    };
  }
  static emailChangeReq(payload, cb) {
    return {
      type: EMAIL_CHANGE_REQ,
      payload,
      cb,
    };
  }
  static emailChangeReqSuccess(payload) {
    return {
      type: EMAIL_CHANGE_REQ_SUCCESS,
      payload,
    };
  }
  static emailChangeReqFailure() {
    return {
      type: EMAIL_CHANGE_REQ_FAILURE,
    };
  }
  static emailChangeVerify(payload, cb) {
    return {
      type: EMAIL_CHANGE_VERIFY,
      payload,
      cb,
    };
  }
  static emailChangeVerifySuccess(payload) {
    return {
      type: EMAIL_CHANGE_VERIFY_SUCCESS,
      payload,
    };
  }
  static emailChangeVerifyFailure() {
    return {
      type: EMAIL_CHANGE_VERIFY_FAILURE,
    };
  }
  static confirmPassword(payload, cb) {
    return {
      type: CONFIRM_PASSWORD,
      payload,
      cb,
    };
  }
  static confirmPasswordSuccess(payload) {
    return {
      type: CONFIRM_PASSWORD_SUCCESS,
      payload,
    };
  }
  static confirmPasswordFailure() {
    return {
      type: CONFIRM_PASSWORD_FAILURE,
    };
  }
  static SignIn(payload, cb) {
    return {
      type: LOGIN,
      payload,
      cb,
    };
  }
  static SignInSuccess(payload) {
    return {
      type: LOGIN_SUCCESS,
      payload,
    };
  }
  static SignInFailure() {
    return {
      type: LOGIN_FAILURE,
    };
  }

  static Logout() {
    return {
      type: LOGOUT,
    };
  }
  static LogoutSuccess() {
    return {
      type: LOGOUT_SUCCESS,
    };
  }
  static LogoutFailure() {
    return {
      type: LOGOUT_FAILURE,
    };
  }

  static LoaderTrue() {
    return {
      type: LOADER_TRUE,
    };
  }
  static LoaderFalse() {
    return {
      type: LOADER_FALSE,
    };
  }
}
