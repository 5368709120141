import React from "react";
import images from "../../assets";

import "./styles.css";
const ChangeButton = (props) => {
  return (
    <button className="change-button-style" {...props}>
      <img src={images.changeButtonIcon} alt="change" />
      <p className="m-0 whitespace-nowrap overflow-hidden text-ellipsis"  title={"Change"}>
        Change
      </p>
    </button>
  );
};

export default ChangeButton;
