export default function NavItems(props) {
  return (
    <div {...props} className="dropdown-profile-nav">
      <div className="dropdown-profile-nav-content">
        <div className="profile-nav-top">
          <div className="profile-nav-img">{props.children}</div>
        </div>
        <div className="dropdown-navigation-title"> {props.titles}</div>
      </div>
    </div>
  );
}
