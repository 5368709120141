import {
  SET_MESSAGES_LIST,
  SET_MESSAGES_LIST_SUCCESS,
  SET_MESSAGES_LIST_FAILURE,
} from "../constants";

export default class MessageAction {
  static setMessageList(payload, cb) {
    return {
      type: SET_MESSAGES_LIST,
      payload,
      cb,
    };
  }
  static setMessageListSuccess(payload) {
    return {
      type: SET_MESSAGES_LIST_SUCCESS,
      payload,
    };
  }
  static setMessageListFailure() {
    return {
      type: SET_MESSAGES_LIST_FAILURE,
    };
  }
}
