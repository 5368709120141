import { Navigate } from "react-router";

import {
  AdvancedSearch,
  BasicSearch,
  Blocklist,
  ChangePassword,
  ConfirmOTP,
  Dashboard,
  Deactivate,
  EditProfile,
  Gallery,
  Interests,
  Login,
  MatchPrefrrence,
  Messages,
  MyNotes,
  NewMembers,
  Notifications,
  PhotoRequests,
  Profile,
  ProfileViewers,
  RecommendedMatches,
  SearchResults,
  Settings,
  Shortlists,
  ViewedContacts,
  ViewProfile,
  TrustBadge,
  ForgotPassword,
  ChangeEmailMigrated,
  MembershipPlan,
  OrderInformation,
  Checkout,
  ExportPDF,
} from "../../containers";

export const routes = {
  protected: {
    dashboard: "/dashboard",

    advancedSearch: "/advanced-search",
    basicSearch: "/basic-search",
    searchResults: "/search-results",

    blocklist: "/block-list",

    interests: "/interests",

    messages: "/messages",

    myNotes: "/my-notes",

    newMembers: "/new-members",

    photoRequests: "/photo-requests",

    profile: "/profile",
    editProfile: "/edit-profile",
    matchPreference: "/match-preference",
    gallery: "/gallery",

    profileViewers: "/profile-viewers",

    recommendedMatches: "/recommended-matches",

    shortlists: "/shortlists",

    viewedContacts: "/viewed-contacts",

    viewProfile: "/view-profile/:id",

    trustBadge: "/trust-badge",

    settings: "/settings",

    deactivate: "/deactivate",

    changePassword: "/change-password",

    notifications: "/notifications",

    membershipPlan: "/membership-plan",

    orderInformation: "/order-information",

    checkout: "/checkout",

    exportPdf: "/print-profile",
  },
  public: {
    login: "/login",
    confirmOtp: "/confirm-otp",
    forgotPassword: "/forgot-password",
    changeEmailMigrated: "/change-email-request",
  },
};

export const PROTECTED_ROUTES = [
  // dashboard
  {
    path: routes.protected.dashboard,
    exact: false,
    component: <Dashboard />,
  },
  // advancedSearch
  {
    path: routes.protected.advancedSearch,
    exact: false,
    component: <AdvancedSearch />,
  },
  // basicSearch
  {
    path: routes.protected.basicSearch,
    exact: false,
    component: <BasicSearch />,
  },
  //search results
  {
    path: routes.protected.searchResults,
    exact: false,
    component: <SearchResults />,
  },
  // blocklist
  {
    path: routes.protected.blocklist,
    exact: false,
    component: <Blocklist />,
  },

  // interests
  {
    path: routes.protected.interests,
    exact: false,
    component: <Interests />,
  },
  // messages
  {
    path: routes.protected.messages,
    exact: false,
    component: <Messages />,
  },
  // my notes
  {
    path: routes.protected.myNotes,
    exact: false,
    component: <MyNotes />,
  },
  // new members
  {
    path: routes.protected.newMembers,
    exact: false,
    component: <NewMembers />,
  },
  // photo requests
  {
    path: routes.protected.photoRequests,
    exact: false,
    component: <PhotoRequests />,
  },
  // profile
  {
    path: routes.protected.profile,
    exact: false,
    component: <Profile />,
  },
  // edit profile
  {
    path: routes.protected.editProfile,
    exact: false,
    component: <EditProfile />,
  },
  // matchPreference
  {
    path: routes.protected.matchPreference,
    exact: false,
    component: <MatchPrefrrence />,
  },
  // gallery
  {
    path: routes.protected.gallery,
    exact: false,
    component: <Gallery />,
  },
  // profile viewers
  {
    path: routes.protected.profileViewers,
    exact: false,
    component: <ProfileViewers />,
  },
  // recommended matches
  {
    path: routes.protected.recommendedMatches,
    exact: false,
    component: <RecommendedMatches />,
  },

  //shortlists
  {
    path: routes.protected.shortlists,
    exact: false,
    component: <Shortlists />,
  },
  // viewed contacts
  {
    path: routes.protected.viewedContacts,
    exact: false,
    component: <ViewedContacts />,
  },
  // viewe Profile
  {
    path: routes.protected.viewProfile,
    exact: false,
    component: <ViewProfile />,
  },
  // Trust Badge
  {
    path: routes.protected.trustBadge,
    exact: false,
    component: <TrustBadge />,
  },
  // settings
  {
    path: routes.protected.settings,
    exact: false,
    component: <Settings />,
  },
  // deactivate
  {
    path: routes.protected.deactivate,
    exact: false,
    component: <Deactivate />,
  },
  // changePassword
  {
    path: routes.protected.changePassword,
    exact: false,
    component: <ChangePassword />,
  },
  // notifications
  {
    path: routes.protected.notifications,
    exact: false,
    component: <Notifications />,
  },
  //
  {
    path: "*",
    exact: false,
    component: <Navigate to={routes.protected.dashboard} />,
  },
  // membership plan
  {
    path: routes.protected.membershipPlan,
    exact: false,
    component: <MembershipPlan />,
  },
  //order info
  {
    path: routes.protected.orderInformation,
    exact: false,
    component: <OrderInformation />,
  },
  //checkout
  {
    path: routes.protected.checkout,
    exact: false,
    component: <Checkout />,
  },
  //print
  {
    path: routes.protected.exportPdf,
    exact: false,
    component: <ExportPDF />,
  },
];

export const PUBLIC_ROUTES = [
  // login
  {
    path: routes.public.login,
    exact: false,
    component: <Login />,
  },
  {
    path: routes.public.confirmOtp,
    exact: false,
    component: <ConfirmOTP />,
  },
  {
    path: routes.public.forgotPassword,
    exact: false,
    component: <ForgotPassword />,
  },
  {
    path: routes.public.changeEmailMigrated,
    exact: false,
    component: <ChangeEmailMigrated />,
  },
  {
    path: "*",
    exact: false,
    component: <Navigate to={routes.public.login} />,
  },
];
