import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Progress, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";

import ProfileDropIcon from "./profiledropicon";
import NavItems from "./navitems";
import Loader from "../../../loader/loader";

import images from "../../../../assets";

export default function ProfileDropdown() {
  const navigate = useNavigate();

  const profileData = useSelector((state) => state?.profile?.profile);
  const profileLoading = useSelector((state) => state?.profile?.isLoading);

  return (
    <div>
      <div className="profile-dropdown-top">
        <div className="dropdown-top-content">
          {profileLoading ? (
            <Loader />
          ) : (
            <>
              {!profileData?.gender ? (
                <Avatar size={54} icon={<UserOutlined />} shape="square" />
              ) : (
                <img
                  src={
                    profileData?.profilePicture ??
                    (profileData?.gender === "male"
                      ? images.defaultMaleIcon
                      : images.defaultFemaleIcon)
                  }
                  className="dropdown-profile-image"
                  alt=""
                />
              )}
            </>
          )}
          <div className="dropdown-top-right">
            <div className="dropdown-top-right2">
              <div className="dropdown-top-right3">
                <div className="dropdown-profile-title">
                  {profileData?.firstName}&nbsp;{profileData?.lastName}
                </div>
                <Progress
                  className="progress-bar-nav"
                  percent={profileData?.profileCompleteness ?? 0}
                  showInfo={false}
                  trailColor={"#ffffff"}
                  strokeColor={"#17C964"}
                />
                <p className="profile-complete-text">
                  {profileData?.profileCompleteness ?? 0}% Profile Completed
                </p>
              </div>
            </div>
            {(profileData?.trustbadgeStatus === "approved" ||
              profileData?.premiumStatus === true) && (
              <div className="profile-dropdown-icon-area">
                {profileData?.premiumStatus === true && (
                  <ProfileDropIcon>
                    <img src={images.premiumIcon} alt="Short List" />
                  </ProfileDropIcon>
                )}
                {profileData?.trustbadgeStatus === "approved" && (
                  <ProfileDropIcon>
                    <img src={images.shortlistIcon} alt="Short List" />
                  </ProfileDropIcon>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="dropdown-bottom-area">
        <NavItems onClick={() => navigate("/profile")} titles="My Profile">
          {profileLoading ? (
            <Loader />
          ) : (
            <>
              {!profileData?.gender ? (
                <Avatar size={30} icon={<UserOutlined />} />
              ) : (
                <img
                  src={
                    profileData?.profilePicture ??
                    (profileData?.gender === "male"
                      ? images.defaultMaleIcon
                      : images.defaultFemaleIcon)
                  }
                  className="h-[30px] w-[30px] rounded-full object-cover"
                  alt=""
                />
              )}
            </>
          )}
        </NavItems>
        <div className="dorpdown-separator"></div>
        <NavItems titles="Photo Gallery" onClick={() => navigate("/gallery")}>
          <img src={images.photoGallery} alt="gallery" />
        </NavItems>
        <NavItems
          titles="Edit Profile"
          onClick={() => navigate("/edit-profile")}
        >
          <img src={images.editProfile} alt="Edit Profile" />
        </NavItems>
        <NavItems
          titles="Match Preference"
          onClick={() => navigate("/match-preference")}
        >
          <img src={images.matchPreference} alt="Match Preference" />
        </NavItems>
        <NavItems onClick={() => navigate("/trust-badge")} titles="Trust Badge">
          <img src={images.trustBadge} alt="Trust Badge" />
        </NavItems>
        <div className="dorpdown-separator"></div>
        <NavItems onClick={() => navigate("/settings")} titles="Settings">
          <img src={images.settingsIcon} alt="settings" />
        </NavItems>
      </div>
    </div>
  );
}
