import BasicInfoColumn from "../../components/profilecard/basicinfocolumn";

import GetresidencyStatusLabel from "../../utils/getresidencystatuslavel";
import calculateage from "../../utils/calculateage";

import images from "../../assets";
import "./styles.css";

export default function BasicInfoCard(props) {
  let birthDate = calculateage(props?.othersProfileData?.dateOfBirth);

  return (
    <div className="basic-info-card">
      <div className="basic-info-head">
        <img src={images.basicInfoIcon} alt="Basic Info" />{" "}
        <span>Basic Info</span>
      </div>
      <div className="basic-info-content">
        {props?.othersProfileData?.gender && (
          <BasicInfoColumn
            style={{ textTransform: "capitalize" }}
            titlename="Gender"
            values={props?.othersProfileData?.gender}
          />
        )}
        {props?.othersProfileData?.dateOfBirth && (
          <BasicInfoColumn titlename="Age" values={`${birthDate} Years`} />
        )}
        {props?.othersProfileData?.stateProvince &&
          props?.othersProfileData?.country && (
            <BasicInfoColumn
              titlename="Lives In"
              values={`${props?.othersProfileData?.stateProvince}, ${props?.othersProfileData?.country}`}
            />
          )}
        {props?.othersProfileData?.homeType && (
          <BasicInfoColumn
            style={{ textTransform: "capitalize" }}
            titlename="Home Type"
            values={props?.othersProfileData?.homeType}
          />
        )}
        {props?.othersProfileData?.relocationPlan && (
          <BasicInfoColumn
            titlename="Relocation Plan"
            values={props?.othersProfileData?.relocationPlan}
          />
        )}
        {props?.othersProfileData?.ethnicOrigin && (
          <BasicInfoColumn
            titlename="Ethnic Origin"
            values={props?.othersProfileData?.ethnicOrigin}
          />
        )}
        {props?.othersProfileData?.residencyStatus && (
          <BasicInfoColumn
            style={{ textTransform: "capitalize" }}
            titlename="Residency Status"
            values={GetresidencyStatusLabel(
              props?.othersProfileData?.residencyStatus
            )}
          />
        )}
        {props?.othersProfileData?.maritalStatus && (
          <BasicInfoColumn
            style={{ textTransform: "capitalize" }}
            titlename="Marital Status"
            values={props?.othersProfileData?.maritalStatus}
          />
        )}
        {props?.othersProfileData?.spokenLanguage?.length > 0 && (
          <BasicInfoColumn
            titlename="Spoken Language"
            values={props?.othersProfileData?.spokenLanguage?.map(
              (item, index) => {
                if (index === 0) {
                  return item;
                } else {
                  return `, ${item}`;
                }
              }
            )}
          />
        )}
      </div>
    </div>
  );
}
