import React from "react";
import { notification } from "antd";
import images from "../../assets";

const DefaultNotification = (title, description, image) => {
  notification.open({
    message: <div className="font-semibold text-[16px] ml-2">{title}</div>,
    description: (
      <div className="text-[14px] font-medium ml-2">{description}</div>
    ),
    placement: "topRight",
    icon: (
      <img
        src={image ?? images.membershipPlan}
        alt="notification-img"
        className="h-[36px] w-[36px]"
      />
    ),
    duration: 10,
    showProgress: true,
    pauseOnHover: true,
  });
};

export default DefaultNotification;
