import React from "react";
import images from "../../assets";

import "./styles.css";
const ApprovedButton = () => {
  return (
    <div className="approved-button-style">
      <img src={images.approvedIcon} alt="change" />
      <p
        className="whitespace-nowrap overflow-hidden text-ellipsis"
        title="Approved"
      >
        Approved
      </p>
    </div>
  );
};

export default ApprovedButton;
