const styles = {
  topSmallText: "text-[16px] font-semibold text-basePrimary text-center",
  topBigText:
    "lg:text-[30px] text-[20px] font-semibold text-basePrimary text-center",

  inputFieldContainer: " grid grid-cols-1 gap-y-4 ",
  inputStyle:
    "w-full outline-none rounded-lg py-3 px-[14px] bg-white border border-primary50",
  wrongInput:
    "w-full outline-none rounded-lg py-3 px-[14px] text-danger900 bg-danger50 border border-danger",
  selectStyle:
    "w-full outline-none rounded-lg py-2 px-[14px] bg-white border border-primary50",
  selectStyleError:
    "w-full outline-none rounded-lg py-2 px-[14px] text-danger900 bg-danger50 border border-danger",
  eyeIcon: "h-5 absolute top-3 right-4 cursor-pointer",
  errorStyle: "text-secondary text-[12px] mt-1",
  linkText: "text-secondary font-semibold underline",
};

export default styles;
