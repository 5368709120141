import React, { useState } from "react";
import { QRCode, Modal } from "antd";

import images from "../../../assets/index";
export default function PrintDownloadCom(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const url = window.location.href;
  const baseUrl = new URL(url).origin;

  const text = `${baseUrl}/view-profile/${props?.profileData?.username}`;

  const handleRedirect = (functionality) => {
    localStorage.setItem("downloadData", JSON.stringify(props?.profileData));
    const url = `/print-profile?function=${functionality}`;
    window.open(url, "_blank");
  };
  return (
    <div className="print-download-btn">
      {(props?.profileData?.trustbadgeStatus === "approved" ||
        props?.profileData?.premiumStatus === true ||
        props?.profileData?.trustBadge?.length > 0 ||
        props?.profileData?.activePlan?.plan) && (
        <div className="bages">
          {(props?.profileData?.trustbadgeStatus === "approved" ||
            props?.profileData?.trustBadge?.length > 0) && (
            <div className="bages-icon-area">
              <div className="bages-icon">
                <img src={images.shortlistIcon} alt="" />
              </div>
            </div>
          )}
          {(props?.profileData?.premiumStatus === true ||
            props?.profileData?.activePlan?.plan) && (
            <div className="bages-icon-area">
              <div className="bages-icon">
                <img src={images.premiumIcon} alt="" />
              </div>
            </div>
          )}
        </div>
      )}
      <div className="main-profile-utility">
        <div className="profile-utility">
          <div
            className="profile-utility-icon cursor-pointer"
            onClick={() => setIsModalOpen(true)}
          >
            <img src={images.scanIcon} alt="" className="cursor-pointer" />
          </div>

          <div
            className="profile-utility-icon cursor-pointer"
            onClick={() => handleRedirect("print")}
          >
            <img src={images.printIcon} alt="" className="cursor-pointer" />
          </div>

          <div
            className="profile-utility-icon cursor-pointer"
            onClick={() => handleRedirect("download")}
          >
            <img
              src={images.downloadIcon}
              alt="Download Profile"
              className="cursor-pointer"
            />
          </div>
        </div>
      </div>

      <Modal
        centered={true}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={false}
        closable={false}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={images?.closeIcon}
            className="modal-close-icon"
            alt=""
            onClick={() => setIsModalOpen(false)}
          />
          <h3>Scan QR code</h3>
          <QRCode value={text || "-"} />
        </div>
      </Modal>
    </div>
  );
}
