const maritalStatusMale = [
  { label: "Never married", value: "neverMarried" },
  { label: "Married", value: "married" },
  { label: "Divorced", value: "divorced" },
  { label: "Widowed", value: "widowed" },
];
const maritalStatusFemale = [
  { label: "Never married", value: "neverMarried" },
  { label: "Divorced", value: "divorced" },
  { label: "Widowed", value: "widowed" },
];
export { maritalStatusMale, maritalStatusFemale };
