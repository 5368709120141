import React from "react";

import "./styles.css";
const SettingsButton = (props) => {
  return (
    <button className="settings-button-style" {...props}>
      <p
        className="whitespace-nowrap overflow-hidden text-ellipsis m-0"
        title={props?.text}
      >
        {props?.text}
      </p>
    </button>
  );
};

export default SettingsButton;
