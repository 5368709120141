import React, { useState } from "react";
import images from "../../assets";

import "./styles.css";
import { Modal } from "antd";
import Spacer from "../spacer/spacer";
import WhiteButton from "./whitebutton";
import PrimaryButton from "./primarybutton";

const LogoutButton = (props) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <button onClick={() => setOpen(true)} className="logout-button-style">
        <img src={images.logoutIcon} alt="logout" />
        <p>Logout</p>
      </button>

      <Modal
        centered={true}
        open={isOpen}
        onCancel={() => setOpen(false)}
        footer={false}
        closable={true}
      >
        <div className="gap-4 set-profile-photo-content">
          <div className="set-profile-photo-icon">
            <img
              style={{ maxWidth: "40px" }}
              src={images?.confirmLogout}
              alt=""
            />
          </div>
          <div className="set-profile-photo-heading">
            <h2>Are you sure you want to logout?</h2>
          </div>
        </div>
        <Spacer height="16px" />
        <div className="flex w-full justify-end">
          <div className="flex gap-4 set-profile-photo-btn">
            <WhiteButton text="Cancel" onClick={() => setOpen(false)} />
            <PrimaryButton
              text="Logout"
              {...props}
              style={{ background: "#F31260" }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LogoutButton;
