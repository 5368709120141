import images from "../../assets";

const dummyUsers = [
  {
    firstName: "asd",
    lastName: "sad",
    dateOfBirth: "21yrs",
    country: "location",
    stateProvince: "location",
    profession: "profession",
    profilePicture: images?.defaultMaleIcon,
    viewed: true,
    active: true,
    pro: true,
    verified: true,
    // matchOfTheDay: true,
  },
  {
    firstName: "asd",
    lastName: "sad",
    dateOfBirth: "21yrs",
    country: "location",
    stateProvince: "location",
    profession: "profession",
    profilePicture: images?.defaultMaleIcon,
    viewed: true,
    active: true,
    pro: false,
    verified: true,
  },
  {
    firstName: "asd",
    lastName: "sad",
    dateOfBirth: "21yrs",
    country: "location",
    stateProvince: "location",
    profession: "profession",
    profilePicture: images?.defaultMaleIcon,
    viewed: true,
    active: false,
    pro: true,
    verified: true,
  },
  {
    firstName: "asd",
    lastName: "sad",
    dateOfBirth: "21yrs",
    country: "location",
    stateProvince: "location",
    profession: "profession",
    profilePicture: images?.defaultMaleIcon,
    viewed: false,
    active: true,
    pro: true,
    verified: true,
  },
  {
    firstName: "asd",
    lastName: "sad",
    dateOfBirth: "21yrs",
    country: "location",
    stateProvince: "location",
    profession: "profession",
    profilePicture: images?.defaultMaleIcon,
    viewed: true,
    active: true,
    pro: true,
    verified: true,
  },
  {
    firstName: "asd",
    lastName: "sad",
    dateOfBirth: "21yrs",
    country: "location",
    stateProvince: "location",
    profession: "profession",
    profilePicture: images?.defaultMaleIcon,
    viewed: true,
    active: true,
    pro: true,
    verified: true,
  },
  {
    firstName: "asd",
    lastName: "sad",
    dateOfBirth: "21yrs",
    country: "location",
    stateProvince: "location",
    profession: "profession",
    profilePicture: images?.defaultMaleIcon,
    viewed: true,
    active: true,
    pro: true,
    verified: true,
  },
  {
    firstName: "asd",
    lastName: "sad",
    dateOfBirth: "21yrs",
    country: "location",
    stateProvince: "location",
    profession: "profession",
    profilePicture: images?.defaultMaleIcon,
    viewed: true,
    active: true,
    pro: true,
    verified: true,
  },
];

export default dummyUsers;
