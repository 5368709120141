import {
  GET_CONTACT_VIEW,
  GET_CONTACT_VIEW_SUCCESS,
  GET_CONTACT_VIEW_FAILURE,
  GET_ALL_CONTACT_VIEW,
  GET_ALL_CONTACT_VIEW_SUCCESS,
  GET_ALL_CONTACT_VIEW_FAILURE,
  SET_CONTACT_VIEW,
  SET_CONTACT_VIEW_SUCCESS,
  SET_CONTACT_VIEW_FAILURE,
} from "../constants";

export default class ContactViewerAction {
  static getContactView(payload, cb) {
    return {
      type: GET_CONTACT_VIEW,
      payload,
      cb,
    };
  }
  static getContactViewSuccess(payload) {
    return {
      type: GET_CONTACT_VIEW_SUCCESS,
      payload,
    };
  }
  static getContactViewFailure() {
    return {
      type: GET_CONTACT_VIEW_FAILURE,
    };
  }
  static getAllContactView(payload, cb) {
    return {
      type: GET_ALL_CONTACT_VIEW,
      payload,
      cb,
    };
  }
  static getAllContactViewSuccess(payload) {
    return {
      type: GET_ALL_CONTACT_VIEW_SUCCESS,
      payload,
    };
  }
  static getAllContactViewFailure() {
    return {
      type: GET_ALL_CONTACT_VIEW_FAILURE,
    };
  }
  static setContactView(payload, cb) {
    return {
      type: SET_CONTACT_VIEW,
      payload,
      cb,
    };
  }
  static setContactViewSuccess(payload) {
    return {
      type: SET_CONTACT_VIEW_SUCCESS,
      payload,
    };
  }
  static setContactViewFailure() {
    return {
      type: SET_CONTACT_VIEW_FAILURE,
    };
  }
}
