import React from "react";
import { Link } from "react-router-dom";
import { Input } from "antd";

import useScreenSize from "../../utils/usescreensize";

import EditProfileInput from "../../components/inputbox/editprofileinput";
import Spacer from "../../components/spacer/spacer";
import EditProfileTextbox from "../../components/inputbox/editprofiletextbox";
import PrimaryButton from "../../components/button/primarybutton";
import NeedHelpCard from "../../components/needhelp/needhelpcard";

import images from "../../assets";

const { Search } = Input;

const faqLink =
  process.env.REACT_APP_ENV === "production"
    ? process.env.REACT_APP_LANDING_URL_PROD
    : process.env.REACT_APP_ENV === "staging"
    ? process.env.REACT_APP_LANDING_URL_STAGING
    : process.env.REACT_APP_LANDING_URL_LOCAL;

const Support = () => {
  const screenSize = useScreenSize();

  return (
    <div className="help-support-body-inner">
      <div className="help-support-heading">
        <h2>We're Here to Help You!</h2>
        <p>Find answers to your questions and get the support you need.</p>
      </div>
      <div
        style={{
          background: `url(${images.assistToday})`,
          backgroundSize: "100% 100%",
        }}
        className="assist-today-area"
      >
        <Search
          placeholder="How can we assist you today?"
          style={{ width: screenSize.width < 768 ? "85%" : "50%" }}
        />
      </div>
      <div className="help-support-faq">
        <p>
          Browse Common Questions, please check our{" "}
          <Link
            to={faqLink}
            target="_blank"
            className="text-secondary font-bold"
          >
            FAQs
          </Link>
        </p>
      </div>
      <div className="what-need-form-body">
        <div className="what-need-from">
          <div className="what-need-title-form">
            <div className="what-need-title">Can't Find What You Need?</div>
            <div className="what-need-input-box">
              {/* name */}
              <div className="edit-profile-name-container">
                <div className="edit-profile-name">
                  <EditProfileInput placeholder="First name" />
                </div>
                <div className="edit-profile-name">
                  <EditProfileInput placeholder="Last name" />
                </div>
              </div>
              <Spacer height="16px" />
              <EditProfileInput placeholder="Enter your email address" />
              <Spacer height="16px" />
              <EditProfileInput placeholder="Assistant Needed" />
              <Spacer height="16px" />
              <EditProfileTextbox placeholder="Message" />
            </div>
          </div>
          <div className="what-need-btn">
            <Spacer height="32px" />
            <PrimaryButton text="Send Message" />
          </div>
        </div>
      </div>
      <Spacer height="50px" />
      <NeedHelpCard style={{ width: screenSize.width < 768 ? "100%" : "" }} />
      <Spacer height="80px" />
      <div className="need-help-body">
        <div className="need-help-inner">
          <div className="need-help-head">
            <h2 style={{ fontSize: 20 }}>Connect With Us</h2>
          </div>
          <div className="social-media-area">
            <div className="social-media-icon">
              <Link
                target="_blank"
                to="https://www.facebook.com/NobleMarriage/"
              >
                <img src={images.facebookIcon} alt="Facebook" />
              </Link>
              <Link
                target="_blank"
                to="https://www.instagram.com/noblemarriage/"
              >
                <img src={images.instagramIcon} alt="Instagram" />
              </Link>
              <Link target="_blank" to="https://wa.me/447501247364/">
                <img src={images.whatsAppIcon} alt="WhatsApp" />
              </Link>
              <Link target="_blank" to="https://twitter.com/noblemarriagehq/">
                <img src={images.twitterIcon} alt="Twitter" />
              </Link>
              <Link
                target="_blank"
                to="https://www.youtube.com/channel/UCm1HcxSzBQQ1MINurgrNIpg/"
              >
                <img src={images.youtubeIcon} alt="Youtube" />
              </Link>
            </div>
            <div className="social-media-icon">
              <Link
                target="_blank"
                to="https://www.linkedin.com/company/42812891/"
              >
                <img src={images.linkdinIcon} alt="Linkdin" />
              </Link>
              <Link
                target="_blank"
                to="https://www.tumblr.com/blog/noblemarriage/"
              >
                <img src={images.tumblrIcon} alt="Tumblr" />
              </Link>
              <Link
                target="_blank"
                to="https://www.pinterest.com/noblemarriage/"
              >
                <img src={images.printerstIcon} alt="Printerst" />
              </Link>
              <Link
                target="_blank"
                to="https://www.reddit.com/user/noblemarriage/"
              >
                <img src={images.redditIcon} alt="Reddit" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
