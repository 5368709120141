import React from "react";

const GreyButton = (props) => {
  const style =
    "w-full rounded-lg bg-[#F3F3FA] border border-primary50 font-semibold text-[12px] text-black flex justify-center items-center py-[5px] pr-[15px] pl-[5px] w-max";
  return (
    <button {...props} className={style}>
      {props.icon && props.iconalign === "left" && (
        <img src={props.icon} alt="icon" className="mr-3" />
      )}{" "}
      <p
        className="mb-0 whitespace-nowrap overflow-hidden text-ellipsis"
        title={props?.text}
      >
        {props.text}
      </p>{" "}
      {props.icon && props.iconalign === "right" && (
        <img src={props.icon} alt="icon" className="ml-3" />
      )}
    </button>
  );
};

export default GreyButton;
