import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import DefaultItems from "./defaultitems";
import BasicSearchItems from "./basicsreachitems";

const Sidebar = (props) => {
  const location = useLocation();

  const [basicSearchMenu, setBasicSearchMenu] = useState(false);

  useEffect(() => {
    if (location?.pathname?.includes("/basic-search")) {
      setBasicSearchMenu(true);
    } else {
      setBasicSearchMenu(false);
    }
  }, [location?.pathname]);

  return (
    <>
      {basicSearchMenu ? (
        <BasicSearchItems setBasicSearchMenu={setBasicSearchMenu} />
      ) : (
        <DefaultItems promotionHeader={props.promotionHeader} />
      )}
    </>
  );
};

export default Sidebar;
