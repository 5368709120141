import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch, message } from "antd";

import { ProfileAction } from "../../store/actions";

import Spacer from "../../components/spacer/spacer";
import { PrimaryButton } from "../../components/button";
import CustomImageUploader from "../../components/imageuploader/customimageuploader";

import truncateString from "../../utils/truncatestring";

export default function DocumentUpload() {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state?.profile?.profile);
  const photoLoading = useSelector((state) => state?.profile?.isPhotoLoading);

  const [state, setState] = useState({
    fileList: [],
  });

  const handleSubmit = () => {
    const payload = new FormData();
    payload.append("username", profileData?.username);
    payload.append("imageData", state?.fileList[0]?.originFileObj);
    // state?.fileList?.map((item) => {
    //   payload.append("imageData", item?.originFileObj);
    // });
    dispatch(
      ProfileAction?.uploadPhoto(payload, (res) => {
        if (res?.status === 200) {
          message.success(`Image uploaded successfully`);
          dispatch(ProfileAction.getProfile());
        } else {
          message.error(`Could not upload image`);
        }
      })
    );
  };

  const handlePhotoBlur = (e) => {
    const payload = {
      isPhotoBlured: e,
    };
    dispatch(
      ProfileAction.updateProfile(payload, () => {
        dispatch(ProfileAction.getProfile());
      })
    );
  };

  return (
    <>
      <div className="photo-blur-switch-container">
        <p>Keep my photo blurred</p>
        <Switch
          defaultChecked={profileData?.isPhotoBlured}
          onChange={(e) => {
            handlePhotoBlur(e);
          }}
          disabled={profileData?.gender === "female"}
        />
      </div>
      <Spacer height="10px" />
      <p className="photo-blur-subtext">
        Blur or reveal your photo. Males can toggle; females are blurred by
        default.
      </p>
      <Spacer height="32px" />
      <div className="gallery-document">
        <CustomImageUploader
          setState={setState}
          state={state}
          formats=".jpg,.png"
          disabled={
            photoLoading || profileData?.imageGallery?.length >= 4
              ? true
              : false
          }
        />
      </div>

      {state?.fileList?.length > 0 && (
        <>
          <Spacer height="32px" />
          <PrimaryButton
            text="Save"
            onClick={handleSubmit}
            loading={photoLoading ? "true" : "false"}
            disabled={photoLoading ? true : false}
          />
        </>
      )}
    </>
  );
}
