import {
  UPDATE_MATCH_PREFERENCE,
  UPDATE_MATCH_PREFERENCE_SUCCESS,
  UPDATE_MATCH_PREFERENCE_FAILURE,
} from "../constants";

const initialState = {
  matches: [],
  isLoading: false,
  error: null,
};

export default function MatchReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_MATCH_PREFERENCE:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case UPDATE_MATCH_PREFERENCE_SUCCESS:
      state = {
        ...state,
        matches: action.payload,
        isLoading: false,
      };
      break;

    case UPDATE_MATCH_PREFERENCE_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }

  return state;
}
