import { put } from "redux-saga/effects";
import { message } from "antd";

import ActivityApiCaller from "../../config/api/activityapicaller";

import { InterestAction } from "../actions";

export default class InterestMiddleware {
  static *getInterests({ payload, cb }) {
    try {
      const action =
        payload?.filter === "My Interests"
          ? "sender"
          : payload?.filter === "Interested in Me"
          ? "receiver"
          : "receiver/pending";

      const res = yield ActivityApiCaller.Get(
        `/activity/interest/${action}?from=${payload.from ?? ""}&size=${
          payload.size ?? ""
        }&sort=${payload.sort ?? ""}`
      );

      if (res?.status === 200) {
        if (payload?.filter === "receiver/pending") {
          yield put(
            InterestAction.getInterestsSuccess({
              count: res.data?.count,
              data: res.data?.data,
              pendingCount: res.data?.count,
            })
          );
        } else {
          yield put(
            InterestAction.getInterestsSuccess({
              count: res.data?.count,
              data: res.data?.data,
            })
          );
        }
      } else {
        yield put(InterestAction.getInterestsFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      console.log(err);
      yield put(InterestAction.getInterestsFailure());
    }
  }
  static *getInterestsAll({ payload, cb }) {
    try {
      const action = "sender";

      const res = yield ActivityApiCaller.Get(
        `/activity/interest/${action}?from=${payload.from ?? ""}&size=${
          payload.size ?? ""
        }&sort=${payload.sort ?? ""}`
      );

      if (res?.status === 200) {
        yield put(
          InterestAction.getInterestsAllSuccess({
            count: res.data?.count,
            data: res.data?.data,
          })
        );
      } else {
        yield put(InterestAction.getInterestsAllFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      console.log(err);
      yield put(InterestAction.getInterestsAllFailure());
    }
  }
  static *sendInterest({ payload, cb }) {
    try {
      const res = yield ActivityApiCaller.Post("/activity/interest", payload);

      if (res.status === 200) {
        message.success("Interest sent succesfully.");
        yield put(InterestAction.sendInterestSuccess(res.data.Data));
        yield put(InterestAction.getInterests({ filter: "My Interests" }));
      } else if (res.status === 409) {
        message.error("Interest already sent.");
        yield put(InterestAction.sendInterestFailure());
      } else {
        // message.error("Could not send interest.");
        yield put(InterestAction.sendInterestFailure());
      }
      if (cb) {
        cb(res.data.Data);
      }
    } catch (err) {
      console.log(err);
      // message.error("Something went wrong while sending interest.");
      yield put(InterestAction.sendInterestFailure());
    }
  }

  static *updateInterest({ payload, cb }) {
    try {
      const res = yield ActivityApiCaller.Put("/activity/interest", payload);

      if (res.status === 200) {
        message.success(`Interest ${payload.status} succesfully.`);
        yield put(InterestAction.updateInterestSuccess(res.data.Data));
      } else {
        // message.error(`Interest could not be ${payload.status}.`);
        yield put(InterestAction.updateInterestFailure());
      }
      if (cb) {
        cb(res.data.Data);
      }
    } catch (err) {
      console.log(err);
      // message.error("Something went wrong while updating interest.");
      yield put(InterestAction.updateInterestFailure());
    }
  }
}
