import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ProfileAction, MatchAction } from "../../store/actions";

import ErrorText from "../../components/errortext/errortext";
import EditProfileBoxLabel from "../../components/label/editprofileboxlabel";
import EditProfileDropdown from "../../components/dropdown/editprofiledropdown";
import EditProfileMultipleSelect from "../../components/dropdown/editprofilemultipleselection";
import PrimaryButton from "../../components/button/primarybutton";

import {
  education,
  profession,
  incomeCurrency,
  incomeValue,
} from "../../utils/arrayitems";

import Spacer from "../../components/spacer/spacer";
import Loader from "../../components/loader/loader";

import images from "../../assets";

import TabHeading from "./tabheading";

import "./styles.css";

const EducationCareer = (props) => {
  const dispatch = useDispatch();
  const profileLoading = useSelector((state) => state?.profile?.isLoading);
  const matchLoading = useSelector((state) => state?.matches?.isLoading);
  const profileData = useSelector((state) => state?.profile?.profile);

  const [isDisabled, setDisabled] = useState(false);

  const [state, setState] = useState({
    education: [],
    profession: [],
    incomeCurrency: "",
    annualIncomeMin: "",
    annualIncomeMax: "",
    islamicInstituteAttended: "",
  });

  const [error, setError] = useState({
    incomeError: "",
  });

  useEffect(() => {
    setState({
      ...state,
      education: profileData?.matchPreference?.education ?? [],
      profession: profileData?.matchPreference?.profession ?? [],
      incomeCurrency: profileData?.matchPreference?.incomeCurrency ?? "",
      annualIncomeMin: profileData?.matchPreference?.annualIncomeMin ?? "",
      annualIncomeMax: profileData?.matchPreference?.annualIncomeMax ?? "",
      islamicInstituteAttended:
        profileData?.matchPreference?.islamicInstituteAttended ?? "",
    });
  }, [profileLoading]);

  const filteredIncomeMinOptions = incomeValue.filter(
    (option) => !state.annualIncomeMax || option.value < state.annualIncomeMax
  );

  const filteredIncomeMaxOptions = incomeValue.filter(
    (option) => !state.annualIncomeMin || option.value > state.annualIncomeMin
  );

  const handleSubmit = () => {
    if (
      (state?.annualIncomeMax || state?.annualIncomeMin) &&
      state?.annualIncomeMax <= state?.annualIncomeMin
    ) {
      setError({
        ...error,
        incomeError: "Max income must be greater than minimum income",
      });
    } else {
      setDisabled(true);
      const payload = {
        username: profileData?.username,
        matchPreference: { ...profileData?.matchPreference, ...state },
      };
      dispatch(
        MatchAction.updateMatchPreference(payload, () => {
          setDisabled(false);
          dispatch(ProfileAction.getProfile());
          props?.handleTab("idealMatch");
        })
      );
    }
  };

  return (
    <>
      <TabHeading
        image={images.matchPrefEducation}
        title="Education & Career"
        subtitle="Tell us a little about your preferences."
      />
      <div className="edit-profile-content">
        {profileLoading || matchLoading ? (
          <Loader />
        ) : (
          <>
            {/* Education */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Education" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="Education"
              data={education}
              onChange={(e) => setState({ ...state, education: e })}
              value={state.education || undefined}
            />

            <Spacer height="12px" />

            {/* Profession */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Profession" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="Profession"
              data={profession}
              onChange={(e) => setState({ ...state, profession: e })}
              value={state.profession || undefined}
            />

            <Spacer height="12px" />

            {/* Annual Income */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Annual Income" />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Income Currency"
              data={incomeCurrency}
              onChange={(e) => setState({ ...state, incomeCurrency: e })}
              value={state.incomeCurrency || undefined}
            />
            <Spacer height="8px" />
            <div className="edit-profile-annual-income-container">
              <div style={{ width: "50%" }}>
                <EditProfileDropdown
                  placeholder="Minimum annual income"
                  data={filteredIncomeMinOptions}
                  onChange={(e) => {
                    setState({ ...state, annualIncomeMin: e });
                    setError({
                      ...error,
                      incomeError: "",
                    });
                  }}
                  value={state.annualIncomeMin || undefined}
                />
              </div>
              <div style={{ width: "5%", textAlign: "center" }}>to</div>
              <div style={{ width: "50%" }}>
                <EditProfileDropdown
                  placeholder="Maximum annual income"
                  data={filteredIncomeMaxOptions}
                  onChange={(e) => {
                    setState({ ...state, annualIncomeMax: e });
                    setError({
                      ...error,
                      incomeError: "",
                    });
                  }}
                  value={state.annualIncomeMax || undefined}
                />
              </div>
            </div>
            {error?.incomeError && <ErrorText text={error?.incomeError} />}
            <Spacer height="12px" />

            {/* Islamic Institute Attended */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Islamic Institute Attended" />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Islamic Institute Attended"
              data={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
              onChange={(e) =>
                setState({ ...state, islamicInstituteAttended: e })
              }
              value={state.islamicInstituteAttended || undefined}
            />
          </>
        )}
        <Spacer height="32px" />
        <PrimaryButton
          text="Save & Continue"
          onClick={handleSubmit}
          disabled={isDisabled}
        />
      </div>
    </>
  );
};

export default EducationCareer;
