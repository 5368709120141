const hobbies = [
  { label: "Antiques", value: "Antiques" },
  { label: "Cars / Mechanics", value: "Cars / Mechanics" },
  { label: "Cooking / Food", value: "Cooking / Food" },
  { label: "Dinner Parties", value: "Dinner Parties" },
  { label: "Gardening / Landscaping", value: "Gardening / Landscaping" },
  { label: "Investing / Finance", value: "Investing / Finance" },
  { label: "Library", value: "Library" },
  { label: "Motorcycles", value: "Motorcycles" },
  { label: "Museums / Galleries", value: "Museums / Galleries" },
  { label: "Music (Playing)", value: "Music (Playing)" },
  { label: "Pets", value: "Pets" },
  { label: "Photography", value: "Photography" },
  { label: "Reading", value: "Reading" },
  { label: "Shopping", value: "Shopping" },
  { label: "TV : Educational / News", value: "TV : Educational / News" },
  { label: "Video / Online Games", value: "Video / Online Games" },
  { label: "Watching Sports", value: "Watching Sports" },
  { label: "Other", value: "Other" },
  { label: "Art / Painting", value: "Art / Painting" },
  { label: "Beach / Parks", value: "Beach / Parks" },
  { label: "Camping / Nature", value: "Camping / Nature" },
  { label: "Concerts / Live Music", value: "Concerts / Live Music" },
  { label: "Crafts", value: "Crafts" },
  { label: "Dining Out", value: "Dining Out" },
  { label: "Education", value: "Education" },
  { label: "Movies / Cinema", value: "Movies / Cinema" },
  { label: "Music (Listening)", value: "Music (Listening)" },
  { label: "News / Politics", value: "News / Politics" },
  { label: "Science and Technology", value: "Science and Technology" },
  { label: "Social Causes / Activism", value: "Social Causes / Activism" },
  { label: "TV: Entertainment", value: "TV: Entertainment" },
  { label: "Traveling", value: "Traveling" },
  { label: "Volunteering", value: "Volunteering" },
  { label: "Science Fiction", value: "Science Fiction" },
];

export default hobbies;
