import React from "react";
import MembershipPlanLayout from "../../components/layout/membershipplan/membershipplanlayout";
import MembershipPriceTable from "./membershippricetable";

export default function MembershipPlan() {
  return (
    <>
      <MembershipPlanLayout
        titlename="Membership Plans"
        breadcrumbs={[
          { label: "Home", route: "/" },
          { label: "Membership Plans", route: "/membership-plan" },
        ]}
      >
        <MembershipPriceTable />
      </MembershipPlanLayout>
    </>
  );
}
