import React from "react";

import { financialStatus, familyType } from "../../utils/arrayitems";

import EditProfileBoxLabel from "../../components/label/editprofileboxlabel";
import Spacer from "../../components/spacer/spacer";
import EditProfileMultipleSelect from "../../components/dropdown/editprofilemultipleselection";

import images from "../../assets";

import "./styles.css";
export default function FamilyDetailsFrom(props) {
  return (
    <div className="adv-search-basic-info-area">
      <div
        style={{
          background: `radial-gradient(120% 110% at 80% 160%, #001528 74%, #073AFF00 92%),radial-gradient(150% 80% at 120% 115%, #02305E 70%, #073AFF00 88%),radial-gradient(140% 140% at 110% 140%, #5D4D8C 76%, #5E519200 92%, #073AFF00 100%),radial-gradient(75% 75% at 50% 50%, #362955 100%, #073AFF00 100%)`,
          backgroundSize: "100%",
          backgroundPosition: "center",
        }}
        className="adv-search-basic-info"
      >
        <h2 className="adv-search-info-title">Family Details</h2>
      </div>
      <div className="adv-search-from-content">
        <div className="adv-search-from-left">
          {/* Financial Status */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel label="Financial Status" />
          </div>
          <Spacer height="8px" />
          <EditProfileMultipleSelect
            placeholder="Financial Status"
            data={financialStatus}
            onChange={(e) =>
              props?.setState({ ...props?.state, financialStatus: e })
            }
            value={props?.state.financialStatus || undefined}
          />
        </div>
        {/* Right From Area */}
        <div className="adv-search-from-right">
          {/* Family Type */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel label="Family Type" />
          </div>
          <Spacer height="8px" />
          <EditProfileMultipleSelect
            placeholder="Family Type"
            data={familyType}
            onChange={(e) =>
              props?.setState({ ...props?.state, familyType: e })
            }
            value={props?.state.familyType || undefined}
          />
        </div>
      </div>
    </div>
  );
}
