import React from "react";
import { Checkbox } from "antd";

import "./styles.css";

const EditProfileCheckbox = (props) => {
  return (
    <Checkbox
      {...props}
      style={{
        fontSize: "14px",
        fontWeight: "400",
      }}
    >
      {props.text}
    </Checkbox>
  );
};

export default EditProfileCheckbox;
