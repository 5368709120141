import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import getMaritalStatusLabel from "../../utils/getmaritalstatuslabel";
import convertedCentoFeet from "../../utils/convertdenttofeet";

import Loader from "../loader/loader";

import ImproveMatchInfo from "./improvematchinfo";

import images from "../../assets";

import "./styles.css";

export default function ImproveMatchCard() {
  const navigate = useNavigate();

  const profileData = useSelector((state) => state?.profile?.profile);
  const profileLoading = useSelector((state) => state?.profile?.isLoading);
  const matchPrefData = useSelector((state) => {
    return state?.profile?.profile?.matchPreference;
  });

  let feetDataMin = convertedCentoFeet(matchPrefData?.heightMin);
  let feetDataMax = convertedCentoFeet(matchPrefData?.heightMax);

  return (
    <div className="improve-match-card-area">
      <div
        style={{
          background: `radial-gradient(120% 110% at 80% 160%, #001528 74%, #073AFF00 92%),radial-gradient(150% 80% at 120% 115%, #02305E 70%, #073AFF00 88%),radial-gradient(140% 140% at 110% 140%, #5D4D8C 76%, #5E519200 92%, #073AFF00 100%),radial-gradient(75% 75% at 50% 50%, #362955 100%, #073AFF00 100%)`,
          backgroundSize: "100% 100%",
        }}
        className="improve-match-card"
      >
        <div className="improve-match-head">
          {profileLoading ? (
            <Loader />
          ) : (
            <img
              src={
                profileData?.profilePicture ??
                (profileData?.gender === "male"
                  ? images?.defaultMaleIcon
                  : images?.defaultFemaleIcon)
              }
              alt="pp"
              className="object-cover"
            />
          )}
          <div className="improve-match-title">Your Match</div>
        </div>
        <div className="improve-match-body">
          <div
            className="improve-match-btn"
            onClick={() => navigate("/match-preference")}
          >
            Improve Match
          </div>
          <div className="improve-match-subtext">
            Tap here to improve the matches
            <br />
            result, if you have not completed yet.
          </div>
        </div>
      </div>
      <div className="improve-match-info">
        <div className="improve-match-info-inner">
          {matchPrefData?.ageMin && matchPrefData?.ageMax && (
            <ImproveMatchInfo
              title="Age"
              subtext={`${matchPrefData?.ageMin} to ${matchPrefData?.ageMax}`}
            />
          )}
          {matchPrefData?.heightMin && (
            <ImproveMatchInfo
              title="Height"
              subtext={`${feetDataMin} (${matchPrefData?.heightMin} cm) to ${feetDataMax} (${matchPrefData?.heightMax} cm)`}
            />
          )}
          {matchPrefData?.maritalStatus?.length > 0 && (
            <ImproveMatchInfo
              title="Marital Status"
              subtext={matchPrefData?.maritalStatus?.map((item, index) => {
                if (index === 0) {
                  return getMaritalStatusLabel(item);
                } else {
                  return `, ${getMaritalStatusLabel(item)}`;
                }
              })}
            />
          )}
          {matchPrefData?.religiousCommitment?.length > 0 && (
            <ImproveMatchInfo
              title="Religious Commitment Level"
              subtext={matchPrefData?.religiousCommitment?.map(
                (item, index) => {
                  if (index === 0) {
                    return item;
                  } else {
                    return `, ${item}`;
                  }
                }
              )}
            />
          )}
          {matchPrefData?.religiousHistory?.length > 0 && (
            <ImproveMatchInfo
              title="Religion / Community"
              subtext={matchPrefData?.religiousHistory?.map((item, index) => {
                if (index === 0) {
                  return item;
                } else {
                  return `, ${item}`;
                }
              })}
            />
          )}
          {matchPrefData?.spokenLanguage?.length > 0 && (
            <ImproveMatchInfo
              title="Mother Tongue"
              subtext={matchPrefData?.spokenLanguage?.map((item, index) => {
                if (index === 0) {
                  return item;
                } else {
                  return `, ${item}`;
                }
              })}
            />
          )}
          {matchPrefData?.country?.length > 0 && (
            <ImproveMatchInfo
              title="Country Living in"
              subtext={matchPrefData?.country?.map((item, index) => {
                if (index === 0) {
                  return item;
                } else {
                  return `, ${item}`;
                }
              })}
            />
          )}
          {matchPrefData?.annualIncomeMax && (
            <ImproveMatchInfo
              title="Annual Income"
              subtext={`${
                matchPrefData?.incomeCurrency
              } ${matchPrefData?.annualIncomeMin?.toLocaleString()} to ${matchPrefData?.annualIncomeMax?.toLocaleString()}`}
            />
          )}
          {matchPrefData?.bloodGroup?.length > 0 && (
            <ImproveMatchInfo
              title="Blood Group"
              subtext={matchPrefData?.bloodGroup?.map((item, index) => {
                if (index === 0) {
                  return item;
                } else {
                  return `, ${item}`;
                }
              })}
            />
          )}
        </div>
      </div>
    </div>
  );
}
