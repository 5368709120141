const validateDescription = (description) => {
  const phoneNumberPattern = /(?:\d.*?){4,}/;
  const consecutiveNumbersPattern = /(?!\b\d{1,2}\b)\d{4,}/;
  const numberWordsPattern =
    /\b(zero|one|two|three|four|five|six|seven|eight|nine|ten|eleven|twelve|thirteen|fourteen|fifteen|sixteen|seventeen|eighteen|nineteen|twenty|thirty|forty|fifty|sixty|seventy|eighty|ninety|hundred|thousand)\b/i;
  const mixedPattern =
    /(?:\b\d+\s+\D+|[a-zA-Z]*\d[a-zA-Z]*\d[a-zA-Z]*\d[a-zA-Z]*)/;
  const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
  const addressPattern = /\d+\s+[a-zA-Z]+\s+\w+.*?/;
  const heightPattern = /\b\d'\d{1,2}"\b/;

  if (
    phoneNumberPattern.test(description) ||
    // (consecutiveNumbersPattern.test(description) &&
    //   !heightPattern.test(description)) ||

    // !heightPattern.test(description) ||
    numberWordsPattern.test(description) ||
    mixedPattern.test(description) ||
    emailPattern.test(description) ||
    addressPattern.test(description)
  ) {
    return false;
  }

  return true;
};

export default validateDescription;
