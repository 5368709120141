import {
  GET_BLOCKLIST,
  GET_BLOCKLIST_SUCCESS,
  GET_BLOCKLIST_FAILURE,
  DELETE_BLOCKLIST,
  DELETE_BLOCKLIST_SUCCESS,
  DELETE_BLOCKLIST_FAILURE,
  UPDATE_BLOCKLIST,
  UPDATE_BLOCKLIST_SUCCESS,
  UPDATE_BLOCKLIST_FAILURE,
} from "../constants";

const initialState = {
  blocklist: [],
  blocklistCount: 0,
  isLoading: false,
  error: null,
};

export default function BlocklistReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BLOCKLIST:
    case UPDATE_BLOCKLIST:
    case DELETE_BLOCKLIST:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_BLOCKLIST_SUCCESS:
      state = {
        ...state,
        blocklist: action.payload.data,
        blocklistCount: action.payload.count,
        isLoading: false,
      };
      break;
    case UPDATE_BLOCKLIST_SUCCESS:
    case DELETE_BLOCKLIST_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;

    case GET_BLOCKLIST_FAILURE:
    case UPDATE_BLOCKLIST_FAILURE:
    case DELETE_BLOCKLIST_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }

  return state;
}
