import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { AuthAction } from "../../store/actions";
import EmailForm from "../changepassword/emailform";
import OtpForm from "../changepassword/otpform";
import ResetForm from "../changepassword/resetform";
import { LoginLayout } from "../../components/layout";

import "../changepassword/styles.css";

const emailValidationChecker = (str) => {
  return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(str);
};
const containsNonNumericCharacters = (str) => {
  return /\D/.test(str);
};

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState("email");

  const [state, setState] = useState({
    email: "",
    otp: "",
    password: "",
    confirmPassword: "",
    error: "",
  });

  const handleEmailSubmit = () => {
    if (!state.email) {
      setState({ ...state, error: "Email is required" });
    } else if (emailValidationChecker(state?.email)) {
      setState({ ...state, error: "" });
      const payload = { username: state?.email };
      dispatch(
        AuthAction.forgotPassword(payload, (res) => {
          if (res === 200) {
            setPage("otp");
          }
        })
      );
    } else {
      setState({ ...state, error: "Invalid email address" });
    }
  };

  const handleOtpSubmit = () => {
    if (!state.otp) {
      setState({ ...state, error: "Enter OTP" });
    } else if (containsNonNumericCharacters(state.otp)) {
      setState({ ...state, error: "OTP must be a number" });
    } else {
      setState({ ...state, error: "" });
      setPage("reset");
    }
  };

  const handleResend = () => {
    const payload = { email: state?.email };
  };

  const handleResetSubmit = () => {
    if (!state?.password || !state?.confirmPassword) {
      setState({
        ...state,
        error: "Please fill all the fields",
      });
    } else if (state?.password !== state?.confirmPassword) {
      setState({
        ...state,
        error: "Passwords do not match",
      });
    } else {
      setState({
        ...state,
        error: "",
      });
      const payload = {
        username: state?.email,
        code: state?.otp,
        password: state?.password,
      };

      dispatch(
        AuthAction.confirmPassword(payload, (res) => {
          if (res === 200) {
            navigate("/login");
          }
        })
      );
    }
  };

  return (
    <>
      <LoginLayout>
        <div className="w-[300px] lg:w-[450px]">
          <div className="forgot-password-container">
            {page === "email" ? (
              <EmailForm
                state={state}
                setState={setState}
                page={page}
                setPage={setPage}
                handleEmailSubmit={handleEmailSubmit}
              />
            ) : page === "otp" ? (
              <OtpForm
                state={state}
                setState={setState}
                page={page}
                setPage={setPage}
                handleOtpSubmit={handleOtpSubmit}
                handleResend={handleResend}
              />
            ) : page === "reset" ? (
              <ResetForm
                state={state}
                setState={setState}
                page={page}
                setPage={setPage}
                handleResetSubmit={handleResetSubmit}
              />
            ) : (
              <EmailForm
                state={state}
                setState={setState}
                page={page}
                setPage={setPage}
                handleEmailSubmit={handleEmailSubmit}
              />
            )}
          </div>
        </div>
      </LoginLayout>
    </>
  );
}
