import React from "react";
import { Switch } from "antd";

import UpgradeButton from "../../components/layout/dashboard/upgrade/upgradebutton";
import MembershipPlanCard from "../../components/membershipplan/membershipplancard";

import images from "../../assets";

const UpgradeNowCard = () => {
  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };

  return (
    <>
      <div className="billing-info-area">
        <div className="billing-info-content">
          <div className="billing-heading">
            <h3>My Billing Info</h3>
            <p>Manage billing information and view receipts</p>
          </div>
          <div className="current-plan-content">
            <p>
              Current Plan: <span>Standard</span>
            </p>
            <p>
              Days left: <span>Unlimited</span>
            </p>
            <p>
              Auto renew: <span>Off</span>
              <Switch onChange={onChange} />
            </p>
            <p>
              <span>
                (If you select “Auto Renew”, there will be
                <br /> 25% discount in the upcoming renewal.)
              </span>
            </p>
          </div>
          <a className="extend-membership" href="/membership-plan">
            <UpgradeButton
              className="upgrade upgrade-membership-btn"
              title="Upgrade Now"
              disscunt="25% OFF"
            />
          </a>
        </div>
        <div
          style={{
            background: `url(${images.membershipBg})`,
            backgroundSize: "100% 100%",
          }}
          className="membership-plan-area"
        >
          <div className="membership-plan-heading">
            <h1>Membership Plan Details</h1>
          </div>
          <div className="membership-plan-content">
            <div className="membership-plan-left">
              <MembershipPlanCard titlename="Plan Name" values="Standard" />
              <MembershipPlanCard titlename="Price" values="FREE" />
              <MembershipPlanCard titlename="Duration" values="Lifetime" />
              <MembershipPlanCard titlename="Contact Limit" values="0" />
              <MembershipPlanCard titlename="Message Limit" values="0" />
              <MembershipPlanCard titlename="Expires" values="Lifetime" />
              <MembershipPlanCard titlename="Payment Method" values="Not used yet"
              />
            </div>
            <div className="membership-plan-logo"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpgradeNowCard;
