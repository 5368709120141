import React from "react";
import "./styles.css";

const ProfileTab = ({ tabItems, handleTab, activeTab, ...rest }) => {
  return (
    <div className="tab-container" {...rest}>
      {tabItems?.map((tab, index) => (
        <div
          key={index}
          onClick={() => handleTab(tab.name)}
          className={`tab-menu ${activeTab === tab.name ? "active" : ""}`}
        >
          {tab.title}
        </div>
      ))}
    </div>
  );
};

export default ProfileTab;
