const BASE_URL_AUTH =
  process.env.REACT_APP_ENV === "local"
    ? process.env.REACT_APP_AUTH_BACKEND_URL_LOCAL
    : process.env.REACT_APP_ENV === "staging"
    ? process.env.REACT_APP_AUTH_BACKEND_URL_STAGING
    : process.env.REACT_APP_AUTH_BACKEND_URL_PROD;
const BASE_URL_USER =
  process.env.REACT_APP_ENV === "local"
    ? process.env.REACT_APP_USER_BACKEND_URL_LOCAL
    : process.env.REACT_APP_ENV === "staging"
    ? process.env.REACT_APP_USER_BACKEND_URL_STAGING
    : process.env.REACT_APP_USER_BACKEND_URL_PROD;
const BASE_URL_SEARCH =
  process.env.REACT_APP_ENV === "local"
    ? process.env.REACT_APP_SEARCH_BACKEND_URL_LOCAL
    : process.env.REACT_APP_ENV === "staging"
    ? process.env.REACT_APP_SEARCH_BACKEND_URL_STAGING
    : process.env.REACT_APP_SEARCH_BACKEND_URL_PROD;
const BASE_URL_MEDIA =
  process.env.REACT_APP_ENV === "local"
    ? process.env.REACT_APP_MEDIA_BACKEND_URL_LOCAL
    : process.env.REACT_APP_ENV === "staging"
    ? process.env.REACT_APP_MEDIA_BACKEND_URL_STAGING
    : process.env.REACT_APP_MEDIA_BACKEND_URL_PROD;
const BASE_URL_ACTIVITY =
  process.env.REACT_APP_ENV === "local"
    ? process.env.REACT_APP_ACTIVITY_BACKEND_URL_LOCAL
    : process.env.REACT_APP_ENV === "staging"
    ? process.env.REACT_APP_ACTIVITY_BACKEND_URL_STAGING
    : process.env.REACT_APP_USER_BACKEND_URL_PROD;
const BASE_URL_SUBSCRIPTION =
  process.env.REACT_APP_ENV === "local"
    ? process.env.REACT_APP_SUBSCRIPTION_BACKEND_URL_LOCAL
    : process.env.REACT_APP_ENV === "staging"
    ? process.env.REACT_APP_SUBSCRIPTION_BACKEND_URL_STAGING
    : process.env.REACT_APP_SUBSCRIPTION_BACKEND_URL_PROD;
const BASE_URL_NOTIFICATION =
  process.env.REACT_APP_ENV === "local"
    ? process.env.REACT_APP_NOTIFICATION_BACKEND_URL_LOCAL
    : process.env.REACT_APP_ENV === "staging"
    ? process.env.REACT_APP_NOTIFICATION_BACKEND_URL_STAGING
    : process.env.REACT_APP_NOTIFICATION_BACKEND_URL_PROD;
const BASE_URL_LANDING =
  process.env.REACT_APP_ENV === "local"
    ? process.env.REACT_APP_LANDING_URL_LOCAL
    : process.env.REACT_APP_ENV === "staging"
    ? process.env.REACT_APP_LANDING_URL_STAGING
    : process.env.REACT_APP_LANDING_URL_PROD;

const configVariables = {
  BASE_URL_ACTIVITY,
  BASE_URL_AUTH,
  BASE_URL_LANDING,
  BASE_URL_MEDIA,
  BASE_URL_NOTIFICATION,
  BASE_URL_SEARCH,
  BASE_URL_SUBSCRIPTION,
  BASE_URL_USER,
};

export default {
  ...configVariables,
};
