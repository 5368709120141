import React, { useState } from "react";
import { Modal } from "antd";

import { PrimaryButton, WhiteButton } from "../../components/button";
import Spacer from "../../components/spacer/spacer";

import images from "../../assets";

export default function FailedMessageCard(props) {
  let messageParts = props?.textmessage?.split("\n");

  const [isOpen, setOpen] = useState(false);

  return (
    <div {...props} className="premium-message-body-failed">
      <div className="premium-message-body-receiver-with">
        <div className="premium-message-body-main-failed">
          <div className="premium-message-body-text-mess">
            {messageParts.map((part, index) => (
              <p key={index}>{part}</p>
            ))}
          </div>
          <div className="premium-message-body-text-time-failed">
            <p onClick={() => setOpen(true)}>See details</p>
            <img src={images.failedMessageIcon} alt="Failed Message" />
          </div>
        </div>
    
          <Modal
            centered={true}
            open={isOpen}
            onCancel={() => setOpen(false)}
            footer={false}
            closable={false}
          >
            <div className="flex gap-4" style={{ alignItems: "self-start" }}>
              <img src={images?.deleteIcon} alt="" />
              <p>
                Message is not delivered. <br />
                <br />
                <b style={{ color: "#222" }}>Upgrade</b> to get full access.
                After upgrading, you can enjoy unlimited chat.
                <br />
                <br />
                Also, you can use our predefined messages to start a
                conversation.
              </p>
            </div>
            <Spacer height="16px" />
            <div className="flex w-full justify-end">
              <div className="flex gap-4 w-[70%]">
                <WhiteButton
                  style={{
                    width: "120px",
                    height: "40px",
                    border: "2px solid #EAE6ED",
                  }}
                  text="Skip"
                  onClick={() => setOpen(false)}
                />
                <PrimaryButton
                  text="Upgrade"
                  style={{
                    background: "#BA0060",
                    width: "157px",
                    height: "40px",
                  }}
                />
              </div>
            </div>
          </Modal>
    
      </div>
    </div>
  );
}
