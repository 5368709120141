const profession = [
  { label: "Accountant", value: "Accountant" },
  { label: "Actor", value: "Actor" },
  {
    label: "Administration Professional",
    value: "Administration Professional",
  },
  { label: "Advertising Professional", value: "Advertising Professional" },
  { label: "Architect", value: "Architect" },
  { label: "Artist", value: "Artist" },
  { label: "Banker", value: "Banker" },
  { label: "Beautician", value: "Beautician" },
  { label: "Business Person", value: "Business Person" },
  { label: "Chartered Accountant", value: "Chartered Accountant" },
  { label: "Chief Executive Officer", value: "Chief Executive Officer" },
  { label: "Chief Finance Officer", value: "Chief Finance Officer" },
  { label: "Civil Engineer", value: "Civil Engineer" },
  { label: "Clerical Official", value: "Clerical Official" },
  { label: "Company Secretary", value: "Company Secretary" },
  { label: "Computer Professional", value: "Computer Professional" },
  { label: "Consultant", value: "Consultant" },
  { label: "Contractor", value: "Contractor" },
  {
    label: "Customer Support Professional",
    value: "Customer Support Professional",
  },
  { label: "Defense Employee", value: "Defense Employee" },
  { label: "Defense / Military Person", value: "Defense / Military Person" },
  { label: "Dentist", value: "Dentist" },
  { label: "Designer", value: "Designer" },
  { label: "Director", value: "Director" },
  { label: "Doctor", value: "Doctor" },
  { label: "Economist", value: "Economist" },
  { label: "Engineer", value: "Engineer" },
  { label: "Entertainment Professional", value: "Entertainment Professional" },
  { label: "Entrepreneur", value: "Entrepreneur" },
  { label: "Event Manager", value: "Event Manager" },
  { label: "Executive", value: "Executive" },
  { label: "Factory Worker", value: "Factory Worker" },
  { label: "Farmer", value: "Farmer" },
  { label: "Fashion Designer", value: "Fashion Designer" },
  { label: "Finance Professional", value: "Finance Professional" },
  { label: "Flight Attendant", value: "Flight Attendant" },
  { label: "Freelancer", value: "Freelancer" },
  { label: "Government Employee", value: "Government Employee" },
  { label: "Healthcare Professional", value: "Healthcare Professional" },
  { label: "Homemaker", value: "Homemaker" },
  {
    label: "Human Resources Professional",
    value: "Human Resources Professional",
  },
  { label: "IT / Telecom Professional", value: "IT / Telecom Professional" },
  { label: "Interior Designer", value: "Interior Designer" },
  { label: "Journalist", value: "Journalist" },
  { label: "Lawyer", value: "Lawyer" },
  { label: "Lecturer", value: "Lecturer" },
  { label: "Manager", value: "Manager" },
  { label: "Managing Director", value: "Managing Director" },
  { label: "Marketing Professional", value: "Marketing Professional" },
  { label: "Media Professional", value: "Media Professional" },
  { label: "Medical Professional", value: "Medical Professional" },
  { label: "Merchant Naval Officer", value: "Merchant Naval Officer" },
  { label: "Military", value: "Military" },
  { label: "Nurse", value: "Nurse" },
  { label: "Occupational Therapist", value: "Occupational Therapist" },
  { label: "Other", value: "Other" },
  { label: "Pharmacist", value: "Pharmacist" },
  { label: "Photographer", value: "Photographer" },
  { label: "Physician Assistant", value: "Physician Assistant" },
  { label: "Physiotherapist", value: "Physiotherapist" },
  { label: "Pilot", value: "Pilot" },
  { label: "Politician", value: "Politician" },
  { label: "Professor", value: "Professor" },
  { label: "Real Estate Professional", value: "Real Estate Professional" },
  { label: "Researcher", value: "Researcher" },
  { label: "Retail Professional", value: "Retail Professional" },
  { label: "Retired Person", value: "Retired Person" },
  { label: "Sales Professional", value: "Sales Professional" },
  { label: "Scientist", value: "Scientist" },
  { label: "Self-employed Person", value: "Self-employed Person" },
  { label: "Senior Executive", value: "Senior Executive" },
  { label: "Senior Manager", value: "Senior Manager" },
  { label: "Social Worker", value: "Social Worker" },
  { label: "Sportsperson", value: "Sportsperson" },
  { label: "Student", value: "Student" },
  { label: "Teacher", value: "Teacher" },
  { label: "Technician", value: "Technician" },
  { label: "Training Professional", value: "Training Professional" },
  {
    label: "Transportation Professional",
    value: "Transportation Professional",
  },
  { label: "Veterinary Doctor", value: "Veterinary Doctor" },
  { label: "Volunteer", value: "Volunteer" },
  { label: "Writer", value: "Writer" },
  { label: "Imam", value: "Imam" },
  { label: "Muezzin", value: "Muezzin" },
  { label: "Preacher of Islam", value: "Preacher of Islam" },
  { label: "Madrasah Teacher", value: "Madrasah Teacher" },
];
export default profession;
