import React, { useState, useEffect } from "react";
import ReactCodeInput from "react-code-input";

import Spacer from "../../components/spacer/spacer";
import { PrimaryButton } from "../../components/button";

import images from "../../assets";

import "./styles.css";

const OtpForm = (props) => {
  const [timer, setTimer] = useState(40);
  const [isInputFocused, setIsInputFocused] = useState(false);

  useEffect(() => {
    if (timer > 0 && props.resendDisabled) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(countdown);
    } else if (timer === 0) {
      props?.setResendDisabled(false);
    }
  }, [timer, props.resendDisabled]);

  const handleResendClick = () => {
    props?.handleResendOTP();
    setTimer(40);
    props?.setResendDisabled(true);
  };

  const handleChange = (val) => {
    props?.setState({ ...props?.state, otp: val, error: "" });
    setIsInputFocused(true);
  };

  return (
    <>
      <img src={images?.otpIcon} alt="" />
      <Spacer height="32px" />
      <h2 className="forgot-password-title">
        Please enter the verification code sent to your Email.
      </h2>
      <Spacer height="24px" />
      <ReactCodeInput
        type="text"
        fields={6}
        onChange={handleChange}
        value={props?.state?.otp}
        inputStyle={{
          border: `1px solid #eae6ed`,
          background: "#fff",
          borderRadius: "5px",
          fontSize: "32px",
          fontWeight: "500",
          height: "48px",
          width: "60px",
          outline: "none",
          textAlign: "center",
          marginLeft: "5px",
          marginRight: "5px",
        }}
        onClick={(e) =>
          props?.setState({ ...props?.state, otp: e.target.value })
        }
      />

      {props?.state?.error && (
        <div className="text-center">
          <p className="text-danger font-semibold text-[14px] m-0">
            {props?.state?.error}
          </p>
        </div>
      )}
      <Spacer height="32px" />
      <PrimaryButton
        text={"Verify OTP"}
        onClick={props?.handleOtpSubmit}
        disabled={!isInputFocused || props?.isDisabled}
      />
      <Spacer height="16px" />
      <p className="otp-form-bottom-text">
        Haven't received an OTP?{" "}
        <span
          className={`font-bold text-red-600 cursor-pointer ${
            props?.resendDisabled && "pointer-events-none opacity-60"
          }`}
          onClick={!props?.resendDisabled ? handleResendClick : null}
        >
          Resend {props?.resendDisabled && `(${timer}s)`}
        </span>
      </p>
      <p className="otp-form-bottom-text">
        Make sure you have checked your spam / junk box.
      </p>
    </>
  );
};

export default OtpForm;
