import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Country, State } from "country-state-city";
import { Modal } from "antd";

import { ProfileAction } from "../../store/actions";

import EditProfileBoxLabel from "../../components/label/editprofileboxlabel";
import EditProfileInput from "../../components/inputbox/editprofileinput";
import EditProfileDropdown from "../../components/dropdown/editprofiledropdown";
import EditProfileDatepicker from "../../components/datepicker/editprofiledatepicker";
import EditProfilePhoneInput from "../../components/phoneinput/editprofilephoneinput";
import EditProfileMultipleSelect from "../../components/dropdown/editprofilemultipleselection";
import PrimaryButton from "../../components/button/primarybutton";
import Loader from "../../components/loader/loader";
import Spacer from "../../components/spacer/spacer";
import ChangeButton from "../../components/button/changebutton";
import ErrorText from "../../components/errortext/errortext";

import images from "../../assets";

import ChangeEmail from "./changeemail";

import {
  ethnicOrigin,
  genderItems,
  homeType,
  maritalStatusMale,
  maritalStatusFemale,
  profileCreatedBy,
  relocationPlan,
  residencyStatus,
  spokenLanguages,
} from "../../utils/arrayitems";

import TabHeading from "./tabheading";

import "./styles.css";
import GenderChangeReq from "./genderchangereq";
import CountryChangeReq from "./countrychangereq";
import ChangePhone from "./changephone";
import DateOfBirthChangeReq from "./dateofbirthchangereq";

const countries = Country.getAllCountries();

const BasicInfo = (props) => {
  const dispatch = useDispatch();

  const profileLoading = useSelector((state) => state?.profile?.isLoading);
  const profileData = useSelector((state) => state?.profile?.profile);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGenderModalOpen, setIsGenderModalOpen] = useState(false);
  const [isDobModalOpen, setIsDobModalOpen] = useState(false);
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);
  const [isCountryModalOpen, setIsCountryModalOpen] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [states, setStates] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const [state, setState] = useState({
    email: "",
    firstName: "",
    lastName: "",
    gender: "",
    dateOfBirth: "",
    phone: "",
    country: "",
    stateProvince: "",
    homeType: "",
    residencyStatus: "",
    relocationPlan: "",
    ethnicOrigin: "",
    maritalStatus: "",
    profileCreatedBy: "",
    spokenLanguage: [],
  });
  const [error, setError] = useState({
    name: "",
    email: "",
    gender: "",
    dateOfBirth: "",
    phone: "",
    country: "",
    stateProvince: "",
    homeType: "",
    residencyStatus: "",
    ethnicOrigin: "",
    maritalStatus: "",
    profileCreatedBy: "",
    spokenLanguage: [],
  });

  useEffect(() => {
    setStates(State.getStatesOfCountry(countryCode));
  }, [countryCode]);

  useEffect(() => {
    setState({
      ...state,
      email: profileData?.email ?? "",
      firstName: profileData?.firstName ?? "",
      lastName: profileData?.lastName ?? "",
      gender: profileData?.gender ?? "",
      dateOfBirth: profileData?.dateOfBirth ?? "",
      phone: profileData?.phone ?? "",
      country: profileData?.country ?? "",
      stateProvince: profileData?.stateProvince ?? "",
      homeType: profileData?.homeType ?? "",
      residencyStatus: profileData?.residencyStatus ?? "",
      relocationPlan: profileData?.relocationPlan ?? "",
      ethnicOrigin: profileData?.ethnicOrigin ?? "",
      maritalStatus: profileData?.maritalStatus ?? "",
      profileCreatedBy: profileData?.profileCreatedBy ?? "",
      spokenLanguage: profileData?.spokenLanguage ?? [],
    });

    const countryCode = countries?.filter(
      (country) => country?.name === profileData?.country
    );
    setCountryCode(countryCode[0]?.isoCode);
  }, [profileLoading]);

  const handleSubmit = () => {
    if (!state?.firstName || !state?.lastName) {
      setError({
        ...error,
        name: "Name cannot be empty",
      });
    } else if (!state?.stateProvince) {
      setError({
        ...error,
        stateProvince: "State cannot be empty",
      });
    } else if (!state?.homeType) {
      setError({
        ...error,
        homeType: "Home Type cannot be empty",
      });
    } else if (!state?.residencyStatus) {
      setError({
        ...error,
        residencyStatus: "Residency status cannot be empty",
      });
    } else if (!state?.ethnicOrigin) {
      setError({
        ...error,
        ethnicOrigin: "Ethnic origin cannot be empty",
      });
    } else if (!state?.maritalStatus) {
      setError({
        ...error,
        maritalStatus: "Marital status cannot be empty",
      });
    } else if (!state?.profileCreatedBy) {
      setError({
        ...error,
        profileCreatedBy: "Profile created by cannot be empty",
      });
    } else if (state?.spokenLanguage?.length === 0) {
      setError({
        ...error,
        spokenLanguage: "Spoken language cannot be empty",
      });
    } else {
      setDisabled(true);
      const payload = state;
      dispatch(
        ProfileAction.updateProfile(payload, () => {
          setDisabled(false);
          dispatch(ProfileAction.getProfile());
          props?.handleTab("physicalLifestyle");
        })
      );
    }
  };

  return (
    <>
      <TabHeading
        image={images.editProfileBasicInfo}
        title="Basic Information"
        subtitle="Complete to easily find your ideal partner!"
      />
      <div className="edit-profile-content">
        {profileLoading ? (
          <Loader />
        ) : (
          <>
            {/* email */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Email" required={true} />
              <ChangeButton onClick={() => setIsModalOpen(true)} />
            </div>
            <Spacer height="8px" />
            <EditProfileInput
              placeholder="Email"
              value={state.email || undefined}
              disabled
            />
            {error?.email && <ErrorText text={error?.email} />}

            <Spacer height="12px" />

            {/* name */}
            <div className="edit-profile-name-container">
              <div className="edit-profile-name">
                <EditProfileBoxLabel label="First Name" />
                <Spacer height="8px" />
                <EditProfileInput
                  placeholder="First name"
                  value={state.firstName || undefined}
                  disabled
                />
              </div>
              <div className="edit-profile-name">
                <EditProfileBoxLabel label="Last Name" />
                <Spacer height="8px" />
                <EditProfileInput
                  placeholder="Last name"
                  value={state.lastName || undefined}
                  disabled
                />
              </div>
            </div>

            {error?.name && <ErrorText text={error?.name} />}
            <Spacer height="12px" />

            {/* gender */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="I am a" required={true} />
              <ChangeButton onClick={() => setIsGenderModalOpen(true)} />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Gender"
              data={genderItems}
              value={state.gender || undefined}
              disabled
            />
            {error?.gender && <ErrorText text={error?.gender} />}
            <Spacer height="12px" />

            {/* date of birth */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Date of Birth" required={true} />
              <ChangeButton onClick={() => setIsDobModalOpen(true)} />
            </div>
            <Spacer height="8px" />
            <EditProfileDatepicker
              placeholder="Select Date"
              value={
                state?.dateOfBirth
                  ? moment(state?.dateOfBirth, "YYYY-MM-DD")
                  : null
              }
              disabled
            />
            {error?.dateOfBirth && <ErrorText text={error?.dateOfBirth} />}
            <Spacer height="12px" />

            {/* phone input */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Phone" required={true} />
              <ChangeButton onClick={() => setIsPhoneModalOpen(true)} />
            </div>
            <Spacer height="8px" />
            <EditProfilePhoneInput
              placeholder="Please enter your phone number"
              value={state.phone || undefined}
              disabled
              // country={countryCode?.toLowerCase()}
            />
            {error?.phone && <ErrorText text={error?.phone} />}
            <Spacer height="12px" />

            {/* country */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Country" required={true} />
              <ChangeButton onClick={() => setIsCountryModalOpen(true)} />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={countries}
              list="country"
              value={state.country || undefined}
              disabled
            />
            {error?.country && <ErrorText text={error?.country} />}

            <Spacer height="12px" />

            {/* state */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="State/Province" required={true} />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={states}
              list="stateProvince"
              value={state.stateProvince || undefined}
              disabled
            />
            {error?.stateProvince && <ErrorText text={error?.stateProvince} />}
            <Spacer height="12px" />

            {/* home type */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Home Type" required={true} />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={homeType}
              onChange={(e) => {
                setState({ ...state, homeType: e });
                setError({ ...error, homeType: "" });
              }}
              value={state.homeType || undefined}
            />
            {error?.homeType && <ErrorText text={error?.homeType} />}
            <Spacer height="12px" />

            {/* residency status */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Residency Status" required={true} />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={residencyStatus}
              onChange={(e) => {
                setState({ ...state, residencyStatus: e });
                setError({ ...error, residencyStatus: "" });
              }}
              value={state.residencyStatus || undefined}
            />
            {error?.residencyStatus && (
              <ErrorText text={error?.residencyStatus} />
            )}
            <Spacer height="12px" />

            {/* Relocation Plan */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Relocation Plan" />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={relocationPlan}
              onChange={(e) => setState({ ...state, relocationPlan: e })}
              value={state.relocationPlan || undefined}
            />

            <Spacer height="12px" />

            {/* Ethnic Origin */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Ethnic Origin" required={true} />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={ethnicOrigin}
              onChange={(e) => {
                setState({ ...state, ethnicOrigin: e });
                setError({ ...error, ethnicOrigin: "" });
              }}
              value={state.ethnicOrigin || undefined}
            />
            {error?.ethnicOrigin && <ErrorText text={error?.ethnicOrigin} />}
            <Spacer height="12px" />

            {/* Marital Status */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Marital Status" required={true} />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={
                profileData?.gender === "female"
                  ? maritalStatusFemale
                  : maritalStatusMale
              }
              onChange={(e) => {
                setState({ ...state, maritalStatus: e });
                setError({ ...error, maritalStatus: "" });
              }}
              value={state.maritalStatus || undefined}
            />
            {error?.maritalStatus && <ErrorText text={error?.maritalStatus} />}
            <Spacer height="12px" />

            {/* Profile Created by */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Profile Created by" required={true} />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={profileCreatedBy}
              onChange={(e) => {
                setState({ ...state, profileCreatedBy: e });
                setError({ ...error, profileCreatedBy: "" });
              }}
              value={state.profileCreatedBy || undefined}
            />
            {error?.profileCreatedBy && (
              <ErrorText text={error?.profileCreatedBy} />
            )}
            <Spacer height="12px" />

            {/* Spoken Language */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Spoken Language" required={true} />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="Please select"
              data={spokenLanguages}
              onChange={(e) => {
                setState({ ...state, spokenLanguage: e });
                setError({ ...error, spokenLanguage: "" });
              }}
              value={state.spokenLanguage || undefined}
            />
            {error?.spokenLanguage && (
              <ErrorText text={error?.spokenLanguage} />
            )}
          </>
        )}
        <Spacer height="32px" />
        <PrimaryButton
          text="Save & Continue"
          onClick={handleSubmit}
          disabled={isDisabled}
        />
      </div>

      <Modal
        centered={true}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={false}
        closable={false}
        className="change-email-modal"
      >
        <img
          src={images?.closeIcon}
          className="modal-close-icon"
          alt=""
          onClick={() => setIsModalOpen(false)}
        />
        <ChangeEmail setIsModalOpen={setIsModalOpen} />
      </Modal>
      <Modal
        centered={true}
        open={isDobModalOpen}
        onCancel={() => setIsDobModalOpen(false)}
        footer={false}
        closable={false}
        className="change-email-modal"
      >
        <img
          src={images?.closeIcon}
          className="modal-close-icon"
          alt=""
          onClick={() => setIsDobModalOpen(false)}
        />
        <DateOfBirthChangeReq setIsDobModalOpen={setIsDobModalOpen} />
      </Modal>

      <Modal
        centered={true}
        open={isGenderModalOpen}
        onCancel={() => setIsGenderModalOpen(false)}
        footer={false}
        closable={false}
        className="change-email-modal"
      >
        <img
          src={images?.closeIcon}
          className="modal-close-icon"
          alt=""
          onClick={() => setIsGenderModalOpen(false)}
        />
        <GenderChangeReq setIsGenderModalOpen={setIsGenderModalOpen} />
      </Modal>

      <Modal
        centered={true}
        open={isCountryModalOpen}
        onCancel={() => setIsCountryModalOpen(false)}
        footer={false}
        closable={false}
        className="change-email-modal"
      >
        <img
          src={images?.closeIcon}
          className="modal-close-icon"
          alt=""
          onClick={() => setIsCountryModalOpen(false)}
        />
        <CountryChangeReq setIsCountryModalOpen={setIsCountryModalOpen} />
      </Modal>

      <Modal
        centered={true}
        open={isPhoneModalOpen}
        onCancel={() => setIsPhoneModalOpen(false)}
        footer={false}
        closable={false}
        className="change-email-modal"
      >
        <img
          src={images?.closeIcon}
          className="modal-close-icon"
          alt=""
          onClick={() => setIsPhoneModalOpen(false)}
        />
        <ChangePhone setIsPhoneModalOpen={setIsPhoneModalOpen} />
      </Modal>
    </>
  );
};

export default BasicInfo;
