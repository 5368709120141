const spokenLanguages = [
  { label: "English", value: "English" },
  { label: "Arabic", value: "Arabic" },
  { label: "Turkish", value: "Turkish" },
  { label: "French", value: "French" },
  { label: "Urdu", value: "Urdu" },
  { label: "Hindi", value: "Hindi" },
  { label: "Bengali", value: "Bengali" },
  { label: "Malay", value: "Malay" },
  { label: "Indonesian", value: "Indonesian" },
  { label: "Afrikaans", value: "Afrikaans" },
  { label: "Albanian", value: "Albanian" },
  { label: "Amharic", value: "Amharic" },
  { label: "Armenian", value: "Armenian" },
  { label: "Assyrian", value: "Assyrian" },
  { label: "Azerbaijani", value: "Azerbaijani" },
  { label: "Belorussian", value: "Belorussian" },
  { label: "Berber", value: "Berber" },
  { label: "Bulgarian", value: "Bulgarian" },
  { label: "Burmese", value: "Burmese" },
  { label: "Cebuano", value: "Cebuano" },
  { label: "Chinese", value: "Chinese" },
  { label: "Creole", value: "Creole" },
  { label: "Croatian", value: "Croatian" },
  { label: "Czech", value: "Czech" },
  { label: "Danish", value: "Danish" },
  { label: "Deutsch", value: "Deutsch" },
  { label: "Dutch", value: "Dutch" },
  { label: "Eritrean", value: "Eritrean" },
  { label: "Estonian", value: "Estonian" },
  { label: "Farsi", value: "Farsi" },
  { label: "Finnish", value: "Finnish" },
  { label: "Georgian", value: "Georgian" },
  { label: "German", value: "German" },
  { label: "Greek", value: "Greek" },
  { label: "Gujarati", value: "Gujarati" },
  { label: "Hausa", value: "Hausa" },
  { label: "Hebrew", value: "Hebrew" },
  { label: "Hungarian", value: "Hungarian" },
  { label: "Icelandic", value: "Icelandic" },
  { label: "Iilocano", value: "Iilocano" },
  { label: "Indonesian", value: "Indonesian" },
  { label: "Inuktitut", value: "Inuktitut" },
  { label: "Italian", value: "Italian" },
  { label: "Japanese", value: "Japanese" },
  { label: "Kannada", value: "Kannada" },
  { label: "Kazakh", value: "Kazakh" },
  { label: "Khmer", value: "Khmer" },
  { label: "Kirgiz", value: "Kirgiz" },
  { label: "Korean", value: "Korean" },
  { label: "Kurdish", value: "Kurdish" },
  { label: "Kutchi", value: "Kutchi" },
  { label: "Kyrgiz", value: "Kyrgiz" },
  { label: "Laotian", value: "Laotian" },
  { label: "Latvian", value: "Latvian" },
  { label: "Lithuanian", value: "Lithuanian" },
  { label: "Macedonian", value: "Macedonian" },
  { label: "Malagasy", value: "Malagasy" },
  { label: "Malayalam", value: "Malayalam" },
  { label: "Maldivian", value: "Maldivian" },
  { label: "Maltese", value: "Maltese" },
  { label: "Marathi", value: "Marathi" },
  { label: "Mongolian", value: "Mongolian" },
  { label: "Nepali", value: "Nepali" },
  { label: "Norwegian", value: "Norwegian" },
  { label: "Pashto", value: "Pashto" },
  { label: "Persian", value: "Persian" },
  { label: "Pidgin", value: "Pidgin" },
  { label: "Polish", value: "Polish" },
  { label: "Portuguese", value: "Portuguese" },
  { label: "Punjabi (Mirpuri)", value: "Punjabi (Mirpuri)" },
  { label: "Punjabi (Pothohari)", value: "Punjabi (Pothohari)" },
  { label: "Quechua", value: "Quechua" },
  { label: "Romanian", value: "Romanian" },
  { label: "Russian", value: "Russian" },
  { label: "Serbian", value: "Serbian" },
  { label: "Sindhi", value: "Sindhi" },
  { label: "Sinhala", value: "Sinhala" },
  { label: "Slovak", value: "Slovak" },
  { label: "Slovene", value: "Slovene" },
  { label: "Somali", value: "Somali" },
  { label: "Spanish", value: "Spanish" },
  { label: "Swahili", value: "Swahili" },
  { label: "Swedish", value: "Swedish" },
  { label: "Tagalog", value: "Tagalog" },
  { label: "Tamil", value: "Tamil" },
  { label: "Telugu", value: "Telugu" },
  { label: "Thai", value: "Thai" },
  { label: "Tibetan", value: "Tibetan" },
  { label: "Tongan", value: "Tongan" },
  { label: "Turkmen", value: "Turkmen" },
  { label: "Ugaritic", value: "Ugaritic" },
  { label: "Ukrainian", value: "Ukrainian" },
  { label: "Uzbek", value: "Uzbek" },
  { label: "Welshn", value: "Welshn" },
  { label: "Other", value: "Other" },
];
export default spokenLanguages;
