import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { ProfileLayout } from "../../components/layout";
import PrimaryButton from "../../components/button/primarybutton";
import ProfileTab from "../../components/tab/profiletab";
import Spacer from "../../components/spacer/spacer";
import useScreenSize from "../../utils/usescreensize";
import matchPreferenceItems from "../../utils/arrayitems/matchpreferenceitems";

import BasicInfo from "./basicinfo";
import BodyPreference from "./bodypreference";
import EducationCareer from "./educationcareer";
import Description from "./description";

import images from "../../assets";

import "./styles.css";

const MatchPrefrrence = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const screenSize = useScreenSize();
  const queryParams = new URLSearchParams(location.search);

  const [activeTab, setActiveTab] = useState(
    queryParams.get("tab") ?? "basicInfo"
  );

  const handleTab = (name) => {
    setActiveTab(name);
    navigate(`/match-preference?tab=${name}`);
  };

  return (
    <ProfileLayout>
      <div className="edit-profile-container">
        {screenSize.width > 1023 && (
          <>
            <div className="edit-profile-header">
              <div>
                <PrimaryButton
                  text="Back"
                  icon={images.arrowIconLeft}
                  iconalign="left"
                  style={{
                    width: "auto",
                    paddingLeft: "12px",
                    paddingRight: "12px",
                    height: "32px",
                    borderRadius: "8px",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  onClick={() => window.history.back()}
                />
              </div>
              <h2 className="edit-profile-heading">Match Preferences</h2>
            </div>
            <Spacer height="20px" />
          </>
        )}
        <ProfileTab
          tabItems={matchPreferenceItems}
          handleTab={handleTab}
          activeTab={activeTab}
        />

        <Spacer height="32px" />
        <div className="edit-profile-content-container">
          {activeTab === "bodyPreference" ? (
            <BodyPreference handleTab={handleTab} />
          ) : activeTab === "educationCareer" ? (
            <EducationCareer handleTab={handleTab} />
          ) : activeTab === "idealMatch" ? (
            <Description handleTab={handleTab} />
          ) : (
            <BasicInfo handleTab={handleTab} />
          )}
        </div>
      </div>
    </ProfileLayout>
  );
};

export default MatchPrefrrence;
