import images from "../../assets";
import "./styles.css";

export default function MyIdealMatchCard(props) {
  return (
    <div className="myideal-match basic-info-card">
      <div className="basic-info-head">
        <img src={images.idealMatchIcon} alt="Religious Status" />{" "}
        <span>{props?.othersProfileData?.firstName}'s Ideal Match</span>
      </div>
      <div className="ideal-match-desc-area">
        {props?.otherMatchPrefData?.idealMatchDescription && (
          <p className="ideal-match-content">
            {props?.otherMatchPrefData?.idealMatchDescription}
          </p>
        )}
      </div>
    </div>
  );
}
