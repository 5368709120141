import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Avatar } from "antd";

import { GreyButton } from "../../components/button";

import getFirstLetters from "../../utils/getfirstletter";
import useScreenSize from "../../utils/usescreensize";
import images from "../../assets";

export default function MessageHeader(props) {
  const profileData = useSelector((state) => state?.profile?.profile);
  const screenSize = useScreenSize();
  let image = "";
  let name = "";

  if (props?.activeUser?.senderId === profileData?.username) {
    image = props?.activeUser?.receiverImageUrl;
    name = props?.activeUser?.receiverName;
  } else {
    image = props?.activeUser?.senderImageUrl;
    name = props?.activeUser?.senderName;
  }
  const profileId =
    props?.activeUser?.senderId === profileData?.username
      ? props?.activeUser?.receiverId
      : props?.activeUser?.senderId;

  return (
    <div className="premium-message-head">
      <div className="premium-meassage-details">
        <div className="premium-meassage-thumb">
          {image && image !== "null" && image !== "undefined" ? (
            <img src={image} alt="dp" />
          ) : (
            <Avatar size={40}>{getFirstLetters(name)}</Avatar>
          )}
        </div>
        <div className="premium-meassage-name">
          <Link to={`/view-profile/${profileId}`}>
            <h2>{name}</h2>
          </Link>
          {screenSize.width < 1023 && (
            <div className="premium-message-active-now-btn">
              <img src={images.messageStatus} alt="Message Status" />
              <p>Active Now</p>
            </div>
          )}
        </div>
      </div>
      {screenSize.width < 1023 && (
        <div {...props} className="premium-message-close">
          <img
            src={images.closeIcon}
            alt="Close"
            onClick={() => props?.setState({ ...props?.state, activeUser: {} })}
          />
        </div>
      )}
      {props?.activeUser?.online && (
        <div className="premium-meassage-status">
          <GreyButton
            style={{
              borderRadius: "15px",
              background: "var(--primary-50, #EAE6ED)",
              boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.25) inset",
              height: "33px",
              fontSize: "9px",
              fontWeight: "400",
            }}
            text="Active Now"
            icon={images.messageStatus}
            iconalign="left"
          />
        </div>
      )}
    </div>
  );
}
