const food = [
  { label: "American", value: "American" },
  { label: "Barbecue", value: "Barbecue" },
  { label: "Cajun / Southern", value: "Cajun / Southern" },
  { label: "California-Fusion", value: "California-Fusion" },
  { label: "Caribbean / Cuban", value: "Caribbean / Cuban" },
  { label: "Chinese / Dim Sum", value: "Chinese / Dim Sum" },
  { label: "Continental", value: "Continental" },
  { label: "Deli", value: "Deli" },
  { label: "Eastern European", value: "Eastern European" },
  { label: "French", value: "French" },
  { label: "German", value: "German" },
  { label: "Greek", value: "Greek" },
  { label: "Italian", value: "Italian" },
  { label: "Japanese / Sushi", value: "Japanese / Sushi" },
  { label: "Jewish / Kosher", value: "Jewish / Kosher" },
  { label: "Korean", value: "Korean" },
  { label: "Mediterranean", value: "Mediterranean" },
  { label: "Mexican", value: "Mexican" },
  { label: "Middle Eastern", value: "Middle Eastern" },
  { label: "Seafood", value: "Seafood" },
  { label: "Soul Food", value: "Soul Food" },
  { label: "South American", value: "South American" },
  { label: "Southwestern", value: "Southwestern" },
  { label: "Thai", value: "Thai" },
  { label: "Vegan", value: "Vegan" },
  { label: "Vegetarian / Organic", value: "Vegetarian / Organic" },
  { label: "Fast Food / Pizza", value: "Fast Food / Pizza" },
  { label: "Vietnamese", value: "Vietnamese" },
  {
    label: "Indian / Pakistani / Bangladeshi",
    value: "Indian / Pakistani / Bangladeshi",
  },
];
export default food;
