import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ProfileAction, MatchAction } from "../../store/actions";

import EditProfileBoxLabel from "../../components/label/editprofileboxlabel";
import EditProfileMultipleSelect from "../../components/dropdown/editprofilemultipleselection";
import HeightSlider from "../../components/rangeslider/heightslider";
import PrimaryButton from "../../components/button/primarybutton";

import {
  bodyType,
  complexion,
  hairColor,
  hairLengthMale,
  hairLengthFemale,
  hairTypeMale,
  hairTypeFemale,
  bloodGroup,
} from "../../utils/arrayitems";

import Spacer from "../../components/spacer/spacer";
import Loader from "../../components/loader/loader";

import images from "../../assets";

import TabHeading from "./tabheading";

import "./styles.css";

const BodyPreference = (props) => {
  const dispatch = useDispatch();
  const profileLoading = useSelector((state) => state?.profile?.isLoading);
  const matchLoading = useSelector((state) => state?.matches?.isLoading);
  const profileData = useSelector((state) => state?.profile?.profile);

  const [isDisabled, setDisabled] = useState(false);

  const [state, setState] = useState({
    bodyType: [],
    heightMin: 142,
    heightMax: 158,
    complexion: [],
    hairColor: [],
    hairType: [],
    hairLength: [],
    bloodGroup: [],
  });

  useEffect(() => {
    setState({
      ...state,
      bodyType: profileData?.matchPreference?.bodyType ?? [],
      heightMin: profileData?.matchPreference?.heightMin ?? 142,
      heightMax: profileData?.matchPreference?.heightMax ?? 158,
      complexion: profileData?.matchPreference?.complexion ?? [],
      hairColor: profileData?.matchPreference?.hairColor ?? [],
      hairType: profileData?.matchPreference?.hairType ?? [],
      hairLength: profileData?.matchPreference?.hairLength ?? [],
      bloodGroup: profileData?.matchPreference?.bloodGroup ?? [],
    });
  }, [profileLoading]);

  const handleSubmit = () => {
    setDisabled(true);
    const payload = {
      username: profileData?.username,
      matchPreference: { ...profileData?.matchPreference, ...state },
    };

    dispatch(
      MatchAction.updateMatchPreference(payload, () => {
        setDisabled(false);
        dispatch(ProfileAction.getProfile());
        props?.handleTab("educationCareer");
      })
    );
  };

  return (
    <>
      <TabHeading
        image={images.matchPrefBody}
        title="Body Preference"
        subtitle="Tell us a little about your preferences."
      />
      <div className="edit-profile-content">
        {profileLoading || matchLoading ? (
          <Loader />
        ) : (
          <>
            {/* Body Type */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Body Type" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="Body Type"
              data={bodyType}
              onChange={(e) => setState({ ...state, bodyType: e })}
              value={state.bodyType || undefined}
            />

            <Spacer height="12px" />

            {/* Height Range */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Height Range" />
            </div>
            <HeightSlider
              onChange={(e) =>
                setState({ ...state, heightMin: e[0], heightMax: e[1] })
              }
              value={[state.heightMin, state.heightMax] || undefined}
            />

            <Spacer height="12px" />

            {/* Complexion */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Complexion" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="Complexion"
              data={complexion}
              onChange={(e) => setState({ ...state, complexion: e })}
              value={state.complexion || undefined}
            />

            <Spacer height="12px" />

            {/* Hair Color */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Hair Color" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="Hair Color"
              data={hairColor}
              onChange={(e) => setState({ ...state, hairColor: e })}
              value={state.hairColor || undefined}
            />

            <Spacer height="12px" />

            {/* Hair Type */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Hair Type" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="Hair Type"
              data={
                profileData?.gender === "male" ? hairTypeFemale : hairTypeMale
              }
              onChange={(e) => setState({ ...state, hairType: e })}
              value={state.hairType || undefined}
            />

            <Spacer height="12px" />
            {/* Hair Length */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Hair Length" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="Hair Length"
              data={
                profileData?.gender === "male"
                  ? hairLengthFemale
                  : hairLengthMale
              }
              onChange={(e) => setState({ ...state, hairLength: e })}
              value={state.hairLength || undefined}
            />

            <Spacer height="12px" />
            {/* Blood Group */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Blood Group" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="Blood Group"
              data={bloodGroup}
              onChange={(e) => setState({ ...state, bloodGroup: e })}
              value={state.bloodGroup || undefined}
            />
          </>
        )}

        <Spacer height="32px" />
        <PrimaryButton
          text="Save & Continue"
          onClick={handleSubmit}
          disabled={isDisabled}
        />
      </div>
    </>
  );
};

export default BodyPreference;
