import {
  GET_NOTE,
  GET_NOTE_SUCCESS,
  GET_NOTE_FAILURE,
  GET_NOTES,
  GET_NOTES_SUCCESS,
  GET_NOTES_FAILURE,
  SET_NOTES,
  SET_NOTES_SUCCESS,
  SET_NOTES_FAILURE,
} from "../constants";

export default class MyNotesAction {
  static getNote(payload, cb) {
    return {
      type: GET_NOTE,
      payload,
      cb,
    };
  }
  static getNoteSuccess(payload) {
    return {
      type: GET_NOTE_SUCCESS,
      payload,
    };
  }
  static getNoteFailure() {
    return {
      type: GET_NOTE_FAILURE,
    };
  }
  static getNotes(payload, cb) {
    return {
      type: GET_NOTES,
      payload,
      cb,
    };
  }
  static getNotesSuccess(payload) {
    return {
      type: GET_NOTES_SUCCESS,
      payload,
    };
  }
  static getNotesFailure() {
    return {
      type: GET_NOTES_FAILURE,
    };
  }
  static setNotes(payload, cb) {
    return {
      type: SET_NOTES,
      payload,
      cb,
    };
  }
  static setNotesSuccess(payload) {
    return {
      type: SET_NOTES_SUCCESS,
      payload,
    };
  }
  static setNotesFailure() {
    return {
      type: SET_NOTES_FAILURE,
    };
  }
}
