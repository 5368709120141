import moment from "moment";

const calculateage = (date) => {
  const birthMoment = moment(date, "YYYY/MM/DD");
  const now = moment();
  const age = now.diff(birthMoment, "years");

  return isNaN(age) ? "N/A" : age;
};

export default calculateage;
