import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spin } from "antd";

import { Amplify } from "aws-amplify";
import { generateClient } from "aws-amplify/api";
import config from "./aws-exports";

import { onMessage, getToken } from "firebase/messaging";
import { messaging } from "./firebase/firebaseConfig";

import { MessageAction } from "./store/actions";

import { listRooms } from "./graphql/queries";

import { onCreateRoom, onUpdateRoom } from "./graphql/subscriptions";

import DefaultNotification from "./components/notification/default-notification";

import AllRoutes from "./config/routes/allroutes";

Amplify.configure(config);

const client = generateClient();

const requestPermission = async () => {
  const permission = await Notification.requestPermission();

  console.log("permission", permission);
  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_VAPID_KEY,
    });
    console.log("device token", token);
    localStorage.setItem("config_state_hash", JSON.stringify(token));
  } else if (permission === "denied") {
    console.log("You denied for the notification");
  }
};

const App = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.auth.isLoading);
  const profileData = useSelector((state) => state?.profile?.profile);

  const [userList, setUserList] = useState([]);
  const [hasInteracted, setHasInteracted] = useState(false);

  const chimeSound = new Audio(
    "https://noble-marriage-public-bucket.s3.us-east-2.amazonaws.com/infographic-app-notification-twinkle-betacut-1-00-02.mp3"
  );

  useEffect(() => {
    const onUserInteraction = () => {
      setHasInteracted(true);
      window?.removeEventListener("click", onUserInteraction);
      window?.removeEventListener("keydown", onUserInteraction);
    };

    window?.addEventListener("click", onUserInteraction);
    window?.addEventListener("keydown", onUserInteraction);

    return () => {
      window?.removeEventListener("click", onUserInteraction);
      window?.removeEventListener("keydown", onUserInteraction);
    };
  }, []);

  useEffect(() => {
    requestPermission();
  }, []);

  useEffect(() => {
    if (profileData?.username) {
      const sortedUserlist = userList?.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
      const payload = {
        userList: sortedUserlist,
        isLoading: false,
      };
      dispatch(MessageAction.setMessageList(payload));
    }
  }, [userList]);

  useEffect(() => {
    if (profileData?.username) {
      const subscription = client
        .graphql({
          query: onCreateRoom,
          variables: {},
        })
        .subscribe({
          next: (data) => {
            console.log("Room Info appjs", data);
            setUserList((prevList) => [...prevList, data?.data?.onCreateRoom]);
          },
        });
      return () => subscription.unsubscribe();
    }
  }, [profileData]);

  useEffect(() => {
    const subscription = client
      .graphql({
        query: onUpdateRoom,
        variables: {},
      })
      .subscribe({
        next: async (data) => {
          console.log("update Room Info appjs", data);

          if (
            hasInteracted &&
            data?.data?.onUpdateRoom?.lastSenderId !== profileData?.username
          ) {
            chimeSound
              .play()
              .catch((err) => console.log("error playing audio", err));
          }

          const result = await client.graphql({
            query: listRooms,
            variables: {
              searchId: profileData?.username,
            },
          });
          console.log("after updating room", result);
          setUserList(result?.data?.listRooms?.items);
        },
      });
    return () => subscription.unsubscribe();
  }, [profileData, hasInteracted]);

  useEffect(() => {
    if (profileData?.username) {
      getRoomList();
    }
  }, [profileData]);

  onMessage(messaging, (payload) => {
    console.log("onMessage", payload);

    const notification =
      payload?.notification ||
      (payload?.data?.default && JSON.parse(payload.data.default));

    if (notification) {
      DefaultNotification(
        notification.title,
        notification.body,
        notification.image
      );
    }
  });

  const getRoomList = async () => {
    try {
      const payload = {
        isLoading: true,
      };
      dispatch(
        MessageAction.setMessageList(payload, async () => {
          const result = await client.graphql({
            query: listRooms,
            variables: {
              searchId: profileData?.username,
            },
          });

          console.log("from app js room list", result);
          setUserList(result?.data?.listRooms?.items);
        })
      );
    } catch (e) {
      console.log("in get Room list app js error", e);
    }
  };

  return (
    <div>
      <AllRoutes />
      <Spin spinning={isLoading} fullscreen />
    </div>
  );
};

export default App;
