const residencyStatus = [
  {
    label: "Citizen",
    value: "citizen",
  },
  {
    label: "Permanent Resident",
    value: "permanentResident",
  },
  {
    label: "Student Visa",
    value: "studentVisa",
  },
  {
    label: "Temporary Visa",
    value: "temporaryVisa",
  },
  {
    label: "Work Permit",
    value: "workPermit",
  },
  {
    label: "Other Visa",
    value: "otherVisa",
  },
];
export default residencyStatus;
