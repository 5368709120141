import BasicInfoColumn from "../../components/profilecard/basicinfocolumn";

import images from "../../assets";
import "./styles.css";

export default function EducationCareerCard(props) {
  return (
    <div className="education-career basic-info-card">
      <div className="basic-info-head">
        <img src={images.educationIcon} alt="Religious Status" />{" "}
        <span>Education & Career</span>
      </div>
      <div className="basic-info-content">
        {props?.othersProfileData?.education && (
          <BasicInfoColumn
            titlename="Education"
            values={props?.othersProfileData?.education}
          />
        )}
        {props?.othersProfileData?.instituteName && (
          <BasicInfoColumn
            titlename="Institute Name"
            values={props?.othersProfileData?.instituteName}
            icon={images.upgradeLock}
          />
        )}
        {props?.othersProfileData?.profession && (
          <BasicInfoColumn
            titlename="Profession"
            values={props?.othersProfileData?.profession}
          />
        )}
        {props?.othersProfileData?.occupationStatus && (
          <BasicInfoColumn
            titlename="Occupation Status"
            values={props?.othersProfileData?.occupationStatus}
          />
        )}
        {props?.othersProfileData?.annualIncomeMin && (
          <BasicInfoColumn
            titlename="Annual Income"
            values={`${
              props?.othersProfileData?.incomeCurrency
            } ${props?.othersProfileData?.annualIncomeMin?.toLocaleString()} to
            ${props?.othersProfileData?.annualIncomeMax?.toLocaleString()}`}
            icon={images.upgradeLock}
          />
        )}
      </div>
    </div>
  );
}
