import {
  GET_SHORTLIST,
  GET_SHORTLIST_SUCCESS,
  GET_SHORTLIST_FAILURE,
  GET_SHORTLIST_ALL,
  GET_SHORTLIST_ALL_SUCCESS,
  GET_SHORTLIST_ALL_FAILURE,
  SEND_SHORTLIST,
  SEND_SHORTLIST_SUCCESS,
  SEND_SHORTLIST_FAILURE,
} from "../constants";

const initialState = {
  shortlists: [],
  shortlistsAll: [],
  shortlistCount: 0,
  shortlistAllCount: 0,
  isLoading: false,
  error: null,
};

export default function ShortlistReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SHORTLIST:
    case GET_SHORTLIST_ALL:
    case SEND_SHORTLIST:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_SHORTLIST_SUCCESS:
      state = {
        ...state,
        shortlists: action.payload.data,
        shortlistCount: action.payload.count,
        isLoading: false,
      };
      break;
    case GET_SHORTLIST_ALL_SUCCESS:
      state = {
        ...state,
        shortlistsAll: action.payload.data,
        shortlistAllCount: action.payload.count,
        isLoading: false,
      };
      break;
    case SEND_SHORTLIST_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;

    case GET_SHORTLIST_FAILURE:
    case GET_SHORTLIST_ALL_FAILURE:
    case SEND_SHORTLIST_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }

  return state;
}
