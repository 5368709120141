import Spacer from "../../components/spacer/spacer";
import { PrimaryButton } from "../../components/button";
import EditProfileDropdown from "../../components/dropdown/editprofiledropdown";

import { genderItems } from "../../utils/arrayitems";

const GenderForm = (props) => {
  return (
    <>
      <div className="change-email-head">
        <h2 className="change-email-title">Gender Change Request</h2>
      </div>
      <div className="change-email-body">
        <p>
          Please select your gender. We will review your request and update it,
          and you will be notified soon.
        </p>
        <Spacer height="32px" />
        <div>I am a</div>
        <EditProfileDropdown
          placeholder="Please Select Your Gender"
          data={genderItems}
          onChange={(e) => {
            props?.setGender(e);
          }}
        />
      </div>
      <div className="change-email-btn">
        <PrimaryButton
          text="Submit Request"
          onClick={props?.handleGenderSubmit}
        />
      </div>
    </>
  );
};

export default GenderForm;
