import React from "react";

import "./styles.css";

const VioletButtonLarge = (props) => {
  return (
    <button {...props} className="violet-button-lg-style">
      <p className="m-0 text-ellipsis overflow-hidden whitespace-nowrap">
        {props?.text}
      </p>
    </button>
  );
};

export default VioletButtonLarge;
