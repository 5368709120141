import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";

import { AuthAction, ProfileAction } from "../../store/actions";

import TrustBadgeForm from "./trustbadgeform";
import GenderForm from "./genderform";

import "./styles.css";

const GenderChangeReq = (props) => {
  const dispatch = useDispatch();

  const trustbadgeStatus = useSelector(
    (state) => state?.profile?.profile?.trustbadgeStatus
  );

  const [gender, setGender] = useState(null);

  const handleGenderSubmit = () => {
    if (!gender) {
      message.error("Please select a gender");
    } else {
      const payload = {
        data: {
          gender,
          type: "genderchange",
        },
      };

      dispatch(
        ProfileAction.changeRequest(payload, (res) => {
          console.log(res);
        })
      );

      props?.setIsGenderModalOpen(false);
    }
  };

  return (
    <div className="forgot-password-container">
      {trustbadgeStatus === undefined ||
      trustbadgeStatus === null ||
      trustbadgeStatus === "" ||
      trustbadgeStatus === "false" ||
      trustbadgeStatus === "rejected" ? (
        <TrustBadgeForm />
      ) : (
        <GenderForm
          gender={gender}
          setGender={setGender}
          handleGenderSubmit={handleGenderSubmit}
        />
      )}
    </div>
  );
};

export default GenderChangeReq;
