import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Switch, Modal } from "antd";

import { ProfileAction } from "../../store/actions";

import PrimaryButton from "../button/primarybutton";

import Loader from "../loader/loader";

import GalleryModal from "./gallerymodal";

import images from "../../assets";
import "./styles.css";

export default function MyGalleryCard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profileLoading = useSelector((state) => state?.profile?.isLoading);
  const profileData = useSelector((state) => state?.profile?.profile);

  const [imageArray, setImageArray] = useState(profileData?.imageGallery ?? []);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleArrayChange = (url) => {
    const imageList = [...imageArray];

    const clickedImageUrl = url;
    const clickedImageIndex = imageList.findIndex(
      (image) => image.url === clickedImageUrl
    );

    if (clickedImageIndex !== -1) {
      const clickedImage = imageList.splice(clickedImageIndex, 1)[0];
      imageList.unshift(clickedImage);
    }

    setImageArray(imageList);
    setIsModalOpen(true);
  };

  const handlePhotoBlur = (e) => {
    const payload = {
      isPhotoBlured: e,
    };
    dispatch(
      ProfileAction.updateProfile(payload, () => {
        dispatch(ProfileAction.getProfile());
      })
    );
  };

  const numOfPhoto = profileData?.imageGallery?.length;

  return (
    <div className="my-photo-card">
      <div className="my-photo-text">
        <div>
          <span>My Photos</span>
        </div>
        <div className="gallery-icon-text">
          <PrimaryButton
            text="Gallery"
            icon={images.galleryIcon}
            iconalign="left"
            style={{
              width: "auto",
              paddingLeft: "12px",
              paddingRight: "12px",
              height: "32px",
              borderRadius: "8px",
              fontSize: "14px",
              fontWeight: "500",
            }}
            onClick={() => navigate("/gallery")}
          />
        </div>
      </div>
      {profileLoading ? (
        <Loader />
      ) : (
        <>
          <div className="keep-my-photo">
            <div className="keep-blurred">
              Keep my photo blurred &nbsp;
              <Switch
                defaultChecked={profileData?.isPhotoBlured}
                onChange={(e) => {
                  handlePhotoBlur(e);
                }}
                disabled={profileData?.gender === "female"}
              />
            </div>
          </div>
          {profileData?.gender === "female" && (
            <div className="text-[10px] text-right pt-1">
              You cannot unblur your photos. Only approved individuals can view
              them clearly.
            </div>
          )}
          <div className="my-photo-gallery">
            {profileData?.imageGallery?.length > 0 && (
              <div className="main-photo-gallery">
                {profileData?.imageGallery?.map((item, index) => (
                  <div key={index} className="galley-images">
                    <img
                      onClick={() => handleArrayChange(item?.url)}
                      src={item.url}
                      alt="Gallery Images"
                    />
                  </div>
                ))}
              </div>
            )}
            {(!profileData?.imageGallery ||
              profileData?.imageGallery?.length === 0) && (
              <>
                <div className="my-photo-gallery-notset">
                  <div className="gallery-document-verified">
                    <img
                      src={images.photoGalleryIcon}
                      alt="Document Verified"
                    />
                  </div>
                  <div className="my-photo-gallery-notset-content">
                    <p>
                      You have not uploaded a photo yet!
                      <br /> Users having a clear picture are <b>80%</b>
                      <br /> more likely to discover their desired match.
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
          <Modal
            centered={true}
            open={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            footer={false}
            closable={false}
            autoFocusButton
            className="gallery-modal-main"
          >
            <GalleryModal
              imagesarr={imageArray}
              initialurl={imageArray[0]?.url}
            >
              <div className="photo-modal-left">
                <h2>My Photos</h2>

                <p>{numOfPhoto} Files</p>
              </div>
              <div className="photo-modal-right">
                {/* <img src={images.zoomingIcon} alt="" /> */}
                <img
                  onClick={() => setIsModalOpen(false)}
                  src={images.closeIcon}
                  alt=""
                />
              </div>
            </GalleryModal>
          </Modal>
        </>
      )}
    </div>
  );
}
