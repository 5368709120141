import images from "../../assets";
export default function ImproveMatchInfo(props) {
  return (
    <div {...props} className="improve-match-info-content">
      <div className="improve-match-info-title">{props.title}</div>
      <div className="improve-match-subarea">
        <div className="improve-match-info-subtext">{props.subtext}</div>
        <div className="improve-match-dotted">
          <img src={images.dottedImg} alt="" />
        </div>
        <div className="improve-match-info-icon">
          <img src={images.checkIcon} alt="Check" />
        </div>
      </div>
    </div>
  );
}
