import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import images from "../../assets";
import "./styles.css";

export default function BottomBarMenu() {
  const navigate = useNavigate();
  const location = useLocation();

  const pathname = location.pathname;

  return (
    <div className="bottom-bar-container">
      <div onClick={() =>
        navigate(`/`)
      } className={`${pathname.includes(`/dashboard`) ? "active" : ""} bottom-menu-bar`}>
        <img src={images.bottomBarHomeBlack} alt="Home" />
        <h3>Dashboard</h3>
      </div>
      <div onClick={() =>
        navigate(`/advanced-search`)} className={`${pathname.includes(`/advanced-search`) ? "active" : ""} bottom-menu-bar`}>
        <img src={images.bottomBarSearchWhite} alt="Search" />
        <h3>Search</h3>
      </div>
      <div onClick={() =>
        navigate(`/`)} className={`${pathname.includes(`/notification`) ? "active" : ""} bottom-menu-bar`}>
        <img src={images.bottomBarBellWhite} alt="Notify" />
        <img
          className="bottom-menu-bar-noti-icon"
          src={images.notifyIcon}
          alt="Nofify"
        />
        <h3>Notifications</h3>
      </div>
      <div onClick={() =>
        navigate(`/messages`)} className="bottom-menu-bar">
        <img src={images.bottomBarMegWhite} alt="Messages" />
        <img
          className="bottom-menu-bar-noti-icon"
          src={images.notifyIcon}
          alt="Messages"
        />
        <h3>Messages</h3>
      </div>
    </div>
  );
}
