import React, { createContext, useRef } from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

const TawkContext = createContext();

export const TawkProvider = ({ children }) => {
  const tawkMessengerRef = useRef();

  const onLoad = () => {
    tawkMessengerRef?.current?.hideWidget();
  };

  const handleMinimize = () => {
    tawkMessengerRef?.current?.hideWidget();
  };

  return (
    <TawkContext.Provider value={tawkMessengerRef}>
      {children}
      <TawkMessengerReact
        propertyId="5ee86efb9e5f69442290a5d2"
        widgetId="default"
        ref={tawkMessengerRef}
        onLoad={onLoad}
        onChatMinimized={handleMinimize}
      />
    </TawkContext.Provider>
  );
};

export default TawkContext;
