import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Empty, Pagination } from "antd";
import moment from "moment";

import { InterestAction } from "../../store/actions";
import useScreenSize from "../../utils/usescreensize";
import ProfileTab from "../../components/tab/profiletab";
import DashboardCard from "../../components/dashboardcard/dashboardcard";
import { PrimaryButton, WhitePurpleButton } from "../../components/button";

import SortDropdown from "../../components/dropdown/sortdropdown";
import Cardloader from "../../components/loader/cardloader";
import Spacer from "../../components/spacer/spacer";

import "./styles.css";
import UnderHeaderMenu from "../../components/underheadermenu";

const Interests = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const screenSize = useScreenSize();

  const { interests, isLoading, interestCount } = useSelector(
    (state) => state.interests
  );

  const profileData = useSelector((state) => state.profile?.profile);

  const [state, setState] = useState({
    activeTab: "Pending",
    sortOption: "",
  });
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(8);

  useEffect(() => {
    fetchInterests();
  }, [state]);

  const handleTab = (name) => {
    setState({ ...state, activeTab: name });
  };

  const fetchInterests = () => {
    const payload = {
      filter: state?.activeTab,
      from: 0,
      size: pageSize,
      searchId: profileData?.username,
      sort: state?.sortOption,
    };
    setPageNo(1);
    dispatch(InterestAction.getInterests(payload));
  };

  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
    const payload = {
      filter: state?.activeTab,
      from: (pageNumber - 1) * pageSize,
      size: pageSize,
      searchId: profileData?.username,
      sort: state?.sortOption,
    };

    dispatch(InterestAction.getInterests(payload));
  };

  const handleUpdateInterest = (id, status) => {
    const payload = {
      senderId: id,
      status,
    };
    console.log(payload);
    dispatch(
      InterestAction.updateInterest(payload, () => {
        fetchInterests();
      })
    );
  };

  const tabItems = [
    { name: "Pending", title: "Pending" },
    { name: "My Interests", title: "My Interests" },
    { name: "Interested in Me", title: "Interested in Me" },
  ];

  return (
    <div>
      {screenSize.width < 1023 && (
        <UnderHeaderMenu titlename="Interests">
          <div style={{ width: "110px" }}>
            <SortDropdown
              data={[
                { label: "Newest First", value: "newest" },
                { label: "Oldest First", value: "oldest" },
              ]}
              onChange={(e) => setState({ ...state, sortOption: e })}
              value={state?.sortOption || undefined}
              placeholder="Sort by"
            />
          </div>
        </UnderHeaderMenu>
      )}
      <Spacer height="16px" />
      <div className="interests-section-top-part-container">
        <div></div>
        <div className="interests-section-header-container">
          {screenSize.width > 1023 && (
            <h1 className="interests-header">Interests</h1>
          )}
          <ProfileTab
            tabItems={tabItems}
            handleTab={handleTab}
            activeTab={state?.activeTab}
            style={{
              background: "white",
              border: "1px solid #EAE6ED",
              width: "100%",
            }}
          />
        </div>
        {screenSize.width > 1023 && (
          <div className="interests-sort-container">
            <div style={{ width: "150px" }}>
              <SortDropdown
                data={[
                  { label: "Newest First", value: "newest" },
                  { label: "Oldest First", value: "oldest" },
                ]}
                onChange={(e) => setState({ ...state, sortOption: e })}
                value={state?.sortOption || undefined}
                placeholder="Sort by"
              />
            </div>
          </div>
        )}
      </div>
      <Spacer height="12px" />
      <div className="interests-results-container">
        {isLoading ? (
          <Cardloader />
        ) : (
          <>
            {interests?.length > 0 ? (
              <>
                <div className="interests-results">
                  {interests?.map((item, index) => (
                    <DashboardCard
                      item={item}
                      index={index}
                      key={index}
                      redirectedFrom="interests"
                    >
                      <div className="flex flex-col w-full gap-3">
                        {state?.activeTab === "Pending" && (
                          <div className="interests-card-text">
                            <p>Pending Since:</p>
                            <p>{moment(item?.updatedAt).fromNow()}</p>
                          </div>
                        )}
                        {state?.activeTab === "My Interests" && (
                          <div className="interests-card-text">
                            <p>Sent on:</p>
                            <p>
                              {moment(item?.updatedAt).format(
                                "YYYY-MM-DD, hh:mm A"
                              )}
                            </p>
                          </div>
                        )}
                        {state?.activeTab === "Interested in Me" && (
                          <div className="interests-card-text">
                            <p>Interest Received on:</p>
                            <p>
                              {moment(item?.updatedAt).calendar(null, {
                                sameDay: "[Today], h:mm A",
                                lastDay: "[Yesterday], h:mm A",
                                lastWeek: "dddd, h:mm A",
                                sameElse: "MMM DD, h:mm A",
                              })}
                            </p>
                          </div>
                        )}
                        {state?.activeTab === "Pending" ? (
                          <div className="interest-mobile-btn">
                            <PrimaryButton
                              style={{
                                background: "#F31260",
                              }}
                              text="Decline"
                              onClick={() =>
                                handleUpdateInterest(item?.username, "rejected")
                              }
                            />
                            <PrimaryButton
                              style={{
                                background: "#17C964",
                              }}
                              text="Accept"
                              onClick={() =>
                                handleUpdateInterest(item?.username, "accepted")
                              }
                            />
                          </div>
                        ) : (
                          <WhitePurpleButton
                            text="View Profile"
                            onClick={() =>
                              navigate(`/view-profile/${item?.username}`)
                            }
                          />
                        )}
                      </div>
                    </DashboardCard>
                  ))}
                </div>
                <Spacer height="36px" />
                <div className="flex justify-center items-center">
                  <Pagination
                    current={pageNo}
                    total={interestCount}
                    defaultPageSize={pageSize}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                  />
                </div>
                <Spacer height="24px" />
              </>
            ) : (
              <Empty description={<p>No Results found</p>} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Interests;
