import {
  GET_PAYMENT_PLAN,
  GET_PAYMENT_PLAN_SUCCESS,
  GET_PAYMENT_PLAN_FAILURE,
  GET_SUBSCRIPTION_INFO,
  GET_SUBSCRIPTION_INFO_SUCCESS,
  GET_SUBSCRIPTION_INFO_FAILURE,
} from "../constants";

export default class SubscriptionAction {
  static getPaymentPlan(cb) {
    return {
      type: GET_PAYMENT_PLAN,
      cb,
    };
  }
  static getPaymentPlanSuccess(payload) {
    return {
      type: GET_PAYMENT_PLAN_SUCCESS,
      payload,
    };
  }
  static getPaymentPlanFailure() {
    return {
      type: GET_PAYMENT_PLAN_FAILURE,
    };
  }
  static getSubscriptionInfo(payload, cb) {
    return {
      type: GET_SUBSCRIPTION_INFO,
      payload,
      cb,
    };
  }
  static getSubscriptionInfoSuccess(payload) {
    return {
      type: GET_SUBSCRIPTION_INFO_SUCCESS,
      payload,
    };
  }
  static getSubscriptionInfoFailure() {
    return {
      type: GET_SUBSCRIPTION_INFO_FAILURE,
    };
  }
}
