import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useScreenSize from "../../../../utils/usescreensize";
import { UiAction } from "../../../../store/actions";

import images from "../../../../assets";
import "../styles.css";

const DefaultItems = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const screenSize = useScreenSize();

  const profileData = useSelector((state) => state.profile?.profile);
  const { recommendedCount } = useSelector((state) => state.search);
  const { pendingInterestCount } = useSelector((state) => state.interests);
  const { blocklistCount } = useSelector((state) => state.blocklist);
  const { profileViewerCount } = useSelector((state) => state.profileView);
  const { newMembersCount } = useSelector((state) => state.newMembers);
  const { contactViewCount } = useSelector((state) => state.contactView);
  const { myNotesCount } = useSelector((state) => state.myNotes);
  const { pendingPhotoReqCount } = useSelector((state) => state.photoreq);
  const { shortlistCount } = useSelector((state) => state.shortlists);
  const { userList } = useSelector((state) => state.message);

  const [collapsed, setCollapsed] = useState(false);
  const [messageCount, setMessageCount] = useState(0);
  const [isActive, setIsActive] = useState("");
  const [showSubItems, setShowSubItems] = useState(false);

  useEffect(() => {
    allItems?.map((item) => {
      if (location?.pathname?.includes(item?.route)) {
        setActiveTab(item?.title);
      }
    });
  }, [location.pathname]);

  useEffect(() => {
    const payload = { collapsed: collapsed };
    dispatch(UiAction.setCollapsed(payload));
  }, [collapsed]);

  useEffect(() => {
    let unreadCount = 0;
    userList?.map((user) => {
      if (user.lastSenderId !== profileData.username && user.lastMessageRead) {
        unreadCount++;
      }
    });
    setMessageCount(unreadCount);
  }, [userList]);

  const setActiveTab = (name) => {
    setIsActive(name);
  };

  const items = [
    {
      title: "Dashboard",
      key: "1",
      image: <img src={images.sideNavDash} alt="dash" />,
      route: "/dashboard",
    },
    {
      title: "Messages",
      key: "2",
      image: <img src={images.sideNavMessages} alt="msg" />,
      unread: messageCount > 0 ? messageCount.toString() : null,
      route: "/messages",
    },

    {
      title: "Advanced Search",
      key: "3",
      image: <img src={images.sideNavSearchIcon} alt="srch" />,
      route: "/advanced-search",
    },
    {
      title: "Recommended Matches",
      key: "4",
      image: <img src={images.sideNavRecommended} alt="rec" />,
      unread:
        recommendedCount > 0
          ? recommendedCount >= 100
            ? "99+"
            : recommendedCount.toString()
          : null,
      route: "/recommended-matches",
    },
    {
      title: "Activities",
      key: "13",
      image: <img src={images.sideNavActivities} alt="act" />,
    },
  ];
  const subItems = [
    {
      title: "Interests",
      key: "5",
      image: <img src={images.sideNavInterests} alt="srch" />,
      unread: pendingInterestCount > 0 ? pendingInterestCount.toString() : null,
      route: "/interests",
    },
    {
      title: "Photo Requests",
      key: "6",
      image: <img src={images.sideNavPhotoReq} alt="srch" />,
      unread: pendingPhotoReqCount > 0 ? pendingPhotoReqCount.toString() : null,
      route: "/photo-requests",
    },
    {
      title: "Shortlists",
      key: "7",
      image: <img src={images.sideNavShortlist} alt="srch" />,
      // unread: shortlistCount > 0 ? shortlistCount.toString() : null,
      unread: null,
      route: "/shortlists",
    },
    {
      title: "Profile Viewers",
      key: "8",
      image: <img src={images.sideNavProfileViewers} alt="srch" />,
      unread: profileViewerCount > 0 ? profileViewerCount.toString() : null,
      route: "/profile-viewers",
    },
    {
      title: "Viewed Contacts",
      key: "9",
      image: <img src={images.sideNavViewedContacts} alt="srch" />,
      // unread: contactViewCount > 0 ? contactViewCount.toString() : null,
      route: "/viewed-contacts",
    },
    {
      title: "New Members",
      key: "10",
      image: <img src={images.sideNavNewMembers} alt="srch" />,
      // unread: newMembersCount > 0 ? newMembersCount.toString() : null,
      unread: null,
      route: "/new-members",
    },
    {
      title: "Block List",
      key: "11",
      image: <img src={images.sideNavBlocklist} alt="srch" />,
      // unread: blocklistCount > 0 ? blocklistCount.toString() : null,
      unread: null,
      route: "/block-list",
    },
    {
      title: "My Notes",
      key: "12",
      image: <img src={images.sideNavNotes} alt="srch" />,
      unread: myNotesCount > 0 ? myNotesCount.toString() : null,
      route: "/my-notes",
    },
  ];
  const allItems = [
    ...items,
    ...subItems,
    {
      title: "Settings",
      key: "5",
      image: <img src={images.settingsIcon} alt="Settings" />,
      route: "/settings",
    },
  ];

  return (
    <div
      className={`${collapsed ? "sidebar-small" : "sidebar-full"} sidebar`}
      style={{
        height: props?.promotionHeader ? "calc(100vh - 60px)" : "100vh",
      }}
    >
      <div className="sidebar-logo-container">
        <img
          onClick={() => navigate("/dashboard")}
          src={images.mainLogo}
          alt="logo"
          style={{ cursor: "pointer" }}
        />
        {screenSize.width < 1023 && (
          <img
            {...props}
            className="p-2 rounded-md border border-n20 bg-n10 cursor-pointer"
            src={images.navbarCross}
            alt="Cross"
          />
        )}
      </div>

      <div
        style={{
          height: "90vh",
          overflowY: "auto",
          overflowX: "hidden",
          width: "100%",
        }}
      >
        {items?.map((item) => (
          <div
            onClick={() => {
              if (item.title === "Activities") {
                setShowSubItems(!showSubItems);
              } else {
                navigate(item.route);
              }
            }}
            key={item.key}
          >
            <div className="sidebar-items">
              <div
                onClick={() =>
                  collapsed ? setCollapsed(!collapsed) : setCollapsed(collapsed)
                }
                className="sidebar-icon"
              >
                {item.image}
              </div>
              <div
                className={
                  isActive === item?.title
                    ? "sidebar-text-active"
                    : "sidebar-text"
                }
              >
                {!collapsed && item.title}
              </div>
              {!collapsed && item?.unread && (
                <div className="unread-badge">{item.unread}</div>
              )}
              {!collapsed && item?.title === "Activities" && (
                <img
                  src={images.sidebarDropdown}
                  alt="srch"
                  className={
                    showSubItems ? "dropdown-icon" : "dropdown-icon-rotated"
                  }
                />
              )}
            </div>
            {item?.title !== "Activities" && <hr />}
          </div>
        ))}

        <div
          className={`sidebar-sub-items-container ${
            !collapsed && showSubItems ? "show" : ""
          }`}
        >
          {subItems?.map((item) => (
            <div
              onClick={() => {
                navigate(item.route);
              }}
              key={item.key}
            >
              <div
                className={
                  isActive === item?.title
                    ? "sidebar-sub-items-active"
                    : "sidebar-sub-items"
                }
              >
                <div className="sidebar-sub-icon">{item.image}</div>
                <div
                  className={
                    isActive === item?.title
                      ? "sidebar-sub-text-active"
                      : "sidebar-sub-text"
                  }
                >
                  {item.title}
                </div>
                {item?.unread && (
                  <div className="unread-badge">{item.unread}</div>
                )}
                {item?.title === "Activities" && (
                  <img
                    src={images.sidebarDropdown}
                    alt="srch"
                    className={
                      showSubItems ? "dropdown-icon" : "dropdown-icon-rotated"
                    }
                    onClick={() => setShowSubItems(!showSubItems)}
                  />
                )}
              </div>
              {item?.title !== "My Notes" && <hr />}
            </div>
          ))}
        </div>
        <hr />
        <div
          onClick={() => {
            navigate("/settings");
          }}
          key={"14"}
        >
          <div className="sidebar-items">
            <div
              onClick={() =>
                collapsed ? setCollapsed(!collapsed) : setCollapsed(collapsed)
              }
              className="sidebar-icon"
            >
              <img src={images.settingsIcon} alt="Settings" />
            </div>
            <div
              className={
                isActive === "Settings" ? "sidebar-text-active" : "sidebar-text"
              }
            >
              {!collapsed && "Settings"}
            </div>
          </div>
        </div>
      </div>
      {screenSize.width > 1024 && (
        <button
          className="collapsed-btn"
          onClick={() => setCollapsed(!collapsed)}
        >
          <div
            style={{
              width: "25px",
              height: "25px",
              margin: "0 auto",
            }}
          >
            {collapsed ? (
              <ion-icon name="arrow-forward-outline"></ion-icon>
            ) : (
              <ion-icon name="arrow-back-outline"></ion-icon>
            )}
          </div>
        </button>
      )}
    </div>
  );
};

export default DefaultItems;
