import images from "../../../../assets";
export default function MembershipPlan() {
  return (
    <div className="notifi-membership-area">
      <div className="notifi-membership-left">
        <div className="notifi-membership-img">
          <img src={images.membershipPlan} alt="Membership Plan" />
        </div>
      </div>
      <div className="notifi-membership-right">
        <div className="notifi-membership-title">Membership Plan</div>
        <div className="notifi-membership-subtitle">
          Your Membership Plan will be expired soon. Please renew to continue
          accessing premium features.
        </div>
        <div style={{ paddingTop: "4px" }} className="notifi-minute">
          2 minutes ago
        </div>
      </div>
    </div>
  );
}
