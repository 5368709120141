export default function MembershipPlanCard(props) {
  return (
    <div {...props} className="membership-plan-column">
      <div className="membership-plan-title">{props.titlename}</div>
      <div className="membership-plan-value capitalize">
        : <p>{props.values}</p>
      </div>
    </div>
  );
}
