import { maritalStatusMale } from "./arrayitems";
const getMaritalStatusLabel = (text) => {
  let value = "";
  for (let i = 0; i < maritalStatusMale?.length; i++) {
    if (maritalStatusMale[i]?.value === text) {
      value = maritalStatusMale[i]?.label;
    }
  }
  return value;
};
export default getMaritalStatusLabel;
