import {
  GET_CONTACT_VIEW,
  GET_CONTACT_VIEW_SUCCESS,
  GET_CONTACT_VIEW_FAILURE,
  GET_ALL_CONTACT_VIEW,
  GET_ALL_CONTACT_VIEW_SUCCESS,
  GET_ALL_CONTACT_VIEW_FAILURE,
  SET_CONTACT_VIEW,
  SET_CONTACT_VIEW_SUCCESS,
  SET_CONTACT_VIEW_FAILURE,
} from "../constants";

const initialState = {
  contactView: [],
  contactViewCount: 0,
  contactViewAll: [],
  contactViewAllCount: 0,
  isLoading: false,
  isAllLoading: false,
  error: null,
};

export default function ContactViewerReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CONTACT_VIEW:
    case SET_CONTACT_VIEW:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_ALL_CONTACT_VIEW:
      state = {
        ...state,
        isAllLoading: true,
        error: null,
      };
      break;
    case GET_CONTACT_VIEW_SUCCESS:
      state = {
        ...state,
        contactView: action.payload.data,
        contactViewCount: action.payload.count,
        isLoading: false,
      };
      break;
    case GET_ALL_CONTACT_VIEW_SUCCESS:
      state = {
        ...state,
        contactViewAll: action.payload.data,
        contactViewAllCount: action.payload.count,
        isAllLoading: false,
      };
      break;
    case SET_CONTACT_VIEW_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;

    case GET_CONTACT_VIEW_FAILURE:
    case SET_CONTACT_VIEW_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    case GET_ALL_CONTACT_VIEW_FAILURE:
      state = {
        ...state,
        isAllLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }

  return state;
}
