import { residencyStatus } from "./arrayitems";
const GetresidencyStatusLabel = (text) => {
  let value = "";
  for (let i = 0; i < residencyStatus?.length; i++) {
    if (residencyStatus[i]?.value === text) {
      value = residencyStatus[i]?.label;
    }
  }
  return value;
};
export default GetresidencyStatusLabel;
