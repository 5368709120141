import approvedIcon from "./images/approvedicon.svg";
import pendingIcon from "./images/pending_button_icon.svg";
import rejectedIcon from "./images/rejected_button_icon.svg";
import attachmentIcon from "./images/attachment_icon.svg";
import appleIcon from "./images/apple_icon.svg";
import arrowIcon from "./images/arrow_icon.svg";
import arrowIconLeft from "./images/arrow-left.svg";
import cardActive from "./images/card_icon_active.svg";
import cardLocation from "./images/card_icon_location.svg";
import cardmatch from "./images/card_icon_match.svg";
import cardMessage from "./images/card_icon_message.svg";
import cardPhotoReq from "./images/card_icon_photoreq.svg";
import cardProfession from "./images/card_icon_profession.svg";
import cardTick from "./images/card_icon_tick.svg";
import cardButtonTick from "./images/card_icon_button_tick.svg";
import changeButtonIcon from "./images/changebuttonicon.svg";
import changePassword from "./images/change-password.svg";
import closeIcon from "./images/close_icon.svg";
import clearSearchfield from "./images/search_clearfield.svg";
import collopseLined from "./images/collapse-lined.svg";
import dropdownIcon from "./images/dropdownicon.svg";
import defaultMaleIcon from "./images/default_male.png";
import defaultFemaleIcon from "./images/default_female.png";
import dragAndDrop from "./images/dnd_plus.svg";
import downArrowIcon from "./images/down_arrow_icon.svg";
import datepickerIcon from "./images/datepickericon.svg";
import editProfileBasicInfo from "./images/editprofile_basicinfo.svg";
import editProfileEducation from "./images/editprofile_education.svg";
import editProfileFamilyDetails from "./images/editprofile_familydetails.svg";
import editProfileMale from "./images/editprofile_male.png";
import editProfileFemale from "./images/editprofile_female.png";
import editProfilePhysicalLifestyle from "./images/editprofile_physciallifestyle.svg";
import editProfileReligiousStatus from "./images/editprofile_religiousstatus.svg";
import editProfileDescription from "./images/editprofile_description.svg";
import expandLined from "./images/expand-lined.svg";
import eyeIcon from "./images/eye_icon.svg";
import eyeOfIcon from "./images/eye_off.svg";
import googleIcon from "./images/google_icon.svg";
import googlePlay from "./images/googleplay.svg";
import hamburger from "./images/hamburger.svg";
import headerSearch from "./images/header_search.svg";
import logoutIcon from "./images/logout.svg";
import mainLogo from "./images/logo_main.svg";
import mainLogoWhite from "./images/logo_white.svg";
import matchPrefBasic from "./images/matchpref_basicinfo.svg";
import matchPrefBody from "./images/matchpref_bodypref.svg";
import matchPrefEducation from "./images/matchpref_education.svg";
import navbarCross from "./images/navbarcross.svg";
import newtag from "./images/new_tag.svg";
import otpIcon from "./images/otp-icon.svg";
import searchCross from "./images/search_cross.svg";
import sidebarDropdown from "./images/sidebar_dropdown.svg";
import sideNavActivities from "./images/sidenav_activities.svg";
import sideNavBlocklist from "./images/sidenav_blocklist.svg";
import sideNavDash from "./images/sidenav_dashboardicon.svg";
import sideNavInterests from "./images/sidenav_interests.svg";
import sideNavMessages from "./images/sidenav_message.svg";
import sideNavNewMembers from "./images/sidenav_newmembers.svg";
import sideNavNotes from "./images/sidenav_notes.svg";
import sideNavPhotoReq from "./images/sidenav_photoreq.svg";
import sideNavProfileViewers from "./images/sidenav_profileviewers.svg";
import sideNavRecommended from "./images/sidenav_recommendedmatch.svg";
import sideNavShortlist from "./images/sidenav_shortlist.svg";
import sideNavSearchIcon from "./images/search_icon.svg";
import sideNavViewedContacts from "./images/sidenav_viewedcontacts.svg";
import upArrowIcon from "./images/up_arrow_icon.svg";
import notification from "./images/notification.svg";
import premiumIcon from "./images/premium-icon.svg";
import profileDropdownBg from "./images/profile-dropdown-bg.jpg";
import shortlistIcon from "./images/shortlist-icon.svg";
import photoGallery from "./images/photo-gallery.svg";
import photoGalleryIcon from "./images/photo-gallery-icon.svg";
import editProfile from "./images/edit-profile.svg";
import matchPreference from "./images/match-preference.svg";
import trustBadge from "./images/trust-badge.svg";
import membershipPlan from "./images/membership-plan.png";
import profileViewBg from "./images/view-profile-bg.jpg";
import calenderIcon from "./images/calender.svg";
import bodyHeight from "./images/body-height.svg";
import locationIcon from "./images/location.svg";
import computerProfession from "./images/computer-profession.svg";
import progressInfo from "./images/process-info.svg";
import progressInfoClose from "./images/progressInfo-close.svg";
import scanIcon from "./images/scan-icon.svg";
import printIcon from "./images/print-icon.svg";
import downloadIcon from "./images/download-icon.svg";
import editIcon from "./images/edit-icon.svg";
import phoneCallIcon from "./images/phone-call-icon.svg";
import emailIcon from "./images/email-icon.svg";
import galleryIcon from "./images/gallery-icon.svg";
import checkIcon from "./images/check-icon.svg";
import upComProgressCheck from "./images/un-complete-progress.svg";
import dottedImg from "./images/dotted-image.svg";
import basicInfoIcon from "./images/basic-info-icon.svg";
import personalityHobbiesIcon from "./images/personality-hobbies-icon.svg";
import heightIcon from "./images/height.svg";
import neverMarride from "./images/never-married.svg";
import educationIcon from "./images/education-icon.svg";
import familyIcon from "./images/family-details-icon.svg";
import idealMatchIcon from "./images/ideal-match-icon.svg";
import physicalAppreIcon from "./images/physical-appreance.svg";
import religiousIcon from "./images/religious-icon.svg";
import infoLockIcon from "./images/info-lock.svg";
import upgradeLock from "./images/upgrade-lock.png";
import threeDotted from "./images/three-dotted.svg";
import personalNote from "./images/personal-note.svg";
import blockUser from "./images/block-user.svg";
import reportUser from "./images/report-user.svg";
import cancelUser from "./images/cancel-user.svg";
import passportImg from "./images/passport-large.jpg";
import documentVerified from "./images/document-verified.png";
import trustBadgeIcon from "./images/trust-badge-icon.png";
import arrowIconRight from "./images/right-arrow.svg";
import viewedTag from "./images/viewed_tag.svg";
import whiteButtonBack from "./images/white-button-back-icon.svg";
import settingsIcon from "./images/settings-icon.svg";
import membershipBg from "./images/membership-bg.png";
import membershipPlanIcon from "./images/membership-plan-icon.svg";
import assistToday from "./images/assist-today-bg.jpg";
import whatsAppBtn from "./images/whats-app0btn.svg";
import facebookIcon from "./images/facebook-icon.svg";
import instagramIcon from "./images/instagram-icon.svg";
import whatsAppIcon from "./images/whats-app-icon.svg";
import twitterIcon from "./images/twitter-icon.svg";
import youtubeIcon from "./images/youtube-icon.svg";
import tumblrIcon from "./images/tumblr-icon.svg";
import redditIcon from "./images/reddit-icon.svg";
import printerstIcon from "./images/printerst-icon.svg";
import linkdinIcon from "./images/linkdin-icon.svg";
import deleteIcon from "./images/delete-icon.svg";
import deleteBtnIcon from "./images/delete-btn-icon.svg";
import zoomingIcon from "./images/zooming-icon.svg";
import BlackArrowIcon from "./images/black-arrow-icon.svg";
import fullProfileIcon from "./images/full-profile-icon.svg";
import messageStatus from "./images/message-status.svg";
import messageStatusSucess from "./images/message-status-sucess.svg";
import messageStatusNotReceive from "./images/message-status-notreceive.svg";
import messageSendIcon from "./images/message-send-btn.svg";
import unreadDot from "./images/unread-dot.svg";
import failedMessageIcon from "./images/failed-message-status.svg";
import submitBtnIcon from "./images/submitbtn-icon.svg";
import homeIcon from "./images/home-icon.svg";
import bestBg from "./images/best-bg.svg";
import crossIconWhite from "./images/close-icon-white.png";
import bkashLogo from "./images/bkash-logo.svg";
import payWithLogo from "./images/pay-with-logo.svg";
import paypalLogo from "./images/paypal-logo.svg";
import paymentSucess from "./images/payment-sucess-btn.svg";
import paymentFailBtn from "./images/payment-failed-btn.svg";
import creditDebitLogo from "./images/credit-debit-card-logo.svg";
import bottomBarHomeWhite from "./images/bottom-bar-home-black.svg";
import bottomBarHomeBlack from "./images/bottom-bar-home-black.svg";
import bottomBarSearchWhite from "./images/bottom-bar-search-white.svg";
import bottomBarSearchBlack from "./images/bottom-bar-search-black.svg";
import bottomBarBellBlack from "./images/bottom-bar-bell-black.svg";
import bottomBarBellWhite from "./images/bottom-bar-bell-white.svg";
import bottomBarMegWhite from "./images/bottom-bar-meg-white.svg";
import bottomBarMegBlack from "./images/bottom-bar-meg-black.svg";
import notifyIcon from "./images/notify-icon.svg";
import headBackArrow from "./images/head-back-arrow.svg";
import confirmLogout from "./images/confirm-logout.svg";
import deleteIcon2 from "./images/delete-icon2.svg";

// audio
import messageSound from "./audio/message.mp3";
import notificationSound from "./audio/notification.mp3";

const images = {
  approvedIcon,
  pendingIcon,
  rejectedIcon,
  attachmentIcon,
  appleIcon,
  arrowIcon,
  arrowIconLeft,
  cardActive,
  cardLocation,
  cardmatch,
  cardMessage,
  cardPhotoReq,
  cardProfession,
  cardTick,
  cardButtonTick,
  changeButtonIcon,
  changePassword,
  closeIcon,
  clearSearchfield,
  collopseLined,
  defaultMaleIcon,
  defaultFemaleIcon,
  downArrowIcon,
  dropdownIcon,
  dragAndDrop,
  datepickerIcon,
  editProfileBasicInfo,
  editProfileEducation,
  editProfileFamilyDetails,
  editProfileMale,
  editProfileFemale,
  editProfilePhysicalLifestyle,
  editProfileReligiousStatus,
  editProfileDescription,
  expandLined,
  eyeIcon,
  eyeOfIcon,
  googleIcon,
  googlePlay,
  hamburger,
  headerSearch,
  logoutIcon,
  mainLogo,
  mainLogoWhite,
  matchPrefBasic,
  matchPrefBody,
  matchPrefEducation,
  navbarCross,
  newtag,
  otpIcon,
  searchCross,
  sidebarDropdown,
  sideNavActivities,
  sideNavBlocklist,
  sideNavDash,
  sideNavInterests,
  sideNavMessages,
  sideNavNewMembers,
  sideNavNotes,
  sideNavPhotoReq,
  sideNavProfileViewers,
  sideNavRecommended,
  sideNavShortlist,
  sideNavSearchIcon,
  sideNavViewedContacts,
  upArrowIcon,
  notification,
  premiumIcon,
  profileDropdownBg,
  photoGalleryIcon,
  shortlistIcon,
  photoGallery,
  editProfile,
  matchPreference,
  trustBadge,
  membershipPlan,
  viewedTag,
  profileViewBg,
  calenderIcon,
  bodyHeight,
  locationIcon,
  computerProfession,
  progressInfo,
  scanIcon,
  printIcon,
  downloadIcon,
  editIcon,
  phoneCallIcon,
  emailIcon,
  galleryIcon,
  checkIcon,
  upComProgressCheck,
  dottedImg,
  basicInfoIcon,
  personalityHobbiesIcon,
  heightIcon,
  neverMarride,
  educationIcon,
  familyIcon,
  idealMatchIcon,
  physicalAppreIcon,
  religiousIcon,
  infoLockIcon,
  upgradeLock,
  threeDotted,
  personalNote,
  blockUser,
  reportUser,
  cancelUser,
  passportImg,
  documentVerified,
  trustBadgeIcon,
  arrowIconRight,
  whiteButtonBack,
  settingsIcon,
  membershipBg,
  membershipPlanIcon,
  assistToday,
  whatsAppBtn,
  facebookIcon,
  instagramIcon,
  whatsAppIcon,
  youtubeIcon,
  twitterIcon,
  printerstIcon,
  tumblrIcon,
  redditIcon,
  linkdinIcon,
  deleteIcon,
  progressInfoClose,
  deleteBtnIcon,
  zoomingIcon,
  BlackArrowIcon,
  fullProfileIcon,
  messageStatus,
  messageStatusSucess,
  messageStatusNotReceive,
  messageSendIcon,
  unreadDot,
  failedMessageIcon,
  submitBtnIcon,
  homeIcon,
  bestBg,
  crossIconWhite,
  bkashLogo,
  payWithLogo,
  paypalLogo,
  paymentSucess,
  paymentFailBtn,
  creditDebitLogo,
  bottomBarHomeBlack,
  bottomBarHomeWhite,
  bottomBarSearchBlack,
  bottomBarSearchWhite,
  bottomBarBellBlack,
  bottomBarBellWhite,
  bottomBarMegWhite,
  bottomBarMegBlack,
  notifyIcon,
  headBackArrow,
  confirmLogout,
  deleteIcon2,

  messageSound,
  notificationSound,
};

export default images;
