import React from "react";
import { Dropdown } from "antd";
import { useSelector } from "react-redux";

import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";

import { FaCaretDown } from "react-icons/fa6";

import ProfileDropdown from "./profiledropdown";

import images from "../../../../assets";
import Loader from "../../../loader/loader";

const items = [
  {
    label: <ProfileDropdown />,
    key: "0",
  },
];

const ProfileDropdownContainer = (props) => {
  const profileData = useSelector((state) => state?.profile?.profile);
  const profileLoading = useSelector((state) => state?.profile?.isLoading);
  return (
    <Dropdown
      menu={{
        items,
      }}
      trigger={["click"]}
      overlayStyle={{
        top: props?.promotionHeader ? "130px" : "80px",
      }}
    >
      <div className="profile-nav-area">
        <div className="profile-nav-photo">
          {profileLoading ? (
            <Loader />
          ) : (
            <>
              {!profileData?.gender ? (
                <Avatar size={40} icon={<UserOutlined />} />
              ) : (
                <img
                  className="profile-circle-photo"
                  src={
                    profileData?.profilePicture ||
                    (profileData?.gender === "male"
                      ? images.defaultMaleIcon
                      : images.defaultFemaleIcon)
                  }
                  alt="img"
                />
              )}
            </>
          )}
        </div>
        <div className="caret-down-icon">
          <FaCaretDown />
        </div>
      </div>
    </Dropdown>
  );
};

export default ProfileDropdownContainer;
