import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ConfigProvider, Modal } from "antd";

import Spacer from "../spacer/spacer";

import PhotoCard from "./photocard";
import PhotoModal from "./photomodal";

import images from "../../assets";

import "./styles.css";

export default function PhotoGallery() {
  const profileData = useSelector((state) => {
    return state?.profile?.profile;
  });

  const [imageArray, setImageArray] = useState(profileData?.imageGallery ?? []);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const numOfPhoto = profileData?.imageGallery?.length;

  const handleArrayChange = (url) => {
    const imageList = [...imageArray];

    const clickedImageUrl = url;
    const clickedImageIndex = imageList.findIndex(
      (image) => image.url === clickedImageUrl
    );

    if (clickedImageIndex !== -1) {
      const clickedImage = imageList.splice(clickedImageIndex, 1)[0];
      imageList.unshift(clickedImage);
    }

    setImageArray(imageList);
    setIsModalOpen(true);
  };

  return (
    <div className="photo-gallery-body">
      {profileData?.imageGallery?.map((item, index) => (
        <PhotoCard key={index} item={item}>
          <img
            onClick={() => handleArrayChange(item?.url)}
            src={item.url}
            alt="Gallery Images"
            className="h-full w-full aspect-square object-cover"
          />
        </PhotoCard>
      ))}
      <ConfigProvider
        theme={{
          token: {
            fontSize: 16,
          },
        }}
      >
        <Modal
          centered={true}
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          footer={false}
          closable={false}
          className="gallery-modal-main"
          destroyOnClose
        >
          <PhotoModal
            imagesarr={imageArray}
            initialurl={imageArray[0]?.url}
            initialStatus={imageArray[0]?.status}
          >
            <div className="photo-modal-left">
              <h2>My Photos</h2>
              <p>{numOfPhoto} Files</p>
            </div>
            <div className="photo-modal-right">
              <img
                onClick={() => setIsModalOpen(false)}
                src={images.closeIcon}
                alt=""
                height={44}
                width={44}
              />
            </div>
          </PhotoModal>
        </Modal>
      </ConfigProvider>
    </div>
  );
}
