import React from "react";
import { Upload, message } from "antd";

import truncateString from "../../utils/truncatestring";

import "./styles.css";
import { PrimaryButton } from "../button";

const FileUploaderButton = (props) => {
  const onChange = ({ fileList: newFileList }) => {
    const filteredList = newFileList.filter((file) => {
      const maxSize = 4 * 1024 * 1024; // 4MB

      if (file.size > maxSize) {
        message.error(
          `The file "${truncateString(
            file.name
          )}" exceeds the maximum file size of 4MB`
        );
        return false;
      }
      return true;
    });
    props?.setState({ ...props?.state, fileList: newFileList });
  };

  return (
    <div className="uploader-container">
      <Upload
        accept={props?.formats ?? ".jpg,.png,.pdf"}
        listType="picture"
        fileList={props?.state?.fileList}
        onChange={onChange}
        showUploadList={false}
        progress={{ strokeWidth: 2, showInfo: false }}
        className={`cursor-pointer gender-change-trust ${
          props?.state?.fileList?.length < 1 || !props?.disabled
            ? ""
            : "opacity-45"
        }`}
        disabled={
          props?.disabled || (props?.state?.fileList?.length < 1 ? false : true)
        }
      >
        <PrimaryButton text="Upload Document" />
      </Upload>
    </div>
  );
};

export default FileUploaderButton;
