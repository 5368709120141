import { put } from "redux-saga/effects";
import { message } from "antd";

import UserApiCaller from "../../config/api/userapicaller";

import { OthersProfileAction } from "../actions";

export default class OthersProfileMiddleware {
  static *getOthersProfile({ payload, cb }) {
    try {
      const res = yield UserApiCaller.Post("/user/othersprofile", payload);
      if (res.status === 200) {
        yield put(OthersProfileAction.getOthersProfileSuccess(res.data.data));
      } else if (res.status === 404) {
        yield put(OthersProfileAction.getOthersProfileFailure({}));
        window.history.back();
      } else if (res.status === 403) {
        yield put(OthersProfileAction.getOthersProfileFailure({}));
        window.history.back();
      } else {
        yield put(OthersProfileAction.getOthersProfileFailure({}));
      }
      if (cb) {
        cb(res.data.data);
      }
    } catch (err) {
      console.log(err);
      message.error("Network Error");
      yield put(OthersProfileAction.getOthersProfileFailure({}));
    }
  }
}
