import React from "react";

const PromotionHeader = () => {
  return (
    <div className="bg-basePrimary text-white text-[14px] px-2 h-[60px] flex items-center">
      PromotionHeader
    </div>
  );
};

export default PromotionHeader;
