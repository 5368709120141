import { Route, Routes } from "react-router-dom";
import { PUBLIC_ROUTES } from "./sitemap";

const AuthRoutes = () => {
  return (
    <Routes>
      {PUBLIC_ROUTES.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={route.component}
          exact={route.exact}
        />
      ))}
    </Routes>
  );
};
export default AuthRoutes;
