import React from "react";
import useScreenSize from "../../utils/usescreensize";

const SkeletonItem = () => {
  const screenSize = useScreenSize();

  return (
    <div className="flex gap-[10px] py-2">
      <div className="skeleton" style={{ height: "36px", width: "36px" }}>
        &zwnj;
      </div>
      <div className="flex flex-col gap-1 w-full">
        <div
          className="skeleton"
          style={{
            height: "20px",
            width: "90%",
          }}
        >
          &zwnj;
        </div>
        <div
          className="skeleton"
          style={{
            height: "12px",
            width: "40%",
          }}
        >
          &zwnj;
        </div>
      </div>
    </div>
  );
};

const Searchbarloader = () => {
  return (
    <div className="w-full overflow-hidden">
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
    </div>
  );
};

export default Searchbarloader;
