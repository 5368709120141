const incomeCurrency = [
  { label: "USD (US Dollar)", value: "USD (US Dollar)" },
  { label: "EUR (Euro)", value: "EUR (Euro)" },
  { label: "GBP (UK Pound)", value: "GBP (UK Pound)" },
  { label: "CAD (Canadian Dollar)", value: "CAD (Canadian Dollar)" },
  { label: "AUD (Australian Dollar)", value: "AUD (Australian Dollar)" },
  { label: "AED (UAE Dirham)", value: "AED (UAE Dirham)" },
  { label: "BDT (Bangladeshi Taka)", value: "BDT (Bangladeshi Taka)" },
  { label: "SAR (Saudi Riyal)", value: "SAR (Saudi Riyal)" },
  { label: "TRY (Turkish Lira)", value: "TRY (Turkish Lira)" },
  { label: "AFA (Afghan Afghani)", value: "AFA (Afghan Afghani)" },
  { label: "CZK (Czech Koruna)", value: "CZK (Czech Koruna)" },
  { label: "CNY (Chinese Yuan)", value: "CNY (Chinese Yuan)" },
  { label: "EGP (Egyptian Pound)", value: "EGP (Egyptian Pound)" },
  { label: "HKD (Hong Kong Dollar)", value: "HKD (Hong Kong Dollar)" },
  { label: "IDR (Indonesian Rupiah)", value: "IDR (Indonesian Rupiah)" },
  { label: "IQD (Iraqi Dinar)", value: "IQD (Iraqi Dinar)" },
  { label: "KWD (Kuwaiti Dinar)", value: "KWD (Kuwaiti Dinar)" },
  { label: "INR (Indian Rupees)", value: "INR (Indian Rupees)" },
];

const incomeValue = [
  { label: "Below 1,000", value: 1000 },
  { label: "5,000", value: 5000 },
  { label: "10,000", value: 10000 },
  { label: "20,000", value: 20000 },
  { label: "30,000", value: 30000 },
  { label: "40,000", value: 40000 },
  { label: "50,000", value: 50000 },
  { label: "60,000", value: 60000 },
  { label: "80,000", value: 80000 },
  { label: "100,000", value: 100000 },
  { label: "125,000", value: 125000 },
  { label: "150,000", value: 150000 },
  { label: "200,000", value: 200000 },
  { label: "250,000", value: 250000 },
  { label: "500,000", value: 500000 },
  { label: "1,000,000", value: 1000000 },
  { label: "5,000,000", value: 5000000 },
  { label: "10,000,000", value: 10000000 },
  { label: "50 Million", value: 50000000 },
  { label: "100 Million", value: 100000000 },
  { label: "500 Million", value: 500000000 },
  { label: "1 Billion", value: 1000000000 },
];
export { incomeCurrency, incomeValue };
