import {
  GET_NOTE,
  GET_NOTE_SUCCESS,
  GET_NOTE_FAILURE,
  GET_NOTES,
  GET_NOTES_SUCCESS,
  GET_NOTES_FAILURE,
  SET_NOTES,
  SET_NOTES_SUCCESS,
  SET_NOTES_FAILURE,
} from "../constants";

const initialState = {
  myNotes: [],
  mynote: {},
  myNotesCount: 0,
  isLoading: false,
  isNoteLoading: false,
  error: null,
};

export default function MyNotesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_NOTES:
    case SET_NOTES:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_NOTE:
      state = {
        ...state,
        isNoteLoading: true,
        error: null,
      };
      break;
    case GET_NOTES_SUCCESS:
      state = {
        ...state,
        myNotes: action.payload.data,
        myNotesCount: action.payload.count,
        isLoading: false,
      };
      break;
    case GET_NOTE_SUCCESS:
      state = {
        ...state,
        myNote: action.payload,
        isNoteLoading: false,
      };
      break;
    case SET_NOTES_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;

    case GET_NOTES_FAILURE:
    case SET_NOTES_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    case GET_NOTE_FAILURE:
      state = {
        ...state,
        isNoteLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }

  return state;
}
