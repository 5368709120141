const styles = {
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  alignCenter: {
    alignSelf: "center",
  },
  itemCenter: {
    display: "flex",
    alignItems: "center",
  },
  itemCenterjustifyCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  background: {
    width: "100%",
    height: "100vh",
    overflow: "hidden",
  },
  loginBackgroundImage: { height: "auto", width: "100%" },
  video: {
    position: "fixed",
    top: "50%",
    left: "50%",
    minHeight: "100%",
    minWidth: "100%",
    width: "auto",
    height: "auto",
    transform: "translate(-50%,-50%)",
    backgroundSize: "cover",
    zIndex: "-10",
  },
  backgroundOpacity: {
    background: "#310048",
    width: "100%",
    height: "100%",
    opacity: "0.25",
  },

  crossIcon: {
    position: "absolute",
    right: "12px",
    top: "12px",
    cursor: "pointer",
  },
  backIcon: {
    position: "absolute",
    left: "12px",
    top: "12px",
    cursor: "pointer",
  },
};

export default styles;
