import React from "react";

import Loader from "../../components/loader/loader";
import Spacer from "../../components/spacer/spacer";
import useScreenSize from "../../utils/usescreensize";
import PremiumMessage from "./premiummessage";

import "./styles.css";

const Messages = (props) => {
  const screenSize = useScreenSize();
  return (
    <>
      {screenSize.width < 1023 && (
        <Spacer height="60px" />
      )}
      <Spacer height="10px" />
      {props?.messagesLoading ? (
        <Loader />
      ) : (
        <PremiumMessage
          messages={props?.messages}
          setMessages={props?.setMessages}
          activeUser={props?.activeUser}
        />
      )}
    </>
  );
};

export default Messages;
