import React from "react";
import { ProfileLayout } from "../../components/layout";
import useScreenSize from "../../utils/usescreensize";
import "./styles.css";
import AboutMeCard from "../../components/profilecard/aboutmecard";
import BasicInfoCard from "../../components/profilecard/basicinfocard";
import MyGalleryCard from "../../components/profilecard/mygallerycard";
import PersonalityHobbiCard from "../../components/profilecard/personalityhobbicard";
import PhysicalAppeCard from "../../components/profilecard/physicalappecard";
import FamilyDetailsCard from "../../components/profilecard/familydetailscard";
import ReligiousStatus from "../../components/profilecard/religiousstatus";
import EducationCareerCard from "../../components/profilecard/educationcareercard";
import ImproveMatchCard from "../../components/profilecard/improvematchcard";
import MyIdealMatchCard from "../../components/profilecard/myidealmatchcard";
import Spacer from "../../components/spacer/spacer";

const Profile = () => {
  const screenSize = useScreenSize();
  return (
    <ProfileLayout>
      <div className="profile-body-content">
        <div className="profile-body-left">
          {screenSize.width < 1024 && (
            <>
              <MyGalleryCard />
              <Spacer height="30px" />
            </>
          )}
          {/* About me Card */}

          <AboutMeCard />
          {/* Basic Info Card */}
          <BasicInfoCard />
          {/* Physical Appearance & Lifestyle */}
          <PhysicalAppeCard />
          {/* Family Details */}
          <FamilyDetailsCard />
          {/* Religious Status */}
          <ReligiousStatus />
          {/* Education & Career */}
          <EducationCareerCard />
          {/* My Ideal Match */}
          <MyIdealMatchCard />
        </div>
        <div className="profile-body-right">
          {/* Gallery Card */}
          {screenSize.width > 1024 && <MyGalleryCard />}
          {/* Personality Hobbies */}
          <PersonalityHobbiCard />
          {/* Improve Match */}
          <ImproveMatchCard />
        </div>
      </div>
    </ProfileLayout>
  );
};

export default Profile;
