import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import ReactCodeInput from "react-code-input";

import { LoginLayout } from "../../components/layout";
import Spacer from "../../components/spacer/spacer";
import { PrimaryButton } from "../../components/button";

import images from "../../assets";

import { AuthAction } from "../../store/actions";

import "./styles.css";

const containsNonNumericCharacters = (str) => {
  return /\D/.test(str);
};

const ConfirmOTP = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [code, setCode] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (
      !location.state ||
      (location?.state && !location?.state?.username) ||
      (location?.state && !location?.state?.password)
    ) {
      navigate("/login");
    }
  }, [location?.state]);

  const handleOtpSubmit = () => {
    if (!code) {
      setError("Enter OTP");
    } else if (containsNonNumericCharacters(code)) {
      setError("OTP must be a number");
    } else {
      setError("");
      const payload = {
        code,
        email: location?.state?.username,
        password: location?.state?.password,
        config_state_hash: location?.state?.config_state_hash,
      };
      dispatch(
        AuthAction.confirmOtpRecover(payload, (res) => {
          if (res.status === 200) {
            const token = res.data.data.AuthenticationResult.AccessToken;
            localStorage.setItem(
              "authUser",
              JSON.stringify({
                AccessToken: token,
              })
            );
            dispatch(
              AuthAction.SignInSuccess({
                AccessToken: token,
              })
            );
          }
        })
      );
    }
  };

  const handleResend = () => {
    const payload = { username: location?.state?.username };
    dispatch(AuthAction.resendOtp(payload));
  };

  const handleChangeEmail = () => {
    navigate("/change-email-request");
  };
  return (
    <LoginLayout>
      <div className="w-[300px] lg:w-[450px]">
        <div className="forgot-password-container">
          <img src={images?.otpIcon} alt="" />
          <Spacer height="32px" />
          <h2 className="forgot-password-title">
            Please enter the verification code sent to your Email.
          </h2>
          <Spacer height="24px" />
          <ReactCodeInput
            type="text"
            fields={6}
            onChange={(e) => setCode(e)}
            value={code}
            inputStyle={{
              border: `1px solid #eae6ed`,
              background: "#fff",
              borderRadius: "5px",
              fontSize: "32px",
              fontWeight: "500",
              height: "48px",
              width: "60px",
              outline: "none",
              textAlign: "center",
              marginLeft: "5px",
              marginRight: "5px",
            }}
            onClick={(e) => setCode(e.target.value)}
          />

          {error && (
            <div className="text-center">
              <p className="text-danger font-semibold text-[14px] m-0">
                {error}
              </p>
            </div>
          )}
          <Spacer height="32px" />
          <PrimaryButton text={"Verify OTP"} onClick={handleOtpSubmit} />
          <Spacer height="16px" />
          <p className="otp-form-bottom-text">
            Didn’t receive verification code?{" "}
            <span className="forgot-password-text" onClick={handleResend}>
              Resend
            </span>
          </p>
          <Spacer height="8px" />
          {/* <p className="otp-form-bottom-text">
            Don't have access to this email?{" "}
            <span className="forgot-password-text" onClick={handleChangeEmail}>
              Change Email
            </span>
          </p>
          <Spacer height="8px" /> */}
          <p className="otp-form-bottom-text">
            Make sure you have checked your spam / junk box.
          </p>
        </div>
      </div>
    </LoginLayout>
  );
};

export default ConfirmOTP;
