import { useSelector } from "react-redux";
import images from "../../assets";
import "./styles.css";
import BasicInfoColumn from "./basicinfocolumn";
import getMaritalStatusLabel from "../../utils/getmaritalstatuslabel";

export default function BasicInfoCard() {
  const profileData = useSelector((state) => {
    return state?.profile?.profile;
  });
  const calculateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };
  let birthDate = calculateAge(profileData?.dateOfBirth);

  return (
    <div className="basic-info-card">
      <div className="basic-info-head">
        <img src={images.basicInfoIcon} alt="Basic Info" />{" "}
        <span>Basic Info</span>
      </div>
      <div className="basic-info-content">
        {profileData?.gender && (
          <BasicInfoColumn
            style={{ textTransform: "capitalize" }}
            titlename="Gender"
            values={profileData?.gender}
          />
        )}
        {profileData?.dateOfBirth && (
          <BasicInfoColumn titlename="Age" values={`${birthDate} Years`} />
        )}
        {profileData?.stateProvince && profileData?.country && (
          <BasicInfoColumn
            titlename="Lives In"
            values={`${profileData?.stateProvince}, ${profileData?.country}`}
          />
        )}
        {profileData?.homeType && (
          <BasicInfoColumn
            style={{ textTransform: "capitalize" }}
            titlename="Home Type"
            values={profileData?.homeType}
          />
        )}
        {profileData?.relocationPlan && (
          <BasicInfoColumn
            titlename="Relocation Plan"
            values={profileData?.relocationPlan}
          />
        )}
        {profileData?.ethnicOrigin && (
          <BasicInfoColumn
            titlename="Ethnic Origin"
            values={profileData?.ethnicOrigin}
          />
        )}
        {profileData?.residencyStatus && (
          <BasicInfoColumn
            style={{ textTransform: "capitalize" }}
            titlename="Residency Status"
            values={profileData?.residencyStatus}
          />
        )}
        {profileData?.maritalStatus && (
          <BasicInfoColumn
            style={{ textTransform: "capitalize" }}
            titlename="Marital Status"
            values={getMaritalStatusLabel(profileData?.maritalStatus)}
          />
        )}
        {profileData?.spokenLanguage?.length > 0 && (
          <BasicInfoColumn
            titlename="Spoken Language"
            values={profileData?.spokenLanguage?.map((item, index) => {
              if (index === 0) {
                return item;
              } else {
                return `, ${item}`;
              }
            })}
          />
        )}
      </div>
    </div>
  );
}
