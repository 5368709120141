const RemoveEmptyKeys = (data) => {
  const filteredData = data;
  for (let key in filteredData) {
    if (
      data[key] === "" ||
      (Array.isArray(data[key]) && data[key].length === 0)
    ) {
      delete data[key];
    }
  }

  return filteredData;
};

export default RemoveEmptyKeys;
