import React from "react";
import { Checkbox } from "antd";

const CheckboxGroup = (props) => (
  <>
    <Checkbox.Group
      {...props}
      options={props?.items}
      defaultValue={[]}
      style={{ flexDirection: "column" }}
    />
  </>
);
export default CheckboxGroup;
