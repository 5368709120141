import { useSelector } from "react-redux";
import images from "../../assets";
import "./styles.css";
import BasicInfoColumn from "./basicinfocolumn";

export default function EducationCareerCard() {
  const profileData = useSelector((state) => {
    return state?.profile?.profile;
  });
  return (
    <div className="education-career basic-info-card">
      <div className="basic-info-head">
        <img src={images.educationIcon} alt="Religious Status" />{" "}
        <span>Education & Career</span>
      </div>
      <div className="basic-info-content">
        {profileData?.education && (
          <BasicInfoColumn
            titlename="Education"
            values={profileData?.education}
          />
        )}
        {profileData?.instituteName && (
          <BasicInfoColumn
            titlename="Institute Name"
            values={profileData?.instituteName}
          />
        )}
        {profileData?.profession && (
          <BasicInfoColumn
            titlename="Profession"
            values={profileData?.profession}
          />
        )}
        {profileData?.occupationStatus && (
          <BasicInfoColumn
            titlename="Occupation Status"
            values={profileData?.occupationStatus}
          />
        )}
        {profileData?.occupationStatus && (
          <BasicInfoColumn
            titlename="Annual Income"
            values={`USD ${profileData?.annualIncomeMin} to
            ${profileData?.annualIncomeMax}`}
          />
        )}
      </div>
    </div>
  );
}
