const ethnicOrigin = [
  { label: "Arab - Middle Eastern", value: "Arab - Middle Eastern" },
  { label: "Arab - North African", value: "Arab - North African" },
  { label: "Asian - Afghan", value: "Asian - Afghan" },
  { label: "Asian - Bangladeshi", value: "Asian - Bangladeshi" },
  { label: "Asian - Central", value: "Asian - Central" },
  { label: "Asian - Chinese", value: "Asian - Chinese" },
  { label: "Asian - Filipino", value: "Asian - Filipino" },
  { label: "Asian - Indian", value: "Asian - Indian" },
  { label: "Asian - Indonesian", value: "Asian - Indonesian" },
  { label: "Asian - Japanese", value: "Asian - Japanese" },
  { label: "Asian - Kurdish", value: "Asian - Kurdish" },
  { label: "Asian - Malay", value: "Asian - Malay" },
  { label: "Asian - Maldivian", value: "Asian - Maldivian" },
  { label: "Asian - Other Background", value: "Asian - Other Background" },
  { label: "Asian - Pakistani", value: "Asian - Pakistani" },
  { label: "Asian - Persian", value: "Asian - Persian" },
  { label: "Asian - Singaporian", value: "Asian - Singaporian" },
  { label: "Asian - Sri Lankan", value: "Asian - Sri Lankan" },
  { label: "Asian - Thai", value: "Asian - Thai" },
  { label: "Berbers - North African", value: "Berbers - North African" },
  { label: "Black - African", value: "Black - African" },
  { label: "Black - American", value: "Black - American" },
  { label: "Black - British", value: "Black - British" },
  { label: "Black - Caribbean", value: "Black - Caribbean" },
  { label: "Black - Nigerian", value: "Black - Nigerian" },
  { label: "Black - Other Background", value: "Black - Other Background" },
  { label: "Hispanic / Latino", value: "Hispanic / Latino" },
  { label: "Indian Carribean", value: "Indian Carribean" },
  { label: "Other Ethnicity", value: "Other Ethnicity" },
  { label: "Pacific Islander", value: "Pacific Islander" },
  { label: "Turkish", value: "Turkish" },
  { label: "White - American", value: "White - American" },
  { label: "White - Australian", value: "White - Australian" },
  { label: "White - British", value: "White - British" },
  { label: "White - Canadian", value: "White - Canadian" },
  { label: "White - European", value: "White - European" },
  { label: "White - Hispanic", value: "White - Hispanic" },
  { label: "White - Other Background", value: "White - Other Background" },
];
export default ethnicOrigin;
