import React, { useState, useEffect } from "react";
import { Upload, Progress, message } from "antd";
import ImgCrop from "antd-img-crop";

import truncateString from "../../utils/truncatestring";

import images from "../../assets";

import variables from "../../config/constants";

import "./styles.css";

const { BASE_URL_MEDIA } = variables;

const FileUploaderWithoutCrop = (props) => {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    props?.setState({ ...props?.state, fileList: fileList });
  }, [fileList]);

  const onChange = ({ fileList: newFileList }) => {
    const filteredList = newFileList.filter((file) => {
      const maxSize = 4 * 1024 * 1024; // 4MB

      if (file.size > maxSize) {
        message.error(
          `The file "${truncateString(
            file.name
          )}" exceeds the maximum file size of 4MB`
        );
        return false;
      }
      return true;
    });
    setFileList(filteredList);
  };

  const removeFile = (file) => {
    const newFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(newFileList);
  };

  return (
    <div className="uploader-container">
      <Upload
        accept={props?.formats ?? ".jpg,.png,.pdf"}
        listType="picture"
        fileList={fileList}
        onChange={onChange}
        showUploadList={false}
        progress={{ strokeWidth: 2, showInfo: false }}
        className={`cursor-pointer ${
          fileList?.length < 1 || !props?.disabled ? "" : "opacity-45"
        }`}
        disabled={props?.disabled || (fileList?.length < 1 ? false : true)}
      >
        <div className="ant-upload-drag-icon">
          <img src={images.dragAndDrop} alt="" />
          <div className="ant-upload-text">
            <img src={images.attachmentIcon} alt="" />
            <h1 className="upload-text-big">Upload your photos or drop here</h1>
          </div>
          <p className="ant-upload-subtext">Maximum file size 4 MB</p>
          <p className="ant-upload-sub-subtext">
            Supported formats: {props?.formats ?? ".jpg,.png"}
          </p>
        </div>
      </Upload>

      <div className="file-list-container">
        {fileList?.length > 0 &&
          fileList?.map((item, index) => (
            <div key={index} className="file-list">
              <img
                src={images?.closeIcon}
                alt={item.name}
                className="remove-file-icon"
                onClick={() => removeFile(item)}
              />
              <div className="flex flex-row gap-3 items-center">
                <img
                  src={URL.createObjectURL(item.originFileObj)}
                  alt={item.name}
                  className="file-thumbnail"
                />
                <div className="file-details">
                  <p className="file-name-text" title={item?.name}>
                    {truncateString(item?.name)}
                  </p>
                  <p className="file-name-subtext">
                    {(item.size / 1024).toFixed(2)} KB
                  </p>
                </div>
              </div>
              <Progress percent={100} className="m-0" />
            </div>
          ))}
      </div>
    </div>
  );
};

export default FileUploaderWithoutCrop;
