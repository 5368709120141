import images from "../../../../assets";
import MembershipPlan from "./membershipplan";
import NotifiMainInbox from "./notifimaininbox";
import "./styles.css";

const NotificationNavs = () => {
  return (
    <div className="notification-area">
      <div className="notify-heading">
        <div className="notifi-heading-text">Notifications</div>
        <div className="notifi-sub-heading">Mark all as read</div>
      </div>
      <div className="notifi-separator" />
      <div className="notifi-content-area">
        <div className="notifi-content-title">New</div>
        <NotifiMainInbox
          title="Faiza"
          subText="has viewed your profile."
          time="2 minutes ago"
          onlineGreen="active"
          pinkColor="new"
          className="notifi-unread"
        >
          <img
            className="notifi-profile-img"
            src={images.editProfileMale}
            alt=""
          />
        </NotifiMainInbox>
        <NotifiMainInbox
          title="Faiza 2"
          subText="has viewed your profile."
          time="2 minutes ago"
          onlineGreen="active"
          className="notifi-unread"
        >
          <img
            className="notifi-profile-img"
            src={images.editProfileMale}
            alt=""
          />
        </NotifiMainInbox>

        <div className="notifi-content-title">Earlier</div>
        <NotifiMainInbox
          title="Faiza 3"
          subText="has viewed your profile."
          time="2 minutes ago"
          className="notifi-read "
        >
          <img
            className="notifi-profile-img"
            src={images.editProfileMale}
            alt=""
          />
        </NotifiMainInbox>

        <MembershipPlan />

        <div className="notifi-separator" />
      </div>
      <div className="notifi-see-all">
        <div className="notifi-see-all-btn">See all</div>
      </div>
    </div>
  );
};
export default NotificationNavs;
