import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { InterestAction } from "../../store/actions";
import calculateage from "../../utils/calculateage";

import { PrimaryButton } from "../button";
import CardBottomButton from "./cardbottombutton";

import images from "../../assets";

import "./styles.css";

const DashboardMobileCard = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSendInterest = (id) => {
    const payload = {
      receiverId: id,
    };
    dispatch(InterestAction.sendInterest(payload));
  };

  return (
    <div {...props} className="recommended-matches-container new-member">
      <div className="new-member-mobile">
        <div className="new-member-mobile-left">
          <div className="new-member-img-mobile">
            <img
              src={
                props?.item?.profilePicture &&
                props?.item?.profilePicture !== "null"
                  ? props?.item?.profilePicture
                  : props?.item?.gender === "male"
                  ? images?.defaultMaleIcon
                  : images?.defaultFemaleIcon
              }
              alt={props?.index}
              onClick={() => navigate(`/view-profile/${props?.item?.username}`)}
            />
          </div>
          <div className="new-member-details-mobile">
            <div className="new-member-content-mobile">
              <p
                onClick={() =>
                  navigate(`/view-profile/${props?.item?.username}`)
                }
              >
                {props?.item?.firstName} {props?.item?.lastName}
              </p>
              <span>
                {props?.item?.dateOfBirth !== "null"
                  ? `${calculateage(props?.item?.dateOfBirth)} yrs`
                  : "N/A"}
              </span>
              <span>
                {props?.item?.stateProvince}, {props?.item?.country}
              </span>
            </div>
            <div className="new-member-content-btn">
              <PrimaryButton
                style={{
                  height: "32px",
                  padding: "0px 12px",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
                text="View Profile"
                onClick={() =>
                  navigate(`/view-profile/${props?.item?.username}`)
                }
              />
            </div>
          </div>
        </div>
        <div className="new-member-mobile-right">
          <CardBottomButton
            icon={images.cardmatch}
            onClick={() => handleSendInterest(props?.item?.username)}
            title="Send Interest"
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardMobileCard;
