import React from "react";
import { Slider } from "antd";

import "./styles.css";

const RangeSlider = (props) => {
  const marks = {
    [props.value[0]]: `${props.value[0]}`,
    [props.value[1]]: `${props.value[1]}`,
  };

  return (
    <Slider
      {...props}
      marks={marks}
      range
      min={18}
      max={100}
      tooltip={{ open: false }}
      styles={{
        track: { backgroundColor: "#310048" },
      }}
      className="match-pref-slider"
    />
  );
};

export default RangeSlider;
