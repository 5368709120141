import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";

import { AuthAction, ProfileAction } from "../../store/actions";

import TrustBadgeForm from "./trustbadgeform";
import DateOfBirthForm from "./dateofbirthform";

import "./styles.css";

const DateOfBirthChangeReq = (props) => {
  const dispatch = useDispatch();

  const trustbadgeStatus = useSelector(
    (state) => state?.profile?.profile?.trustbadgeStatus
  );

  const [dateOfBirth, setDateOfBirth] = useState(null);

  const handleDobSubmit = () => {
    if (!dateOfBirth) {
      message.error("Please provide your date of birth");
    } else {
      const payload = {
        data: {
          dateOfBirth,
          type: "dobchange",
        },
      };

      dispatch(
        ProfileAction.changeRequest(payload, (res) => {
          console.log(res);
        })
      );
      props?.setIsDobModalOpen(false);
    }
  };

  return (
    <div className="forgot-password-container">
      {trustbadgeStatus === undefined ||
      trustbadgeStatus === null ||
      trustbadgeStatus === "" ||
      trustbadgeStatus === "false" ||
      trustbadgeStatus === "rejected" ? (
        <TrustBadgeForm />
      ) : (
        <DateOfBirthForm
          dateOfBirth={dateOfBirth}
          setDateOfBirth={setDateOfBirth}
          handleDobSubmit={handleDobSubmit}
        />
      )}
    </div>
  );
};

export default DateOfBirthChangeReq;
