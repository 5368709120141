import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ProfileAction } from "../../store/actions";

import EditProfileBoxLabel from "../../components/label/editprofileboxlabel";
import EditProfileDropdown from "../../components/dropdown/editprofiledropdown";
import PrimaryButton from "../../components/button/primarybutton";
import Loader from "../../components/loader/loader";
import ErrorText from "../../components/errortext/errortext";

import RemoveEmptyKeys from "../../utils/removeemptykeys";

import {
  religiousHistory,
  religiousSect,
  polygamy,
  dailyPrayer,
  readQuran,
  readIslamicBooks,
  religiousCommitment,
} from "../../utils/arrayitems";

import Spacer from "../../components/spacer/spacer";
import images from "../../assets";

import TabHeading from "./tabheading";
import "./styles.css";

const ReligiousStatus = (props) => {
  const dispatch = useDispatch();
  const profileLoading = useSelector((state) => state?.profile?.isLoading);
  const profileData = useSelector((state) => state?.profile?.profile);

  const [isDisabled, setDisabled] = useState(false);
  const [state, setState] = useState({
    religiousHistory: "",
    religiousSect: "",
    polygamy: "",
    dailyPrayer: "",
    readQuran: "",
    readIslamicBook: "",
    religiousCommitment: "",
  });
  const [error, setError] = useState({
    religiousHistory: "",
    religiousSect: "",
    religiousCommitment: "",
  });

  useEffect(() => {
    setState({
      ...state,
      religiousHistory: profileData?.religiousHistory ?? "",
      religiousSect: profileData?.religiousSect ?? "",
      polygamy: profileData?.polygamy ?? "",
      dailyPrayer: profileData?.dailyPrayer ?? "",
      readQuran: profileData?.readQuran ?? "",
      readIslamicBook: profileData?.readIslamicBook ?? "",
      religiousCommitment: profileData?.religiousCommitment ?? "",
    });
  }, [profileLoading]);

  const handleSubmit = () => {
    if (!state?.religiousHistory) {
      setError({
        ...error,
        religiousHistory: "Religious history cannot be empty",
      });
    } else if (!state?.religiousSect) {
      setError({
        ...error,
        religiousSect: "Religious sect cannot be empty",
      });
    } else if (!state?.religiousCommitment) {
      setError({
        ...error,
        religiousCommitment: "Religious commitment level cannot be empty",
      });
    } else {
      setDisabled(true);
      const payload = state;
      dispatch(
        ProfileAction.updateProfile(payload, () => {
          setDisabled(false);
          dispatch(ProfileAction.getProfile());
          props?.handleTab("educationCareer");
        })
      );
    }
  };

  return (
    <>
      <TabHeading
        image={images.editProfileReligiousStatus}
        title="Religious Status"
        subtitle="Complete to easily find your ideal partner!"
      />
      <div className="edit-profile-content">
        {profileLoading ? (
          <Loader />
        ) : (
          <>
            {/* Religious History */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Religious History" required={true} />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={religiousHistory}
              onChange={(e) => {
                setState({ ...state, religiousHistory: e });
                setError({ ...error, religiousHistory: "" });
              }}
              value={state.religiousHistory || undefined}
            />
            {error?.religiousHistory && (
              <ErrorText text={error?.religiousHistory} />
            )}
            <Spacer height="12px" />

            {/* Religious Sect. */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Religious Sect." required={true} />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={religiousSect}
              onChange={(e) => {
                setState({ ...state, religiousSect: e });
                setError({ ...error, religiousSect: "" });
              }}
              value={state.religiousSect || undefined}
            />
            {error?.religiousSect && <ErrorText text={error?.religiousSect} />}
            <Spacer height="12px" />

            {/* Polygamy */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Polygamy" />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={polygamy}
              onChange={(e) => setState({ ...state, polygamy: e })}
              value={state.polygamy || undefined}
            />

            <Spacer height="12px" />

            {/* Daily Prayer */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Daily Prayer" />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={dailyPrayer}
              onChange={(e) => setState({ ...state, dailyPrayer: e })}
              value={state.dailyPrayer || undefined}
            />

            <Spacer height="12px" />

            {/* how often do you read Qur'an? */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="How often do you read Qur'an?" />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={readQuran}
              onChange={(e) => setState({ ...state, readQuran: e })}
              value={state.readQuran || undefined}
            />

            <Spacer height="12px" />

            {/* Do you read Islamic books, letures? */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Do you read Islamic books, letures?" />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={readIslamicBooks}
              onChange={(e) => setState({ ...state, readIslamicBook: e })}
              value={state.readIslamicBook || undefined}
            />

            <Spacer height="12px" />

            {/* Religious Commitment Level */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel
                label="Religious Commitment Level"
                required={true}
              />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={religiousCommitment}
              onChange={(e) => {
                setState({ ...state, religiousCommitment: e });
                setError({ ...error, religiousCommitment: "" });
              }}
              value={state.religiousCommitment || undefined}
            />
            {error?.religiousCommitment && (
              <ErrorText text={error?.religiousCommitment} />
            )}
          </>
        )}
        <Spacer height="32px" />
        <PrimaryButton
          text="Save & Continue"
          onClick={handleSubmit}
          disabled={isDisabled}
        />
      </div>
    </>
  );
};

export default ReligiousStatus;
