import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Empty } from "antd";

import {
  InterestAction,
  PhotoreqAction,
  ShortlistAction,
} from "../../store/actions";

import Cardloader from "../../components/loader/cardloader";
import DashboardCard from "../../components/dashboardcard/dashboardcard";
import CardBottomButton from "../../components/dashboardcard/cardbottombutton";
import { VioletButton, VioletButtonLarge } from "../../components/button";
import Carousel from "../../components/carousel/carousel";
import StartChattingProfile from "../../components/startchatting/startchattingprofile";

import useScreenSize from "../../utils/usescreensize";

import images from "../../assets";

import "./styles.css";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const screenSize = useScreenSize();

  const {
    recommendedMatches,
    isRecommendedLoading,
    matchOfTheDay,
    isModLoading,
  } = useSelector((state) => state.search);
  const { newMembers, isLoading } = useSelector((state) => state.newMembers);

  const [recommendedArray, setRecommendedArray] = useState([]);

  useEffect(() => {
    const array = [];
    if (matchOfTheDay && matchOfTheDay?.username) {
      array.push({ ...matchOfTheDay, matchOfTheDay: true });
    }
    if (recommendedMatches?.length > 0) {
      array.push(...recommendedMatches);
    }
    console.log(array);
    setRecommendedArray(array);
  }, [recommendedMatches, matchOfTheDay]);

  const handleSendInterest = (id) => {
    const payload = {
      receiverId: id,
    };
    dispatch(InterestAction.sendInterest(payload));
  };

  const handleShortlist = (id) => {
    const payload = {
      receiverId: id,
    };
    dispatch(ShortlistAction.sendShortlist(payload));
  };

  const handleSendPhotoReq = (id) => {
    const payload = {
      receiverId: id,
    };
    dispatch(PhotoreqAction.sendPhotoreq(payload));
  };
  return (
    <>
      <div className="recommended-container recommended-container-mobile">
        {/* {screenSize.width < 1024 && (
          <div className="start-chatting">
            <div className="chatting-heading">
              <h3>Start Chatting</h3>
            </div>
            <div className="chatting-profile-logo">
              <StartChattingProfile image={images.defaultMaleIcon} />
              <StartChattingProfile image={images.defaultFemaleIcon} />
              <StartChattingProfile image={images.defaultMaleIcon} />
              <StartChattingProfile image={images.defaultFemaleIcon} />
              <StartChattingProfile image={images.defaultMaleIcon} />
              <StartChattingProfile image={images.defaultFemaleIcon} />
              <StartChattingProfile image={images.defaultMaleIcon} />
              <StartChattingProfile image={images.defaultFemaleIcon} />
            </div>
          </div>
        )} */}
        <div className="header-container gap-1">
          <h1 className="section-heading">Recommended Matches</h1>
          <VioletButton
            onClick={() => navigate("/match-preference")}
            text="Improve Match"
          />
        </div>
        {isRecommendedLoading || isModLoading ? (
          <Cardloader />
        ) : (
          <>
            {recommendedArray?.length > 0 ? (
              <div className="recommended-matches-container">
                {recommendedArray?.map((item, index) => (
                  <DashboardCard
                    item={item}
                    index={index}
                    key={index}
                    redirectedFrom="recommendedSearch"
                  >
                    {item?.matchOfTheDay && (
                      <CardBottomButton
                        icon={images.cardButtonTick}
                        onClick={() => handleShortlist(item?.username)}
                        title="Shortlist"
                      />
                    )}
                    <CardBottomButton
                      icon={images.cardMessage}
                      onClick={() =>
                        navigate(
                          `/messages?username=${item?.username}&firstName=${item?.firstName}&lastName=${item?.lastName}&photo=${item?.profilePicture}`
                        )
                      }
                      title="Send Message"
                    />
                    <CardBottomButton
                      icon={images.cardPhotoReq}
                      onClick={() => handleSendPhotoReq(item?.username)}
                      title="Send Photo Request"
                    />
                    <CardBottomButton
                      icon={images.cardmatch}
                      onClick={() => handleSendInterest(item?.username)}
                      title="Send Interest"
                    />
                  </DashboardCard>
                ))}
              </div>
            ) : (
              <Empty description={<p>No Results found</p>} />
            )}
          </>
        )}
        <div className="view-all-button-container">
          <VioletButtonLarge
            onClick={() => navigate("/recommended-matches")}
            text="View All Matches"
          />
        </div>
      </div>
      {/* <div style={{ height: "32px" }}></div>
      <div className="recommended-container">
        <div className="header-container">
          <h1 className="section-heading">Your Profile Viewers</h1>
          <div>
            <VioletButton text="View All"/>
          </div>
        </div>
        <div>
          <Carousel data={dummyUsers} />
        </div>
      </div> */}
      <div style={{ height: "32px" }}></div>
      <div className="recommended-container">
        <div className="header-container">
          <h1 className="section-heading">New Members</h1>
          <div>
            <VioletButton
              text="View All"
              onClick={() => navigate("/new-members")}
            />
          </div>
        </div>
        {isLoading ? (
          <Cardloader />
        ) : (
          <div>
            {newMembers?.length > 0 ? (
              <Carousel data={newMembers} redirectedFrom="newMembers" />
            ) : (
              <Empty description={<p>No Results found</p>} />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Dashboard;
