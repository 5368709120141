import React, { useState } from "react";
import { Carousel } from "antd";

import images from "../../assets";

import Spacer from "../spacer/spacer";

export default function GalleryModal({ children, imagesarr, initialurl }) {
  const [url, setUrl] = useState(initialurl ?? "");

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={className}>
        <button onClick={onClick} className="photo-gallery-next-btn">
          <img
            style={{ transform: "rotate(180deg)" }}
            src={images.BlackArrowIcon}
            alt="arrow"
          />
        </button>
      </div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={className}>
        <button onClick={onClick} className="photo-gallery-prev-btn">
          <img src={images.BlackArrowIcon} alt="arrow" />
        </button>
      </div>
    );
  };

  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: (current) => {
      const currentImageUrl = imagesarr[current]?.url;
      setUrl(currentImageUrl);
    },
  };

  return (
    <div className="photo-modal-container">
      <div className="photo-modal-head">{children}</div>
      <Spacer height="24px" />

      <div className="photo-modal-img">
        <Carousel arrows {...settings}>
          {imagesarr?.map((item, index) => (
            <div className="photo-gallery-modal" key={index}>
              <img
                src={item.url}
                alt="glry"
                className="h-full w-full aspect-square object-cover"
              />
            </div>
          ))}
        </Carousel>
      </div>
      <Spacer height="24px" />
    </div>
  );
}
