import { useSelector } from "react-redux";
import images from "../../assets";
import "./styles.css";
import BasicInfoColumn from "./basicinfocolumn";

export default function FamilyDetailsCard() {
  const profileData = useSelector((state) => {
    return state?.profile?.profile;
  });
  return (
    <div className="family-details basic-info-card">
      <div className="basic-info-head">
        <img src={images.familyIcon} alt="Family Details" />{" "}
        <span>Family Details</span>
      </div>
      <div className="basic-info-content">
        {profileData?.fatherStatus && (
          <BasicInfoColumn
            titlename="Father’s Status"
            values={profileData?.fatherStatus}
          />
        )}
        {profileData?.motherStatus && (
          <BasicInfoColumn
            titlename="Mother’s Status"
            values={profileData?.motherStatus}
          />
        )}
        {profileData?.financialStatus && (
          <BasicInfoColumn
            titlename="Financial Status"
            values={profileData?.financialStatus}
          />
        )}
        {profileData?.familyLocation && (
          <BasicInfoColumn
            titlename="Family Location"
            values={profileData?.familyLocation}
          />
        )}
        {profileData?.fatherEthnicOrigin && (
          <BasicInfoColumn
            titlename="Father’s Ethnic Origin"
            values={profileData?.fatherEthnicOrigin}
          />
        )}
        {profileData?.motherEthnicOrigin && (
          <BasicInfoColumn
            titlename="Mother’s Ethnic Origin"
            values={profileData?.motherEthnicOrigin}
          />
        )}
        {profileData?.familyType && (
          <BasicInfoColumn
            titlename="Family Type"
            values={profileData?.familyType}
          />
        )}
        {profileData?.familyValues && (
          <BasicInfoColumn
            titlename="Family Values"
            values={profileData?.familyValues}
          />
        )}
        {profileData?.femaleChildren && (
          <BasicInfoColumn
            titlename="Male Sibling"
            values={profileData?.femaleChildren}
          />
        )}
        {profileData?.maleChildren && (
          <BasicInfoColumn
            titlename="Female Sibling"
            values={profileData?.maleChildren}
          />
        )}
      </div>
    </div>
  );
}
