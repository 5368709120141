import Navbar from "./navbar";
import styles from "./styles";
import images from "../../../assets";

const LoginLayout = ({ children }) => {
  return (
    <div style={styles.background}>
      <Navbar />

      <div className="w-full lg:h-screen h-full flex items-center justify-center relative">
        <video
          className="w-full h-full object-cover"
          autoPlay
          loop
          muted
          playsInline
          src="https://noble-marriage-public-bucket.s3.us-east-2.amazonaws.com/bgvideo.mp4"
        />
        <div
          className="absolute top-0 left-0 w-full h-full"
          style={{
            backgroundColor: "rgb(49,0,72,0.2)",
            backdropFilter: "blur(2px)",
            WebkitBackdropFilter: "blur(2px)",
          }}
        />
      </div>

      <div className="flex justify-center lg:justify-end p-10">
        <div className="absolute top-0 bottom-0 z-10 min-w-[300px] h-max lg:max-h-screen max-h-[90vh] overflow-y-auto lg:my-auto my-[80px] mx-4 lg:mx-0 px-6 lg:px-8 py-12 rounded-2xl bg-regCard backdrop-blur-md ">
          <a href={`${process.env.REACT_APP_LANDING_URL}/`}>
            <img
              src={images.closeIcon}
              style={styles.crossIcon}
              alt="crossIcon"
            />
          </a>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
