const familyType = [
  { label: "Nuclear", value: "Nuclear" },
  {
    label: "Joint (Living with parents)",
    value: "Joint (Living with parents)",
  },
  { label: "Parents Separated", value: "Parents Separated" },
  { label: "Parents Divorced", value: "Parents Divorced" },
  { label: "Other", value: "Other" },
];
export default familyType;
