import React, { useState, useEffect } from "react";
import { Country, State } from "country-state-city";
import { useSelector, useDispatch } from "react-redux";

import { ProfileAction } from "../../store/actions";

import EditProfileBoxLabel from "../../components/label/editprofileboxlabel";
import EditProfileCheckbox from "../../components/checkbox/editprofilecheckbox";
import EditProfileDropdown from "../../components/dropdown/editprofiledropdown";
import PrimaryButton from "../../components/button/primarybutton";
import ErrorText from "../../components/errortext/errortext";
import Spacer from "../../components/spacer/spacer";
import Loader from "../../components/loader/loader";

import images from "../../assets";

import {
  fatherStatus,
  motherStatus,
  financialStatus,
  ethnicOrigin,
  familyType,
  familyValue,
  siblingcount,
} from "../../utils/arrayitems";

import TabHeading from "./tabheading";
import "./styles.css";

const countries = Country.getAllCountries();

const FamilyDetails = (props) => {
  const dispatch = useDispatch();
  const profileLoading = useSelector((state) => state?.profile?.isLoading);
  const profileData = useSelector((state) => state?.profile?.profile);

  const [isDisabled, setDisabled] = useState(false);
  const [sameLocation, setSameLocation] = useState(false);
  const [states, setStates] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const [state, setState] = useState({
    fatherStatus: "",
    motherStatus: "",
    financialStatus: "",
    familyCountry: "",
    familyStateProvince: "",
    fatherEthnicOrigin: "",
    motherEthnicOrigin: "",
    maleSiblingMarried: 0,
    maleSiblingUnmarried: 0,
    femaleSiblingMarried: 0,
    femaleSiblingUnmarried: 0,
    maleChildren: 0,
    femaleChildren: 0,
    familyType: "",
    familyValues: "",
  });
  const [error, setError] = useState({
    familyLocation: "",
    fatherEthnicOrigin: "",
    motherEthnicOrigin: "",
    familyValues: "",
  });

  useEffect(() => {
    setStates(State.getStatesOfCountry(countryCode));
  }, [countryCode]);

  useEffect(() => {
    if (sameLocation) {
      setState({
        ...state,
        familyCountry: profileData?.country,
        familyStateProvince: profileData?.stateProvince,
      });
    }
    const countryCode = countries?.filter(
      (country) => country?.name === profileData?.country
    );
    setCountryCode(countryCode[0]?.isoCode);
  }, [sameLocation]);

  useEffect(() => {
    if (
      profileData?.country === state?.familyCountry &&
      profileData?.stateProvince === state?.familyStateProvince
    ) {
      setSameLocation(true);
    } else {
      setSameLocation(false);
    }
  }, [profileData, state?.familyCountry, state?.familyStateProvince]);

  useEffect(() => {
    setState({
      ...state,
      fatherStatus: profileData?.fatherStatus ?? "",
      motherStatus: profileData?.motherStatus ?? "",
      financialStatus: profileData?.financialStatus ?? "",
      familyCountry: profileData?.familyCountry ?? "",
      familyStateProvince: profileData?.familyStateProvince ?? "",
      fatherEthnicOrigin: profileData?.fatherEthnicOrigin ?? "",
      motherEthnicOrigin: profileData?.motherEthnicOrigin ?? "",
      maleSiblingMarried: profileData?.maleSiblingMarried ?? "",
      maleSiblingUnmarried: profileData?.maleSiblingUnmarried ?? "",
      femaleSiblingMarried: profileData?.femaleSiblingMarried ?? "",
      femaleSiblingUnmarried: profileData?.femaleSiblingUnmarried ?? "",
      maleChildren: profileData?.maleChildren ?? "",
      femaleChildren: profileData?.femaleChildren ?? "",
      familyType: profileData?.familyType ?? "",
      familyValues: profileData?.familyValues ?? "",
    });

    const countryCode = countries?.filter(
      (country) => country?.name === profileData?.country
    );
    setCountryCode(countryCode[0]?.isoCode);
  }, [profileLoading]);

  const handleSubmit = () => {
    if (!state?.familyCountry || !state?.familyStateProvince) {
      setError({
        ...error,
        familyLocation: "Family location cannot be empty",
      });
    } else if (!state?.fatherEthnicOrigin) {
      setError({
        ...error,
        fatherEthnicOrigin: "Father's ethnic origin cannot be empty",
      });
    } else if (!state?.motherEthnicOrigin) {
      setError({
        ...error,
        motherEthnicOrigin: "Mother's ethnic origin cannot be empty",
      });
    } else if (!state?.familyValues) {
      setError({
        ...error,
        familyValues: "Family values cannot be empty",
      });
    } else {
      setDisabled(true);
      const payload = state;

      dispatch(
        ProfileAction.updateProfile(payload, () => {
          setDisabled(false);
          dispatch(ProfileAction.getProfile());
          props?.handleTab("religiousStatus");
        })
      );
    }
  };

  return (
    <>
      <TabHeading
        image={images.editProfileFamilyDetails}
        title="Family Details"
        subtitle="Complete to easily find your ideal partner!"
      />
      <div className="edit-profile-content">
        {profileLoading ? (
          <Loader />
        ) : (
          <>
            {/* father status */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Father's Status" />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={fatherStatus}
              onChange={(e) => setState({ ...state, fatherStatus: e })}
              value={state.fatherStatus || undefined}
            />

            <Spacer height="12px" />

            {/* Mother status */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Mother's Status" />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={motherStatus}
              onChange={(e) => setState({ ...state, motherStatus: e })}
              value={state.motherStatus || undefined}
            />

            <Spacer height="12px" />

            {/* Financial status */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Financial Status" />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={financialStatus}
              onChange={(e) => setState({ ...state, financialStatus: e })}
              value={state.financialStatus || undefined}
            />

            <Spacer height="12px" />

            {/* family location */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Family Location" required={true} />
            </div>
            <Spacer height="8px" />
            <div className="edit-profile-name-container">
              <div className="edit-profile-name">
                <EditProfileDropdown
                  placeholder="Please select"
                  data={countries}
                  list="country"
                  onChange={(e) => {
                    const country = JSON.parse(e);
                    setCountryCode(country.isoCode);
                    setState({
                      ...state,
                      familyCountry: country.name,
                      familyStateProvince: "",
                    });
                    setError({ ...error, familyLocation: "" });
                  }}
                  value={state.familyCountry || undefined}
                />
              </div>
              <div className="edit-profile-name">
                <EditProfileDropdown
                  placeholder="Please select"
                  data={states}
                  list="stateProvince"
                  onChange={(e) => {
                    setState({ ...state, familyStateProvince: e });
                    setError({ ...error, familyLocation: "" });
                  }}
                  value={state.familyStateProvince || undefined}
                />
              </div>
            </div>
            {error?.familyLocation && (
              <ErrorText text={error?.familyLocation} />
            )}

            <Spacer height="8px" />
            <EditProfileCheckbox
              text="Same as me"
              onChange={() => setSameLocation(!sameLocation)}
              checked={sameLocation}
            />

            <Spacer height="12px" />

            {/* Father's Ethnic Origin */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel
                label="Father's Ethnic Origin"
                required={true}
              />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={ethnicOrigin}
              onChange={(e) => {
                setState({ ...state, fatherEthnicOrigin: e });
                setError({ ...error, fatherEthnicOrigin: "" });
              }}
              value={state.fatherEthnicOrigin || undefined}
            />
            {error?.fatherEthnicOrigin && (
              <ErrorText text={error?.fatherEthnicOrigin} />
            )}
            <Spacer height="12px" />

            {/* Mother's Ethnic Origin */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel
                label="Mother's Ethnic Origin"
                required={true}
              />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={ethnicOrigin}
              onChange={(e) => {
                setState({ ...state, motherEthnicOrigin: e });
                setError({ ...error, motherEthnicOrigin: "" });
              }}
              value={state.motherEthnicOrigin || undefined}
            />
            {error?.motherEthnicOrigin && (
              <ErrorText text={error?.motherEthnicOrigin} />
            )}
            <Spacer height="12px" />

            {/* No of siblings */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="No. of Siblings" />
            </div>
            <Spacer height="8px" />
            <div className="edit-profile-siblings-container">
              <div className="edit-profile-siblings">
                <div className="edit-profile-siblings-left-male">
                  <img src={images.editProfileMale} alt="" />
                </div>
                <div className="edit-profile-siblings-right">
                  <div className="edit-profile-siblings-box-title">
                    <EditProfileDropdown
                      data={siblingcount()}
                      onChange={(e) =>
                        setState({ ...state, maleSiblingMarried: e })
                      }
                      value={state.maleSiblingMarried || undefined}
                    />
                    <p className="married-text">Married</p>
                  </div>
                  <div className="edit-profile-siblings-box-title">
                    <EditProfileDropdown
                      data={siblingcount()}
                      onChange={(e) =>
                        setState({ ...state, maleSiblingUnmarried: e })
                      }
                      value={state.maleSiblingUnmarried || undefined}
                    />
                    <p className="married-text">Unmarried</p>
                  </div>
                </div>
              </div>
              <div className="edit-profile-siblings">
                <div className="edit-profile-siblings-left-female">
                  <img src={images.editProfileFemale} alt="" />
                </div>
                <div className="edit-profile-siblings-right">
                  <div className="edit-profile-siblings-box-title">
                    <EditProfileDropdown
                      data={siblingcount()}
                      onChange={(e) =>
                        setState({ ...state, femaleSiblingMarried: e })
                      }
                      value={state.femaleSiblingMarried || undefined}
                    />
                    <p className="married-text">Married</p>
                  </div>
                  <div className="edit-profile-siblings-box-title">
                    <EditProfileDropdown
                      data={siblingcount()}
                      onChange={(e) =>
                        setState({ ...state, femaleSiblingUnmarried: e })
                      }
                      value={state.femaleSiblingUnmarried || undefined}
                    />
                    <p className="married-text">Unmarried</p>
                  </div>
                </div>
              </div>
            </div>

            <Spacer height="12px" />

            {/* No of Kids */}
            {profileData?.maritalStatus !== "neverMarried" && (
              <>
                <div className="edit-profile-label-container">
                  <EditProfileBoxLabel label="No. of Kids" required={true} />
                </div>
                <Spacer height="8px" />
                <div className="edit-profile-kids-container">
                  <div style={{ width: "32%" }} className="edit-profile-kids">
                    <div className="edit-profile-siblings-left-male">
                      <img src={images.editProfileMale} alt="" />
                    </div>
                    <div style={{ width: "100%" }}>
                      <EditProfileDropdown
                        data={siblingcount()}
                        onChange={(e) =>
                          setState({ ...state, maleChildren: e })
                        }
                        value={state.maleChildren || undefined}
                        disabled={
                          profileData?.maritalStatus === "neverMarried"
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>
                  <div style={{ width: "32%" }} className="edit-profile-kids">
                    <div className="edit-profile-siblings-left-female">
                      <img src={images.editProfileFemale} alt="" />
                    </div>
                    <div style={{ width: "100%" }}>
                      <EditProfileDropdown
                        data={siblingcount()}
                        onChange={(e) =>
                          setState({ ...state, femaleChildren: e })
                        }
                        value={state.femaleChildren || undefined}
                        disabled={
                          profileData?.maritalStatus === "neverMarried"
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <Spacer height="12px" />

            {/* Family Type */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Family Type" />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={familyType}
              onChange={(e) => setState({ ...state, familyType: e })}
              value={state.familyType || undefined}
            />

            <Spacer height="12px" />

            {/* Family Values */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Family Values" required={true} />
            </div>
            <Spacer height="8px" />
            <EditProfileDropdown
              placeholder="Please select"
              data={familyValue}
              onChange={(e) => {
                setState({ ...state, familyValues: e });
                setError({ ...error, familyValues: "" });
              }}
              value={state.familyValues || undefined}
            />
            {error?.familyValues && <ErrorText text={error?.familyValues} />}
          </>
        )}
        <Spacer height="32px" />
        <PrimaryButton
          text="Save & Continue"
          onClick={handleSubmit}
          disabled={isDisabled}
        />
      </div>
    </>
  );
};

export default FamilyDetails;
