import React from "react";
import "./styles.css";

const EditProfileInput = (props) => {
  return (
    <div className="edit-profile-input">
      <input
        {...props}
        value={props?.value ?? ""}
        style={{
          width: "100%",
          outline: "none",
          background: "transparent",
          height: "20px",
          opacity: props?.disabled ? "0.5" : "1",
          cursor: props?.disabled ? "not-allowed" : "",
        }}
      />
    </div>
  );
};

export default EditProfileInput;
