import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";

import { ProfileAction } from "../../store/actions";

import images from "../../assets";
import Spacer from "../spacer/spacer";

import { PrimaryButton, WhiteButton } from "../button";

export default function PhotoCard({ children, item }) {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => {
    return state?.profile?.profile;
  });

  const [isOpen, setOpen] = useState(false);

  const handleDelete = () => {
    const payload = {
      image: item?.url,
    };

    dispatch(
      ProfileAction.deletePhoto(payload, () => {
        dispatch(ProfileAction.getProfile());
      })
    );
  };
  return (
    <div className="main-photo-galley">
      <div className="photo-galley-img">{children}</div>
      <Spacer height="12px" />
      <div className="photo-gallery-footer-action">
        <div className="photo-gallery-status">
          <p>Status:</p>
          {item?.status === "active" ? (
            <div className="approved-button-style">
              <img src={images.approvedIcon} alt="change" />
              <p style={{ marginBottom: "0px" }}>Approved</p>
            </div>
          ) : (
            <div
              style={{ background: "#FEFCE8" }}
              className="approved-button-style"
            >
              <img src={images.pendingIcon} alt="change" />
              <p style={{ marginBottom: "0px", color: "#F5A524" }}>Pending</p>
            </div>
          )}
        </div>
        {profileData?.profilePicture !== item?.url && (
          <div className="photo-gallery-delete">
            <img
              onClick={() => setOpen(true)}
              src={images.deleteBtnIcon}
              alt="Delete"
            />
          </div>
        )}
      </div>

      <Modal
        centered={true}
        open={isOpen}
        onCancel={() => setOpen(false)}
        footer={false}
        closable={true}
      >
        <div className="gap-4 set-profile-photo-content">
          <div className="set-profile-photo-icon">
            <img
              style={{ maxWidth: "40px" }}
              src={images?.deleteIcon2}
              alt=""
            />
          </div>
          <div className="set-profile-photo-heading">
            <h2>Are you sure you want to delete this photo?</h2>
            <p>
              This action cannot be undone. Deleting this photo will permanently
              remove it from your profile.
            </p>
          </div>
        </div>
        <Spacer height="16px" />
        <div className="flex w-full justify-end">
          <div className="flex gap-4 set-profile-photo-btn">
            <WhiteButton text="Skip" onClick={() => setOpen(false)} />
            <PrimaryButton
              text="Delete"
              onClick={handleDelete}
              style={{ background: "#F31260" }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
