import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ProfileAction } from "../../store/actions";

import Spacer from "../../components/spacer/spacer";
import EditProfileBoxLabel from "../../components/label/editprofileboxlabel";
import { PrimaryButton } from "../../components/button";
import FileUploaderWithoutCrop from "../../components/imageuploader/fileuploaderwithoutcrop.js";
import EditProfileDropdown from "../../components/dropdown/editprofiledropdown";
import ErrorText from "../../components/errortext/errortext";

import { documentType } from "../../utils/arrayitems";

export default function DocumentUpload() {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state?.profile?.profile);

  const [state, setState] = useState({
    documentType: "",
    fileList: [],
  });
  const [error, setError] = useState({
    documentType: "",
    fileList: "",
  });

  const handleSubmit = () => {
    if (!state?.documentType) {
      setError({ ...error, documentType: "Select a document type" });
    } else if (state?.fileList?.length === 0) {
      setError({ ...error, fileList: "Please select a file" });
    } else {
      const payload = new FormData();

      payload.append("username", profileData?.username);
      payload.append("documentType", state?.documentType);
      payload.append("fileData", state?.fileList[0]?.originFileObj);
      // state?.fileList?.map((item) => {
      //   payload.append("fileData", item?.originFileObj);
      // });
      dispatch(
        ProfileAction?.uploadTrustBadge(payload, (res) => {
          dispatch(ProfileAction?.getProfile());
        })
      );
    }
  };
  return (
    <>
      {/* Document Type */}
      <div className="edit-profile-label-container">
        <EditProfileBoxLabel label="Document Type" />
      </div>
      <Spacer height="8px" />
      <EditProfileDropdown
        placeholder="Document Type"
        data={documentType}
        onChange={(e) => setState({ ...state, documentType: e })}
        value={state.documentType || undefined}
      />
      {error?.documentType && (
        <>
          <Spacer height="8px" />
          <ErrorText text={error?.documentType} />
        </>
      )}
      <Spacer height="32px" />
      <div className="trustbadge-document">
        <FileUploaderWithoutCrop
          setState={setState}
          state={state}
          formats=".jpg,.png,.pdf"
        />
      </div>
      {error?.fileList && (
        <>
          <Spacer height="8px" />
          <ErrorText text={error?.fileList} />
        </>
      )}
      {state?.fileList?.length > 0 && (
        <>
          <Spacer height="32px" />
          <PrimaryButton text="Save" onClick={handleSubmit} />
        </>
      )}
    </>
  );
}
