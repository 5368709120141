import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Input, message } from "antd";

import { ProfileAction } from "../../store/actions";

import EditProfileBoxLabel from "../../components/label/editprofileboxlabel";
import PrimaryButton from "../../components/button/primarybutton";
import ApprovedButton from "../../components/button/approvedbutton";
import Loader from "../../components/loader/loader";
import Spacer from "../../components/spacer/spacer";

import validateDescription from "../../utils/validate-description";

import images from "../../assets";

import TabHeading from "./tabheading";

const { TextArea } = Input;

const ProfileDescription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profileLoading = useSelector((state) => state?.profile?.isLoading);
  const profileData = useSelector((state) => state?.profile?.profile);
  const [profileDescription, setProfileDescription] = useState("");
  const [isDisabled, setDisabled] = useState(false);

  useEffect(() => {
    if (profileData) {
      setProfileDescription(profileData.profileDescription || "");
    }
  }, [profileData]);

  const handleSubmit = useCallback(() => {
    if (!validateDescription(profileDescription)) {
      message.error(
        "Invalid input. Please avoid phone numbers, email formats, addresses, and numeric patterns."
      );
      return;
    }

    setDisabled(true);
    const payload = { profileDescription: profileDescription.trim() };

    dispatch(
      ProfileAction.updateProfile(payload, () => {
        setDisabled(false);
        dispatch(ProfileAction.getProfile());
        navigate("/match-preference");
      })
    );
  }, [dispatch, profileDescription]);

  return (
    <>
      <TabHeading
        image={images.editProfileDescription}
        title="Profile Description"
        subtitle="Complete to easily find your ideal partner!"
      />
      <div className="edit-profile-content">
        {profileLoading ? (
          <Loader />
        ) : (
          <>
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Write a Short Description About Yourself" />
              {profileData?.profileDescriptionStatus === "approved" ||
              profileData?.profileDescriptionStatus === "true" ? (
                <ApprovedButton />
              ) : profileData?.profileDescriptionStatus === "pending" ||
                profileData?.profileDescriptionStatus === "false" ? (
                <div
                  style={{ background: "#FEFCE8" }}
                  className="approved-button-style"
                >
                  <img src={images.pendingIcon} alt="change" />
                  <p style={{ marginBottom: "0px", color: "#F5A524" }}>
                    Pending
                  </p>
                </div>
              ) : null}
            </div>
            <Spacer height="8px" />
            <div className="edit-profile-input match-padding">
              <TextArea
                className="w-full outline-none bg-transparent"
                showCount
                maxLength={1000}
                rows={10}
                onChange={(e) => setProfileDescription(e.target.value)}
                placeholder="Write a Short Description About Yourself"
                value={profileDescription}
              />
            </div>
            <Spacer height="8px" />
          </>
        )}
        <Spacer height="32px" />
        <PrimaryButton
          text="Save & Continue"
          onClick={handleSubmit}
          disabled={isDisabled}
        />
      </div>
    </>
  );
};

export default ProfileDescription;
