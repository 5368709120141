import React from "react";

const EditProfileBoxLabel = (props) => {
  return (
    <div style={{ display: "flex" }}>
      <span style={{ color: "#222", fontSize: "14px", fontWeight: "400" }}>
        {props.label}
      </span>
      {props.required && (
        <span style={{ color: "#F31260", fontSize: "14px", fontWeight: "400" }}>
          *
        </span>
      )}
    </div>
  );
};

export default EditProfileBoxLabel;
