const bloodGroup = [
  { label: "A+", value: "A+" },
  { label: "O+", value: "O+" },
  { label: "B+", value: "B+" },
  { label: "AB+", value: "AB+" },
  { label: "A-", value: "A-" },
  { label: "O-", value: "O-" },
  { label: "B-", value: "B-" },
  { label: "AB-", value: "AB-" },
  { label: "Not Known", value: "Not Known" },
];
export default bloodGroup;
