import React from "react";
import { Select } from "antd";
import "./styles.css";

const EditProfileMultipleSelect = (props) => {
  return (
    <div className="edit-profile-input">
      <Select
        {...props}
        suffixIcon={null}
        mode="multiple"
        placeholder="Please select"
        className="edit-profile-select-multiple"
        filterOption={(input, option) =>
          (option?.children ?? "")
            .toString()
            .toLowerCase()
            .startsWith(input.toLowerCase())
        }
      >
        {props?.data?.map((item, index) => {
          return (
            <Select.Option key={index} value={item.value}>
              {item.label}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};

export default EditProfileMultipleSelect;
