import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Carousel, Modal } from "antd";

import { ProfileAction } from "../../store/actions";

import { PrimaryButton, WhiteButton } from "../button";
import Spacer from "../spacer/spacer";

import images from "../../assets";
export default function PhotoModal({
  children,
  imagesarr,
  initialurl,
  initialStatus,
}) {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => {
    return state?.profile?.profile;
  });

  const [url, setUrl] = useState(initialurl ?? "");
  const [status, setStatus] = useState(initialurl ?? "");

  const [isOpen, setOpen] = useState(false);

  const handleProfilePhoto = () => {
    const payload = {
      profilePicture: url,
    };
    dispatch(
      ProfileAction.updateProfilePic(payload, () => {
        dispatch(ProfileAction.getProfile());
      })
    );
  };
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={className}>
        <button onClick={onClick} className="photo-gallery-next-btn">
          <img
            style={{ transform: "rotate(180deg)" }}
            src={images.BlackArrowIcon}
            alt="arrow"
          />
        </button>
      </div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={className}>
        <button onClick={onClick} className="photo-gallery-prev-btn">
          <img src={images.BlackArrowIcon} alt="arrow" />
        </button>
      </div>
    );
  };

  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: (current) => {
      const currentImageUrl = imagesarr[current]?.url;
      const currentImageStatus = imagesarr[current]?.status;
      setUrl(currentImageUrl);
      setStatus(currentImageStatus);
    },
  };

  console.log(status);
  return (
    <div>
      <div className="photo-modal-head">{children}</div>
      <Spacer height="24px" />

      <div className="photo-modal-img">
        <Carousel arrows {...settings}>
          {imagesarr?.map((item, index) => (
            <div className="photo-gallery-modal" key={index}>
              <img
                src={item.url}
                alt="glry"
                className="h-full w-full aspect-square object-cover"
              />
            </div>
          ))}
        </Carousel>
      </div>

      <Spacer height="24px" />
      <div className="photo-modal-btn">
        <PrimaryButton
          text="Use this as a Profile Photo"
          iconalign="left"
          style={{
            paddingLeft: "12px",
            paddingRight: "12px",
            height: "32px",
            borderRadius: "8px",
            fontSize: "14px",
            fontWeight: "500",
          }}
          onClick={() => setOpen(true)}
          disabled={profileData?.profilePicture === url || status !== "active"}
        />
      </div>

      <Modal
        centered={true}
        open={isOpen}
        onCancel={() => setOpen(false)}
        footer={false}
        closable={true}
      >
        <div className="gap-4 set-profile-photo-content">
          <div className="set-profile-photo-icon">
            <img
              style={{ maxWidth: "40px", borderRadius: "100%" }}
              src={url}
              alt=""
            />
          </div>
          <div className="set-profile-photo-heading">
            <h2>
              Are you sure you want to set this photo as your profile picture?
            </h2>
            <p>
              This will update your profile picture with the selected photo.
            </p>
          </div>
        </div>
        <Spacer height="16px" />
        <div className="flex w-full justify-end">
          <div className="flex gap-4 set-profile-photo-btn">
            <WhiteButton text="Skip" onClick={() => setOpen(false)} />
            <PrimaryButton
              text="Set Profile Photo"
              onClick={handleProfilePhoto}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
