import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { City, Country, State } from "country-state-city";

import { SearchAction } from "../../../../store/actions";
import useScreenSize from "../../../../utils/usescreensize";
import RemoveEmptyKeys from "../../../../utils/removeemptykeys";
import EditProfileBoxLabel from "../../../label/editprofileboxlabel";
import EditProfileMultipleSelect from "../../../dropdown/editprofilemultipleselection";
import BasicSearchMultipleSelectCountry from "../../../dropdown/basicsearchmultipleselectcountry";
import CheckboxGroup from "../../../checkbox/checkboxgroup";

import PrimaryButton from "../../../button/primarybutton";
import Spacer from "../../../spacer/spacer";

import {
  maritalStatusMale,
  maritalStatusFemale,
  spokenLanguages,
} from "../../../../utils/arrayitems";

import images from "../../../../assets";
import "../styles.css";
import UnderHeaderMenu from "../../../underheadermenu";

const typeofProfile = [
  { label: "Doesn't matter", value: "Doesn't matter" },
  { label: "Verified", value: "Verified" },
  { label: "Premium", value: "Premium" },
  { label: "Basic", value: "Basic" },
];
const activityStatus = [
  { label: "All", value: "All" },
  { label: "Online", value: "Online" },
  { label: "Offline", value: "Offline" },
];
const countries = Country.getAllCountries();
// const states = State.getAllStates();
// const cities = City.getAllCities();

const BasicSearchItems = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const screenSize = useScreenSize();

  const { searchPayload } = useSelector((state) => state.search);
  const gender = useSelector((state) => state.profile?.profile?.gender);

  const [countryCode, setCountryCode] = useState([]);
  const [states, setStates] = useState([]);
  const [state, setState] = useState({
    country: [],
    stateProvince: [],
    maritalStatus: [],
    spokenLanguage: [],
    typeofProfile: [],
    activityStatus: [],
  });
  const handleClear = () => {
    setState({
      country: [],
      stateProvince: [],
      maritalStatus: [],
      spokenLanguage: [],
      typeofProfile: [],
      activityStatus: [],
    });
  };
  useEffect(() => {
    if (!state?.country) return;
    const countryCodes = state.country.flatMap((country) =>
      countries
        .filter((item) => item?.name === country)
        .map((item) => item?.isoCode)
    );
    setCountryCode(countryCodes);
  }, [state?.country, countries]);

  useEffect(() => {
    if (!countryCode) return;
    const stateProvince = countryCode.flatMap((countryCode) => {
      const states = State.getStatesOfCountry(countryCode);
      return states ? states.map((state) => state) : [];
    });
    setStates(stateProvince);
  }, [countryCode]);

  const handleSearch = () => {
    const payload = {
      ...searchPayload,
      searchData: RemoveEmptyKeys(state),
      from: 0,
      gender,
    };
    screenSize.width < 1023 && props?.setOpen(false);
    dispatch(SearchAction.searchPayload(payload));
  };

  return (
    <div className={"sidebar-full sidebar"}>
      {screenSize.width < 1023 && (
        <UnderHeaderMenu {...props} titlename="Standard Search">
          <p className="cursor-pointer" onClick={handleClear}>
            Clear
          </p>
        </UnderHeaderMenu>
      )}
      {screenSize.width > 1023 && (
        <div className="sidebar-logo-container-basic">
          <img
            src={images.mainLogo}
            alt="logo"
            onClick={() => navigate("/dashboard")}
            style={{ cursor: "pointer" }}
          />
        </div>
      )}
      <div
        style={{
          height: "90vh",
          overflowY: "auto",
          overflowX: "hidden",
          width: "100%",
        }}
      >
        {screenSize.width > 1023 && (
          <>
            <PrimaryButton
              text={"Menu"}
              icon={images.arrowIconLeft}
              iconalign="left"
              style={{ width: "120px" }}
              onClick={() => props?.setBasicSearchMenu(false)}
            />
            <Spacer height="20px" />
            <h1 className="sidebar-basic-search-title">Standard Search</h1>
          </>
        )}
        <Spacer height="16px" />
        {/* country */}
        <div className="edit-profile-label-container">
          <EditProfileBoxLabel label="Country" />
        </div>
        <Spacer height="8px" />
        <BasicSearchMultipleSelectCountry
          placeholder="Country"
          data={countries}
          onChange={(e) => {
            setState({ ...state, country: e });
          }}
          value={state.country || undefined}
        />
        <Spacer height="12px" />
        {/* state */}
        <div className="edit-profile-label-container">
          <EditProfileBoxLabel label="State / Province" />
        </div>
        <Spacer height="8px" />
        <BasicSearchMultipleSelectCountry
          placeholder="States"
          data={states}
          onChange={(e) => {
            setState({ ...state, stateProvince: e });
          }}
          value={state.stateProvince || undefined}
        />
        <Spacer height="12px" />

        {/* Marital Status */}
        <div className="edit-profile-label-container">
          <EditProfileBoxLabel label="Marital Status" />
        </div>
        <Spacer height="8px" />
        <EditProfileMultipleSelect
          placeholder="Marital Status"
          data={gender === "male" ? maritalStatusFemale : maritalStatusMale}
          onChange={(e) => {
            setState({ ...state, maritalStatus: e });
          }}
          value={state.maritalStatus || undefined}
        />
        <Spacer height="12px" />

        {/* Spoken Language */}
        <div className="edit-profile-label-container">
          <EditProfileBoxLabel label="Spoken Language" />
        </div>
        <Spacer height="8px" />
        <EditProfileMultipleSelect
          placeholder="Spoken Language"
          data={spokenLanguages}
          onChange={(e) => {
            setState({ ...state, spokenLanguage: e });
          }}
          value={state.spokenLanguage || undefined}
        />
        <Spacer height="12px" />

        {/* checkbox part container */}
        <div className="basic-search-sidebar-checkbox">
          <div>
            {/*typeofprofile */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Type of Profile" />
            </div>
            <Spacer height="8px" />
            <CheckboxGroup
              items={typeofProfile}
              value={state.typeofProfile || undefined}
              onChange={(e) => setState({ ...state, typeofProfile: e })}
            />
          </div>

          <div>
            {/*activity status */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="Activity Status" />
            </div>
            <Spacer height="8px" />
            <CheckboxGroup
              items={activityStatus}
              value={state.activityStatus || undefined}
              onChange={(e) => setState({ ...state, activityStatus: e })}
            />
          </div>
        </div>
        <Spacer height="32px" />
        <PrimaryButton text={"Search"} onClick={handleSearch} />
        <Spacer height="100px" />
        {screenSize.width > 1023 && (
          <p
            style={{
              fontSize: "14px",
              fontWeight: "400",
              color: "#8993A4",
              textAlign: "center",
            }}
          >
            Or use{" "}
            <span
              className="purple-text"
              onClick={() => navigate("/advanced-search")}
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#BA0060",
                textDecoration: "underline",
              }}
            >
              Advanced Search
            </span>
          </p>
        )}
      </div>
    </div>
  );
};

export default BasicSearchItems;
