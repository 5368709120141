import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, Empty } from "antd";

import {
  SearchAction,
  InterestAction,
  PhotoreqAction,
} from "../../store/actions";

import DashboardCard from "../../components/dashboardcard/dashboardcard";
import CardBottomButton from "../../components/dashboardcard/cardbottombutton";
import EditProfileDropdown from "../../components/dropdown/editprofiledropdown";
import Cardloader from "../../components/loader/cardloader";
import Spacer from "../../components/spacer/spacer";
import UnderHeaderMenu from "../../components/underheadermenu";
import DashboardMobileCard from "../../components/dashboardcard/dashboardmobilecard";

import useScreenSize from "../../utils/usescreensize";
import images from "../../assets";

import "./styles.css";

const SearchResults = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const screenSize = useScreenSize();

  const { quickMatches, isQuickLoading, quickCount, quickSearchPayload } =
    useSelector((state) => state.search);
  const gender = useSelector((state) => state.profile?.profile?.gender);

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortOption, setSortOption] = useState(null);
  const [matchedUsers, setMatchedUsers] = useState([]);

  useEffect(() => {
    setMatchedUsers(quickMatches);
  }, [quickMatches]);

  useEffect(() => {
    if (quickSearchPayload?.searchtext?.length > 2) {
      const payload = {
        searchtext: "",
        from: pageNo - 1,
        size: pageSize,
        sort: sortOption,
        gender,
      };
      dispatch(SearchAction.quickSearchPayload(payload));
    }
  }, [sortOption]);

  useEffect(() => {
    if (quickSearchPayload?.searchtext?.length > 2) {
      const payload = {
        ...quickSearchPayload,
        from: 0,
        size: pageSize,
        sort: sortOption,
      };
      setPageNo(1);

      if (gender) {
        dispatch(SearchAction.quickSearch(payload));
      }
    }
  }, [quickSearchPayload]);

  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
    const payload = {
      ...quickSearchPayload,
      from: (pageNumber - 1) * pageSize,
      size: pageSize,
      sort: sortOption,
    };

    if (gender) {
      dispatch(SearchAction.quickSearch(payload), () => {
        setPageNo(1);
      });
    }
  };

  const handleSendInterest = (id) => {
    const payload = {
      receiverId: id,
    };
    if (gender) {
      dispatch(InterestAction.sendInterest(payload));
    }
  };

  const handleSendPhotoReq = (id) => {
    const payload = {
      receiverId: id,
    };
    if (gender) {
      dispatch(PhotoreqAction.sendPhotoreq(payload));
    }
  };

  return (
    <>
      {screenSize.width < 768 && (
        <UnderHeaderMenu>
          <div style={{ width: "150px" }}>
            <EditProfileDropdown
              data={[
                { label: "Newest First", value: "newest" },
                { label: "Oldest First", value: "oldest" },
              ]}
              onChange={(e) => setSortOption(e)}
              value={sortOption}
              placeholder="Sort by"
            />
          </div>
        </UnderHeaderMenu>
      )}
      <div className="recommended-container">
        <div className="header-container standard-search">
          <h1 className="section-heading">Search Results</h1>
          {screenSize.width > 768 && (
            <div style={{ width: "150px" }}>
              <EditProfileDropdown
                data={[
                  { label: "Newest First", value: "newest" },
                  { label: "Oldest First", value: "oldest" },
                ]}
                onChange={(e) => setSortOption(e)}
                value={sortOption}
                placeholder="Sort by"
              />
            </div>
          )}
        </div>

        {isQuickLoading ? (
          <Cardloader />
        ) : (
          <>
            {matchedUsers?.length > 0 ? (
              <>
                {screenSize.width < 768 ? (
                  <>
                    {matchedUsers?.map((item, index) => (
                      <DashboardMobileCard
                        item={item}
                        index={index}
                        key={index}
                      />
                    ))}
                  </>
                ) : (
                  <div className="recommended-matches-container">
                    {matchedUsers?.map((item, index) => (
                      <DashboardCard
                        item={item}
                        index={index}
                        key={index}
                        redirectedFrom="quickSearch"
                      >
                        {item?.matchOfTheDay && (
                          <CardBottomButton icon={images.cardButtonTick} />
                        )}
                        <CardBottomButton
                          icon={images.cardMessage}
                          onClick={() =>
                            navigate(
                              `/messages?username=${item?.username}&firstName=${item?.firstName}&lastName=${item?.lastName}&photo=${item?.profilePicture}`
                            )
                          }
                          title="Send Message"
                        />
                        <CardBottomButton
                          icon={images.cardPhotoReq}
                          onClick={() => handleSendPhotoReq(item?.username)}
                          title="Send Photo Request"
                        />
                        <CardBottomButton
                          icon={images.cardmatch}
                          onClick={() => handleSendInterest(item?.username)}
                          title="Send Interest"
                        />
                      </DashboardCard>
                    ))}
                  </div>
                )}
                <Spacer height="36px" />
                <div className="flex justify-center items-center">
                  <Pagination
                    current={pageNo}
                    total={quickCount}
                    onChange={handlePageChange}
                    defaultPageSize={pageSize}
                    showSizeChanger={false}
                  />
                </div>
                <Spacer height="24px" />
              </>
            ) : (
              <Empty description={<p>No Results found</p>} />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default SearchResults;
