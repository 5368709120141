import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, Modal, Empty } from "antd";
import moment from "moment";

import { BlocklistAction } from "../../store/actions";

import DashboardCard from "../../components/dashboardcard/dashboardcard";
import Cardloader from "../../components/loader/cardloader";
import Spacer from "../../components/spacer/spacer";
import UnderHeaderMenu from "../../components/underheadermenu";
import { PrimaryButton, WhiteButton } from "../../components/button";

import useScreenSize from "../../utils/usescreensize";
import SortDropdown from "../../components/dropdown/sortdropdown";

import images from "../../assets";
import "./styles.css";

const Blocklist = () => {
  const dispatch = useDispatch();
  const screenSize = useScreenSize();

  const { blocklist, isLoading, blocklistCount } = useSelector(
    (state) => state?.blocklist
  );

  const [state, setState] = useState({
    sortOption: "",
  });
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [isOpen, setOpen] = useState(false);
  const [username, setUsername] = useState("");

  useEffect(() => {
    getBocklist();
  }, [state]);

  const getBocklist = () => {
    const payload = {
      from: 0,
      size: pageSize,
      sort: state?.sortOption,
    };
    setPageNo(1);
    dispatch(BlocklistAction.getBlocklist(payload));
  };
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
    const payload = {
      from: (pageNumber - 1) * pageSize,
      size: pageSize,
      sort: state?.sortOption,
    };
    dispatch(BlocklistAction.getBlocklist(payload));
  };

  const handleUnblock = () => {
    setOpen(false);
    const payload = { blockedId: username };
    dispatch(
      BlocklistAction.deleteBlocklist(payload, () => {
        getBocklist();
      })
    );
  };
  return (
    <div>
      {screenSize.width < 1023 && (
        <UnderHeaderMenu titlename="My Blocklists">
          <div style={{ width: "110px" }}>
            <SortDropdown
              data={[
                { label: "Newest First", value: "newest" },
                { label: "Oldest First", value: "oldest" },
              ]}
              onChange={(e) => setState({ ...state, sortOption: e })}
              value={state?.sortOption || undefined}
              placeholder="Sort by"
            />
          </div>
        </UnderHeaderMenu>
      )}
      <Spacer height="16px" />
      <div className="blocklist-section-top-part-container">
        <div></div>
        {screenSize.width > 1023 && (
          <div className="blocklist-section-header-container">
            <h1 className="blocklist-header">My Blocklists</h1>
          </div>
        )}
        {screenSize.width > 1023 && (
          <div className="blocklist-sort-container">
            <div style={{ width: "150px" }}>
              <SortDropdown
                data={[
                  { label: "Newest First", value: "newest" },
                  { label: "Oldest First", value: "oldest" },
                ]}
                onChange={(e) => setState({ ...state, sortOption: e })}
                value={state?.sortOption || undefined}
                placeholder="Sort by"
              />
            </div>
          </div>
        )}
      </div>
      <Spacer height="12px" />
      <div className="blocklist-results-container">
        {isLoading ? (
          <Cardloader />
        ) : (
          <>
            {blocklist?.length > 0 ? (
              <>
                <div className="blocklist-results">
                  {blocklist?.map((item, index) => (
                    <DashboardCard
                      blocklist={true}
                      item={item}
                      index={index}
                      key={index}
                      redirectedFrom="blocklist"
                    >
                      <div className="flex flex-col w-full gap-3">
                        <div className="blocklist-card-text">
                          <p>Blocked Since:</p>
                          <p>
                            {moment(item?.updatedAt).format(
                              "YYYY-MM-DD, hh:mm A"
                            )}
                          </p>
                        </div>

                        <PrimaryButton
                          style={{ height: "32px" }}
                          text="Unblock"
                          onClick={() => {
                            setUsername(item?.username);
                            setOpen(true);
                          }}
                        />
                      </div>
                    </DashboardCard>
                  ))}
                </div>
                <Spacer height="36px" />
                <div className="flex justify-center items-center">
                  <Pagination
                    current={pageNo}
                    total={blocklistCount}
                    defaultPageSize={pageSize}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                  />
                </div>
                <Spacer height="24px" />
              </>
            ) : (
              <Empty description={<p>No Results found</p>} />
            )}
          </>
        )}

        <Modal
          centered={true}
          open={isOpen}
          onCancel={() => {
            setUsername("");
            setOpen(false);
          }}
          footer={false}
          closable={true}
        >
          <div className="gap-4 set-profile-photo-content">
            <div className="set-profile-photo-icon">
              <img
                style={{ maxWidth: "40px" }}
                src={images?.deleteIcon}
                alt=""
              />
            </div>
            <div className="set-profile-photo-heading">
              <h2>Are you sure you want to unblock this user?</h2>
              <p>You will be able to communicate with them again.</p>
            </div>
          </div>
          <Spacer height="16px" />
          <div className="flex w-full justify-end">
            <div className="flex gap-4 set-profile-photo-btn">
              <WhiteButton
                style={{
                  height: "40px",
                  border: "2px solid #EAE6ED",
                }}
                text="Cancel"
                onClick={() => {
                  setUsername("");
                  setOpen(false);
                }}
              />
              <PrimaryButton
                text="Unblock"
                style={{
                  background: "#F31260",
                  height: "40px",
                }}
                onClick={() => handleUnblock()}
              />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Blocklist;
