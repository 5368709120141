import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Pagination, Checkbox, Empty } from "antd";

import { InterestAction, PhotoreqAction } from "../../store/actions";
import { SearchAction } from "../../store/actions";
import useScreenSize from "../../utils/usescreensize";
import RemoveEmptyKeys from "../../utils/removeemptykeys";
import EditProfileDropdown from "../../components/dropdown/editprofiledropdown";
import Spacer from "../../components/spacer/spacer";
import Cardloader from "../../components/loader/cardloader";
import DashboardCard from "../../components/dashboardcard/dashboardcard";
import CardBottomButton from "../../components/dashboardcard/cardbottombutton";

import BasicInfoFrom from "./basicinfofrom";
import AppeLifeStyleFrom from "./appelifestylefrom";
import FamilyDetailsFrom from "./familydetailsfrom";
import ReligiousStatusFrom from "./religiousstatusfrom";
import EducationCareerForm from "./educationcareerform";
import PrimaryButton from "../../components/button/primarybutton";

import images from "../../assets";
import "./styles.css";
import UnderHeaderMenu from "../../components/underheadermenu";

const AdvancedSearch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const screenSize = useScreenSize();

  const {
    advancedMatches,
    advancedCount,
    advancedSearchPayload,
    isAdvancedLoading,
  } = useSelector((state) => state.search);
  const gender = useSelector((state) => state.profile?.profile?.gender);

  const [searched, setSearched] = useState(false);
  const [sortOption, setSortOption] = useState(null);
  const [matchedUsers, setMatchedUsers] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [state, setState] = useState({
    // basic
    country: [],
    ageMin: 18,
    ageMax: 30,
    spokenLanguage: [],
    ethnicOrigin: [],
    haveChildren: [],
    stateProvince: [],
    residencyStatus: [],
    maritalStatus: [],
    relocationPlan: [],
    profileCreatedBy: [],
    typeOfProfile: [],
    hasPhoto: [],
    // lifestyle
    hairColor: [],
    heightMin: 142,
    heightMax: 158,
    bodyType: [],
    bloodGroup: [],
    foodHabit: [],
    // family
    financialStatus: [],
    familyType: [],
    // religious
    religiousHistory: [],
    beard: [],
    readQuran: [],
    religiousSect: [],
    dailyPrayer: [],
    religiousCommitment: [],
    polygamy: [],
    // edu career
    education: [],
    profession: [],
    incomeCurrency: "",
    annualIncomeMin: "",
    annualIncomeMax: "",
  });

  const [incomeError, setIncomeError] = useState("");

  useEffect(() => {
    setMatchedUsers(advancedMatches);
  }, [advancedMatches]);

  useEffect(() => {
    const payload = {
      searchData: {},
      ...advancedSearchPayload,
      from: 0,
      size: pageSize,
      sort: sortOption,
    };
    setPageNo(1);
    dispatch(SearchAction.advancedSearch(payload));
  }, [advancedSearchPayload, sortOption]);

  const handleSendInterest = (id) => {
    const payload = {
      receiverId: id,
    };
    dispatch(InterestAction.sendInterest(payload));
  };

  const handleSendPhotoReq = (id) => {
    const payload = {
      receiverId: id,
    };
    dispatch(PhotoreqAction.sendPhotoreq(payload));
  };

  const handleSearch = () => {
    if (
      (state?.annualIncomeMax || state?.annualIncomeMin) &&
      state?.annualIncomeMax <= state?.annualIncomeMin
    ) {
      setIncomeError("Max income must be greater than minimum income");
    } else {
      setSearched(true);
      const payload = {
        ...advancedSearchPayload,
        searchData: RemoveEmptyKeys(state),
        from: 0,
        size: pageSize,
        gender,
        sort: sortOption,
      };
      dispatch(SearchAction.advancedSearchPayload(payload));
    }
  };

  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
    const payload = {
      ...advancedSearchPayload,
      from: (pageNumber - 1) * pageSize,
      size: pageSize,
      gender,
      sort: sortOption,
    };

    dispatch(SearchAction.advancedSearch(payload));
  };

  const handleClear = () => {
    setState({
      country: [],
      ageMin: 18,
      ageMax: 30,
      spokenLanguage: [],
      ethnicOrigin: [],
      stateProvince: [],
      residencyStatus: [],
      maritalStatus: [],
      relocationPlan: [],
      profileCreatedBy: [],
      hairColor: [],
      heightMin: 142,
      heightMax: 158,
      bodyType: [],
      bloodGroup: [],
      foodHabit: [],
      financialStatus: [],
      familyType: [],
      religiousHistory: [],
      beard: [],
      readQuran: [],
      religiousSect: [],
      dailyPrayer: [],
      religiousCommitment: [],
      polygamy: [],
      education: [],
      profession: [],
      incomeCurrency: "",
      annualIncomeMin: "",
      annualIncomeMax: "",
    });
  };
  return (
    <div className="adv-search-recommended-container">
      {!searched ? (
        <>
          {screenSize.width > 1023 && (
            <div className="adv-search-header-container">
              <h1 className="section-heading">Advanced Search</h1>
              <button className="advance-clear-btn" onClick={handleClear}>
                Clear Form
              </button>
            </div>
          )}
          {screenSize.width < 1023 && (
            <UnderHeaderMenu titlename="Advanced Search">
              <p onClick={handleClear}>Clear</p>
            </UnderHeaderMenu>
          )}
          <BasicInfoFrom state={state} setState={setState} />
          <AppeLifeStyleFrom state={state} setState={setState} />
          <FamilyDetailsFrom state={state} setState={setState} />
          <ReligiousStatusFrom state={state} setState={setState} />
          <EducationCareerForm
            state={state}
            setState={setState}
            incomeError={incomeError}
          />
          <div className="adv-search-btn" style={{ padding: "0px 24px" }}>
            <Checkbox>Save search preferences</Checkbox>
          </div>
          <Spacer height="16px" />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "0px 16px",
            }}
          >
            <PrimaryButton
              text="Search"
              style={{ width: "518px" }}
              onClick={handleSearch}
            />
          </div>
          <Spacer height="58px" />
        </>
      ) : (
        <div className="search-results-container">
          <PrimaryButton
            text={"Back"}
            icon={images.arrowIconLeft}
            iconalign="left"
            style={{ width: "120px" }}
            onClick={() => setSearched(false)}
          />
          <Spacer height="10px" />
          <div className="header-container">
            <h1 className="section-heading">Search Results</h1>
            <div style={{ width: "150px" }}>
              <EditProfileDropdown
                data={[
                  { label: "Newest First", value: "newest" },
                  { label: "Oldest First", value: "oldest" },
                ]}
                onChange={(e) => setSortOption(e)}
                value={sortOption}
                placeholder="Sort by"
              />
            </div>
          </div>
          {isAdvancedLoading ? (
            <Cardloader />
          ) : (
            <>
              {advancedMatches?.length > 0 ? (
                <>
                  <div className="recommended-matches-container">
                    {matchedUsers?.map((item, index) => (
                      <DashboardCard
                        item={item}
                        index={index}
                        key={index}
                        redirectedFrom="advancedSearch"
                      >
                        {item?.matchOfTheDay && (
                          <CardBottomButton icon={images.cardButtonTick} />
                        )}
                        <CardBottomButton
                          icon={images.cardMessage}
                          onClick={() =>
                            navigate(
                              `/messages?username=${item?.username}&firstName=${item?.firstName}&lastName=${item?.lastName}&photo=${item?.profilePicture}`
                            )
                          }
                          title="Send Message"
                        />
                        <CardBottomButton
                          icon={images.cardPhotoReq}
                          onClick={() => handleSendPhotoReq(item?.username)}
                          title="Send Photo Request"
                        />
                        <CardBottomButton
                          icon={images.cardmatch}
                          onClick={() => handleSendInterest(item?.username)}
                          title="Send Interest"
                        />
                      </DashboardCard>
                    ))}
                  </div>
                  <Spacer height="36px" />
                  <div className="flex justify-center items-center">
                    <Pagination
                      current={pageNo}
                      total={advancedCount}
                      defaultPageSize={pageSize}
                      onChange={handlePageChange}
                      showSizeChanger={false}
                    />
                  </div>
                  <Spacer height="24px" />
                </>
              ) : (
                <Empty description={<p>No Results found</p>} />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default AdvancedSearch;
