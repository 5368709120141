const NotifiMainInbox = ({
  className,
  pinkColor,
  onlineGreen,
  title,
  subText,
  time,
  children,
}) => {
  return (
    <div className={`${className} notifi-main-inbox`}>
      <div className="notifi-imageicon">
        <div className="notifi-profile-img-area">
          {children}
          <div className="notifi-online-icon">
            <div className={`${onlineGreen} notifi-green-icon`} />
          </div>
        </div>
      </div>
      <div className="notifi-viewed-area">
        <div className="">
          <span className="notifi-viewed-name">{title}&nbsp;</span>
          <span className="notifi-viewed-text">{subText}</span>
          <br />
          {/* <span className="notifi-viewed-parent">djkfdlf kdfjdkl</span> */}
        </div>
        <div className="notifi-pink-area">
          <div className={`${pinkColor} notifi-pink-color`} />
        </div>
        <div className="notifi-minute">{time}</div>
      </div>
    </div>
  );
};
export default NotifiMainInbox;
