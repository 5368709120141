import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const PrimaryButton = (props) => {
  const style =
    props.loading === "true"
      ? `w-full rounded-xl bg-secondaryPressed font-medium text-[16px] text-white h-12 opacity-50 flex justify-center items-center px-2 py-1 whitespace-nowrap overflow-hidden text-ellipsis gap-3 ${
          props?.disabled && "opacity-60"
        }`
      : `w-full rounded-xl bg-secondary font-medium text-[16px] text-white h-12 flex justify-center items-center px-2 py-1 whitespace-nowrap overflow-hidden text-ellipsis gap-3 ${
          props?.disabled && "opacity-60"
        }`;
  return (
    <button {...props} className={style}>
      {props.icon && props.iconalign === "left" && (
        <img src={props.icon} alt="icon" />
      )}{" "}
      <p
        className="mb-0 whitespace-nowrap overflow-hidden text-ellipsis"
        title={props?.text}
      >
        {props.text}
      </p>{" "}
      {props.icon && props.iconalign === "right" && (
        <img src={props.icon} alt="icon" />
      )}
      {props.loading === "true" && <FontAwesomeIcon icon={faSpinner} spin />}
    </button>
  );
};

export default PrimaryButton;
