import React, { useState, useEffect } from "react";
import Spacer from "../../components/spacer/spacer";
import { PrimaryButton, WhiteButton } from "../../components/button";
import ReactCodeInput from "react-code-input";

import "./styles.css";

const OtpForm = (props) => {
  const [timer, setTimer] = useState(40);
  const [isInputFocused, setIsInputFocused] = useState(false);

  useEffect(() => {
    if (timer > 0 && props.resendDisabled) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(countdown);
    } else if (timer === 0) {
      props?.setResendDisabled(false);
    }
  }, [timer, props.resendDisabled]);

  const handleResendClick = () => {
    props?.handleResendOTP();
    setTimer(40);
    props?.setResendDisabled(true);
  };

  const handleChange = (val) => {
    props?.setState({ ...props?.state, otp: val, error: "" });
    setIsInputFocused(true);
  };

  return (
    <>
      <div className="change-email-head">
        <h2 className="change-email-title">OTP Verification</h2>
      </div>
      <div className="change-email-body">
        <p>
          We have sent an OTP to your new email address. Please enter it below
          to verify your email.
        </p>
        <Spacer height="32px" />
        <ReactCodeInput
          className="otp-code-input"
          type="number"
          fields={6}
          onChange={handleChange}
          value={props?.state?.otp}
          inputStyle={{
            border: `1px solid #eae6ed`,
            background: "#fff",
            borderRadius: "5px",
            fontSize: "32px",
            fontWeight: "500",
            height: "48px",
            width: "60px",
            outline: "none",
            textAlign: "center",
            marginLeft: "5px",
            marginRight: "5px",
          }}
        />
      </div>
      {props?.state?.error && (
        <div className="text-center">
          <p className="text-danger font-semibold text-[14px] m-0">
            {props?.state?.error}
          </p>
        </div>
      )}
      <div className="change-email-btn">
        <WhiteButton
          text={props?.resendDisabled ? `Resend (${timer}s)` : "Resend"}
          style={{
            background: "#EAE6ED",
            border: "none",
          }}
          disabled={props?.resendDisabled}
          onClick={handleResendClick}
        />
        <PrimaryButton
          text={"Verify OTP"}
          onClick={props?.handleOtpSubmit}
          disabled={!isInputFocused || props?.isDisabled}
        />
      </div>
    </>
  );
};

export default OtpForm;
