import {
  GET_BLOCKLIST,
  GET_BLOCKLIST_SUCCESS,
  GET_BLOCKLIST_FAILURE,
  DELETE_BLOCKLIST,
  DELETE_BLOCKLIST_SUCCESS,
  DELETE_BLOCKLIST_FAILURE,
  UPDATE_BLOCKLIST,
  UPDATE_BLOCKLIST_SUCCESS,
  UPDATE_BLOCKLIST_FAILURE,
} from "../constants";

export default class BlocklistAction {
  static getBlocklist(payload, cb) {
    return {
      type: GET_BLOCKLIST,
      payload,
      cb,
    };
  }
  static getBlocklistSuccess(payload) {
    return {
      type: GET_BLOCKLIST_SUCCESS,
      payload,
    };
  }
  static getBlocklistFailure() {
    return {
      type: GET_BLOCKLIST_FAILURE,
    };
  }
  static deleteBlocklist(payload, cb) {
    return {
      type: DELETE_BLOCKLIST,
      payload,
      cb,
    };
  }
  static deleteBlocklistSuccess(payload) {
    return {
      type: DELETE_BLOCKLIST_SUCCESS,
      payload,
    };
  }
  static deleteBlocklistFailure() {
    return {
      type: DELETE_BLOCKLIST_FAILURE,
    };
  }
  static updateBlocklist(payload, cb) {
    return {
      type: UPDATE_BLOCKLIST,
      payload,
      cb,
    };
  }
  static updateBlocklistSuccess(payload) {
    return {
      type: UPDATE_BLOCKLIST_SUCCESS,
      payload,
    };
  }
  static updateBlocklistFailure() {
    return {
      type: UPDATE_BLOCKLIST_FAILURE,
    };
  }
}
