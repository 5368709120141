import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, Empty } from "antd";
import moment from "moment";

import { ContactViewerAction } from "../../store/actions";
import useScreenSize from "../../utils/usescreensize";
import ProfileTab from "../../components/tab/profiletab";
import DashboardCard from "../../components/dashboardcard/dashboardcard";
import { WhitePurpleButton } from "../../components/button";

import SortDropdown from "../../components/dropdown/sortdropdown";
import Cardloader from "../../components/loader/cardloader";
import Spacer from "../../components/spacer/spacer";

import "./styles.css";
import UnderHeaderMenu from "../../components/underheadermenu";

const ViewedContacts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const screenSize = useScreenSize();

  const { contactView, isLoading, contactViewCount } = useSelector(
    (state) => state.contactView
  );

  const [state, setState] = useState({
    activeTab: "My Contact Viewers",
    sortOption: "",
  });
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(8);

  useEffect(() => {
    const payload = {
      filter: state?.activeTab,
      from: 0,
      size: pageSize,
    };
    setPageNo(1);

    dispatch(ContactViewerAction.getContactView(payload));
  }, [state]);

  const handleTab = (name) => {
    setState({ ...state, activeTab: name });
  };

  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
    const payload = {
      filter: state?.activeTab,
      from: (pageNumber - 1) * pageSize,
      size: pageSize,
    };

    dispatch(ContactViewerAction.getContactView(payload));
  };

  const tabItems = [
    { name: "My Contact Viewers", title: "My Contact Viewers" },
    { name: "Contacts Viewed by Me", title: "Contacts Viewed by Me" },
  ];

  return (
    <div>
      {screenSize.width < 1023 && (
        <UnderHeaderMenu titlename="Viewed Contacts">
          <div style={{ width: "110px" }}>
            <SortDropdown
              data={[
                { label: "Newest First", value: "newest" },
                { label: "Oldest First", value: "oldest" },
              ]}
              onChange={(e) => setState({ ...state, sortOption: e })}
              value={state?.sortOption || undefined}
              placeholder="Sort by"
            />
          </div>
        </UnderHeaderMenu>
      )}
      <Spacer height="16px" />
      <div className="contactviewers-section-top-part-container">
        <div></div>
        <div className="contactviewers-section-header-container">
          {screenSize.width > 1023 && (
            <h1 className="contactviewers-header">Viewed Contacts</h1>
          )}
          <ProfileTab
            tabItems={tabItems}
            handleTab={handleTab}
            activeTab={state?.activeTab}
            style={{
              background: "white",
              border: "1px solid #EAE6ED",
              width: "330px",
            }}
          />
        </div>
        {screenSize.width > 1023 && (
          <div className="contactviewers-sort-container">
            <div style={{ width: "150px" }}>
              <SortDropdown
                data={[
                  { label: "Newest First", value: "newest" },
                  { label: "Oldest First", value: "oldest" },
                ]}
                onChange={(e) => setState({ ...state, sortOption: e })}
                value={state?.sortOption || undefined}
                placeholder="Sort by"
              />
            </div>
          </div>
        )}
      </div>
      <Spacer height="12px" />
      <div className="contactviewers-results-container">
        {isLoading ? (
          <Cardloader />
        ) : (
          <>
            {contactView?.length > 0 ? (
              <>
                <div className="contactviewers-results">
                  {contactView?.map((item, index) => (
                    <DashboardCard
                      item={item}
                      index={index}
                      key={index}
                      redirectedFrom="contactView"
                    >
                      <div className="flex flex-col w-full gap-3">
                        <div className="contactviewers-card-text">
                          <p>Viewed on:</p>
                          <p>{moment(item?.updatedAt).fromNow()}</p>
                        </div>

                        <WhitePurpleButton
                          text="View Profile"
                          onClick={() =>
                            navigate(`/view-profile/${item?.username}`)
                          }
                        />
                      </div>
                    </DashboardCard>
                  ))}
                </div>
                <Spacer height="36px" />
                <div className="flex justify-center items-center">
                  <Pagination
                    current={pageNo}
                    total={contactViewCount}
                    defaultPageSize={pageSize}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                  />
                </div>
                <Spacer height="24px" />
              </>
            ) : (
              <Empty description={<p>No Results found</p>} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ViewedContacts;
