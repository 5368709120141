const relocationPlan = [
  { label: "Willing to relocate", value: "Willing to relocate" },
  { label: "Not willing to relocate", value: "Not willing to relocate" },
  {
    label: "Willing to relocate within the country",
    value: "Willing to relocate within the country",
  },
  {
    label: "Willing to relocate to another country",
    value: "Willing to relocate to another country",
  },
  { label: "Not sure about relocating", value: "Not sure about relocating" },
];
export default relocationPlan;
