import React from "react";
import images from "../../assets";

import "./styles.css";

const RejectedButton = () => {
  return (
    <div className="rejected-button-style">
      <img src={images.rejectedIcon} alt="change" />
      <p
        className="whitespace-nowrap overflow-hidden text-ellipsis"
        title={"Rejected"}
      >
        Rejected
      </p>
    </div>
  );
};

export default RejectedButton;
