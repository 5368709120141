import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AuthAction } from "../../store/actions";

import { PrimaryButton } from "../../components/button";
import { LoginLayout } from "../../components/layout";
import { CheckBox } from "../../components/checkbox";
import { TextInput } from "../../components/inputbox";

import images from "../../assets";

import styles from "./styles";
import variables from "../../config/constants/index";

const { BASE_URL_LANDING } = variables;

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  console.log(
    `env url when env is ${process.env.REACT_APP_ENV}`,
    `${BASE_URL_LANDING}`
  );

  const [isPassword, setIsPassword] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");
  const [invalidError, setInvalidError] = useState(false);

  const handleSubmit = () => {
    if (!email || !password) {
      setError("Both email and password are required");
    } else {
      setError("");

      const deviceId = localStorage.getItem("config_state_hash");

      // if (!deviceId) {
      //   console.log("No device id found");
      //   return;
      // }
      const payload = {
        username: email,
        password,
        config_state_hash: JSON.parse(deviceId),
      };

      dispatch(
        AuthAction.SignIn(payload, (res) => {
          if (
            res.status === 301 ||
            (res.status === 400 &&
              res.message === "User is not confirmed. OTP resend initiated.")
          ) {
            navigate("/confirm-otp", { state: payload });
          }
          if (res.status === 400 && res.message === "NotAuthorizedException") {
            setInvalidError(true);
          }
        })
      );
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <LoginLayout>
      <div className="w-[300px] lg:w-[450px]">
        <div className="mb-[24px]">
          <div className={styles.topBigText}>Members Login</div>
        </div>
        <div>
          <div className={styles.inputFieldContainer}>
            <TextInput
              className={
                invalidError ? `${styles.wrongInput}` : `${styles.inputStyle}`
              }
              type="email"
              id="email"
              placeholder="Your email address"
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={handleKeyDown}
            />

            <div className="relative">
              <TextInput
                className={
                  invalidError ? `${styles.wrongInput}` : `${styles.inputStyle}`
                }
                type={isPassword ? "password" : "text"}
                id="pass"
                placeholder="Enter your password"
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <img
                src={isPassword ? images.eyeIcon : images.eyeOfIcon}
                className={styles.eyeIcon}
                onClick={() => setIsPassword(!isPassword)}
                alt="see"
              />
            </div>
          </div>
          <div className="text-xs mt-4 mb-4 flex flex-row items-center justify-between font-mons-light space-x-2">
            <CheckBox
              type="checkbox"
              className="h-5 w-5 hidden lg:block"
              onChange={() => setRememberMe(!rememberMe)}
            >
              <span className="text-neutral-800 text-base font-normal font-Poppins leading-normal hidden lg:block">
                &nbsp;Remember Me
              </span>
            </CheckBox>

            <div className="justify-center items-center gap-2.5 inline-flex">
              <div
                onClick={() => navigate("/forgot-password")}
                className="text-pink-700 text-base font-semibold underline cursor-pointer"
              >
                Forgot your password?
              </div>
            </div>
          </div>

          {error && (
            <div className="text-pink-700 text-base font-normal text-center">
              {error}
            </div>
          )}
          <PrimaryButton text={"Login"} onClick={handleSubmit} />

          <div className="text-[16px] font-normal text-center mt-4">
            Don't have an account?{"  "}
            <a
              href={`${BASE_URL_LANDING}/register`}
              className={styles.linkText}
            >
              Register
            </a>
          </div>
        </div>
      </div>
    </LoginLayout>
  );
};

export default Login;
