import React from "react";
import Spacer from "../spacer/spacer";
import { PrimaryButton } from "../button";

const ModalForm = (props) => {
    return (
        <>
            <div className="change-email-head">
                <h2 className="change-email-title">{props.titletext}</h2>
            </div>
            <div className="change-email-body">
                <p>{props.subtitle}</p>
                <Spacer height="32px" />
                {props.children}
            </div>
            <div {...props} className="change-email-btn">
                <PrimaryButton text={props.btntext} />
            </div>
        </>
    );
};

export default ModalForm;
