const countryChangeReason = [
  { label: "Moved to a New Country", value: "Moved to a New Country" },
  {
    label: "Traveling or Temporarily Living Abroad",
    value: "Traveling or Temporarily Living Abroad",
  },
  {
    label: "Mistake in Original Country Selection",
    value: "Mistake in Original Country Selection",
  },
  { label: "Other", value: "Other" },
];
export { countryChangeReason };
