import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Radio, Space, Switch } from "antd";

import { EditProfileCheckbox } from "../../components/checkbox";
import useScreenSize from "../../utils/usescreensize";
import Loader from "../../components/loader/loader";

import { PrimaryButton, WhiteButton } from "../../components/button";

import NotificationCheckbox from "./notificationcheckbox";
import Spacer from "../../components/spacer/spacer";

const recommendedMatches = [
  { label: "Daily", value: "Daily" },
  { label: "Weekly", value: "Weekly" },
  { label: "Unsubscribe", value: "Unsubscribe" },
];
const expertAlert = [
  { label: "Occasionally", value: "Occasionally" },
  { label: "Unsubscribe", value: "Unsubscribe" },
];

const NotificationPref = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const screenSize = useScreenSize();

  const profileLoading = useSelector((state) => state?.profile?.isLoading);
  const profileData = useSelector((state) => state?.profile?.profile);

  const [state, setState] = useState({
    extensiveMatches: false,
    recommendedMatches: "",
    photoRequest: "",
    interestReceived: "",
    profileShortlisted: "",
    profileVisitors: "",
    messageReceivedAlert: "",
    expertAlert: "",
  });

  useEffect(() => {
    setState({
      ...state,
      extensiveMatches:
        profileData?.settingsPreference?.extensiveMatches ?? false,
      recommendedMatches:
        profileData?.settingsPreference?.recommendedMatches ?? "",
      photoRequest: profileData?.settingsPreference?.photoRequest ?? "",
      interestReceived: profileData?.settingsPreference?.interestReceived ?? "",
      profileShortlisted:
        profileData?.settingsPreference?.profileShortlisted ?? "",
      profileVisitors: profileData?.settingsPreference?.profileVisitors ?? "",
      messageReceivedAlert:
        profileData?.settingsPreference?.messageReceivedAlert ?? "",
      expertAlert: profileData?.settingsPreference?.expertAlert ?? "",
    });
  }, [profileLoading]);

  const handleUnsubscribe = (e) => {
    if (e) {
      setState({
        ...state,
        extensiveMatches: "Unsubscribe",
        recommendedMatches: "Unsubscribe",
        photoRequest: "Unsubscribe",
        interestReceived: "Unsubscribe",
        profileShortlisted: "Unsubscribe",
        profileVisitors: "Unsubscribe",
        messageReceivedAlert: "Unsubscribe",
        expertAlert: "Unsubscribe",
      });
    } else {
      setState({
        ...state,
        extensiveMatches:
          profileData?.settingsPreference?.extensiveMatches ?? false,
        recommendedMatches:
          profileData?.settingsPreference?.recommendedMatches ?? "",
        photoRequest: profileData?.settingsPreference?.photoRequest ?? "",
        interestReceived:
          profileData?.settingsPreference?.interestReceived ?? "",
        profileShortlisted:
          profileData?.settingsPreference?.profileShortlisted ?? "",
        profileVisitors: profileData?.settingsPreference?.profileVisitors ?? "",
        messageReceivedAlert:
          profileData?.settingsPreference?.messageReceivedAlert ?? "",
        expertAlert: profileData?.settingsPreference?.expertAlert ?? "",
      });
    }
  };
  const handleReset = () => {
    setState({
      ...state,
      extensiveMatches:
        profileData?.settingsPreference?.extensiveMatches ?? false,
      recommendedMatches:
        profileData?.settingsPreference?.recommendedMatches ?? "",
      photoRequest: profileData?.settingsPreference?.photoRequest ?? "",
      interestReceived: profileData?.settingsPreference?.interestReceived ?? "",
      profileShortlisted:
        profileData?.settingsPreference?.profileShortlisted ?? "",
      profileVisitors: profileData?.settingsPreference?.profileVisitors ?? "",
      messageReceivedAlert:
        profileData?.settingsPreference?.messageReceivedAlert ?? "",
      expertAlert: profileData?.settingsPreference?.expertAlert ?? "",
    });
  };
  const handleSave = () => {
    console.log(state);
  };

  return (
    <div>
      <h2 className="settings-content-header">Notification Settings</h2>
      <Spacer height="8px" />
      <p className="settings-content-subheader-subheader">
        Customize your notifications and make sure you never miss a match.
      </p>
      <Spacer height="25px" />
      {profileLoading ? (
        <Loader />
      ) : (
        <>
          <>
            <h5 className="settings-content-subheader h-9 flex items-center">
              Recommended Matches
            </h5>
            <Spacer height="12px" />
            <p className="settings-content-subheader-subheader">
              Recommended matches for you on your email. Highly effective
              match-making process.
            </p>
            <Spacer height="8px" />
            <EditProfileCheckbox
              text="Send me extensive matches if there’s no new preferred match."
              onChange={() =>
                setState({
                  ...state,
                  extensiveMatches: !state?.extensiveMatches,
                })
              }
            />
            <Spacer height="8px" />
            <div className="settings-notification-checkbox-container">
              <h5 className="checkbox-container-text">Send Email Alert:</h5>
              <Radio.Group
                onChange={(e) =>
                  setState({ ...state, recommendedMatches: e.target.value })
                }
                value={state?.recommendedMatches}
              >
                <Space direction="vertical">
                  {recommendedMatches?.map((item, index) => (
                    <Radio value={item?.value} key={index}>
                      {item?.label}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </div>
          </>

          <Spacer height="40px" />
          <NotificationCheckbox
            title="Photo Requests"
            subtitle="An email notification for the Photo Requests by other users."
            onChange={(e) =>
              setState({ ...state, photoRequest: e.target.value })
            }
            value={state?.photoRequest}
          />
          <Spacer height="40px" />
          <NotificationCheckbox
            title="Interest Received Alert"
            subtitle="An email notification of the member who have sent an interest."
            onChange={(e) =>
              setState({ ...state, interestReceived: e.target.value })
            }
            value={state?.interestReceived}
          />
          <Spacer height="40px" />
          <NotificationCheckbox
            title="Profile Shortlisted by"
            subtitle="An email notification of members who have shortlisted your profile."
            onChange={(e) =>
              setState({ ...state, profileShortlisted: e.target.value })
            }
            value={state?.profileShortlisted}
          />
          <Spacer height="40px" />
          <NotificationCheckbox
            title="Profile Visitors"
            subtitle="An email notification of members who have viewed your profile."
            onChange={(e) =>
              setState({ ...state, profileVisitors: e.target.value })
            }
            value={state?.profileVisitors}
          />
          <Spacer height="40px" />
          <NotificationCheckbox
            title="Message Received Alert"
            subtitle="An email notification of your messages recieved."
            onChange={(e) =>
              setState({ ...state, messageReceivedAlert: e.target.value })
            }
            value={state?.messageReceivedAlert}
          />
          <Spacer height="40px" />

          <>
            <h5 className="settings-content-subheader h-9 flex items-center">
              NobleMarriage Express Alert
            </h5>
            <Spacer height="12px" />
            <p className="settings-content-subheader-subheader">
              An email notification of discounts, promo, offers and many more.
            </p>

            <Spacer height="8px" />
            <div className="settings-notification-checkbox-container">
              <h5 className="checkbox-container-text">Send Email Alert:</h5>
              <Radio.Group
                onChange={(e) =>
                  setState({ ...state, expertAlert: e.target.value })
                }
                value={state?.expertAlert}
              >
                <Space direction="vertical">
                  {expertAlert?.map((item, index) => (
                    <Radio value={item?.value} key={index}>
                      {item?.label}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </div>
          </>
          <Spacer height="40px" />
          <div
            style={{ width: screenSize.width < 768 ? "100%" : "400px" }}
            className="flex gap-4"
          >
            <WhiteButton text="Reset" onClick={handleReset} />
            <PrimaryButton text="Save" onClick={handleSave} />
          </div>
          <Spacer height="10px" />
          <div className="settings-content-divider"></div>
          <Spacer height="10px" />
          <div className="flex justify-between items-center">
            <p className="switch-text">Unsubscribe From Everything</p>
            <Switch
              defaultChecked={state?.contactAccess}
              onChange={(e) => handleUnsubscribe(e)}
            />
          </div>
          <Spacer height="8px" />
          <p className="settings-content-subheader-subheader">
            We’ll continue to only send necessary emails about your account.
          </p>
        </>
      )}
    </div>
  );
};

export default NotificationPref;
