import { Link } from "react-router-dom";

export default function ProfileCompleteInfoCard(props) {
  return (
    <div {...props} className="improve-match-subarea2">
      <div className="w-5">
        <img src={props.icon} alt="Check" height={20} width={20} />
      </div>

      <Link to={props?.route ?? null}>
        <div className="improve-match-info-subtext w-[150px]">
          {props.titletext}
        </div>
      </Link>
      <div className="improve-match-dotted w-8">
        <p>{props.completenum}</p>
      </div>
      <div
        className={`${props.className} completion-popup-content-percent w-6`}
      >
        <p>{props.completepercent}</p>
      </div>
    </div>
  );
}
