import { COLLAPSED, COLLAPSED_SUCCESS, COLLAPSED_FAILURE } from "../constants";

const initialState = {
  collapsed: false,
  isLoading: false,
  error: null,
};

export default function UiReducer(state = initialState, action) {
  switch (action.type) {
    case COLLAPSED:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case COLLAPSED_SUCCESS:
      state = {
        ...state,
        collapsed: action?.payload,
        isLoading: false,
      };
      break;

    case COLLAPSED_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
