import { useSelector } from "react-redux";
import images from "../../assets";
import "./styles.css";
import BasicInfoColumn from "./basicinfocolumn";

export default function PhysicalAppeCard() {
  const profileData = useSelector((state) => {
    return state?.profile?.profile;
  });

  /**Convert CentoFeet **/
  const convertedCentoFeet = (values) => {
    var realFeet = (values * 0.3937) / 12;
    var feet = Math.floor(realFeet);
    var inches = Math.round((realFeet - feet) * 12);
    return feet + "'" + inches + "''";
  };
  let feetData = convertedCentoFeet(profileData?.height);

  return (
    <div className="physical-appearance basic-info-card">
      <div className="basic-info-head">
        <img
          src={images.physicalAppreIcon}
          alt="Physical Appearance & Lifestyle"
        />{" "}
        <span>Physical Appearance & Lifestyle</span>
      </div>
      <div className="basic-info-content">
        {profileData?.height && (
          <BasicInfoColumn
            titlename="Height"
            values={`${feetData} (${profileData?.height} cm)`}
          />
        )}
        {profileData?.hairColor && (
          <BasicInfoColumn
            titlename="Hair Color"
            values={profileData?.hairColor}
          />
        )}
        {profileData?.hairType && (
          <BasicInfoColumn
            titlename="Hair Type"
            values={profileData?.hairType}
          />
        )}
        {profileData?.hairLength && (
          <BasicInfoColumn
            titlename="Hair Length"
            values={profileData?.hairLength}
          />
        )}
        {profileData?.complexion && (
          <BasicInfoColumn
            titlename="Complexion"
            values={profileData?.complexion}
          />
        )}
        {profileData?.bloodGroup && (
          <BasicInfoColumn
            titlename="Blood Group"
            values={profileData?.bloodGroup}
          />
        )}
        {profileData?.foodHabit?.length > 0 && (
          <BasicInfoColumn
            titlename="Food Habit"
            values={profileData?.foodHabit?.map((item, index) => {
              if (index === 0) {
                return item;
              } else {
                return `, ${item}`;
              }
            })}
          />
        )}
        {profileData?.healthCondition && (
          <BasicInfoColumn
            titlename="Health Condition"
            values={profileData?.healthCondition}
          />
        )}
        {profileData?.bodyType && (
          <BasicInfoColumn
            titlename="Body Type"
            values={profileData?.bodyType}
          />
        )}
        {profileData?.physicalStatus && (
          <BasicInfoColumn
            titlename="Physical Health Status"
            values={profileData?.physicalStatus}
          />
        )}
        {profileData?.smoking && (
          <BasicInfoColumn titlename="Smoking" values={profileData?.smoking} />
        )}
        {profileData?.beard && (
          <BasicInfoColumn titlename="Beard" values={profileData?.beard} />
        )}
      </div>
    </div>
  );
}
