import {
  ADVANCED_SEARCH,
  ADVANCED_SEARCH_SUCCESS,
  ADVANCED_SEARCH_FAILURE,
  ADVANCED_SEARCH_PAYLOAD,
  ADVANCED_SEARCH_PAYLOAD_SUCCESS,
  ADVANCED_SEARCH_PAYLOAD_FAILURE,
  BASIC_SEARCH,
  BASIC_SEARCH_SUCCESS,
  BASIC_SEARCH_FAILURE,
  GET_MOD,
  GET_MOD_SUCCESS,
  GET_MOD_FAILURE,
  GET_UNIVERSITIES,
  GET_UNIVERSITIES_SUCCESS,
  GET_UNIVERSITIES_FAILURE,
  SEARCH_PAYLOAD,
  SEARCH_PAYLOAD_SUCCESS,
  SEARCH_PAYLOAD_FAILURE,
  QUICK_SEARCH_PAYLOAD,
  QUICK_SEARCH_PAYLOAD_SUCCESS,
  QUICK_SEARCH_PAYLOAD_FAILURE,
  QUICK_SEARCH,
  QUICK_SEARCH_SUCCESS,
  QUICK_SEARCH_FAILURE,
  RECOMMENDED_SEARCH,
  RECOMMENDED_SEARCH_SUCCESS,
  RECOMMENDED_SEARCH_FAILURE,
} from "../constants";

const initialState = {
  universities: [],
  universitiesCount: 0,
  advancedMatches: [],
  advancedCount: 0,
  basicMatches: [],
  basicCount: 0,
  quickMatches: [],
  quickCount: 0,
  recommendedMatches: [],
  recommendedCount: 0,
  matchOfTheDay: {},
  searchPayload: { searchData: {} },
  advancedSearchPayload: {},
  quickSearchPayload: {},
  isUniversityLoading: false,
  isModLoading: false,
  isAdvancedLoading: false,
  isBasicLoading: false,
  isQuickLoading: false,
  isRecommendedLoading: false,
  error: null,
};

export default function SearchReducer(state = initialState, action) {
  switch (action.type) {
    case ADVANCED_SEARCH:
      state = {
        ...state,
        isAdvancedLoading: true,
        error: null,
      };
      break;
    case BASIC_SEARCH:
      state = {
        ...state,
        isBasicLoading: true,
        error: null,
      };
      break;
    case GET_UNIVERSITIES:
      state = {
        ...state,
        isUniversityLoading: true,
        error: null,
      };
      break;
    case GET_MOD:
      state = {
        ...state,
        isModLoading: true,
        error: null,
      };
      break;
    case SEARCH_PAYLOAD:
    case ADVANCED_SEARCH_PAYLOAD:
    case QUICK_SEARCH_PAYLOAD:
      state = {
        ...state,
        error: null,
      };
      break;
    case QUICK_SEARCH:
      state = {
        ...state,
        isQuickLoading: true,
        error: null,
      };
      break;
    case RECOMMENDED_SEARCH:
      state = {
        ...state,
        isRecommendedLoading: true,
        error: null,
      };
      break;
    case ADVANCED_SEARCH_SUCCESS:
      state = {
        ...state,
        advancedMatches: action.payload.data,
        advancedCount: action.payload.total,
        isAdvancedLoading: false,
      };
      break;
    case BASIC_SEARCH_SUCCESS:
      state = {
        ...state,
        basicMatches: action.payload.data,
        basicCount: action.payload.total,
        isBasicLoading: false,
      };
      break;
    case GET_UNIVERSITIES_SUCCESS:
      state = {
        ...state,
        universities: action.payload.data,
        universitiesCount: action.payload.total,
        isUniversityLoading: false,
      };
      break;
    case GET_MOD_SUCCESS:
      state = {
        ...state,
        matchOfTheDay: action.payload,
        isModLoading: false,
      };
      break;
    case ADVANCED_SEARCH_PAYLOAD_SUCCESS:
      state = {
        ...state,
        advancedSearchPayload: action.payload,
      };
      break;
    case SEARCH_PAYLOAD_SUCCESS:
      state = {
        ...state,
        searchPayload: action.payload,
      };
      break;
    case QUICK_SEARCH_PAYLOAD_SUCCESS:
      state = {
        ...state,
        quickSearchPayload: action.payload,
      };
      break;
    case QUICK_SEARCH_SUCCESS:
      state = {
        ...state,
        quickMatches: action.payload.data,
        quickCount: action.payload.total,
        isQuickLoading: false,
      };
      break;
    case RECOMMENDED_SEARCH_SUCCESS:
      state = {
        ...state,
        recommendedMatches: action.payload.data,
        recommendedCount: action.payload.total,
        isRecommendedLoading: false,
      };
      break;
    case ADVANCED_SEARCH_FAILURE:
      state = {
        ...state,
        isAdvancedLoading: false,
        error: action.payload,
      };
      break;
    case BASIC_SEARCH_FAILURE:
      state = {
        ...state,
        isBasicLoading: false,
        error: action.payload,
      };
      break;
    case GET_UNIVERSITIES_FAILURE:
      state = {
        ...state,
        isUniversityLoading: false,
        error: action.payload,
      };
      break;
    case GET_MOD_FAILURE:
      state = {
        ...state,
        isModLoading: false,
        error: action.payload,
      };
      break;
    case ADVANCED_SEARCH_PAYLOAD_FAILURE:
    case SEARCH_PAYLOAD_FAILURE:
    case QUICK_SEARCH_PAYLOAD_FAILURE:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case QUICK_SEARCH_FAILURE:
      state = {
        ...state,
        isQuickLoading: false,
        error: action.payload,
      };
      break;
    case RECOMMENDED_SEARCH_FAILURE:
      state = {
        ...state,
        isRecommendedLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }

  return state;
}
