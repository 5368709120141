import { all, takeLatest } from "redux-saga/effects";

import {
  ADVANCED_SEARCH,
  ADVANCED_SEARCH_PAYLOAD,
  BASIC_SEARCH,
  COLLAPSED,
  CONFIRM_PASSWORD,
  CHANGE_PROFILE_STATUS,
  CONFIRM_OTP_RECOVER,
  DELETE_PHOTO,
  DELETE_BLOCKLIST,
  EMAIL_CHANGE_REQ,
  EMAIL_CHANGE_VERIFY,
  FORGOT_PASSWORD,
  GET_CONTACT_VIEW,
  GET_ALL_CONTACT_VIEW,
  SET_CONTACT_VIEW,
  GET_INTERESTS,
  GET_INTERESTS_ALL,
  GET_UNIVERSITIES,
  GET_NEW_MEMBERS,
  GET_NOTIFICATIONS,
  GET_BLOCKLIST,
  GET_MOD,
  GET_NOTE,
  GET_NOTES,
  SET_MESSAGES_LIST,
  GET_PAYMENT_PLAN,
  GET_PROFILE,
  GET_PROFILE_VIEWER,
  GET_PROFILE_VIEWED,
  GET_ALL_PROFILE_VIEWED,
  GET_PHOTOREQ,
  GET_PHOTOREQ_ALL,
  GET_SHORTLIST,
  GET_SHORTLIST_ALL,
  GET_SUBSCRIPTION_INFO,
  GET_OTHERS_PROFILE,
  GET_TRUST_BADGE,
  DELETE_TRUST_BADGE,
  LOGIN,
  LOGOUT,
  SEARCH_PAYLOAD,
  REMOVE_PROFILE_STORE,
  QUICK_SEARCH,
  QUICK_SEARCH_PAYLOAD,
  RECOMMENDED_SEARCH,
  RESEND_OTP,
  SEND_INTEREST,
  SEND_PHOTOREQ,
  SEND_SHORTLIST,
  SET_PROFILE_VIEW,
  SET_NOTES,
  UPDATE_MATCH_PREFERENCE,
  UPDATE_BLOCKLIST,
  UPDATE_INTEREST,
  UPDATE_PROFILE,
  UPDATE_PROFILE_PIC,
  UPDATE_NOTIFICATION,
  CHANGE_REQ,
  UPDATE_PHOTOREQ,
  UPLOAD_PHOTO,
  UPLOAD_TRUST_BADGE,
} from "../constants";
import {
  AuthMiddleware,
  BlocklistMiddleware,
  ContactViewerMiddleware,
  InterestMiddleware,
  MatchMiddleware,
  MessageMiddleware,
  MyNotesMiddleware,
  NewMembersMiddleware,
  NotificationMiddleware,
  OthersProfileMiddleware,
  PhotoreqMiddleware,
  ProfileMiddleware,
  ProfileViewerMiddleware,
  SearchMiddleware,
  ShortlistMiddleware,
  SubscriptionMiddleware,
  UiMiddleware,
} from "../middlewares";

export function* Sagas() {
  yield all([
    // ui
    yield takeLatest(COLLAPSED, UiMiddleware.setCollapsed),

    //auth
    yield takeLatest(LOGIN, AuthMiddleware.SignIn),
    yield takeLatest(CONFIRM_OTP_RECOVER, AuthMiddleware.confirmOtpRecover),
    yield takeLatest(RESEND_OTP, AuthMiddleware.resendOtp),
    yield takeLatest(LOGOUT, AuthMiddleware.Logout),
    yield takeLatest(EMAIL_CHANGE_REQ, AuthMiddleware.emailChangeReq),
    yield takeLatest(EMAIL_CHANGE_VERIFY, AuthMiddleware.emailChangeVerify),
    yield takeLatest(CONFIRM_PASSWORD, AuthMiddleware.confirmPassword),
    yield takeLatest(FORGOT_PASSWORD, AuthMiddleware.forgotPassword),

    // search
    yield takeLatest(ADVANCED_SEARCH, SearchMiddleware.advancedSearch),
    yield takeLatest(GET_MOD, SearchMiddleware.getMod),
    yield takeLatest(BASIC_SEARCH, SearchMiddleware.basicSearch),
    yield takeLatest(QUICK_SEARCH, SearchMiddleware.quickSearch),
    yield takeLatest(RECOMMENDED_SEARCH, SearchMiddleware.recommendedSearch),
    yield takeLatest(SEARCH_PAYLOAD, SearchMiddleware.searchPayload),
    yield takeLatest(QUICK_SEARCH_PAYLOAD, SearchMiddleware.quickSearchPayload),
    yield takeLatest(
      ADVANCED_SEARCH_PAYLOAD,
      SearchMiddleware.advancedSearchPayload
    ),

    // profile
    yield takeLatest(GET_PROFILE, ProfileMiddleware.getProfile),
    yield takeLatest(
      REMOVE_PROFILE_STORE,
      ProfileMiddleware.removeProfileStore
    ),
    yield takeLatest(UPDATE_PROFILE, ProfileMiddleware.updateProfile),
    yield takeLatest(CHANGE_REQ, ProfileMiddleware.changeRequest),
    yield takeLatest(UPDATE_PROFILE_PIC, ProfileMiddleware.updateProfilePic),
    yield takeLatest(UPLOAD_PHOTO, ProfileMiddleware.uploadPhoto),
    yield takeLatest(DELETE_PHOTO, ProfileMiddleware.deletePhoto),
    yield takeLatest(UPLOAD_TRUST_BADGE, ProfileMiddleware.uploadTrustBadge),
    yield takeLatest(GET_TRUST_BADGE, ProfileMiddleware.getTrustBadge),
    yield takeLatest(DELETE_TRUST_BADGE, ProfileMiddleware.deleteTrustBadge),
    yield takeLatest(
      CHANGE_PROFILE_STATUS,
      ProfileMiddleware.changeProfileStatus
    ),

    // othersprofile
    yield takeLatest(
      GET_OTHERS_PROFILE,
      OthersProfileMiddleware.getOthersProfile
    ),

    // matches
    yield takeLatest(
      UPDATE_MATCH_PREFERENCE,
      MatchMiddleware.updateMatchPreference
    ),

    // message
    yield takeLatest(SET_MESSAGES_LIST, MessageMiddleware.setMessageList),

    // interests
    yield takeLatest(GET_INTERESTS, InterestMiddleware.getInterests),
    yield takeLatest(GET_INTERESTS_ALL, InterestMiddleware.getInterestsAll),
    yield takeLatest(SEND_INTEREST, InterestMiddleware.sendInterest),
    yield takeLatest(UPDATE_INTEREST, InterestMiddleware.updateInterest),

    // photo req
    yield takeLatest(GET_PHOTOREQ, PhotoreqMiddleware.getPhotoreq),
    yield takeLatest(GET_PHOTOREQ_ALL, PhotoreqMiddleware.getPhotoreqAll),
    yield takeLatest(SEND_PHOTOREQ, PhotoreqMiddleware.sendPhotoreq),
    yield takeLatest(UPDATE_PHOTOREQ, PhotoreqMiddleware.updatePhotoreq),

    // shortlists
    yield takeLatest(GET_SHORTLIST, ShortlistMiddleware.getShortlist),
    yield takeLatest(GET_SHORTLIST_ALL, ShortlistMiddleware.getShortlistAll),
    yield takeLatest(SEND_SHORTLIST, ShortlistMiddleware.sendShortlist),

    // profile viewer
    yield takeLatest(
      GET_PROFILE_VIEWER,
      ProfileViewerMiddleware.getProfileViewer
    ),
    yield takeLatest(
      GET_PROFILE_VIEWED,
      ProfileViewerMiddleware.getProfileViewed
    ),
    yield takeLatest(
      GET_ALL_PROFILE_VIEWED,
      ProfileViewerMiddleware.getAllProfileViewer
    ),
    yield takeLatest(SET_PROFILE_VIEW, ProfileViewerMiddleware.setProfileView),

    // profile viewer
    yield takeLatest(SET_CONTACT_VIEW, ContactViewerMiddleware.setContactView),
    yield takeLatest(
      GET_ALL_CONTACT_VIEW,
      ContactViewerMiddleware.getAllContactView
    ),
    yield takeLatest(GET_CONTACT_VIEW, ContactViewerMiddleware.getContactView),

    // subscription
    yield takeLatest(
      GET_SUBSCRIPTION_INFO,
      SubscriptionMiddleware.getSubscriptionInfo
    ),
    yield takeLatest(GET_PAYMENT_PLAN, SubscriptionMiddleware.getPaymentPlan),

    // uiniversities
    yield takeLatest(GET_UNIVERSITIES, SearchMiddleware.getUniversities),

    // blocklist
    yield takeLatest(GET_BLOCKLIST, BlocklistMiddleware.getBlocklist),
    yield takeLatest(UPDATE_BLOCKLIST, BlocklistMiddleware.updateBlocklist),
    yield takeLatest(DELETE_BLOCKLIST, BlocklistMiddleware.deleteBlocklist),

    // my notes
    yield takeLatest(GET_NOTE, MyNotesMiddleware.getNote),
    yield takeLatest(GET_NOTES, MyNotesMiddleware.getNotes),
    yield takeLatest(SET_NOTES, MyNotesMiddleware.setNotes),

    // new members
    yield takeLatest(GET_NEW_MEMBERS, NewMembersMiddleware.getNewMembers),

    // notifications
    yield takeLatest(
      GET_NOTIFICATIONS,
      NotificationMiddleware.getNotifications
    ),
    yield takeLatest(
      UPDATE_NOTIFICATION,
      NotificationMiddleware.updateNotification
    ),
  ]);
}
