export default function ReceiverMessageCard(props) {
  let messageParts = props?.textmessage?.split("\n");

  return (
    <div {...props} className="premium-message-body-sender">
      <div className="premium-message-body-sender-with">
        <div className="premium-message-body-main-sender">
          <div className="premium-message-body-text-mess">
            {messageParts.map((part, index) => (
              <p key={index}>{part}</p>
            ))}
          </div>
          <div className="premium-message-body-text-time">
            <p>{props.delivertime}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
