import {
  GET_INTERESTS,
  GET_INTERESTS_SUCCESS,
  GET_INTERESTS_FAILURE,
  GET_INTERESTS_ALL,
  GET_INTERESTS_ALL_SUCCESS,
  GET_INTERESTS_ALL_FAILURE,
  SEND_INTEREST,
  SEND_INTEREST_SUCCESS,
  SEND_INTEREST_FAILURE,
  UPDATE_INTEREST,
  UPDATE_INTEREST_SUCCESS,
  UPDATE_INTEREST_FAILURE,
} from "../constants";

const initialState = {
  interests: [],
  interestsAll: [],
  interestCount: 0,
  interestAllCount: 0,
  pendingInterestCount: 0,
  isLoading: false,
  error: null,
};

export default function InterestReducer(state = initialState, action) {
  switch (action.type) {
    case GET_INTERESTS:
    case GET_INTERESTS_ALL:
    case SEND_INTEREST:
    case UPDATE_INTEREST:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_INTERESTS_SUCCESS:
      state = {
        ...state,
        interests: action.payload.data,
        interestCount: action.payload.count,
        pendingInterestCount:
          action.payload.pendingCount ?? state?.pendingInterestCount,
        isLoading: false,
      };
      break;
    case GET_INTERESTS_ALL_SUCCESS:
      state = {
        ...state,
        interestsAll: action.payload.data,
        interestAllCount: action.payload.count,
        isLoading: false,
      };
      break;
    case SEND_INTEREST_SUCCESS:
    case UPDATE_INTEREST_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;

    case GET_INTERESTS_FAILURE:
    case GET_INTERESTS_ALL_FAILURE:
    case SEND_INTEREST_FAILURE:
    case UPDATE_INTEREST_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }

  return state;
}
