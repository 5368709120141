import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";
import ReactCodeInput from "react-code-input";

import { AuthAction } from "../../store/actions";

import Spacer from "../../components/spacer/spacer";
import GreyButton from "../../components/button/greybutton";
import { TextInput } from "../../components/inputbox";
import { PrimaryButton } from "../../components/button";

import ForgotPassword from "./forgotpassword";

import images from "../../assets";

import "./styles.css";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profileData = useSelector((state) => state?.profile?.profile);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [state, setState] = useState({
    oldPassword: "",
    isOldPassword: true,
    newPassword: "",
    isNewPassword: true,
    confirmPassword: "",
    isConfirmPassword: true,
    otp: "",
    error: "",
  });

  const defaultStates = () => {
    setState({
      oldPassword: "",
      isOldPassword: true,
      newPassword: "",
      isNewPassword: true,
      confirmPassword: "",
      isConfirmPassword: true,
      otp: "",
      error: "",
    });
  };

  const handleSubmit = () => {
    if (!state?.oldPassword || !state?.newPassword || !state?.confirmPassword) {
      setState({
        ...state,
        error: "Please fill all the fields",
      });
    } else if (state?.newPassword !== state?.confirmPassword) {
      setState({
        ...state,
        error: "Passwords do not match",
      });
    } else {
      setState({
        ...state,
        error: "",
      });
      const payload = {
        username: profileData?.email,
      };
      dispatch(
        AuthAction.forgotPassword(payload, (res) => {
          if (res === 200) {
            setIsOtpModalOpen(true);
          } else {
            defaultStates();
          }
        })
      );
    }
  };
  const handleOtpSubmit = () => {
    const payload = {
      code: state?.otp,
      password: state?.newPassword,
      username: profileData?.email,
    };
    dispatch(
      AuthAction.confirmPassword(payload, () => {
        setIsOtpModalOpen(false);
        defaultStates();
      })
    );
  };
  return (
    <div className="change-password-container">
      <div className="change-password-top-part-container">
        <h1 className="change-password-title">Password</h1>
        <Spacer height="4px" />
        <p className="change-password-subtitle">
          Set a unique password to protect your account.
        </p>
      </div>
      <div className="content-container">
        <GreyButton
          text="Back"
          icon={images.whiteButtonBack}
          iconalign="left"
          onClick={() => navigate("/settings")}
        />
        <div className="change-password-content">
          <img src={images.changePassword} alt="" />
          <Spacer height="36px" />
          <div className="relative w-full">
            <div>
              <TextInput
                className="password-input"
                type={state?.isOldPassword ? "password" : "text"}
                placeholder="Enter old password"
                onChange={(e) =>
                  setState({ ...state, oldPassword: e.target.value })
                }
              />
              <img
                src={state?.isOldPassword ? images.eyeIcon : images.eyeOfIcon}
                className="h-5 absolute top-3 right-4"
                onClick={() =>
                  setState({ ...state, isOldPassword: !state.isOldPassword })
                }
                alt="see"
              />
            </div>
          </div>
          <Spacer height="36px" />
          <div className="relative w-full">
            <div>
              <TextInput
                className="password-input"
                type={state?.isNewPassword ? "password" : "text"}
                placeholder="Enter new password"
                onChange={(e) =>
                  setState({ ...state, newPassword: e.target.value })
                }
              />
              <img
                src={state?.isNewPassword ? images.eyeIcon : images.eyeOfIcon}
                className="h-5 absolute top-3 right-4"
                onClick={() =>
                  setState({ ...state, isNewPassword: !state.isNewPassword })
                }
                alt="see"
              />
            </div>
          </div>
          <Spacer height="36px" />
          <div className="relative w-full">
            <div>
              <TextInput
                className="password-input"
                type={state?.isConfirmPassword ? "password" : "text"}
                placeholder="Confirm new password"
                onChange={(e) =>
                  setState({ ...state, confirmPassword: e.target.value })
                }
              />
              <img
                src={
                  state?.isConfirmPassword ? images.eyeIcon : images.eyeOfIcon
                }
                className="h-5 absolute top-3 right-4"
                onClick={() =>
                  setState({
                    ...state,
                    isConfirmPassword: !state.isConfirmPassword,
                  })
                }
                alt="see"
              />
            </div>
          </div>
          <Spacer height="5px" />
          {state?.error && (
            <div className="text-center">
              <p className="text-danger font-semibold text-[14px] m-0">
                {state?.error}
              </p>
            </div>
          )}
          <Spacer height="36px" />
          <PrimaryButton text={"Update Password"} onClick={handleSubmit} />
          <Spacer height="16px" />

          <p
            className="forgot-password-text"
            onClick={() => setIsModalOpen(true)}
          >
            Forgot Your Password?
          </p>
        </div>
      </div>

      <Modal
        centered={true}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={false}
        closable={false}
        className="forgot-password-modal"
      >
        <img
          src={images?.closeIcon}
          className="modal-close-icon"
          alt=""
          onClick={() => setIsModalOpen(false)}
        />
        <ForgotPassword setIsModalOpen={setIsModalOpen} />
      </Modal>

      <Modal
        centered={true}
        open={isOtpModalOpen}
        onCancel={() => setIsOtpModalOpen(false)}
        footer={false}
        closable={false}
        className="forgot-password-modal"
      >
        <div className="flex justify-center flex-col items-center">
          <img src={images?.otpIcon} alt="" />
          <Spacer height="32px" />
          <h2 className="forgot-password-title">
            Please enter the verification code sent to your Email.
          </h2>
          <Spacer height="24px" />
          <ReactCodeInput
            type="text"
            fields={6}
            onChange={(val) => setState({ ...state, otp: val, error: "" })}
            value={state?.otp}
            inputStyle={{
              border: `1px solid #eae6ed`,
              background: "#fff",
              borderRadius: "5px",
              fontSize: "32px",
              fontWeight: "500",
              height: "48px",
              width: "60px",
              outline: "none",
              textAlign: "center",
              marginLeft: "5px",
              marginRight: "5px",
            }}
            onClick={(e) => setState({ ...state, otp: e.target.value })}
          />

          {state?.error && (
            <div className="text-center">
              <p className="text-danger font-semibold text-[14px] m-0">
                {state?.error}
              </p>
            </div>
          )}
          <Spacer height="32px" />
          <PrimaryButton text={"Verify OTP"} onClick={handleOtpSubmit} />
        </div>
      </Modal>
    </div>
  );
};

export default ChangePassword;
