import images from "../../assets";

const dummyMessages = [
  {
    id: "dasdasd",
    name: "Sadia Khatun",
    unreadCount: 12,
    lastMessagedAt: new Date(),
    profilePicture: images.defaultFemaleIcon,
    online: true,
    lastMessage: "how are you?",
  },
  {
    id: "rhfgdhfg",
    name: "Sadia alam",
    unreadCount: 0,
    lastMessagedAt: new Date(),
    profilePicture: images.defaultFemaleIcon,
    online: false,
    lastMessage: "how are you?",
  },
  {
    id: "ttyki7p546",
    name: "Jamal",
    unreadCount: 123,
    lastMessagedAt: new Date(),
    profilePicture: images.defaultFemaleIcon,
    online: true,
    lastMessage: "how are you?",
  },
  {
    id: "yiuypo8765",
    name: "Sadia Khatun 2",
    unreadCount: 0,
    lastMessagedAt: new Date(),
    profilePicture: images.defaultFemaleIcon,
    online: true,
    lastMessage: "how are you?",
  },
  {
    id: "tuyuiurert",
    name: "Lata",
    unreadCount: 0,
    lastMessagedAt: new Date(),
    profilePicture: images.defaultFemaleIcon,
    online: false,
    lastMessage: "how are you?",
  },
  {
    id: "shejtykliupo85",
    name: "Anonymous",
    unreadCount: 12,
    lastMessagedAt: new Date(),
    profilePicture: images.defaultFemaleIcon,
    online: true,
    lastMessage: "how are you?",
  },
  {
    id: "yi8o8776534",
    name: "Sadia Khatun d",
    unreadCount: 12,
    lastMessagedAt: new Date(),
    profilePicture: images.defaultFemaleIcon,
    online: true,
    lastMessage: "how are you?",
  },
];

export default dummyMessages;
