import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Navigation, Pagination } from "swiper/modules";

import { InterestAction, PhotoreqAction } from "../../../store/actions";

import { SwiperNavButtons } from "../../../components/carousel/swiprnavbutton";

import DashboardCard from "../../../components/dashboardcard/dashboardcard";
import CardBottomButton from "../../../components/dashboardcard/cardbottombutton";

import useScreenSize from "../../../utils/usescreensize";

import images from "../../../assets";

import "swiper/css";
import "swiper/css/pagination";
import "./styles.css";

const SimilarProfileCarousel = (props) => {
  const screensize = useScreenSize();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const collapsed = useSelector((state) => state.ui.collapsed);

  const data = props.data;

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setCurrentIndex(swiper.activeIndex);
  };

  const handleSendInterest = (id) => {
    const payload = {
      receiverId: id,
    };
    dispatch(InterestAction.sendInterest(payload));
  };

  const handleSendPhotoReq = (id) => {
    const payload = {
      receiverId: id,
    };
    dispatch(PhotoreqAction.sendPhotoreq(payload));
  };

  const calculateWidth = () => {
    if (collapsed) {
      if (screensize.width >= 1920) {
        return "86vw";
      } else if (screensize.width >= 1700 && screensize.width < 1920) {
        return "84vw";
      } else if (screensize.width >= 1500 && screensize.width < 1700) {
        return "81vw";
      } else if (screensize.width >= 1024 && screensize.width < 1500) {
        return "78vw";
      }
    } else {
      if (screensize.width >= 1920) {
        return "76vw";
      } else if (screensize.width >= 1700 && screensize.width < 1920) {
        return "73vw";
      } else if (screensize.width >= 1500 && screensize.width < 1700) {
        return "68vw";
      } else if (screensize.width >= 1024 && screensize.width < 1500) {
        return "66vw";
      }
    }
  };

  const calculatSlides = () => {
    if (screensize.width >= 1920) {
      return 7;
    } else if (screensize.width >= 1400 && screensize.width < 1920) {
      return 5;
    } else if (screensize.width >= 768 && screensize.width < 1400) {
      return 3;
    } else if (screensize.width >= 500 && screensize.width < 768) {
      return 2;
    } else {
      return 1;
    }
  };
  return (
    <Swiper
      onSlideChange={handleSlideChange}
      modules={[Navigation, Pagination, A11y]}
      spaceBetween={16}
      slidesPerView={calculatSlides()}
      style={{
        width: calculateWidth(),
        padding: "10px",
        margin: "0px",
      }}
    >
      {data?.map(
        (item, index) =>
          !item?.matchOfTheDay && (
            <SwiperSlide key={index}>
              <DashboardCard
                item={item}
                index={index}
                redirectedFrom={props?.from}
              >
                <CardBottomButton
                  icon={images.cardMessage}
                  onClick={() =>
                    navigate(
                      `/messages?username=${item?.username}&firstName=${item?.firstName}&lastName=${item?.lastName}&photo=${item?.profilePicture}`
                    )
                  }
                  title="Send Message"
                />
                <CardBottomButton
                  icon={images.cardPhotoReq}
                  onClick={() => handleSendPhotoReq(item?.username)}
                  title="Send Photo Request"
                />
                <CardBottomButton
                  icon={images.cardmatch}
                  onClick={() => handleSendInterest(item?.username)}
                  title="Send Interest"
                />
              </DashboardCard>
            </SwiperSlide>
          )
      )}

      <SwiperNavButtons index={currentIndex} />
    </Swiper>
  );
};

export default SimilarProfileCarousel;
