import {
  GET_INTERESTS,
  GET_INTERESTS_SUCCESS,
  GET_INTERESTS_FAILURE,
  GET_INTERESTS_ALL,
  GET_INTERESTS_ALL_SUCCESS,
  GET_INTERESTS_ALL_FAILURE,
  SEND_INTEREST,
  SEND_INTEREST_SUCCESS,
  SEND_INTEREST_FAILURE,
  UPDATE_INTEREST,
  UPDATE_INTEREST_SUCCESS,
  UPDATE_INTEREST_FAILURE,
} from "../constants";

export default class InterestAction {
  static getInterests(payload, cb) {
    return {
      type: GET_INTERESTS,
      payload,
      cb,
    };
  }
  static getInterestsSuccess(payload) {
    return {
      type: GET_INTERESTS_SUCCESS,
      payload,
    };
  }
  static getInterestsFailure() {
    return {
      type: GET_INTERESTS_FAILURE,
    };
  }
  static getInterestsAll(payload, cb) {
    return {
      type: GET_INTERESTS_ALL,
      payload,
      cb,
    };
  }
  static getInterestsAllSuccess(payload) {
    return {
      type: GET_INTERESTS_ALL_SUCCESS,
      payload,
    };
  }
  static getInterestsAllFailure() {
    return {
      type: GET_INTERESTS_ALL_FAILURE,
    };
  }
  static sendInterest(payload, cb) {
    return {
      type: SEND_INTEREST,
      payload,
      cb,
    };
  }
  static sendInterestSuccess(payload) {
    return {
      type: SEND_INTEREST_SUCCESS,
      payload,
    };
  }
  static sendInterestFailure() {
    return {
      type: SEND_INTEREST_FAILURE,
    };
  }
  static updateInterest(payload, cb) {
    return {
      type: UPDATE_INTEREST,
      payload,
      cb,
    };
  }
  static updateInterestSuccess(payload) {
    return {
      type: UPDATE_INTEREST_SUCCESS,
      payload,
    };
  }
  static updateInterestFailure() {
    return {
      type: UPDATE_INTEREST_FAILURE,
    };
  }
}
