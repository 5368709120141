import { put } from "redux-saga/effects";

import ActivityApiCaller from "../../config/api/activityapicaller";

import { MyNotesAction } from "../actions";

export default class MyNotesMiddleware {
  static *getNote({ payload, cb }) {
    try {
      const res = yield ActivityApiCaller.Get(
        `/activity/mynote/${payload.username}`
      );

      if (res?.status === 200) {
        yield put(MyNotesAction.getNoteSuccess(res.data?.data[0]));
      } else {
        yield put(MyNotesAction.getNoteFailure());
      }
      if (cb) {
        cb(res.data?.data[0]);
      }
    } catch (err) {
      console.log(err);
      yield put(MyNotesAction.getNoteFailure());
    }
  }
  static *getNotes({ payload, cb }) {
    try {
      const res = yield ActivityApiCaller.Get(
        `/activity/mynotes?from=${payload.from ?? ""}&size=${
          payload.size ?? ""
        }&sort=${payload.sort ?? ""}`
      );

      if (res?.status === 200) {
        yield put(
          MyNotesAction.getNotesSuccess({
            count: res.data?.count,
            data: res.data?.data,
          })
        );
      } else {
        yield put(MyNotesAction.getNotesFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      console.log(err);
      yield put(MyNotesAction.getNotesFailure());
    }
  }
  static *setNotes({ payload, cb }) {
    try {
      const res = yield ActivityApiCaller.Post(`/activity/mynote`, payload);

      if (res.status === 200) {
        yield put(MyNotesAction.setNotesSuccess(res.data.Data));
      } else {
        yield put(MyNotesAction.setNotesFailure());
      }
      if (cb) {
        cb(res.data.Data);
      }
    } catch (err) {
      console.log(err);
      // message.error("Something went wrong while sending note");
      yield put(MyNotesAction.setNotesFailure());
    }
  }
}
