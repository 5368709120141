import {
  GET_PROFILE_VIEWED,
  GET_PROFILE_VIEWED_SUCCESS,
  GET_PROFILE_VIEWED_FAILURE,
  GET_ALL_PROFILE_VIEWED,
  GET_ALL_PROFILE_VIEWED_SUCCESS,
  GET_ALL_PROFILE_VIEWED_FAILURE,
  GET_PROFILE_VIEWER,
  GET_PROFILE_VIEWER_SUCCESS,
  GET_PROFILE_VIEWER_FAILURE,
  SET_PROFILE_VIEW,
  SET_PROFILE_VIEW_SUCCESS,
  SET_PROFILE_VIEW_FAILURE,
} from "../constants";

export default class ProfileViewerAction {
  static getProfileViewed(payload, cb) {
    return {
      type: GET_PROFILE_VIEWED,
      payload,
      cb,
    };
  }
  static getProfileViewedSuccess(payload) {
    return {
      type: GET_PROFILE_VIEWED_SUCCESS,
      payload,
    };
  }
  static getProfileViewedFailure() {
    return {
      type: GET_PROFILE_VIEWED_FAILURE,
    };
  }
  static getAllProfileViewer(payload, cb) {
    return {
      type: GET_ALL_PROFILE_VIEWED,
      payload,
      cb,
    };
  }
  static getAllProfileViewerSuccess(payload) {
    return {
      type: GET_ALL_PROFILE_VIEWED_SUCCESS,
      payload,
    };
  }
  static getAllProfileViewerFailure() {
    return {
      type: GET_ALL_PROFILE_VIEWED_FAILURE,
    };
  }
  static getProfileViewer(payload, cb) {
    return {
      type: GET_PROFILE_VIEWER,
      payload,
      cb,
    };
  }
  static getProfileViewerSuccess(payload) {
    return {
      type: GET_PROFILE_VIEWER_SUCCESS,
      payload,
    };
  }
  static getProfileViewerFailure() {
    return {
      type: GET_PROFILE_VIEWER_FAILURE,
    };
  }
  static setProfileView(payload, cb) {
    return {
      type: SET_PROFILE_VIEW,
      payload,
      cb,
    };
  }
  static setProfileViewSuccess(payload) {
    return {
      type: SET_PROFILE_VIEW_SUCCESS,
      payload,
    };
  }
  static setProfileViewFailure() {
    return {
      type: SET_PROFILE_VIEW_FAILURE,
    };
  }
}
