import React, { useState } from "react";
import ProfileTab from "../../components/tab/profiletab";
import Spacer from "../../components/spacer/spacer";
import { settingsItems } from "../../utils/arrayitems";
import useScreenSize from "../../utils/usescreensize";
import AccountSettings from "./accountsettings";
import Billing from "./billing";
import NotificationPref from "./notificationpref";
import Support from "./support";

import "./styles.css";
import UnderHeaderMenu from "../../components/underheadermenu";

const Settings = () => {
  const screenSize = useScreenSize();
  const [activeTab, setActiveTab] = useState("accountSettings");

  const handleTab = (name) => {
    setActiveTab(name);
  };

  return (
    <>
      {screenSize.width < 1023 && (
        <UnderHeaderMenu titlename="Settings"></UnderHeaderMenu>
      )}
      <div className="settings-container">
        <div className="settings-top-part-container">
          {screenSize.width > 1023 && (
            <>
              <h1 className="settings-title">Settings</h1>
              <Spacer height="4px" />
            </>
          )}
          <p className="settings-subtitle">
            Manage your account, explore membership options, adjust notification
            settings, and get support when you need it.
          </p>
          <Spacer height="16px" />
          <div style={{ width: screenSize.width < 768 ? "100%" : "420px" }}>
            <ProfileTab
              tabItems={settingsItems}
              handleTab={handleTab}
              activeTab={activeTab}
              style={{
                background: "white",
                border: "1px solid #EAE6ED",
              }}
            />
          </div>
        </div>
        <div className="content-container">
          {activeTab === "accountSettings" ? (
            <AccountSettings />
          ) : activeTab === "billing" ? (
            <Billing />
          ) : activeTab === "notification" ? (
            <NotificationPref />
          ) : activeTab === "support" ? (
            <Support />
          ) : (
            <AccountSettings />
          )}
        </div>
      </div>
    </>
  );
};

export default Settings;
