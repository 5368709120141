import React from "react";
import { Select } from "antd";
import images from "../../assets";
import "./styles.css";

const EditProfileDropdown = (props) => {
  const filterOption = (input, option) => {
    return (option?.value?.toString() ?? "")
      .toLowerCase()
      .startsWith(input.toLowerCase());
  };
  const filterOptionCountry = (input, option) => {
    return (option?.children ?? "")
      .toLowerCase()
      .startsWith(input.toLowerCase());
  };

  return (
    <div className="edit-profile-input mobile">
      <Select
        {...props}
        suffixIcon={<img src={images.dropdownIcon} alt="dropdown" />}
        showSearch
        placeholder={props?.placeholder || "Please select"}
        optionFilterProp="children"
        filterOption={
          props?.list === "country" || props?.list === "stateProvince"
            ? filterOptionCountry
            : filterOption
        }
        className="edit-profile-select"
      >
        {props?.data?.map((item, index) => {
          if (props?.list === "stateProvince") {
            if (!item?.name?.includes("Division")) {
              return (
                <Select.Option
                  key={index}
                  value={item.name?.replace("District", "")}
                >
                  {item.name?.replace("District", "")}
                </Select.Option>
              );
            }
          } else if (props?.list === "country") {
            return (
              <Select.Option key={item.isoCode} value={JSON.stringify(item)}>
                {item.name}
              </Select.Option>
            );
          } else {
            return (
              <Select.Option key={index} value={item.value}>
                {item.label}
              </Select.Option>
            );
          }
        })}
      </Select>
    </div>
  );
};

export default EditProfileDropdown;
