import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Divider } from "antd";

import ReceiverMessageCard from "./receivermessagecard";
import SenderMessageCard from "./sendermessagecard";
import FailedMessageCard from "./failedmessagecard";

import images from "../../assets";

const formatDividerDate = (date) => {
  const now = moment();
  const givenDate = moment(date);

  if (now.isSame(givenDate, "day")) {
    return "Today";
  } else if (now.subtract(1, "day").isSame(givenDate, "day")) {
    return "Yesterday";
  } else {
    return givenDate.format("MMMM Do YYYY");
  }
};

export default function PremiumMessage(props) {
  const messageEndRef = useRef(null);

  const profileData = useSelector((state) => state?.profile?.profile);

  useEffect(() => {
    scrollToBottom();
  }, [props.messages]);

  const scrollToBottom = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  let lastMessageDate = null;

  return (
    <div className="premium-message-body-content">
      {props?.messages?.map((item, index) => {
        const messageDate = moment(item?.createdAt).startOf("day");
        let showDivider = false;

        if (!lastMessageDate || !lastMessageDate.isSame(messageDate, "day")) {
          showDivider = true;
          lastMessageDate = messageDate;
        }

        return (
          <div key={index}>
            {showDivider && (
              <div className="premium-message-body-timer">
                <Divider className="premium-message-body-time">
                  {formatDividerDate(item?.createdAt)}
                </Divider>
              </div>
            )}
            {item?.roomId === props?.activeUser?.id &&
              (item?.content?.sender !== profileData?.username ? (
                <ReceiverMessageCard
                  delivertime={moment(item?.createdAt).format("h:mm A")}
                  textmessage={item?.content?.text}
                />
              ) : item?.failed ? (
                <FailedMessageCard textmessage={item?.message} />
              ) : (
                <SenderMessageCard
                  icon={
                    item?.status === "read"
                      ? images.messageStatusSucess
                      : images?.messageStatusNotReceive
                  }
                  delivertime={moment(item?.createdAt).format("h:mm A")}
                  textmessage={item?.content?.text}
                />
              ))}
          </div>
        );
      })}
      <div ref={messageEndRef} />
    </div>
  );
}
