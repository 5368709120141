const heightList = [
  { label: "4'5\" (134 cm)", value: 134 },
  { label: "4'6\" (137 cm)", value: 137 },
  { label: "4'7\" (139 cm)", value: 139 },
  { label: "4'8\" (142 cm)", value: 142 },
  { label: "4'9\" (144 cm)", value: 144 },
  { label: "4'10\" (147 cm)", value: 147 },
  { label: "4'11\" (149 cm)", value: 149 },
  { label: "5' (152 cm)", value: 152 },
  { label: "5'1\" (155 cm)", value: 155 },
  { label: "5'2\" (158 cm)", value: 158 },
  { label: "5'3\" (161 cm)", value: 161 },
  { label: "5'4\" (163 cm)", value: 163 },
  { label: "5'5\" (166 cm)", value: 166 },
  { label: "5'6\" (168 cm)", value: 168 },
  { label: "5'7\" (171 cm)", value: 171 },
  { label: "5'8\" (173 cm)", value: 173 },
  { label: "5'9\" (176 cm)", value: 176 },
  { label: "5'10\" (178 cm)", value: 178 },
  { label: "5'11\" (181 cm)", value: 181 },
  { label: "6' (183 cm)", value: 183 },
  { label: "6'1\" (185 cm)", value: 185 },
  { label: "6'2\" (188 cm)", value: 188 },
  { label: "6'3\" (191 cm)", value: 191 },
  { label: "6'4\" (194 cm)", value: 194 },
  { label: "6'5\" (196 cm)", value: 196 },
  { label: "6'6\" (199 cm)", value: 199 },
  { label: "6'7\" (201 cm)", value: 201 },
  { label: "6'8\" (204 cm)", value: 204 },
  { label: "6'9\" (206 cm)", value: 206 },
  { label: "6'10\" (209 cm)", value: 209 },
  { label: "6'11\" (211 cm)", value: 211 },
  { label: "7' (214 cm)", value: 214 },
  { label: "7'1\" (216 cm)", value: 216 },
  { label: "7'2\" (219 cm)", value: 219 },
];
export default heightList;
