const apiKey =
  process.env.REACT_APP_ENV === "production"
    ? process.env.REACT_APP_APPSYNC_API_KEY_PROD
    : process.env.REACT_APP_ENV === "staging"
    ? process.env.REACT_APP_APPSYNC_API_KEY_STAGING
    : process.env.REACT_APP_APPSYNC_API_KEY_LOCAL;

const url =
  process.env.REACT_APP_ENV === "production"
    ? process.env.REACT_APP_MESSAGE_BACKEND_URL_PROD
    : process.env.REACT_APP_ENV === "staging"
    ? process.env.REACT_APP_MESSAGE_BACKEND_URL_STAGING
    : process.env.REACT_APP_MESSAGE_BACKEND_URL_LOCAL;

const awsmobile = {
  aws_project_region: "us-east-2",
  aws_appsync_graphqlEndpoint: url,
  aws_appsync_region: "us-east-2",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: apiKey,
};

export default awsmobile;
