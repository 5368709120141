const beard = [
  { label: "Mustache", value: "Mustache" },
  { label: "Sunnah Beard", value: "Sunnah Beard" },
  { label: "Short Beard", value: "Short Beard" },
  { label: "Medium Beard", value: "Medium Beard" },
  { label: "Long Beard", value: "Long Beard" },
  { label: "Clean Shaven", value: "Clean Shaven" },
  { label: "Goatee", value: "Goatee" },
  { label: "Other", value: "Other" },
];
export default beard;
