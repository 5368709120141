import React from "react";
import Spacer from "../../components/spacer/spacer";
import { Radio, Space } from "antd";

const alerts = [
  { label: "Instant", value: "Instant" },
  {
    label: "Daily - All requests received in a day",
    value: "Daily - All requests received in a day",
  },
  { label: "Unsubscribe", value: "Unsubscribe" },
];

const NotificationCheckbox = (props) => {
  return (
    <>
      <h5 className="settings-content-subheader h-9 flex items-center">
        {props?.title}
      </h5>
      <Spacer height="12px" />
      <p className="settings-content-subheader-subheader">{props?.subtitle}</p>

      <Spacer height="8px" />
      <div className="settings-notification-checkbox-container">
        <h5 className="checkbox-container-text">Send Email Alert:</h5>
        <Radio.Group {...props}>
          <Space direction="vertical">
            {alerts?.map((item, index) => (
              <Radio value={item?.value} key={index}>
                {item?.label}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </div>
    </>
  );
};

export default NotificationCheckbox;
