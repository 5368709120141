const occupationStatus = [
  { label: "Full Time", value: "Full Time" },
  { label: "Part Time", value: "Part Time" },
  { label: "Not Employed", value: "Not Employed" },
  { label: "Student", value: "Student" },
  { label: "Homemaker", value: "Homemaker" },
  { label: "Retired", value: "Retired" },
  { label: "Other", value: "Other" },
];
export default occupationStatus;
