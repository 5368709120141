import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { AuthAction, ProfileAction } from "../../store/actions";

import EmailForm from "./emailform";
import OtpForm from "./otpform";

import "./styles.css";

const containsNonNumericCharacters = (str) => {
  return /\D/.test(str);
};

const ChangeEmail = (props) => {
  const dispatch = useDispatch();

  const [page, setPage] = useState("email");
  const [resendDisabled, setResendDisabled] = useState(false);

  const [state, setState] = useState({
    email: "",
    otp: "",
    error: "",
  });

  const handleEmailSubmit = () => {
    if (!state.email) {
      setState({ ...state, error: "Email is required" });
    } else {
      setState({ ...state, error: "" });
      const payload = { email: state?.email };
      dispatch(
        AuthAction.emailChangeReq(payload, (res) => {
          if (res === 200) {
            setPage("otp");
          }
        })
      );
    }
  };

  const handleOtpSubmit = () => {
    if (!state.otp) {
      setState({ ...state, error: "Enter OTP" });
    } else if (containsNonNumericCharacters(state.otp)) {
      setState({ ...state, error: "OTP must be a number" });
    } else {
      setState({ ...state, error: "" });
      const payload = { code: state.otp, email: state?.email };
      dispatch(
        AuthAction.emailChangeVerify(payload, (res) => {
          if (res === 200) {
            props?.setIsModalOpen(false);
            dispatch(ProfileAction.getProfile());
          }
        })
      );
    }
  };
  const handleResendOTP = () => {
    console.log("in otp resend");
    const payload = { username: state?.email };
    dispatch(AuthAction.resendOtp(payload));
  };

  return (
    <div className="forgot-password-container">
      {page === "otp" ? (
        <OtpForm
          state={state}
          setState={setState}
          page={page}
          setPage={setPage}
          handleOtpSubmit={handleOtpSubmit}
          handleResendOTP={handleResendOTP}
          resendDisabled={resendDisabled}
          setResendDisabled={setResendDisabled}
        />
      ) : (
        <EmailForm
          state={state}
          setState={setState}
          page={page}
          setPage={setPage}
          handleEmailSubmit={handleEmailSubmit}
        />
      )}
    </div>
  );
};

export default ChangeEmail;
