import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Progress, Popover, message, Avatar } from "antd";
import { useNavigate, useLocation } from "react-router-dom";

import { UserOutlined } from "@ant-design/icons";

import { ProfileAction } from "../../../store/actions";
import useScreenSize from "../../../utils/usescreensize";
import Loader from "../../loader/loader";
import Spacer from "../../spacer/spacer";

import PrintDownloadCom from "./printdownload";
import ProfileInfoCom from "./profileinfo";
import PercentageComponent from "./percentagecomponent";
import PercentageComponentMatchPref from "./percentagecomponentmatchpref";
import UnderHeaderMenu from "../../underheadermenu";

import images from "../../../assets/index";

import "./styles.css";

const ProfileLayout = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const screenSize = useScreenSize();
  const location = useLocation();

  const pathName = location.pathname;

  const [open, setOpen] = useState(false);

  const profileData = useSelector((state) => {
    return state?.profile?.profile;
  });
  const profileLoading = useSelector((state) => {
    return state?.profile?.isLoading;
  });

  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  return (
    <>
      {screenSize.width < 1023 && (
        <UnderHeaderMenu
          titlename={
            pathName.includes("/edit-profile")
              ? "Edit Profile"
              : pathName.includes("/match-preference")
              ? "Match Preference"
              : pathName.includes("/gallery")
              ? "Photo Gallery"
              : pathName.includes("/trust-badge")
              ? "Trust Badge"
              : "My Profile"
          }
          navigateTo={"/dashboard"}
        >
          {pathName === "/edit-profile" ||
          pathName === "/match-preference" ||
          pathName === "/trust-badge" ||
          pathName === "/gallery" ? (
            ""
          ) : (
            <p onClick={() => navigate("/edit-profile")}>Edit Profile</p>
          )}
        </UnderHeaderMenu>
      )}
      <div className="profile-layout">
        <div className="profile-layout-inner">
          {(screenSize.width < 1023 && pathName === "/edit-profile") ||
          (screenSize.width < 1023 && pathName === "/gallery") ||
          (screenSize.width < 1023 && pathName === "/trust-badge") ||
          (screenSize.width < 1023 && pathName === "/match-preference") ? (
            ""
          ) : (
            <div
              style={{
                background: `radial-gradient(120% 110% at 80% 160%, #001528 74%, #073AFF00 92%),radial-gradient(150% 80% at 120% 115%, #02305E 70%, #073AFF00 88%),radial-gradient(140% 140% at 110% 140%, #5D4D8C 76%, #5E519200 92%, #073AFF00 100%),radial-gradient(75% 75% at 50% 50%, #362955 100%, #073AFF00 100%)`,
                backgroundSize: "100% 100%",
              }}
              className="profile-head"
            >
              <div className="profile-head-inner">
                <div className="profile-info">
                  <div className="profile-info-thumb">
                    {profileLoading ? (
                      <Loader />
                    ) : (
                      <>
                        {!profileData?.gender ? (
                          <Avatar
                            size={192}
                            icon={<UserOutlined />}
                            shape="square"
                          />
                        ) : (
                          <img
                            src={
                              profileData?.profilePicture ??
                              (profileData?.gender === "male"
                                ? images.defaultMaleIcon
                                : images.defaultFemaleIcon)
                            }
                            className="object-cover"
                            alt=""
                          />
                        )}
                      </>
                    )}
                    <p className="profile-id">
                      User ID: {profileData?.username}&nbsp;
                      <CopyToClipboard
                        text={profileData?.username}
                        onCopy={() => {
                          message.success("Copied to clipboard");
                        }}
                      >
                        <ion-icon
                          style={{ cursor: "pointer" }}
                          name="copy-outline"
                        ></ion-icon>
                      </CopyToClipboard>
                    </p>
                  </div>
                  <div className="profile-info-content">
                    <ProfileInfoCom profileData={profileData} />
                    <div className="profile-completion">
                      <Popover
                        overlayClassName="profile-completion-popup-area"
                        content={
                          <div className="profile-completion-popup">
                            <div className="profile-completion-popup-heading">
                              <h2>Profile Completeness</h2>
                              <div
                                onClick={hide}
                                className="completion-popup-close"
                              >
                                <img
                                  src={images.progressInfoClose}
                                  alt="Progress Close"
                                />
                              </div>
                            </div>
                            <div className="max-h-[300px] overflow-auto">
                              <div className="profile-completion-popup-progress">
                                <Progress
                                  type="dashboard"
                                  percent={profileData?.profileCompleteness}
                                  gapDegree={180}
                                  strokeColor="#17C964"
                                  className="profile-popup-progress"
                                />
                                <span className="completed-text-progress">
                                  Completed
                                </span>
                              </div>

                              <div className="improve-match-info-content2">
                                <div className="completion-popup-content-title">
                                  My Profile
                                </div>
                                <PercentageComponent
                                  profileCompleteBreak={
                                    profileData?.profileCompleteBreak
                                  }
                                />
                                {/* <Spacer height="6px" />
                                <div className="completion-popup-content-title">
                                  My Match Preferences
                                </div>
                                <PercentageComponentMatchPref
                                  profileCompleteBreak={
                                    profileData?.profileCompleteBreak
                                  }
                                /> */}
                              </div>
                            </div>
                          </div>
                        }
                        trigger="click"
                        open={open}
                        onOpenChange={handleOpenChange}
                        placement="bottomRight"
                      >
                        <div className="progress-info-icon">
                          <img src={images.progressInfo} alt="Progress info" />
                        </div>
                      </Popover>

                      <h3>Profile Completeness</h3>
                      <Progress
                        className="progress-bar"
                        percent={profileData?.profileCompleteness}
                        showInfo={false}
                        trailColor={"#ffffff"}
                        strokeColor={"#17C964"}
                      />
                      <p className="complete-progess">
                        {profileData?.profileCompleteness}% Profile Completed
                      </p>
                      {/* <p className="completion-trust">
                        Next: Get a &nbsp;<span>Trust Badge</span>&nbsp;(+5%)
                      </p> */}
                    </div>
                  </div>
                </div>

                <PrintDownloadCom profileData={profileData} />
              </div>
            </div>
          )}
          <div className="profile-body">
            <div className="profile-body-inner">
              {profileLoading ? (
                <div className="p-6 h-[400px] flex justify-center items-center">
                  <Loader />
                </div>
              ) : (
                <>{props?.children}</>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileLayout;
