import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { InterestAction, PhotoreqAction } from "../../store/actions";

import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Navigation, Pagination } from "swiper/modules";
import { SwiperNavButtons } from "./swiprnavbutton";

import DashboardCard from "../dashboardcard/dashboardcard";
import CardBottomButton from "../dashboardcard/cardbottombutton";

import useScreenSize from "../../utils/usescreensize";

import images from "../../assets";

import "swiper/css";
import "swiper/css/pagination";
import "./styles.css";

const Carousel = (props) => {
  const screensize = useScreenSize();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const collapsed = useSelector((state) => state.ui.collapsed);
  const { photoreq } = useSelector((state) => state.photoreq);
  const { interests } = useSelector((state) => state.interests);

  const data = props.data;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isEnd, setIsEnd] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(true);

  const handleSlideChange = (swiper) => {
    setCurrentIndex(swiper.activeIndex);
    setIsEnd(swiper.isEnd);
  };

  const handleSendInterest = (id) => {
    const payload = {
      receiverId: id,
    };
    dispatch(InterestAction.sendInterest(payload));
  };

  const handleSendPhotoReq = (id) => {
    const payload = {
      receiverId: id,
    };
    dispatch(PhotoreqAction.sendPhotoreq(payload));
  };

  const calculateWidth = () => {
    if (collapsed) {
      if (screensize.width >= 1920) {
        return "90vw";
      } else if (screensize.width >= 1700 && screensize.width < 1920) {
        return "87vw";
      } else if (screensize.width >= 1500 && screensize.width < 1700) {
        return "85vw";
      } else if (screensize.width >= 1024 && screensize.width < 1500) {
        return "82vw";
      }
    } else {
      if (screensize.width >= 1920) {
        return "80vw";
      } else if (screensize.width >= 1700 && screensize.width < 1920) {
        return "77vw";
      } else if (screensize.width >= 1500 && screensize.width < 1700) {
        return "72vw";
      } else if (screensize.width >= 1024 && screensize.width < 1500) {
        return "70vw";
      }
    }
  };

  const calculatSlides = () => {
    if (screensize.width >= 1400) {
      return 5;
    } else if (screensize.width >= 768 && screensize.width < 1400) {
      return 3;
    } else if (screensize.width >= 500 && screensize.width < 768) {
      return 2;
    } else {
      return 1;
    }
  };

  const calculateOverflowing = () => {
    const slidesToShow = calculatSlides();
    return data?.length > slidesToShow;
  };

  useEffect(() => {
    setIsOverflowing(calculateOverflowing());
  }, [screensize.width, data]);

  return (
    <Swiper
      onSlideChange={handleSlideChange}
      modules={[Navigation, Pagination, A11y]}
      spaceBetween={16}
      slidesPerView={calculatSlides()}
      style={{
        width: calculateWidth(),
        padding: "10px",
        margin: "0px",
      }}
    >
      {data?.map((item, index) => {
        if (!item?.matchOfTheDay) {
          const interestSent = interests?.some(
            (profile) => profile.username === item?.username
          );

          const photoreqSent = photoreq?.some(
            (profile) => profile.username === item?.username
          );
          return (
            <SwiperSlide key={index}>
              <DashboardCard
                item={item}
                index={index}
                redirectedFrom={props?.redirectedFrom}
              >
                <CardBottomButton
                  icon={images.cardMessage}
                  onClick={() =>
                    navigate(
                      `/messages?username=${item?.username}&firstName=${item?.firstName}&lastName=${item?.lastName}&photo=${item?.profilePicture}`
                    )
                  }
                  title="Send Message"
                />
                <CardBottomButton
                  icon={images.cardPhotoReq}
                  onClick={() => handleSendPhotoReq(item?.username)}
                  title="Send Photo Request"
                  disabled={photoreqSent}
                />
                <CardBottomButton
                  icon={images.cardmatch}
                  onClick={() => handleSendInterest(item?.username)}
                  title="Send Interest"
                  disabled={interestSent}
                />
              </DashboardCard>
            </SwiperSlide>
          );
        }
      })}
      <SwiperNavButtons
        index={currentIndex}
        isEnd={isEnd}
        isOverflowing={isOverflowing}
      />
    </Swiper>
  );
};

export default Carousel;
