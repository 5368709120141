import {
  CHANGE_REQ,
  CHANGE_REQ_SUCCESS,
  CHANGE_REQ_FAILURE,
  CHANGE_PROFILE_STATUS,
  CHANGE_PROFILE_STATUS_SUCCESS,
  CHANGE_PROFILE_STATUS_FAILURE,
  DELETE_PHOTO,
  DELETE_PHOTO_SUCCESS,
  DELETE_PHOTO_FAILURE,
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  GET_TRUST_BADGE,
  GET_TRUST_BADGE_SUCCESS,
  GET_TRUST_BADGE_FAILURE,
  DELETE_TRUST_BADGE,
  DELETE_TRUST_BADGE_SUCCESS,
  DELETE_TRUST_BADGE_FAILURE,
  REMOVE_PROFILE_STORE,
  REMOVE_PROFILE_STORE_SUCCESS,
  REMOVE_PROFILE_STORE_FAILURE,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_PIC,
  UPDATE_PROFILE_PIC_SUCCESS,
  UPDATE_PROFILE_PIC_FAILURE,
  UPLOAD_PHOTO,
  UPLOAD_PHOTO_SUCCESS,
  UPLOAD_PHOTO_FAILURE,
  UPLOAD_TRUST_BADGE,
  UPLOAD_TRUST_BADGE_SUCCESS,
  UPLOAD_TRUST_BADGE_FAILURE,
} from "../constants";

export default class ProfileAction {
  static changeRequest(payload, cb) {
    return {
      type: CHANGE_REQ,
      payload,
      cb,
    };
  }
  static changeRequestSuccess(payload) {
    return {
      type: CHANGE_REQ_SUCCESS,
      payload,
    };
  }
  static changeRequestFailure() {
    return {
      type: CHANGE_REQ_FAILURE,
    };
  }
  static changeProfileStatus(payload, cb) {
    return {
      type: CHANGE_PROFILE_STATUS,
      payload,
      cb,
    };
  }
  static changeProfileStatusSuccess(payload) {
    return {
      type: CHANGE_PROFILE_STATUS_SUCCESS,
      payload,
    };
  }
  static changeProfileStatusFailure() {
    return {
      type: CHANGE_PROFILE_STATUS_FAILURE,
    };
  }
  static deletePhoto(payload, cb) {
    return {
      type: DELETE_PHOTO,
      payload,
      cb,
    };
  }
  static deletePhotoSuccess(payload) {
    return {
      type: DELETE_PHOTO_SUCCESS,
      payload,
    };
  }
  static deletePhotoFailure() {
    return {
      type: DELETE_PHOTO_FAILURE,
    };
  }
  static getProfile(payload, cb) {
    return {
      type: GET_PROFILE,
      payload,
      cb,
    };
  }
  static getProfileSuccess(payload) {
    return {
      type: GET_PROFILE_SUCCESS,
      payload,
    };
  }
  static getProfileFailure() {
    return {
      type: GET_PROFILE_FAILURE,
    };
  }
  static removeProfileStore(cb) {
    return {
      type: REMOVE_PROFILE_STORE,
      cb,
    };
  }
  static removeProfileStoreSuccess() {
    return {
      type: REMOVE_PROFILE_STORE_SUCCESS,
    };
  }
  static removeProfileStoreFailure() {
    return {
      type: REMOVE_PROFILE_STORE_FAILURE,
    };
  }
  static updateProfile(payload, cb) {
    return {
      type: UPDATE_PROFILE,
      payload,
      cb,
    };
  }
  static updateProfileSuccess(payload) {
    return {
      type: UPDATE_PROFILE_SUCCESS,
      payload,
    };
  }
  static updateProfileFailure() {
    return {
      type: UPDATE_PROFILE_FAILURE,
    };
  }

  static updateProfilePic(payload, cb) {
    return {
      type: UPDATE_PROFILE_PIC,
      payload,
      cb,
    };
  }
  static updateProfilePicSuccess(payload) {
    return {
      type: UPDATE_PROFILE_PIC_SUCCESS,
      payload,
    };
  }
  static updateProfilePicFailure() {
    return {
      type: UPDATE_PROFILE_PIC_FAILURE,
    };
  }
  static uploadPhoto(payload, cb) {
    return {
      type: UPLOAD_PHOTO,
      payload,
      cb,
    };
  }
  static uploadPhotoSuccess(payload) {
    return {
      type: UPLOAD_PHOTO_SUCCESS,
      payload,
    };
  }
  static uploadPhotoFailure() {
    return {
      type: UPLOAD_PHOTO_FAILURE,
    };
  }
  static uploadTrustBadge(payload, cb) {
    return {
      type: UPLOAD_TRUST_BADGE,
      payload,
      cb,
    };
  }
  static uploadTrustBadgeSuccess(payload) {
    return {
      type: UPLOAD_TRUST_BADGE_SUCCESS,
      payload,
    };
  }
  static uploadTrustBadgeFailure() {
    return {
      type: UPLOAD_TRUST_BADGE_FAILURE,
    };
  }
  static getTrustBadge(payload, cb) {
    return {
      type: GET_TRUST_BADGE,
      payload,
      cb,
    };
  }
  static getTrustBadgeSuccess(payload) {
    return {
      type: GET_TRUST_BADGE_SUCCESS,
      payload,
    };
  }
  static getTrustBadgeFailure() {
    return {
      type: GET_TRUST_BADGE_FAILURE,
    };
  }
  static deleteTrustBadge(payload, cb) {
    return {
      type: DELETE_TRUST_BADGE,
      payload,
      cb,
    };
  }
  static deleteTrustBadgeSuccess(payload) {
    return {
      type: DELETE_TRUST_BADGE_SUCCESS,
      payload,
    };
  }
  static deleteTrustBadgeFailure() {
    return {
      type: DELETE_TRUST_BADGE_FAILURE,
    };
  }
}
