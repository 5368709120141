import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ProfileAction } from "../../store/actions";

import EditProfileBoxLabel from "../../components/label/editprofileboxlabel";
import EditProfileMultipleSelect from "../../components/dropdown/editprofilemultipleselection";
import PrimaryButton from "../../components/button/primarybutton";
import Loader from "../../components/loader/loader";
import Spacer from "../../components/spacer/spacer";

import {
  food,
  music,
  personalityTraits,
  hobbies,
  books,
  countries,
  weekend,
} from "../../utils/arrayitems";

import images from "../../assets";

import TabHeading from "./tabheading";
import "./styles.css";

const PersonalityHobby = (props) => {
  const dispatch = useDispatch();
  const profileLoading = useSelector((state) => state?.profile?.isLoading);
  const profileData = useSelector((state) => state?.profile?.profile);

  const [isDisabled, setDisabled] = useState(false);
  const [state, setState] = useState({
    foodPreference: [],
    musicPreference: [],
    personalityTraits: [],
    visitedCountries: [],
    weekendPreference: [],
    bookPreference: [],
    hobbies: [],
  });

  useEffect(() => {
    setState({
      ...state,
      foodPreference: profileData?.foodPreference ?? [],
      musicPreference: profileData?.musicPreference ?? [],
      personalityTraits: profileData?.personalityTraits ?? [],
      visitedCountries: profileData?.visitedCountries ?? [],
      weekendPreference: profileData?.weekendPreference ?? [],
      bookPreference: profileData?.bookPreference ?? [],
      hobbies: profileData?.hobbies ?? [],
    });
  }, [profileLoading]);

  const handleSubmit = () => {
    setDisabled(true);
    const payload = state;

    dispatch(
      ProfileAction.updateProfile(payload, () => {
        setDisabled(false);
        dispatch(ProfileAction.getProfile());
        props?.handleTab("profileDesc");
      })
    );
  };

  return (
    <>
      <TabHeading
        image={images.personalityHobbiesIcon}
        title="Personality & Hobbies"
        subtitle="Complete to easily find your ideal partner!"
      />
      <div className="edit-profile-content">
        {profileLoading ? (
          <Loader />
        ) : (
          <>
            {/* What sort of food do you like? */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="What sort of food do you like?" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="What sort of food do you like?"
              data={food}
              onChange={(e) => setState({ ...state, foodPreference: e })}
              value={state.foodPreference || undefined}
            />
            <Spacer height="12px" />
            {/* What sort of music do you like? */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="What sort of music do you like?" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="What sort of music do you like?"
              data={music}
              onChange={(e) => setState({ ...state, musicPreference: e })}
              value={state.musicPreference || undefined}
            />
            <Spacer height="12px" />
            {/* What are your main personality traits? */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="What are your main personality traits?" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="What are your main personality traits?"
              data={personalityTraits}
              onChange={(e) => setState({ ...state, personalityTraits: e })}
              value={state.personalityTraits || undefined}
            />
            <Spacer height="12px" />
            {/* What are the countries you visited? */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="What are the countries you visited?" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="What are the countries you visited?"
              data={countries}
              onChange={(e) => setState({ ...state, visitedCountries: e })}
              value={state.visitedCountries || undefined}
            />
            <Spacer height="12px" />
            {/* How would you like to spend weekend with your family? */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="How would you like to spend weekend with your family?" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="How would you like to spend weekend with your family?"
              data={weekend}
              onChange={(e) => setState({ ...state, weekendPreference: e })}
              value={state.weekendPreference || undefined}
            />
            <Spacer height="12px" />
            {/* What are the types of books you like? */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="What are the types of books you like?" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="What are the types of books you like?"
              data={books}
              onChange={(e) => setState({ ...state, bookPreference: e })}
              value={state.bookPreference || undefined}
            />
            <Spacer height="12px" />

            {/* What are your hobbies? */}
            <div className="edit-profile-label-container">
              <EditProfileBoxLabel label="What are your hobbies?" />
            </div>
            <Spacer height="8px" />
            <EditProfileMultipleSelect
              placeholder="What are your hobbies?"
              data={hobbies}
              onChange={(e) => setState({ ...state, hobbies: e })}
              value={state.hobbies || undefined}
            />
          </>
        )}
        <Spacer height="32px" />
        <PrimaryButton
          text="Save & Continue"
          onClick={handleSubmit}
          disabled={isDisabled}
        />
      </div>
    </>
  );
};

export default PersonalityHobby;
