export default function MembershipList(props) {
  return (
    <div {...props} className="flex flex-row justify-between">
      <div className="w-full border-solid border-b border-r border-[#E5E7EB] p-2 px-2 md:px-4 lg:px-6 py-5">
        <h3 className="text-sm leading-5 font-light">{props.headingtext}</h3>
      </div>
      <div className="w-[200px] flex flex-col items-center justify-center gap-8 border-solid border-b border-r border-[#E5E7EB] px-6 py-5">
        <img className="w-[20px]" src={props.icon1} alt="" />
      </div>
      <div className="w-[200px] flex flex-col items-center justify-center gap-8 border-solid border-b border-[#E5E7EB] px-6 py-5">
        <img className="w-[20px]" src={props.icon2} alt="" />
      </div>
    </div>
  );
}
