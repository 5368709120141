import React from "react";
import images from "../../assets";

import "./styles.css";

const PendingButton = () => {
  return (
    <div className="pending-button-style">
      <img src={images.pendingIcon} alt="change" />
      <p
        className="whitespace-nowrap overflow-hidden text-ellipsis"
        title={"Pending"}
      >
        Pending
      </p>
    </div>
  );
};

export default PendingButton;
