import BasicInfoColumn from "../../components/profilecard/basicinfocolumn";
import convertedCentoFeet from "../../utils/convertdenttofeet";

import images from "../../assets";
import "./styles.css";

export default function PhysicalAppeCard(props) {
  let feetData = convertedCentoFeet(props?.othersProfileData?.height);

  return (
    <div className="physical-appearance basic-info-card">
      <div className="basic-info-head">
        <img
          src={images.physicalAppreIcon}
          alt="Physical Appearance & Lifestyle"
        />{" "}
        <span>Physical Appearance & Lifestyle</span>
      </div>
      <div className="basic-info-content">
        {props?.othersProfileData?.height && (
          <BasicInfoColumn
            titlename="Height"
            values={`${feetData} (${props?.othersProfileData?.height} cm)`}
          />
        )}
        {props?.othersProfileData?.hairColor && (
          <BasicInfoColumn
            titlename="Hair Color"
            values={props?.othersProfileData?.hairColor}
          />
        )}
        {props?.othersProfileData?.hairType && (
          <BasicInfoColumn
            titlename="Hair Type"
            values={props?.othersProfileData?.hairType}
          />
        )}
        {props?.othersProfileData?.hairLength && (
          <BasicInfoColumn
            titlename="Hair Length"
            values={props?.othersProfileData?.hairLength}
          />
        )}
        {props?.othersProfileData?.complexion && (
          <BasicInfoColumn
            titlename="Complexion"
            values={props?.othersProfileData?.complexion}
          />
        )}
        {props?.othersProfileData?.bloodGroup && (
          <BasicInfoColumn
            titlename="Blood Group"
            values={props?.othersProfileData?.bloodGroup}
          />
        )}
        {props?.othersProfileData?.foodHabit?.length > 0 && (
          <BasicInfoColumn
            titlename="Food Habit"
            values={props?.othersProfileData?.foodHabit?.map((item, index) => {
              if (index === 0) {
                return item;
              } else {
                return `, ${item}`;
              }
            })}
          />
        )}
        {props?.othersProfileData?.healthCondition && (
          <BasicInfoColumn
            titlename="Health Condition"
            values={props?.othersProfileData?.healthCondition}
          />
        )}
        {props?.othersProfileData?.bodyType && (
          <BasicInfoColumn
            titlename="Body Type"
            values={props?.othersProfileData?.bodyType}
          />
        )}
        {props?.othersProfileData?.physicalStatus && (
          <BasicInfoColumn
            titlename="Physical Health Status"
            values={props?.othersProfileData?.physicalStatus}
          />
        )}
        {props?.othersProfileData?.smoking && (
          <BasicInfoColumn
            titlename="Smoking"
            values={props?.othersProfileData?.smoking}
          />
        )}
        {props?.othersProfileData?.beard && (
          <BasicInfoColumn
            titlename="Beard"
            values={props?.othersProfileData?.beard}
          />
        )}
      </div>
    </div>
  );
}
