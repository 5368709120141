import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Pagination } from "antd";

import {
  SearchAction,
  InterestAction,
  PhotoreqAction,
} from "../../store/actions";

import Cardloader from "../../components/loader/cardloader";

import Spacer from "../../components/spacer/spacer";
import DashboardCard from "../../components/dashboardcard/dashboardcard";
import CardBottomButton from "../../components/dashboardcard/cardbottombutton";
import { VioletButton } from "../../components/button";

import images from "../../assets";

import "./styles.css";

const RecommendedMatches = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile?.profile);
  const { recommendedMatches, recommendedCount, isRecommendedLoading } =
    useSelector((state) => state.search);

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(8);

  useEffect(() => {
    const payload = {
      username: profileData?.username,
      from: 0,
      size: 8,
    };
    if (profileData?.username) {
      dispatch(SearchAction.recommendedSearch(payload));
    }
  }, [profileData]);

  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
    const payload = {
      username: profileData?.username,
      from: (pageNumber - 1) * pageSize,
      size: pageSize,
    };
    dispatch(SearchAction.recommendedSearch(payload));
  };

  const handleSendInterest = (id) => {
    const payload = {
      receiverId: id,
    };
    dispatch(InterestAction.sendInterest(payload));
  };

  const handleSendPhotoReq = (id) => {
    const payload = {
      receiverId: id,
    };
    dispatch(PhotoreqAction.sendPhotoreq(payload));
  };

  return (
    <div>
      <div className="recommended-container">
        <div className="header-container">
          <h1 className="section-heading">Recommended Matches</h1>

          <VioletButton
            onClick={() => navigate("/match-preference")}
            text="Improve Match"
          />
        </div>
        {isRecommendedLoading ? (
          <Cardloader />
        ) : (
          <>
            {recommendedMatches?.length > 0 ? (
              <div className="recommended-matches-container">
                {recommendedMatches?.map((item, index) => (
                  <DashboardCard
                    item={item}
                    index={index}
                    key={index}
                    redirectedFrom="recommendedSearch"
                  >
                    {item?.matchOfTheDay && (
                      <CardBottomButton icon={images.cardButtonTick} />
                    )}
                    <CardBottomButton
                      icon={images.cardMessage}
                      onClick={() =>
                        navigate(
                          `/messages?username=${item?.username}&firstName=${item?.firstName}&lastName=${item?.lastName}&photo=${item?.profilePicture}`
                        )
                      }
                      title="Send Message"
                    />
                    <CardBottomButton
                      icon={images.cardPhotoReq}
                      onClick={() => handleSendPhotoReq(item?.username)}
                      title="Send Photo Request"
                    />
                    <CardBottomButton
                      icon={images.cardmatch}
                      onClick={() => handleSendInterest(item?.username)}
                      title="Send Interest"
                    />
                  </DashboardCard>
                ))}
              </div>
            ) : (
              <div>No Results found</div>
            )}
          </>
        )}
        <Spacer height="36px" />
        <div className="flex justify-center items-center">
          <Pagination
            current={pageNo}
            total={recommendedCount}
            defaultPageSize={pageSize}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </div>
        <Spacer height="12px" />
      </div>
    </div>
  );
};

export default RecommendedMatches;
