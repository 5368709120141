import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, Popover, Space } from "antd";
import { SearchAction } from "../../../store/actions";
import useScreenSize from "../../../utils/usescreensize";
import Searchbarloader from "../../loader/searchbarloader";
import images from "../../../assets";
import "./styles.css";
import BasicSearchItems from "./sidebar/basicsreachitems";
import DefaultItems from "./sidebar/defaultitems";

const SearchField = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const screenSize = useScreenSize();
  const location = useLocation();

  const { quickMatches, isQuickLoading, quickSearchPayload } = useSelector(
    (state) => state?.search
  );
  const gender = useSelector((state) => state.profile?.profile?.gender);

  const [matchedUsers, setMatchedUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [open, setOpen] = useState(false);
  const [basicSearchMenu, setBasicSearchMenu] = useState(false);
  const [popoverWidth, setPopoverWidth] = useState(null);

  const searchBarRef = useRef(null);

  useEffect(() => {
    if (location?.pathname?.includes("/basic-search")) {
      setBasicSearchMenu(true);
    } else {
      setBasicSearchMenu(false);
    }
  }, [location?.pathname]);

  useEffect(() => {
    setMatchedUsers(quickMatches);
  }, [quickMatches]);

  useEffect(() => {
    const payload = {
      ...quickSearchPayload,
      searchText: search,
      gender,
    };
    dispatch(SearchAction.quickSearchPayload(payload));
  }, [search]);

  useEffect(() => {
    if (search?.length > 2) {
      const payload = { ...quickSearchPayload, gender };
      if (gender) {
        dispatch(SearchAction.quickSearch(payload));
      }
    }
  }, [quickSearchPayload, matchedUsers.length]);

  useEffect(() => {
    const updatePopoverWidth = () => {
      if (searchBarRef.current) {
        setPopoverWidth(searchBarRef.current.offsetWidth - 20);
      }
    };
    updatePopoverWidth();
    window.addEventListener("resize", updatePopoverWidth);
    return () => window.removeEventListener("resize", updatePopoverWidth);
  }, [isFocused]);

  const handleSearch = (e) => {
    setSearch(e);
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const searchContent = (
    <div className="w-full">
      {isQuickLoading ? (
        <div style={{ margin: "0px auto", padding: "20px" }}>
          <Searchbarloader />
        </div>
      ) : (
        <>
          {search?.length <= 2 ? (
            <div>
              <h4
                style={{ padding: "20px", fontSize: "14px", fontWeight: "500" }}
              >
                Type at least three characters to search
              </h4>
              <hr />
              <div className="see-all-text-container stand-search-mobile">
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#8993A4",
                  }}
                >
                  Use{" "}
                  <span
                    className="purple-text underline text-secondary cursor-pointer"
                    onClick={() => {
                      showDrawer();
                      navigate("/basic-search");
                    }}
                  >
                    Standard Search
                  </span>{" "}
                  instead
                </p>
              </div>
            </div>
          ) : matchedUsers.length > 0 ? (
            <div style={{ maxHeight: "400px", overflowX: "auto" }}>
              {matchedUsers?.map((user, index) => (
                <div
                  key={index}
                  className="result-items"
                  onClick={() =>
                    navigate(`/view-profile/${user?.username}?from=quickSearch`)
                  }
                >
                  <img
                    src={
                      user?.profilePicture && user?.profilePicture !== "null"
                        ? user?.profilePicture
                        : user?.gender === "male"
                        ? images.defaultMaleIcon
                        : images.defaultFemaleIcon
                    }
                    className="result-items-image"
                    alt="user"
                  />
                  <div className="result-items-text-container text-wrap">
                    <p style={{ fontSize: "14px", fontWeight: "400" }}>
                      {user.firstName} {user.lastName}
                    </p>
                    <p style={{ fontSize: "12px", color: "#8993A4" }}>
                      {user.stateProvince}, {user.country}
                    </p>
                  </div>
                </div>
              ))}
              <hr />
              <div className="see-all-text-container">
                <p
                  className="purple-text underline text-secondary cursor-pointer"
                  onClick={() => navigate("/search-results")}
                >
                  See All
                </p>
              </div>
            </div>
          ) : (
            <div>
              <h4
                style={{ padding: "20px", fontSize: "16px", fontWeight: "500" }}
              >
                No users found
              </h4>
              <hr />
              <div className="see-all-text-container">
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#8993A4",
                  }}
                >
                  Use{" "}
                  <span
                    className="purple-text"
                    onClick={() => {
                      showDrawer();
                      navigate("/basic-search");
                    }}
                    style={{ color: "#BA0060", textDecoration: "underline" }}
                  >
                    Standard Search
                  </span>{" "}
                  instead
                </p>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );

  return (
    <div className="header-searchbar-container" ref={searchBarRef}>
      {screenSize.width < 1023 && (
        <Drawer
          className="standard-search-drawer"
          closable={false}
          mask={false}
          placement="left"
          width="100%"
          onClose={onClose}
          open={open}
          extra={<Space />}
        >
          <BasicSearchItems onClick={onClose} setOpen={setOpen} />
        </Drawer>
      )}
      <Popover
        content={searchContent}
        trigger="click"
        arrow={false}
        overlayInnerStyle={{
          padding: "0px",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
          width: popoverWidth || "auto",
        }}
        open={isFocused}
        overlayStyle={{
          top:
            screenSize.width < 1023
              ? props?.promotionHeader
                ? "118px"
                : "58px"
              : props?.promotionHeader
              ? "125px"
              : "65px",
        }}
        // onOpenChange={handleDropdownVisibleChange}
        placement="bottomLeft"
      >
        <div
          className={
            isFocused ? "header-searchbar-focused" : "header-searchbar"
          }
        >
          <img src={images.headerSearch} alt="search" />
          <input
            type="text"
            placeholder="Search"
            className="header-searchbar-input"
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setTimeout(() => setIsFocused(false), 500)}
          />
          {search && (
            <img
              src={images.clearSearchfield}
              alt="clear"
              onClick={() => setSearch("")}
            />
          )}
          {!search && isFocused && (
            <img
              src={images.searchCross}
              alt="clear"
              onClick={() => setIsFocused(false)}
            />
          )}
        </div>
      </Popover>
    </div>
  );
};

export default SearchField;
