import images from "../../assets";
export default function AboutMeContact(props) {
  return (
    <div className="about-number-area">
      <div className="phone-call-icon">{props?.children}</div>
      {!props?.viewed && (
        <div className="hide-show-number">
          <img src={images.infoLockIcon} alt="" />
        </div>
      )}
    </div>
  );
}
