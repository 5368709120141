import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Country } from "country-state-city";

import ModalForm from "../../components/modalform/modalform";
import EditProfilePhoneInput from "../../components/phoneinput/editprofilephoneinput";

const countries = Country.getAllCountries();

const PhoneForm = (props) => {
  const profileData = useSelector((state) => state?.profile?.profile);

  const [countryCode, setCountryCode] = useState("");

  useEffect(() => {
    const countryCode = countries?.filter(
      (country) => country?.name === profileData?.country
    );
    setCountryCode(countryCode[0]?.isoCode);
  }, [profileData]);

  return (
    <ModalForm
      titletext="Change Phone Number"
      subtitle={[
        "Enter your new phone number.",
        <br />,
        " We'll send you an OTP to verify.",
      ]}
      btntext="Send OTP"
      onClick={props?.handlePhoneSubmit}
    >
      <div>Phone</div>
      <EditProfilePhoneInput
        placeholder="Please enter your new phone number"
        country={countryCode?.toLowerCase()}
        onChange={(e) => props?.setPhone(e)}
      />
    </ModalForm>
  );
};

export default PhoneForm;
