import React from "react";
import BlurredImage from "../../components/blurimagewhileloading/blurimagewhileloading";

import "./styles.css";

const TabHeading = ({ className, image, title, subtitle }) => {
  return (
    <div className="edit-profile-content-heading">
      <div className="edit-profile-content-heading-image-container">
        <BlurredImage className={className} src={image} alt="img" />
      </div>
      <h3 className="edit-profile-content-heading-title">{title}</h3>
      <h5 className="edit-profile-content-heading-subtitle">{subtitle}</h5>
    </div>
  );
};

export default TabHeading;
