import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Popover, Modal, Input } from "antd";

import {
  InterestAction,
  PhotoreqAction,
  ShortlistAction,
  BlocklistAction,
  MyNotesAction,
} from "../../../store/actions";

import CardBottomButton from "../../dashboardcard/cardbottombutton";
import { PrimaryButton, WhiteButton } from "../../button";
import Spacer from "../../spacer/spacer";

import EditProfileDropdown from "../../dropdown/editprofiledropdown";

import images from "../../../assets";

const { TextArea } = Input;
const { confirm } = Modal;

export default function ProfileCompletion() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { photoreq } = useSelector((state) => state.photoreq);
  const { interests } = useSelector((state) => state.interests);
  const { shortlists } = useSelector((state) => state.shortlists);
  const profileData = useSelector((state) => {
    return state?.othersProfile?.profile;
  });

  const [shortlisted, setShortlisted] = useState(false);
  const [sentInterest, setsentInterest] = useState(false);
  const [sentPhotoreq, setsentPhotoreq] = useState(false);
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [note, setNote] = useState("");

  useEffect(() => {
    getNote();
    dispatch(
      ShortlistAction.getShortlist({
        filter: "Shortlisted by Me",
      })
    );
  }, []);

  useEffect(() => {
    const interestSent = interests?.some(
      (profile) => profile.username === profileData?.username
    );
    setsentInterest(interestSent);

    const photoreqSent = photoreq?.some(
      (profile) => profile.username === profileData?.username
    );
    setsentPhotoreq(photoreqSent);
    // const sentshortlist = shortlists?.some(
    //   (profile) => profile.username === profileData?.username
    // );
    // setShortlisted(sentshortlist);
  }, [interests, photoreq, shortlists]);

  const getNote = () => {
    const payload = { username: profileData?.username };
    dispatch(
      MyNotesAction.getNote(payload, (res) => {
        console.log(res);
      })
    );
  };
  const handleSendNote = () => {
    setIsNoteModalOpen(false);
    const payload = { note, receiverId: profileData?.username };
    dispatch(MyNotesAction.setNotes(payload));
  };
  const handleSendInterest = (id) => {
    const payload = {
      receiverId: id,
    };
    dispatch(InterestAction.sendInterest(payload));
  };

  const handleSendPhotoReq = (id) => {
    const payload = {
      receiverId: id,
    };
    dispatch(PhotoreqAction.sendPhotoreq(payload));
  };

  const handleShortlist = (id) => {
    const payload = {
      receiverId: id,
    };
    dispatch(ShortlistAction.sendShortlist(payload));
  };

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const BlockUserConfirm = () => {
    confirm({
      centered: true,
      icon: <img src={images.deleteIcon} alt="Block" />,
      content: `Are you sure you want to Block ${profileData?.firstName} ${profileData?.lastName}`,
      okText: "Block",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        const payload = { blockedId: profileData?.username };
        dispatch(
          BlocklistAction.updateBlocklist(payload, () => {
            dispatch(
              BlocklistAction.getBlocklist({
                from: 0,
                size: 8,
              })
            );
            navigate("/block-list");
          })
        );
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <div className="other-profile-completion">
        <div className="other-profile-completion-bottom">
          <CardBottomButton
            icon={images.cardButtonTick}
            onClick={() => handleShortlist(profileData?.username)}
            title="Shortlist"
            disabled={shortlisted}
          />
          <CardBottomButton
            icon={images.cardMessage}
            onClick={() =>
              navigate(
                `/messages?username=${profileData?.username}&firstName=${profileData?.firstName}&lastName=${profileData?.lastName}&photo=${profileData?.profilePicture}`
              )
            }
            title="Send Message"
          />
          <CardBottomButton
            icon={images.cardPhotoReq}
            onClick={() => handleSendPhotoReq(profileData?.username)}
            title="Send Photo Request"
            disabled={sentPhotoreq}
          />
          <CardBottomButton
            icon={images.cardmatch}
            onClick={() => handleSendInterest(profileData?.username)}
            title="Send Interest"
            disabled={sentInterest}
          />

          <Popover
            style={{
              width: "240px",
            }}
            content={
              <div className="other-user-activity">
                <div
                  className="other-user-content"
                  onClick={() => setIsNoteModalOpen(true)}
                >
                  <img src={images.personalNote} alt="" />
                  <p>My personal note</p>
                </div>
                <div onClick={BlockUserConfirm} className="other-user-content">
                  <img src={images.blockUser} alt="" />
                  <p>
                    Block {profileData?.firstName}&nbsp;
                    {profileData?.lastName}
                  </p>
                </div>
                <div
                  onClick={() => setIsModalOpen(true)}
                  className="other-user-content"
                >
                  <img src={images.reportUser} alt="" />
                  <p>Report Activity</p>
                </div>
                <div onClick={hide} className="other-user-content">
                  <img src={images.cancelUser} alt="" />
                  <p>Cancel</p>
                </div>
              </div>
            }
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
            placement="bottomRight"
            arrow={false}
          >
            <div className="three-dotted-btn">
              <img src={images.threeDotted} alt="" />
            </div>
          </Popover>
        </div>
      </div>

      <Modal
        centered={true}
        open={isNoteModalOpen}
        onCancel={() => isNoteModalOpen(false)}
        footer={false}
        closable={false}
        className="forgot-password-modal"
      >
        <h1
          style={{
            position: "absolute",
            top: "10px",
          }}
          className="mynote-header"
        >
          My Notes
        </h1>
        <img
          src={images?.closeIcon}
          className="modal-close-icon"
          alt=""
          onClick={() => setIsNoteModalOpen(false)}
        />
        <div className="user-report-user-content">
          <div className="user-report-body">
            <div className="user-report-heading">
              <p
                style={{
                  color: "#222",
                  fontFamily: " Poppins",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Add your personal note about {profileData?.firstName}&nbsp;
                {profileData?.lastName}
              </p>
            </div>
            <div className="user-report-message">
              <TextArea
                showCount
                maxLength={1000}
                rows="10"
                placeholder="Type your personal note ..."
                onChange={(e) => setNote(e.target.value)}
                value={note}
              />
            </div>
          </div>
          <Spacer height="48px" />
          <div className="user-report-footer">
            <div className="user-report-btn">
              <WhiteButton
                style={{
                  border: "2px solid var #EAE6ED",
                }}
                text={"Close"}
                onClick={() => setIsNoteModalOpen(false)}
              />
              <PrimaryButton text={"Save"} onClick={handleSendNote} />
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        centered={true}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={false}
        closable={false}
        className="forgot-password-modal"
      >
        <img
          src={images?.closeIcon}
          className="modal-close-icon"
          alt=""
          onClick={() => setIsModalOpen(false)}
        />
        <div className="user-report-user-content">
          <div className="user-report-body">
            <div className="user-report-heading">
              <h1>
                Report {profileData?.firstName}&nbsp;
                {profileData?.lastName}’s Activity
              </h1>
            </div>
            <div className="user-report-inputselect">
              <EditProfileDropdown placeholder="Please Select" />
            </div>
            <div className="user-report-message">
              <TextArea
                style={{
                  width: "100%",
                  outline: "none",
                  background: "transparent",
                }}
                showCount
                maxLength={1000}
                rows="10"
                placeholder="Provide additional information ..."
              />
            </div>
          </div>
          <Spacer height="48px" />
          <div className="user-report-footer">
            <div className="user-report-btn">
              <WhiteButton
                style={{
                  height: "40px",
                  width: "120px",
                  borderRadius: "12px",
                  border:
                    "2px solid var(--Color-Content-button-bg-white, #EAE6ED)",
                }}
                text={"Close"}
                onClick={() => setIsModalOpen(false)}
              />
              <PrimaryButton
                style={{
                  height: "40px",
                  width: "120px",
                }}
                text={"Save"}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
