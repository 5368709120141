import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Switch } from "antd";
import PrimaryButton from "../button/primarybutton";
import { ProfileAction } from "../../store/actions";
import Loader from "../loader/loader";

import images from "../../assets";
import "./styles.css";

export default function AboutMeCard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profileLoading = useSelector((state) => state?.profile?.isLoading);
  const profileData = useSelector((state) => state?.profile?.profile);

  const handleContactHidden = (e) => {
    const payload = {
      isContactHidden: e,
    };
    dispatch(
      ProfileAction.updateProfile(payload, () => {
        dispatch(ProfileAction.getProfile());
      })
    );
  };

  return (
    <div className="about-me-card">
      <div className="about-me-main">
        <div className="about-me">
          <h2>About Me</h2>
          <p>Profile created by {profileData?.profileCreatedBy}</p>
        </div>
        <div className="edit-profile">
          <div
            onClick={() => navigate("/edit-profile")}
            className="edit-icon-text"
          >
            <PrimaryButton
              text="Edit Profile"
              icon={images.editIcon}
              iconalign="left"
              style={{
                width: "auto",
                paddingLeft: "12px",
                paddingRight: "12px",
                height: "32px",
                borderRadius: "8px",
                fontSize: "14px",
                fontWeight: "500",
              }}
            />
          </div>
        </div>
      </div>

      <div className="about-me-number-email">
        {profileLoading ? (
          <Loader />
        ) : (
          <>
            {profileData?.phone && (
              <>
                <div className="about-number-area">
                  <div className="phone-call-icon">
                    <img src={images.phoneCallIcon} alt="" />
                    <p>{profileData?.phone}</p>
                  </div>
                  <div className="text-right w-[200px]">
                    <Switch
                      defaultChecked={profileData?.isContactHidden}
                      onChange={(e) => {
                        handleContactHidden(e);
                      }}
                      disabled={profileData?.gender === "male"}
                    />
                    <div className="text-[10px]">
                      {profileData?.gender === "male"
                        ? "Contact number visibility cannot be hidden for male users."
                        : "Hide My Number"}
                    </div>
                  </div>
                </div>
                <hr />
              </>
            )}
            {profileData?.email && (
              <>
                <div className="about-me-email">
                  <div className="about-me-email-icon">
                    <img src={images.emailIcon} alt="" />
                    <p>{profileData?.email}</p>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>

      <div className="about-me-desc-area">
        {profileData?.profileDescription ? (
          <p className="about-me-content">{profileData?.profileDescription}</p>
        ) : (
          <div className="text-secondary font-semibold text-[14px]">
            Your profile description is currently empty. Add a few details about
            yourself to help others get to know you better!
          </div>
        )}
      </div>
    </div>
  );
}
