import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { Modal } from "antd";

import {
  ProfileAction,
  BlocklistAction,
  NewMembersAction,
  MyNotesAction,
  SearchAction,
  SubscriptionAction,
  ShortlistAction,
  InterestAction,
  PhotoreqAction,
  ProfileViewerAction,
  ContactViewerAction,
  NotificationAction,
} from "../../../store/actions";

import useScreenSize from "../../../utils/usescreensize";

import Sidebar from "./sidebar/sidebar";
import NavHeader from "./navheader";
import BottomBarMenu from "../../bottombarmenu/bottombarmenu";
import PromotionHeader from "./promotionheader";

import "./styles.css";

const DashboardLayout = ({ children }) => {
  const screenSize = useScreenSize();
  const dispatch = useDispatch();
  const location = useLocation();

  const pathname = location.pathname;

  const user = useSelector((state) => state.auth?.user);
  const profileData = useSelector((state) => state.profile?.profile);

  const [showBottomBar, setShowBottomBar] = useState(true);
  const [promotionHeader, setPromotionHeader] = useState(false);
  const [isPromotionModalOpen, setIsPromotionModalOpen] = useState(false);

  useEffect(() => {
    if (location.pathname.includes("dashboard")) {
      setIsPromotionModalOpen(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    dispatch(
      ProfileAction.getProfile({}, (res) => {
        if (res?.activePlan) {
          dispatch(
            SubscriptionAction.getSubscriptionInfo({
              transactionId: res?.activePlan?.transactionId,
            })
          );
        }
      })
    );
  }, [user]);

  useEffect(() => {
    const payloadInterest = {
      filter: "Pending",
      searchId: profileData?.username,
    };
    const payloadPhotoReq = {
      filter: "Pending",
    };
    const payloadShortlist = {
      filter: "Shortlisted Me",
    };

    const payloadContactView = {
      filter: "My Contact Viewers",
    };
    const payloadGeneral = {
      from: 0,
    };
    dispatch(InterestAction.getInterestsAll(payloadInterest));
    dispatch(PhotoreqAction.getPhotoreqAll(payloadPhotoReq));
    dispatch(ShortlistAction.getShortlistAll(payloadShortlist));
    dispatch(BlocklistAction.getBlocklist(payloadGeneral));
    dispatch(MyNotesAction.getNotes(payloadGeneral));
    dispatch(NewMembersAction.getNewMembers(payloadGeneral));
    dispatch(ProfileViewerAction.getAllProfileViewer(payloadGeneral));
    dispatch(ProfileViewerAction.getProfileViewer(payloadGeneral));
    dispatch(ContactViewerAction.getContactView(payloadContactView));
    dispatch(ContactViewerAction.getAllContactView(payloadContactView));
    dispatch(NotificationAction.getNotifications());
  }, [profileData]);

  useEffect(() => {
    dispatch(SubscriptionAction?.getPaymentPlan());
  }, []);

  useEffect(() => {
    const payload = {
      username: profileData?.username,
      from: 0,
      size: 8,
    };
    if (profileData?.username) {
      dispatch(SearchAction.recommendedSearch(payload));
      dispatch(SearchAction.getMod());
    }
  }, [profileData]);

  useEffect(() => {
    if (
      pathname.includes("/edit-profile") ||
      pathname.includes("/view-profile") ||
      pathname.includes("/match-preference") ||
      pathname.includes("/gallery") ||
      pathname.includes("/trust-badge")
    ) {
      setShowBottomBar(false);
    } else {
      setShowBottomBar(true);
    }
  }, [pathname]);

  return (
    <div className={promotionHeader ? "dashboard-layout-promotion" : ""}>
      {promotionHeader && <PromotionHeader />}
      <div
        className="dashboard-layout"
        style={{ height: promotionHeader ? "calc(100vh - 60px)" : "100vh" }}
      >
        {screenSize.width > 1024 && (
          <Sidebar promotionHeader={promotionHeader} />
        )}
        <div className="dashboard-right-side">
          <NavHeader promotionHeader={promotionHeader} />
          <div
            className="content"
            style={{
              height: promotionHeader
                ? "calc(100vh - 140px)"
                : "calc(100vh - 80px)",
            }}
          >
            <Outlet />
            {children}
          </div>
        </div>
        {screenSize.width < 1024 && showBottomBar && (
          <>
            <div style={{ height: "60px" }}></div>
            <BottomBarMenu />
          </>
        )}
      </div>
      <Modal
        centered={true}
        open={isPromotionModalOpen}
        onCancel={() => setIsPromotionModalOpen(false)}
        footer={false}
        closable={true}
        autoFocusButton
      >
        Promotion Modal
      </Modal>
    </div>
  );
};

export default DashboardLayout;
