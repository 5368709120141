import {
  CONFIRM_PASSWORD,
  CONFIRM_PASSWORD_SUCCESS,
  CONFIRM_PASSWORD_FAILURE,
  EMAIL_CHANGE_REQ,
  EMAIL_CHANGE_REQ_SUCCESS,
  EMAIL_CHANGE_REQ_FAILURE,
  CONFIRM_OTP_RECOVER,
  CONFIRM_OTP_RECOVER_SUCCESS,
  CONFIRM_OTP_RECOVER_FAILURE,
  RESEND_OTP,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_FAILURE,
  EMAIL_CHANGE_VERIFY,
  EMAIL_CHANGE_VERIFY_SUCCESS,
  EMAIL_CHANGE_VERIFY_FAILURE,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  LOGIN,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  LOADER_FALSE,
  LOADER_TRUE,
} from "../constants";

const initialState = {
  isLoggedIn: localStorage.getItem("authUser") ? true : false,
  user: localStorage.getItem("authUser")
    ? JSON.parse(localStorage.getItem("authUser"))
    : {},
  isLoading: false,
  isEmailLoading: false,
  isPasswordLoading: false,
  error: null,
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
    case CONFIRM_OTP_RECOVER:
    case RESEND_OTP:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case EMAIL_CHANGE_REQ:
    case EMAIL_CHANGE_VERIFY:
      state = {
        ...state,
        isEmailLoading: true,
        error: null,
      };
      break;
    case FORGOT_PASSWORD:
    case CONFIRM_PASSWORD:
      state = {
        ...state,
        isPasswordLoading: true,
        error: null,
      };
      break;
    case CONFIRM_OTP_RECOVER_SUCCESS:
    case RESEND_OTP_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        error: null,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        user: { ...action.payload },
        isLoggedIn: true,
        isLoading: false,
      };
      break;
    case EMAIL_CHANGE_REQ_SUCCESS:
    case EMAIL_CHANGE_VERIFY_SUCCESS:
      state = {
        ...state,
        isEmailLoading: false,
      };
      break;
    case FORGOT_PASSWORD_SUCCESS:
    case CONFIRM_PASSWORD_SUCCESS:
      state = {
        ...state,
        isPasswordLoading: false,
      };
      break;
    case LOGOUT_SUCCESS:
      state = {
        ...state,
        user: {},
        isLoggedIn: false,
        isLoading: false,
      };
      break;
    case LOGIN_FAILURE:
    case LOGOUT_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    case EMAIL_CHANGE_REQ_FAILURE:
    case EMAIL_CHANGE_VERIFY_FAILURE:
      state = {
        ...state,
        isEmailLoading: false,
        error: action.payload,
      };
      break;
    case CONFIRM_OTP_RECOVER_FAILURE:
    case RESEND_OTP_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    case FORGOT_PASSWORD_FAILURE:
    case CONFIRM_PASSWORD_FAILURE:
      state = {
        ...state,
        isPasswordLoading: false,
        error: action.payload,
      };
      break;

    case LOADER_TRUE:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case LOADER_FALSE:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    default:
      break;
  }

  return state;
}
