import React from "react";
import Spacer from "../../components/spacer/spacer";
import { TextInput } from "../../components/inputbox";
import { PrimaryButton } from "../../components/button";

import images from "../../assets";

const EmailForm = (props) => {
  return (
    <>
      {/* <img src={images?.changePassword} alt="" /> */}
      <div className="change-email-head">
        <h2 className="change-email-title">Change Email</h2>
      </div>
      <div className="change-email-body">
        <p>Enter your new email address.<br />We'll send you an OTP to verify.</p>
        <Spacer height="32px" />
        <span style={{ color: "rgb(34, 34, 34)", fontSize: "14px", fontWeight: "400" }}>Email</span>
        <TextInput
          className="password-input"
          type="email"
          id="email"
          placeholder="Please enter your new email"
          onChange={(e) =>
            props?.setState({ ...props?.state, email: e.target.value, error: "" })
          }
        />
        {props?.state?.error && (
          <div className="text-center">
            <p className="text-danger font-semibold text-[14px] m-0">
              {props?.state?.error}
            </p>
          </div>
        )}
      </div>
      <div className="change-email-btn">
        <PrimaryButton text={"Send OTP"} onClick={props?.handleEmailSubmit} />
      </div>
    </>
  );
};

export default EmailForm;
