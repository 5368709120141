import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAILURE,
} from "../constants";

const initialState = {
  notifications: [],
  notificationCount: 0,
  isLoading: false,
  error: null,
};

export default function NotificationReducer(state = initialState, action) {
  switch (action.type) {
    case GET_NOTIFICATIONS:
    case UPDATE_NOTIFICATION:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_NOTIFICATIONS_SUCCESS:
      state = {
        ...state,
        notifications: action.payload.data,
        notificationCount: action.payload.count,
        isLoading: false,
      };
      break;
    case UPDATE_NOTIFICATION_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;

    case GET_NOTIFICATIONS_FAILURE:
    case UPDATE_NOTIFICATION_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }

  return state;
}
