import React from "react";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import "./styles.css";
const EditProfilePhoneInput = (props) => {
  return (
    <div className="edit-profile-input">
      <PhoneInput
        {...props}
        style={{
          opacity: props?.disabled ? "0.5" : "1",
          cursor: props?.disabled ? "not-allowed" : "",
        }}
      />
    </div>
  );
};

export default EditProfilePhoneInput;
