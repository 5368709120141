import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ProfileAction } from "../../store/actions";
import useScreenSize from "../../utils/usescreensize";
import { ProfileLayout } from "../../components/layout";
import PrimaryButton from "../../components/button/primarybutton";
import Spacer from "../../components/spacer/spacer";

import DocumentView from "./documentview";
import DocumentUpload from "./documentupload";

import images from "../../assets";
import "./styles.css";

const TrustBadge = () => {
  const dispatch = useDispatch();
  const screenSize = useScreenSize();

  const profileData = useSelector((state) => state?.profile?.profile);
  const trustbadge = useSelector((state) => state?.profile?.trustbadge);
  const trustbadgeStatus = useSelector(
    (state) => state?.profile?.profile?.trustbadgeStatus
  );

  useEffect(() => {
    if (profileData?.username) {
      dispatch(ProfileAction.getTrustBadge());
    }
  }, [profileData]);

  return (
    <>
      <ProfileLayout>
        <div className="edit-profile-container">
          {screenSize.width > 1023 && (
            <div className="edit-profile-header">
              <div className="trustbadge-btn">
                <PrimaryButton
                  text="Back"
                  icon={images.arrowIconLeft}
                  iconalign="left"
                  style={{
                    width: "auto",
                    paddingLeft: "12px",
                    paddingRight: "12px",
                    height: "32px",
                    borderRadius: "8px",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  onClick={() => window.history.back()}
                />
              </div>
              <h2 className="edit-profile-heading">Trust Badge</h2>
            </div>
          )}
          <Spacer height="32px" />
          <div className="trustbadge-content-container">
            <div className="edit-profile-content-heading">
              <img
                className="trustbadge-icon"
                src={images.trustBadgeIcon}
                alt="img"
              />

              <h3 className="edit-profile-content-heading-title trust-badge-title">
                {trustbadgeStatus === "approved"
                  ? "Congratulations!"
                  : "Verify Your Profile & Get the trust badge"}
              </h3>
              <h5 className="edit-profile-content-heading-subtitle">
                {trustbadgeStatus === "approved" ? (
                  "your trust badge has been approved by the admins and you are now a verified user."
                ) : (
                  <>
                    {trustbadgeStatus === "pending"
                      ? "You have already submitted"
                      : "Submit the"}{" "}
                    proof of your identification. If you do not hear from us
                    within 24 hours, please send an email with your username or
                    user ID to
                    <span> support@noblemarriage.com</span>
                  </>
                )}
              </h5>
            </div>
            {trustbadgeStatus === "pending" && (
              <DocumentView trustbadge={trustbadge} />
            )}

            {trustbadge?.length === 0 &&
              (trustbadgeStatus === "rejected" ||
                trustbadgeStatus === "false") && <DocumentUpload />}

            <Spacer height="60px" />
            <div className="trustbadge-footer">
              <div className="trustbadge-document-verified">
                <img src={images.documentVerified} alt="Document Verified" />
              </div>
              <div className="trustbadge-footer-content">
                <p>
                  This information you provide is only used for profile
                  verification and is not shared with other members or third
                  parties.
                </p>
              </div>
            </div>
            <Spacer height="17px" />
          </div>
        </div>
      </ProfileLayout>
    </>
  );
};

export default TrustBadge;
