import React from "react";
import { useSelector } from "react-redux";

import UpgradeNowCard from "./upgradenowcard";
import ExtendMembershipCard from "./extendmembershipcard";

const Billing = () => {
  const subscriptionData = useSelector((state) => state?.subscription);

  return (
    <>
      {!subscriptionData?.plan?.name ? (
        <UpgradeNowCard />
      ) : (
        <ExtendMembershipCard subdata={subscriptionData} />
      )}
    </>
  );
};

export default Billing;
