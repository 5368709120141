/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMessage = /* GraphQL */ `
  mutation CreateMessage($input: MessageInput!) {
    createMessage(input: $input) {
      id
      content {
        text
        sender
        __typename
      }
      createdAt
      updatedAt
      roomId
      status
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage($input: MessageInput!) {
    updateMessage(input: $input) {
      id
      content {
        text
        sender
        __typename
      }
      createdAt
      updatedAt
      roomId
      status
      __typename
    }
  }
`;
export const updateMessageStatuses = /* GraphQL */ `
  mutation UpdateMessageStatuses($input: UpdateMessageStatusInput!) {
    updateMessageStatuses(input: $input) {
      roomId
      __typename
    }
  }
`;
export const createRoom = /* GraphQL */ `
  mutation CreateRoom($input: RoomInput!) {
    createRoom(input: $input) {
      id
      senderId
      receiverId
      senderName
      senderImageUrl
      receiverName
      receiverImageUrl
      lastMessage
      lastSenderId
      lastMessageRead
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRoom = /* GraphQL */ `
  mutation UpdateRoom($input: RoomUpdateInput!) {
    updateRoom(input: $input) {
      id
      senderId
      receiverId
      senderName
      senderImageUrl
      receiverName
      receiverImageUrl
      lastMessage
      lastSenderId
      lastMessageRead
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserStatus = /* GraphQL */ `
  mutation UpdateUserStatus($username: String!, $onlineStatus: String!) {
    updateUserStatus(username: $username, onlineStatus: $onlineStatus) {
      username
      onlineStatus
      __typename
    }
  }
`;
