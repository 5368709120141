import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

import { persistor, store } from "./store";
import App from "./App";

import "./index.css";
import "antd/dist/reset.css";

const root = createRoot(document.getElementById("root"));

root.render(
  <Router basename="/">
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </Router>
);
