/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listRooms = /* GraphQL */ `
  query ListRooms($searchId: String, $limit: Int) {
    listRooms(searchId: $searchId, limit: $limit) {
      items {
        id
        senderId
        receiverId
        senderName
        senderImageUrl
        receiverName
        receiverImageUrl
        lastMessage
        lastSenderId
        lastMessageRead
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMessagesForRoom = /* GraphQL */ `
  query ListMessagesForRoom($roomId: ID, $sortDirection: ModelSortDirection) {
    listMessagesForRoom(roomId: $roomId, sortDirection: $sortDirection) {
      items {
        id
        content {
          text
          sender
          __typename
        }
        createdAt
        updatedAt
        roomId
        status
        __typename
      }
      nextToken
      __typename
    }
  }
`;
