const dummyConversation = [
  {
    message: "Hi.\n Are You There?",
    receiver: true,
    time: new Date(),
  },
  {
    message: "Walaikum assalam. I am fine. How are you?",
    receiver: false,
    time: new Date(),
    sent: true,
    received: true,
    failed: false,
  },
  {
    message: "I am fine, alhamdulillah.",
    receiver: true,
    time: new Date(),
  },
  {
    message: "What are you doing?",
    receiver: true,
    time: new Date(),
    sent: true,
    received: true,
    failed: false,
  },
  {
    message: "Nothing Special.",
    receiver: false,
    time: new Date(),
    sent: true,
    received: false,
    failed: false,
  },
  {
    message: "Nothing Special.",
    receiver: false,
    time: new Date(),
    sent: true,
    received: true,
    failed: true,
  },
];

export default dummyConversation;
