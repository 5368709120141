const books = [
  { label: "Quran", value: "Quran" },
  { label: "Hadith", value: "Hadith" },
  { label: "History", value: "History" },
  { label: "Islamic", value: "Islamic" },
  { label: "Fiction", value: "Fiction" },
  { label: "Tech", value: "Tech" },
  { label: "Adventure", value: "Adventure" },
  { label: "Romance", value: "Romance" },
  { label: "Art", value: "Art" },
  { label: "Novel", value: "Novel" },
  { label: "Lifestyle Magazines", value: "Lifestyle Magazines" },
  { label: "Islamic Study", value: "Islamic Study" },
  { label: "Writing", value: "Writing" },
];
export default books;
