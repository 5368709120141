export default function SenderMessageCard(props) {
  let messageParts = props?.textmessage?.split("\n");

  return (
    <div {...props} className="premium-message-body-receiver">
      <div className="premium-message-body-receiver-with">
        <div className="premium-message-body-main-receiver">
          <div className="premium-message-body-text-mess">
            {messageParts.map((part, index) => (
              <p key={index}>{part}</p>
            ))}
          </div>
          <div className="premium-message-body-text-time-receiver">
            <p>{props.delivertime}</p>
            <img src={props.icon} alt="Send Success" />
          </div>
        </div>
      </div>
    </div>
  );
}
