function getImageName(url) {
  let bucketName = "";
  if (!url || url === "null" || url === "undefined") {
    return bucketName;
  } else {
    bucketName = url.replace(/^.*\.com\//, "");
    bucketName = bucketName.split("?")[0];

    return bucketName;
  }
}
export default getImageName;
