import { COLLAPSED, COLLAPSED_SUCCESS, COLLAPSED_FAILURE } from "../constants";

export default class UiAction {
  static setCollapsed(payload, cb) {
    return {
      type: COLLAPSED,
      payload,
      cb,
    };
  }
  static setCollapsedSuccess(payload) {
    return {
      type: COLLAPSED_SUCCESS,
      payload,
    };
  }
  static setCollapsedFailure() {
    return {
      type: COLLAPSED_FAILURE,
    };
  }
}
